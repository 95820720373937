import * as React from "react";
import { Navigate } from "react-router-dom";
import { CONSTANT } from "../utils/constant";
import { DefaultLayout } from "../views/layouts/defaultLayout";
import AdminLeftMenu from "../views/layouts/adminLeftMenu";


const AdminAuth = ({ children }) => {
  let isLoggedIn = false;
  let role = "";
  let userData = localStorage.getItem('authorization');

  if (userData !== null && userData !== "undefined") {
    userData = JSON.parse(userData);
    role = userData?.roles[0]?.roleId && userData?.roles[0]?.roleId;
    isLoggedIn = true;
  }

  if ((isLoggedIn == true && role === CONSTANT.USER_ROLE.ADMIN) || (isLoggedIn == true && role === CONSTANT.USER_ROLE.LEADERSHIP) || (isLoggedIn == true && role === CONSTANT.USER_ROLE.BUYER) || (isLoggedIn == true && role === CONSTANT.USER_ROLE.SUPPLIER) || (isLoggedIn == true && role === CONSTANT.USER_ROLE.DESIGN)|| (isLoggedIn == true && role === CONSTANT.USER_ROLE.OPERATIONS)) {
    return ( 
      <>
        <DefaultLayout>
          {children}
        </DefaultLayout>
      </>
    );
  } else {
    return (<Navigate to="/login" />)
  }
};
export default AdminAuth;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

export const addUpdateSample = createAsyncThunk(
  "addUpdateSample",
  async (formData, { rejectWithValue }) => {      
    try {
      
      const response = await fetch(
       API_URLS.SAMPLE.ADD_UPDATE_SAMPLE,
        {
          method: "POST",
          body: formData
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllSamples = createAsyncThunk(
  "getAllSamples",
  async (data,{rejectWithValue})=>{
    
    try{
      let url = API_URLS.SAMPLE.GET_ALL_SAMPLES;
      url = url + "?page="+ data.page + "&limit="+ data.limit +"&search="+ data.filters.search +"&category="+ data.filters.category + "&factory="+ data.filters.factory +"&mill="+ data.filters.mill +"&season="+ data.filters.season + "&buyer=" + data.filters.buyer + '&supplierId=' + data.filters.supplierId + '&location=' + data.filters.location + '&employee=' + data.filters.employee;
      const response = await fetch(url);
      if(!response.ok) return rejectWithValue(response.statusText)
      const result =await response.json();
    return result;
    }catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSampleById = createAsyncThunk(
  "getSampleById",
  async (id,{rejectWithValue})=>{
    
    try{
      const response = await fetch(
        API_URLS.SAMPLE.GET_SAMPLE_BY_ID + "/"+ id,
       );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json()
      return result;
      
    }catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
);

export const deleteSelectedSamples = createAsyncThunk(
  "deleteSelectedSamples",
  async (data, { rejectWithValue }) => {  
    const idData = {ids:[data]}    
    try {
      const response = await fetch(
       API_URLS.SAMPLE.DELETE_SELECTED_SAMPLE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(idData),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const deleteSampleFile = createAsyncThunk(
  "deleteSampleImage",
  async (data, { rejectWithValue }) => {      
    try {
      const id = {id:data}
      const response = await fetch(
       API_URLS.SAMPLE.DELETE_SAMPLE_FILE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify(id) ,
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const addSampleImage = createAsyncThunk(
  "addSampleImage",
  async (data, { rejectWithValue }) => {      
    try {
      const response = await fetch(
       API_URLS.SAMPLE.ADD_SAMPLE_IMAGE,
        {
          method: "POST",
          body: data,
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editSampleFile = createAsyncThunk(
  "editSampleFile",
  async (data, { rejectWithValue }) => {      
    try {
      const fileData = {fileData:data};
      const response = await fetch(
       API_URLS.SAMPLE.EDIT_SAMPLE_FILE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify(fileData) ,
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendSamplesToSelectedMail = createAsyncThunk(
  "sendSamplesToSelectedMail",
  async (data, { rejectWithValue }) => {
    try {
      const data_mail = { mail: data }
      const response = await fetch(
        API_URLS.SAMPLE.Send_Samples_ToSelectedMail,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data_mail),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const exportAsPPT = createAsyncThunk(
  "exportAsPPT",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.SAMPLE.EXPORT_AS_PPT,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
import { createSlice } from "@reduxjs/toolkit";
import { addUpdateSample,getAllSamples,getSampleById,deleteSelectedSamples,deleteSampleFile,addSampleImage, editSampleFile,sendSamplesToSelectedMail, exportAsPPT } from "./action";


export const sample = createSlice({
  name: "sample",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
    allsamples: null,
    sample: null
  },

  reducers: {
    resetSample: (state, action) => {
      state.success = false
      state.actionName = null
      state.message = null
      state.sample = null
    },
  },

  extraReducers: {
    [addUpdateSample.pending]: (state) => {
      state.loading = true;
    },
    [addUpdateSample.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "addUpdateSample";
    },
    [addUpdateSample.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getAllSamples.pending]: (state) => {
      state.loading = true;
    },
    [getAllSamples.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.allsamples = action.payload.sample;
      state.actionName = "getAllSamples"
    },
    [getAllSamples.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getSampleById.pending]: (state) => {
      state.loading = true;
    },
    [getSampleById.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.sample = action.payload.sampleDetail;
      state.actionName = "getSampleById"
    },
    [getSampleById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [deleteSelectedSamples.pending]: (state) => {
      state.loading = true;
    },
    [deleteSelectedSamples.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "deleteSelectedSamples"
    },
    [deleteSelectedSamples.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [deleteSampleFile.pending]: (state) => {
      state.loading = true;
    },
    [deleteSampleFile.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "deleteSampleFile"
    },
    [deleteSampleFile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [addSampleImage.pending]: (state) => {
      state.loading = true;
    },
    [addSampleImage.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "addSampleImage";
    },
    [addSampleImage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [editSampleFile.pending]: (state) => {
      state.loading = true;
    },
    [editSampleFile.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "editSampleFile";
    },
    [editSampleFile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [sendSamplesToSelectedMail.pending]: (state) => {
      state.loading = true;
    },
    [sendSamplesToSelectedMail.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "sendSamples";
    },
    [sendSamplesToSelectedMail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [exportAsPPT.pending]: (state) => {
      state.loading = false;
    },
    [exportAsPPT.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      state.actionName = "exportAsPPT";
    },
    [exportAsPPT.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default sample.reducer;

export const { resetSample } = sample.actions;

import React from "react";

export const POInfoActionBtn = (props) => {
  const {
    isShow,
    handleRejectPO,
    poFactories,
    factoryMilestones,
    handleAcceptPO,
    index2,
    index
  } = props;

  const avoidOpenAccordian = (e) => {
    e.stopPropagation();
  }

  return (
    <>
      {isShow(poFactories, factoryMilestones, "first_time_date_add") && (
        <td>
          <button
            className="btn btn-date"
            onClick={(e) => handleRejectPO(e, poFactories, factoryMilestones, 'add_dates')}
            style={{ pointerEvents: 'auto' }}
          >
            Add Dates
          </button>
        </td>
      )}

      {isShow(poFactories, factoryMilestones, "both_accepted") && (
        <td className="pe-5">
          <label className="form-label ">Status</label>
          <div className="form-control text-success d-flex">
            Accepted
            <span className="ms-2 po-accodian-status">
              <div className="rounded-circle bg-success text-white d-flex justify-content-center align-items-center">
                <i className="fas fa-check" style={{ fontSize: "8px" }}></i>
              </div>
            </span>
          </div>
        </td>
      )}

      {isShow(poFactories, factoryMilestones, "show_rejected_admin") && (
        <td className="pe-5">
          <label className="form-label ">Status</label>
          <div className="form-control text-danger d-flex">
            Rejected
            <span className="ms-2 po-accodian-status">
              <div className="rounded-circle bg-danger text-white d-flex justify-content-center align-items-center">
                <i className="fas fa-times" style={{ fontSize: "8px" }}></i>
              </div>
            </span>
          </div>
        </td>
      )}

      {isShow(poFactories, factoryMilestones, "propose_new_date_admin") && (
        <td>
          <button
            className="btn btn-date"
            onClick={(e) => handleRejectPO(e, poFactories, factoryMilestones,'propose_new_date')}
            style={{ pointerEvents: 'auto', width: '150px' }}
          >
            Propose New Dates
          </button>
        </td>
      )}

      {isShow(poFactories, factoryMilestones, "show_rejected_factory") && (
        <td>
          <label className="form-label ">Status</label>
          <div className="form-control text-danger d-flex">
            Rejected by me
            <span className="ms-2 po-accodian-status">
              <div className="rounded-circle bg-danger text-white d-flex justify-content-center align-items-center">
                <i className="fas fa-times" style={{ fontSize: "8px" }}></i>
              </div>
            </span>
          </div>
        </td>
      )}

      {isShow(poFactories, factoryMilestones, "show_pending_status_admin") && (
        <td>
          <label className="form-label">Status</label>
          <div className="form-control text-info">
            Waiting for Factory response
          </div>
        </td>
      )}

      {isShow(
        poFactories,
        factoryMilestones,
        "show_pending_status_factory"
      ) && (
        <td>
          <label className="form-label">Status</label>
          <div className="form-control text-info">
            Waiting for Groyyo Team's response
          </div>
        </td>
      )}

      <td>
        <div className="row">
          <div className="col-6">
            {isShow(poFactories, factoryMilestones, "accept_admin") && (
              <div className="col-xl-1 col-lg-1 col-md-2 col-1">
                <label className="form-label">Accept</label>
                <br />
                <input
                  type="radio"
                  name={"accept" + index2}
                  className="form-check-input"
                  onClick={avoidOpenAccordian}
                  onChange={(e) => handleAcceptPO(e, factoryMilestones)}
                />
              </div>
            )}
          </div>
          <div className="col-6">
            {isShow(poFactories, factoryMilestones, "reject_admin") && (
              <div className="col-xl-1 col-lg-1 col-md-2 col-1">
                <label className="form-label">Reject</label>
                <br />
                <input
                  type="radio"
                  name={"accept" + index2 + '' + index}
                  className="form-check-input"
                  onClick={avoidOpenAccordian}
                  onChange={(e) =>
                    handleRejectPO(e, poFactories, factoryMilestones,'reject')
                  }
                />
              </div>
            )}
          </div>
        </div>
      </td>

      <td>
        <div className="row">
          <div className="col-6">
            {isShow(poFactories, factoryMilestones, "accept_factory") && (
              <div className="col-xl-1 col-lg-1 col-md-2 col-1">
                <label className="form-label">Accept</label>
                <br />
                <input
                  type="radio"
                  name={"accept" + index2}
                  className="form-check-input"
                  onClick={avoidOpenAccordian}
                  onChange={(e) => handleAcceptPO(e, factoryMilestones)}
                />
              </div>
            )}
          </div>
          <div className="col-6">
            {isShow(poFactories, factoryMilestones, "reject_factory") && (
              <div className="col-xl-1 col-lg-1 col-md-2 col-1">
                <label className="form-label">Reject</label>
                <br />
                <input
                  type="radio"
                  name={"accept" + index2 + '' + index}
                  className="form-check-input"
                  onClick={avoidOpenAccordian}
                  onChange={(e) =>
                    handleRejectPO(e, poFactories, factoryMilestones,'reject')
                  }
                />
              </div>
            )}
          </div>
        </div>
      </td>
    </>
  );
};

import React, { useEffect, useState } from "react";
import logoImg from "../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { CONSTANT } from "../../utils/constant";

const LeftMenu = () => {
  const [initial, setInitial] = useState("");
  const dispatch = useDispatch();
  const [userName, setUserName] = useState();
  const [roleName, setRoleName] = useState();
  const roleId = localStorage.getItem("roleId");

  // const [open, setOpen] = useState("");

  // useEffect(() => {
  //   let menuOpen = localStorage.getItem("menuopen");
  //   menuOpen === true ? setOpen("open") : setOpen("");
  // }, []);

  // const handleOpen = () => {
  //   if (open == "open") {
  //     setOpen("");
  //     localStorage.setItem("menuopen", false);
  //   } else {
  //     setOpen("open");
  //     localStorage.setItem("menuopen", true);
  //   }
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("authorization"));
    setUserName(
      userData?.user?.name ? userData?.user?.name : userData?.user?.emailId
    );
    setRoleName(userData?.roles[0]?.roleName);
    if (userData?.user?.name) {
      const firstLetter = userData?.user?.name.charAt(0).toUpperCase();
      setInitial(firstLetter);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <div className="left-fixed-menu">
      <div className="slide-left">
        <a
          href="#"
          className="logo"
        >
          <img src={logoImg} alt="Logo" />
        </a>
        <a data-tooltip-id="my-tooltip-inline"
          data-tooltip-content="Home" href="/rfq-list">
          <img src={logoImg} alt="slide-left" />
        </a>
      </div>
      <div className="icon1 menu">
        <a data-tooltip-id="my-tooltip-inline"
          data-tooltip-content="RFQ List" href="/rfq-list">
          <span className="icon align-middle"></span>
          <span className="name align-middle">RFQ List</span>
        </a>
      </div>
      <div className="icon2 menu">
        <a data-tooltip-id="my-tooltip-inline"
          data-tooltip-content="Sample List" href="/sample-list">
          <span className="icon align-middle"></span>
          <span className="name align-middle">Sample List</span>
        </a>
      </div>
      <div className="icon8 menu">
        <a data-tooltip-id="my-tooltip-inline"
          data-tooltip-content="PO List" href="/po-list">
          <span className="icon align-middle"></span>
          <span className="name align-middle">PO List</span>
        </a>
      </div>
      <div className="icon9 menu">
        <a data-tooltip-id="my-tooltip-inline"
          data-tooltip-content="TNA Template List" href="/template-list">
          <span className="icon align-middle"></span>
          <span className="name align-middle">Add Template</span>
        </a>
      </div>
      {localStorage.getItem("roleType") == "Internal" && (
        <div className="icon5 menu">
          <a data-tooltip-id="my-tooltip-inline"
            data-tooltip-content="User Dashboard" href="/user-dashboard">
            <span className="icon align-middle"></span>
            <span className="name align-middle">User Management</span>
          </a>
        </div>
      )}
      {(roleId == CONSTANT.USER_ROLE.ADMIN || roleId == CONSTANT.USER_ROLE.LEADERSHIP) && (
        <div className="cat-icon menu">
          <a data-tooltip-id="my-tooltip-inline"
            data-tooltip-content="Category" href="/all-categories">
            {/* <span className="icon align-middle"></span> */}
            <i className="fa fa-list"></i>
            <span className="name align-middle">Category List</span>
          </a>
        </div>
      )}
      <div className="icon3 menu">
        <a data-tooltip-id="my-tooltip-inline"
          data-tooltip-content="Dashboard" href="#">
          <span className="icon align-middle"></span>
          <span className="name align-middle">Dashboard</span>
        </a>
      </div>
      <div className="icon4 menu">
        <a data-tooltip-id="my-tooltip-inline"
          data-tooltip-content="Configuration" href="#">
          <span className="icon align-middle"></span>
          <span className="name align-middle">Configuration</span>
        </a>
      </div>
      <div className="icon6 menu">
        <a data-tooltip-id="my-tooltip-inline"
          data-tooltip-content="Pending Tasks" href="#">
          <span className="icon align-middle"></span>
          <span className="name align-middle">+ Pending Tasks</span>
        </a>
      </div>
      <div className="icon7 menu dropdown logout-btn">
        <button type="button" data-bs-toggle="dropdown" className="menu icon7">
          <div className="icon" data-initial={initial}></div>
        </button>

        <ul className="dropdown-menu logt-ul">
          <div className="dv-usrinfo">
            <div className="icon" data-initial={initial}></div>
            <div>
              <li className="spn-name">
                <span> {userName && userName}</span>
              </li>
              <li>
                <small>{roleName && roleName}</small>
              </li>
            </div>
          </div>
          <li className="li-itm"></li>
          <li>
            <a className="dropdown-item item1">Details</a>
          </li>
          <li>
            <a className="dropdown-item item2" onClick={handleLogout}>
              Log Out
            </a>
          </li>
        </ul>
      </div>
      <Tooltip
        id="my-tooltip-inline"
        style={{
          backgroundColor: "#FE4900",
          color: "white",
          fontWeight: "bolder",
        }}
      />
    </div>
  );
};

export default LeftMenu;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

export const getAllPOChats = createAsyncThunk(
  "getAllPOChats",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO_CHAT.GET_ALL_POCHATS,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const getAllPOChatMessages = createAsyncThunk(
  "getAllPOChatMessages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO_CHAT.GET_ALL_CHAT_MESSAGES,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveNewPOChatMessage = createAsyncThunk(
  "saveNewPOChatMessage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO_CHAT.SAVE_NEW_CHAT_MESSAGE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSinglePOChat = createAsyncThunk(
  "createSinglePOChat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO_CHAT.CREATE_SINGLE_CHAT,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createGroupPOChat = createAsyncThunk(
  "createGroupPOChat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO_CHAT.CREATE_CHAT_GROUP,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateGroupPOChat = createAsyncThunk(
  "updateGroupPOChat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO_CHAT.UPDATE_CHAT_GROUP,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);




import { createSlice } from "@reduxjs/toolkit";
import {
  addSampleToRfq, getSampleByRfqId, setRfqStatus, getRfqStatusByRfqId, addUpdateRfq, getAllRfq, getAllFactoryRfq,
  getRfqById, getUserByRoleType, releaseRfq, deleteRfqFile, getBidByRfqId, 
  replyBid,getRfqBySampleId,
  getUserByNameNRoleType,
  isRfqAssignee,
  getBidReplyDetails,acceptOneBid,getGeneratePo,addBulkRfq,
  showRfqDetailsToFactory,
  getShowRfqDetailsData
} from "./action";


export const rfq = createSlice({
  name: "rfq",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
    allRfq: null,
    rfqSampleList: null,
    rfqStatuses: null,
    rfqMessages: null,
    rfqChats: null,
    rfqData: null,
    samplesRfq:null,
    isAssignee: false,
    replyDetails: null,
    rfqDetailsVisibilityInfo: null
  },

  reducers: {
    resetRfq: (state, action) => {
      state.success = false
      state.actionName = null;
      state.message = null
    },
  },

  extraReducers: {
    [addUpdateRfq.pending]: (state) => {
      state.loading = true;
    },
    [addUpdateRfq.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "addUpdateRfq";
    },
    [addUpdateRfq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [addBulkRfq.pending]: (state) => {
      state.loading = true;
    },
    [addBulkRfq.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "addBulkRfq";
    },
    [addBulkRfq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getAllRfq.pending]: (state) => {
      state.loading = true;
    },
    [getAllRfq.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.allRfq = action.payload.rfq;
      state.actionName = "getAllRfq"
    },
    [getAllRfq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getAllFactoryRfq.pending]: (state) => {
      state.loading = true;
    },
    [getAllFactoryRfq.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.allRfq = action.payload.rfq;
      state.actionName = "getAllFactoryRfq"
    },
    [getAllFactoryRfq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getSampleByRfqId.pending]: (state) => {
      state.loading = true;
    },
    [getSampleByRfqId.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.rfqSampleList = action.payload.rfq;
      state.actionName = "getSampleByRfqId"
    },
    [getSampleByRfqId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [addSampleToRfq.pending]: (state) => {
      state.loading = true;
    },
    [addSampleToRfq.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message= action.payload.message;
      state.actionName = "addSampleToRfq"
    },
    [addSampleToRfq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getRfqById.pending]: (state) => {
      state.loading = true;
    },
    [getRfqById.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.rfqData = action.payload.rfq;
      state.actionName = "getRfqById"
    },
    [getRfqById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getUserByRoleType.pending]: (state) => {
      state.loading = true;
    },
    [getUserByRoleType.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.roleDetails = action.payload.roleDetails;
      state.actionName = "userByRoleType"
    },
    [getUserByRoleType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getRfqStatusByRfqId.pending]: (state) => {
      state.loading = true;
    },
    [getRfqStatusByRfqId.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.rfqStatuses = action.payload;
      state.actionName = "getRfqStatusByRfqId"
    },
    [getRfqStatusByRfqId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [setRfqStatus.pending]: (state) => {
      state.loading = true;
    },
    [setRfqStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "setRfqStatus";
    },
    [setRfqStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [releaseRfq.pending]: (state) => {
      state.loading = true;
    },
    [releaseRfq.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "releaseRFQ";
    },
    [releaseRfq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [deleteRfqFile.pending]: (state) => {
      state.loading = true;
    },
    [deleteRfqFile.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "deleteRfqFile"
    },
    [deleteRfqFile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getBidByRfqId.pending]: (state) => {
      state.loading = true;
    },
    [getBidByRfqId.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.bidList = action.payload;
      state.actionName = "getBidByRfqId"
    },
    [getBidByRfqId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [replyBid.pending]: (state) => {
      state.loading = true;
    },
    [replyBid.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "replyBid"
    },
    [replyBid.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getRfqBySampleId.pending]: (state) => {
      state.loading = true;
    },
    [getRfqBySampleId.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.samplesRfq = action.payload.rfq;
      state.actionName = "getRfqBySampleId"
    },
    [getRfqBySampleId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [isRfqAssignee.pending]: (state) => {
      state.loading = true;
    },
    [isRfqAssignee.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.isAssignee = action.payload.isAssignee;
      state.actionName = "isRfqAssignee"
    },
    [isRfqAssignee.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getBidReplyDetails.pending]: (state) => {
      state.loading = true;
    },
    [getBidReplyDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.replyDetails = action.payload.replyDetails;
      state.actionName = "getBidReplyDetails"
    },
    [getBidReplyDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [acceptOneBid.pending]: (state) => {
      state.loading = true;
    },
    [acceptOneBid.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.bidList = action.payload;
      state.actionName = "acceptOneBid"
    },
    [acceptOneBid.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getGeneratePo.pending]: (state) => {
      state.loading = true;
    },
    [getGeneratePo.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.checkStatus = action.payload;
      state.actionName = "getGeneratePo"
    },
    [getGeneratePo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [showRfqDetailsToFactory.pending]: (state) => {
      state.loading = true;
    },
    [showRfqDetailsToFactory.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "showRfqDetailsToFactory"
    },
    [showRfqDetailsToFactory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getShowRfqDetailsData.pending]: (state) => {
      state.loading = true;
    },
    [getShowRfqDetailsData.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.rfqDetailsVisibilityInfo = action.payload
      state.actionName = "getShowRfqDetailsData"
    },
    [getShowRfqDetailsData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default rfq.reducer;

export const { resetRfq } = rfq.actions;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { resetRfq } from "../../../features/rfq/slice";
import { notify } from "../../../features/notification/notify";
import { useDispatch, useSelector } from "react-redux";
import { getShowRfqDetailsData, showRfqDetailsToFactory } from "../../../features/rfq/action";

export const ShowHideRfq = (props) => {
    const { openModalFor,setOpenModalFor, rfqId, showHideRfqDetailsModal, setShowHideRfqDetailsModal } = props;
    const rfqResponse = useSelector((state) => state.rfq);
    const dispatch = useDispatch();
    useEffect(() => {
        if (rfqId && openModalFor) {
            dispatch(getShowRfqDetailsData({ rfqId, openModalFor }));
        }
    }, [rfqId, openModalFor]);
    const [rfqDetail, setRfqDetail] = useState()

    const handleCloseModal = () => {
        setShowHideRfqDetailsModal(false)
        setRfqDetail()
        setOpenModalFor()
    }

    const handleChangeRfqInput = (e) => {
        setRfqDetail({
            ...rfqDetail,
            [e.target.name]: e.target.checked,
        });
    }

    const submit = () => {
        var payload = {
            ...rfqDetail,
            rfqId: rfqId,
            userType: openModalFor
        }
        dispatch(showRfqDetailsToFactory(payload))
    }

    useEffect(() => {
        if (rfqResponse.actionName === "showRfqDetailsToFactory" && rfqResponse.success) {
            notify('Rfq details visibility to factories has been updated!', 'success')
            dispatch(resetRfq());
            setShowHideRfqDetailsModal(false)
            setRfqDetail()
            setOpenModalFor()
        }

        if (rfqResponse.actionName === "getShowRfqDetailsData" && rfqResponse.success) {
            let visibilityInfo = rfqResponse.rfqDetailsVisibilityInfo
            if (visibilityInfo) {
                setRfqDetail({
                    showPOCEmail: visibilityInfo.showPOCEmail,
                    showSales: visibilityInfo.showSales,
                    showType: visibilityInfo.showType,
                    showRFQName: visibilityInfo.showRFQName,
                    showBrand: visibilityInfo.showBrand,
                    showCategoryId: visibilityInfo.showCategoryId,
                    showSubCategoryId: visibilityInfo.showSubCategoryId,
                    showStyleNo: visibilityInfo.showStyleNo,
                    showStyleName: visibilityInfo.showStyleName,
                    showProductDescription: visibilityInfo.showProductDescription,
                    showQuantity: visibilityInfo.showQuantity,
                    showOrderValueProjection: visibilityInfo.showOrderValueProjection,
                    showAssignee: visibilityInfo.showAssignee,
                    showFabricDetails: visibilityInfo.showFabricDetails,
                    showRfqReceivedDate: visibilityInfo.showRfqReceivedDate,
                    showTechPack: visibilityInfo.showTechPack,
                    showSpecSheet: visibilityInfo.showSpecSheet,
                    showCostSheet: visibilityInfo.showCostSheet,
                    showGarment: visibilityInfo.showGarment
                })
            } else {
                setRfqDetail({
                    showPOCEmail: true,
                    showSales: true,
                    showType: true,
                    showRFQName: true,
                    showBrand: true,
                    showCategoryId: true,
                    showSubCategoryId: true,
                    showStyleNo: true,
                    showStyleName: true,
                    showProductDescription: true,
                    showQuantity: true,
                    showOrderValueProjection: true,
                    showAssignee: true,
                    showFabricDetails: true,
                    showRfqReceivedDate: true,
                    showTechPack: true,
                    showSpecSheet: true,
                    showCostSheet: true,
                    showGarment: true
                })
            }
            dispatch(resetRfq());
        }

    }, [rfqResponse]);

    return (
        <Modal show={showHideRfqDetailsModal} className="custom-modal" centered size="lg">
            <Modal.Header>
                <h2>
                    <button type="button" onClick={handleCloseModal} className="btn">
                        &#8249;
                    </button>
                    Modify visibility
                </h2>
            </Modal.Header>

            <Modal.Body>
                {rfqDetail &&
                    <div className="row dv-rfq-info" >

                        <div className=" col-md-6 form-check form-switch input-group-sm">
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">

                                    </div>
                                    <div className="col-md-6">
                                        <p className="">Show/Hide</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">POC Email</label>
                                    </div>
                                    <div className="col-md-6 ">
                                        <input role="switch" type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showPOCEmail} name='showPOCEmail' value={rfqDetail.showPOCEmail} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Sales</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showSales} name='showSales' value={rfqDetail.showSales} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Type</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showType} name='showType' value={rfqDetail.showType} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Assignee</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showAssignee} name='showAssignee' value={rfqDetail.showAssignee} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Buyer</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showBrand} name='showBrand' value={rfqDetail.showBrand} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Category</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showCategoryId} name='showCategoryId' value={rfqDetail.showCategoryId} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Sub Category</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showSubCategoryId} name='showSubCategoryId' value={rfqDetail.showSubCategoryId} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Style No</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showStyleNo} name='showStyleNo' value={rfqDetail.showStyleNo} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Style Name</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showStyleName} name='showStyleName' value={rfqDetail.showStyleName} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 form-check form-switch input-group-sm">
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">

                                    </div>
                                    <div className="col-md-6">
                                        <p className="">Show/Hide</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Product Description</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showProductDescription} name='showProductDescription' value={rfqDetail.showProductDescription} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Quantity</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showQuantity} name='showQuantity' value={rfqDetail.showQuantity} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Order Value Projection</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showOrderValueProjection} name='showOrderValueProjection' value={rfqDetail.showOrderValueProjection} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">RFQ Received Date</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showRfqReceivedDate} name='showRfqReceivedDate' value={rfqDetail.showRfqReceivedDate} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Fabric Detail</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showFabricDetails} name='showFabricDetails' value={rfqDetail.showFabricDetails} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Tech Pack</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showTechPack} name='showTechPack' value={rfqDetail.showTechPack} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Spec Sheet</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showSpecSheet} name='showSpecSheet' value={rfqDetail.showSpecSheet} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Cost sheet</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showCostSheet} name='showCostSheet' value={rfqDetail.showCostSheet} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Garment Images</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="checkbox" onChange={handleChangeRfqInput} defaultChecked={rfqDetail.showGarment} name='showGarment' value={rfqDetail.showGarment} className="form-check-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>

            <Modal.Footer>
                <button
                    type="button"
                    className="btn grey-brdr-btn"
                    onClick={handleCloseModal}
                >
                    Discard
                </button>
                <button type="submit" onClick={submit} className="btn ornge-brdr-btn">
                    Submit
                </button>
            </Modal.Footer>
        </Modal>
    );
};

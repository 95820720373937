import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../../../utils/api.urls";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');


export const getAllApplication = createAsyncThunk(
    "getAllApplication", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_ALLAPPLICATION);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getReportingInfo = createAsyncThunk(
    "getReportingInfo", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_REPORTING_INFO);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getPendingDaysReport = createAsyncThunk(
    "getPendingDaysReport", async (status, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_PENDINGDAYS_REPORT + "/" + status);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getCreditLimit = createAsyncThunk(
    "getCreditLimit", async (status, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_CREDIT_LIMIT_REPORT + "/" + status);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//update action
export const saveUpdateSupplier = createAsyncThunk(
    "saveUpdateSupplier", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.SAVE_SUPPLIER,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getSuppliers = createAsyncThunk(
    "getSuppliers", async (guarantorId, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_SUPPLIER + "/" + guarantorId);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const deleteSupplier = createAsyncThunk(
    "deleteSupplier", async (supplierId, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.DELETE_SUPPLIER + "/" + supplierId);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getAllInsight = createAsyncThunk(
    "getAllInsight", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_ALL_INSIGHT);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getWeeklyReport = createAsyncThunk(
    "getWeeklyReport", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_WEEKLY_REPORT);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
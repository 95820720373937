import React, { useState } from "react";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import uploadImg from "../../assets/images/upload-image.svg";
import deleteImg from "../../assets/images/deleteicon.png";
import { useEffect } from "react";
import { Form, Formik } from "formik";
import { handleDateClick } from "../../utils/datePickerHelper";
import * as Yup from "yup";
import { v1 as uuidv1 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateSample } from "../../features/sample/action";
import { notify } from "../../features/notification/notify";
import { resetSample } from "../../features/sample/slice";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { addUpdateRfq, getRfqById } from "../../features/rfq/action";
import { resetRfq } from "../../features/rfq/slice";
import { getUserByRoleType } from "../../features/rfq/action";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import Select from "react-select";

import {
  getCategory,
  getSubCategoryByCategoryId,
} from "../../features/common/action";
import { resetCommon } from "../../features/common/slice";
import { getUserByRoleName } from "../../features/userDetail/action";
import Multiselect from "multiselect-react-dropdown";
export const AddUpdateRFQ = () => {
  const [costSheet, setCostSheet] = useState([]);
  const [specSheet, setSpecSheet] = useState([]);
  const [techPack, setTechPack] = useState([]);
  const [attachImage, setAttachImage] = useState([]);
  const rfqResponse = useSelector((state) => state.rfq);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roleDetail, setRoleDetail] = useState([]);
  const { state } = useLocation();
  const [rfqId, setRfqId] = useState();
  const [rfqDetail, setRfqDetail] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [brand, setBrand] = useState([]);
  const [removeAssignee, setRemoveAssignee] = useState([]);
  const userResponse = useSelector((state) => state.userDetail);
  const commonResponse = useSelector((state) => state.common);
  const loggedInUser = localStorage.getItem("userId");
  const userData = JSON.parse(localStorage.getItem("authorization"));
  const [brandOption, setBrandOption] = useState();
  const [categoryOption, setCategoryOption] = useState([]);
  const [subCategoryOption, setSubCategoryOption] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([{ id: userData.user.id, name: userData.user.name ? userData.user.name : userData.user.emailId }]);
  useEffect(() => {
    dispatch(getUserByRoleType({ rollType: "Internal", userId: loggedInUser }));
    dispatch(getUserByRoleName("Buyer"));
    dispatch(getCategory());
    if (state && state.rfqId) {
      setRfqId(state.rfqId);
    }
  }, []);
  useEffect(() => {
    if (rfqId) {
      dispatch(getRfqById(rfqId));
    }
  }, [rfqId]);

  const handleSelectAssignee = (selectedList, selectedItem) => {
    // Handle selection changes
    setSelectedAssignees(selectedList);
    setRfq({
      ...rfq,
      assignee: selectedList.length,
    });
  };
  let rm = [];
  const handleRemoveAssignee = (selectedList, removedItem) => {
    const updatedRemovedAssignees = [...selectedAssignees];
    updatedRemovedAssignees.push(removedItem);
    setRemoveAssignee([...removeAssignee, removedItem]);
  }
  const [rfq, setRfq] = useState({
    id: "",
    pOCEmail: "",
    sales: "",
    type: "",
    rFQName: "",
    brand: "",
    category: "",
    subCategory: "",
    styleNo: "",
    styleName: "",
    productDescription: "",
    quantity: null,
    orderValueProjection: null,
    // internalCost: null,
    rfqRecievedDate: "",
    samplingRequired: false,
    internalSamplingCost: "",
    internalSamplingTime: "",
    internalProductionCost: "",
    internalProductionTime: "",
    productionApprovalRequired: false,
    assignee: "",
    createdBy: loggedInUser,
    fabricDetails: null
  });

  useEffect(() => {
    if (rfqDetail) {
      setRfq({
        id: rfqDetail.id,
        pOCEmail: rfqDetail.pOCEmail,
        sales: rfqDetail.sales,
        type: rfqDetail.type,
        rFQName: rfqDetail.rFQName,
        brand: rfqDetail.brand,
        category: rfqDetail.category?.id,
        subCategory: rfqDetail.subCategory?.id,
        styleNo: rfqDetail.styleNo,
        styleName: rfqDetail.styleName,
        productDescription: rfqDetail.productDescription,
        quantity: rfqDetail.quantity,
        orderValueProjection: rfqDetail.orderValueProjection,
        //internalCost: rfqDetail.internalCost,
        rfqRecievedDate: moment(rfqDetail.rfqRecievedDate).format("YYYY-MM-DD"),
        samplingRequired: rfqDetail.samplingRequired,
        internalSamplingCost: rfqDetail.internalSamplingCost,
        internalSamplingTime: rfqDetail.internalSamplingTime,
        internalProductionCost: rfqDetail.internalProductionCost,
        internalProductionTime: rfqDetail.internalProductionTime,
        productionApprovalRequired: rfqDetail.productionApprovalRequired,
        assignee: rfqDetail.Users.length,
        fabricDetails: rfqDetail.fabricDetails
      });
      let temp = [];
      rfqDetail.Users.map((item) => {
        temp.push({ emailId: item.emailId, id: item.id, name: item.name, Roles: [] })
      })
      setSelectedAssignees(temp);
      dispatch(getSubCategoryByCategoryId(rfqDetail?.category.id));
    }
    if (rfqDetail?.rfqFiles && rfqDetail.rfqFiles.length > 0) {
      const specFile = [];
      const costFile = [];
      const techFile = [];
      const attachImageFile = [];
      rfqDetail.rfqFiles.map((item) => {
        if (item.fileType == "spec sheet")
          specFile.push({
            id: item.id,
            fileType: "spec sheet",
            fileName: item.fileName,
            isDeleted: false,
          });
        if (item.fileType == "cost sheet")
          costFile.push({
            id: item.id,
            fileType: "cost sheet",
            fileName: item.fileName,
            isDeleted: false,
          });
        if (item.fileType == "tech pack")
          techFile.push({
            id: item.id,
            fileType: "tech pack",
            fileName: item.fileName,
            isDeleted: false,
          });
        if (item.fileType == "attach image")
          attachImageFile.push({
            id: item.id,
            fileType: "attach image",
            fileName: item.fileName,
            isDeleted: false,
          });
      });
      setSpecSheet(specFile);
      setCostSheet(costFile);
      setTechPack(techFile);
      setAttachImage(attachImageFile);
    }
  }, [rfqDetail]);

  useEffect(() => {
    if (commonResponse.actionName === "getCategory" && commonResponse.success) {
      setCategoryList(commonResponse.category);
      let categoryArr = [];
      if (commonResponse.category.length > 0)
        commonResponse.category.map((item) => {
          let list = {
            value: item.id,
            label: item.categoryName,
          };
          categoryArr.push(list);
        })
      setCategoryOption(categoryArr.sort((a, b) => a.label.localeCompare(b.label)));
      dispatch(resetCommon());
    }
    if (
      commonResponse.actionName === "getSubCategory" &&
      commonResponse.success
    ) {
      setSubCat(commonResponse.subCategory);
      let subCatArr = [];
      if (commonResponse.subCategory.length > 0)
        commonResponse.subCategory.map((item) => {
          let list = {
            value: item.id,
            label: item.subCategoryName,
          };
          subCatArr.push(list);
        })
      setSubCategoryOption(subCatArr.sort((a, b) => a.label.localeCompare(b.label)));
      dispatch(resetCommon());
    }
    if (userResponse.actionName == "userByRoleName") {
      setBrand(userResponse.roleDetails);
      let brandArr = [];
      if (userResponse.roleDetails.length > 0)
        userResponse.roleDetails.map((item) => {
          let list = {
            value: item.id,
            label: item.name,
          };
          brandArr.push(list);
        })
      setBrandOption(brandArr.sort((a, b) => a.label.localeCompare(b.label)));
      dispatch(resetCommon());
    }
  }, [commonResponse, userResponse]);

  const rfqSchema = Yup.object().shape({
    pOCEmail: Yup.string().required("Required"),
    sales: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    rFQName: Yup.string().required("Required"),
    brand: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    subCategory: Yup.string().required("Required"),
    productDescription: Yup.string().required("Required"),
    orderValueProjection: Yup.number().nullable().required("Required"),
    //internalCost: Yup.number().nullable().required("Required"),
    samplingRequired: Yup.boolean().required("Required"),
    //internalSamplingCost: Yup.number().nullable().required("Required"),
    //internalSamplingTime: Yup.number().nullable().required("Required"),
    // internalProductionCost: Yup.number().nullable().required("Required"),
    // internalProductionTime: Yup.number().nullable().required("Required"),
    productionApprovalRequired: Yup.boolean().required("Required"),
    fabricDetails: Yup.string().nullable().required("Required"),
    //assignee: Yup.string().required("Required"),
    // costSheetFile: Yup.mixed().required("File is required"),
    // specSheetFile: Yup.mixed().required("File is required"),
    // techPackFile: Yup.mixed().required("File is required"),
    rfqRecievedDate: Yup.date().required("Required"),
  });
  useEffect(() => {
    if (rfqResponse.actionName == "addUpdateRfq" && rfqResponse.success) {
      //this if block will be changed in future as this condition is hardcoded
      if (rfqResponse.message == "Exist") {
        notify("RFQ name already exist!", "info");
      } else {
        notify(rfqResponse.message, "success");
        navigate("/rfq-list");
      }
      dispatch(resetRfq());
    } else if (rfqResponse.actionName == "userByRoleType") {
      let assignArr = [];
      if (rfqResponse.roleDetails.length > 0)
        rfqResponse.roleDetails.map((item) => {
          let list = {
            id: item.id,
            name: item.name ? item.name : item.emailId,
          };
          assignArr.push(list);
        })
      setRoleDetail(assignArr.sort((a, b) => a.name.localeCompare(b.name)));
      dispatch(resetRfq());
    }
    if (rfqResponse.actionName == "getRfqById" && rfqResponse.success) {
      setRfqDetail(rfqResponse.rfqData);
      dispatch(resetRfq());
    }
  }, [rfqResponse]);

  const handleChange = (e) => {

    if (e.target.name == "quantity") {
      if (parseInt(e.target.value) < 1) {
        e.preventDefault();
        e.target.value = 1
      }
    }
    if (e.target.name == "orderValueProjection") {
      if (parseInt(e.target.value) < 1)
        e.target.value = 1
    }
    // if (e.target.name == "internalCost") {
    //   if (parseInt(e.target.value) < 1)
    //     e.target.value = 1
    // }
    // if (e.target.name == "internalSamplingCost") {
    //   if(parseInt(e.target.value) < 1)
    //     e.target.value = 1
    // }
    // if (e.target.name == "internalSamplingTime") {
    //   if (parseInt(e.target.value) < 1)
    //     e.target.value = 1
    // }
    // if (e.target.name == "internalProductionCost") {
    //   if (parseInt(e.target.value) < 1)
    //     e.target.value = 1
    // }
    // if (e.target.name == "internalProductionTime") {
    //   if (parseInt(e.target.value) < 1)
    //     e.target.value = 1
    // }
    setRfq({
      ...rfq,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {

    // if (techPack.filter(x => x.isDeleted == false).length == 0) {
    //   notify("Please add Tech Pack file", "info");
    // } 
    if (costSheet.length == 0 && specSheet.length == 0 && techPack.length == 0) {
      notify("Select atleast 1 file", "info");
    }
    else {
      let formData = new FormData();
      const fileNames = [];
      costSheet.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({
          id: fileSet.id,
          fileType: "cost sheet",
          fileName: fileSet.fileName,
          isDeleted: fileSet.isDeleted,
        });
      });
      specSheet.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({
          id: fileSet.id,
          fileType: "spec sheet",
          fileName: fileSet.fileName,
          isDeleted: fileSet.isDeleted,
        });
      });
      techPack.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({
          id: fileSet.id,
          fileType: "tech pack",
          fileName: fileSet.fileName,
          isDeleted: fileSet.isDeleted,
        });
      });

      attachImage.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({
          id: fileSet.id,
          fileType: "attach image",
          fileName: fileSet.fileName,
          isDeleted: fileSet.isDeleted,
        });
      });
      formData.append("content", JSON.stringify(rfq));
      formData.append("fileList", JSON.stringify(fileNames));
      formData.append("assignees", JSON.stringify(selectedAssignees));
      formData.append("removeAssignee", JSON.stringify(removeAssignee));
      formData.append("assigner", JSON.stringify(userData.user.name));

      dispatch(addUpdateRfq(formData));
    }
  };
  // console.log("userData is",userData?.user?.id);
  //console.log("selected assignee",selectedAssignees);
  //  console.log("image attach",attachImage);
  //  console.log("techPack",techPack);
  // console.log("removeAssignee ", removeAssignee);

  const handleImageAttach = (e, type) => {
    const fileExtension = e.target.files[0]?.name
      .split(".")
      .pop()
      .toLowerCase();
    var acceptedFormats = ["jpeg", "jpg", "png"];
    if (!acceptedFormats.includes(fileExtension)) {

      notify("File type not supported", "error")
      e.target.value = "";
      return;
    }
    else {
      const { file, newImageName } = getFile(e, fileExtension);
      if (type === "image" && attachImage.length < 5) {
        setAttachImage((attachImage) => [
          ...attachImage,
          { id: 0, file: file, fileName: newImageName, isDeleted: false },
        ]);
      } else {
        notify("Only 5 files can be added at a time", "info");
      }
    }
  }

  const getFile = (e, fileExtension) => {
    let newImageName, index;
    const image = e.target.files[0];
    const blob = image.slice(0, image.size);

    //const id = uuidv1();
    // const newImageName = id + "." + fileExtension;
    
    if (fileExtension == "jpeg" || fileExtension == "jpg" || fileExtension == "png") {
      if (e.target.files[0]?.name.indexOf("AM") != -1)
        index = e.target.files[0]?.name.indexOf("AM");

      else if (e.target.files[0]?.name.indexOf("PM") != -1)
        index = e.target.files[0]?.name.indexOf("PM");

      if (index) {
        newImageName =
          e.target.files[0]?.name.slice(0, index - 1) + "_" + e.target.files[0]?.name.slice(index);
      }

      else
        newImageName = e.target.files[0]?.name;

    }
    else
      newImageName = e.target.files[0]?.name;
    const file = new File([blob], newImageName);
    return { file, newImageName };
  };
  const handleFileSheetUpload = (e, type) => {

    const fileExtension = e.target.files[0]?.name
      .split(".")
      .pop()
      .toLowerCase();
    var acceptedFormats = ["pdf", "xlsx", "docx", "csv", "xls", "doc", "jpeg", "jpg"];
    if (!acceptedFormats.includes(fileExtension)) {

      notify("File type not supported", "error")
      e.target.value = "";
      return;
    }
    else {
      const { file, newImageName } = getFile(e, fileExtension);
      if (type === "cost" && costSheet.length != 5) {
        setCostSheet((costSheet) => [
          ...costSheet,
          { id: 0, file: file, fileName: newImageName, isDeleted: false },
        ]);
      } else if (type === "spec" && specSheet.length != 5) {
        setSpecSheet((specSheet) => [
          ...specSheet,
          { id: 0, file: file, fileName: newImageName, isDeleted: false },
        ]);
      } else if (type === "tech" && specSheet.length != 5) {
        setTechPack((techPack) => [
          ...techPack,
          { id: 0, file: file, fileName: newImageName, isDeleted: false },
        ]);
      } else {
        notify("Only 5 files can be added at a time", "info");
      }
    }

  };

  const handleDelete = (e, index, type) => {
    if (type === "cost") {
      const cost = [...costSheet];
      cost[index].isDeleted = true;
      setCostSheet(cost);
    } else if (type == "spec") {
      const spec = [...specSheet];
      spec[index].isDeleted = true;
      setSpecSheet(spec);
    } else if (type == "tech") {
      const tech = [...techPack];
      tech[index].isDeleted = true;
      setTechPack(tech);
    }
    else if (type == "image") {
      const img = [...attachImage];
      img[index].isDeleted = true;
      setAttachImage(img);

      // delete attachImage[index];
      // setAttachImage(attachImage);
    }
  };

  const removeFile = (e, index, type) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure want to delete this file?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleDelete(e, index, type);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleBack = () => {
    if (rfqId && rfqId > 0) {
      navigate("/rfq-info", {
        replace: true,
        state: { rfqId: rfqId },
      });
    } else {
      navigate("/rfq-list");
    }
  };

  const changeValue = (e) => {
    if (
      e.target.name == "samplingRequired" ||
      e.target.name == "productionApprovalRequired"
    ) {
      setRfq({
        ...rfq,
        [e.target.name]: rfq[e.target.name] == false ? true : false,
      });
    }
  };
  const preventNegativeValues = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleTypeChange = (type) => {
    setRfq({
      ...rfq,
      type: type.value,
    });
  }

  const handleSalesChange = (sale) => {
    setRfq({
      ...rfq,
      sales: sale.value,
    });
  }
  const handleBrandChange = (brand) => {
    setRfq({
      ...rfq,
      brand: brand.value,
    });
  }
  const handleCatChange = (category) => {
    setRfq({
      ...rfq,
      category: category.value,
    });
    dispatch(getSubCategoryByCategoryId(category.value));
  }
  const handleSubCatChange = (subCategory) => {
    setRfq({
      ...rfq,
      subCategory: subCategory.value,
    });
  }
  return (
    <>

      <LeftMenu />
      <div className="right-column">
        <Header />

        <div className="body-wrap">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/rfq-list" >RFQ List </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#"> Add RFQ</a>
              </li>
            </ol>
          </nav>
          <Formik
            initialValues={rfq}
            validationSchema={rfqSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, isValid, handleBlur }) => (
              <>
                <Form>
                  <div className="top-heading">
                    <div className="d-flex">
                      <a onClick={handleBack} className="back">
                        &#8249;
                      </a>
                      {rfqId && rfqId > 0 && <h2>Update RFQ</h2>}
                      {!rfqId && <h2>Add RFQ</h2>}
                    </div>
                    <div className="d-flex gap-2">
                      <button
                        onClick={handleBack}
                        className="btn grey-brdr-btn"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn ornge-brdr-btn">
                        Next
                      </button>
                    </div>
                  </div>
                  <div className="user-wrap">
                    <h6>RFQ Details</h6>

                    <div className="row row-gap-4">
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">POC Email <span className="star-color"> *</span></label>
                        <input
                          type="text"
                          name="pOCEmail"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.pOCEmail}
                        />
                        {errors.pOCEmail && touched.pOCEmail ? (
                          <div className="invalid-feedback d-block">
                            {errors.pOCEmail}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">Sales<span className="star-color"> *</span></label>

                        <Select
                          value={rfq.sales ?? ""}
                          onChange={handleSalesChange}
                          options={[{ value: "Enquiry", label: "Enquiry" }, { value: "Pitch", label: "Pitch" }]}
                          placeholder={rfq.sales == "" ? 'Sales: All' : rfq.sales}
                        />
                        {errors.sales && touched.sales ? (
                          <div className="invalid-feedback d-block">
                            {errors.sales}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">Type<span className="star-color"> *</span></label>

                        <Select
                          value={rfq.type ?? ""}
                          onChange={handleTypeChange}
                          options={[{ value: "Domestic", label: "Domestic" }, { value: "International", label: "International" }]}
                          placeholder={rfq.type == "" ? 'Type: All' : rfq.type}
                        />
                        {errors.type && touched.type ? (
                          <div className="invalid-feedback d-block">
                            {errors.type}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">RFQ Name<span className="star-color"> *</span></label>
                        <input
                          type="text"
                          name="rFQName"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.rFQName}
                        />
                        {errors.rFQName && touched.rFQName ? (
                          <div className="invalid-feedback d-block">
                            {errors.rFQName}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">Brand<span className="star-color"> *</span></label>
                        <Select
                          value={rfq.brand ?? ""}
                          onChange={handleBrandChange}
                          options={brandOption}
                          placeholder={rfq.brand == "" ? 'Brand: All' : brandOption.find(x => x.value == rfq.brand)?.label}
                        />
                        {errors.brand && touched.brand ? (
                          <div className="invalid-feedback d-block">
                            {errors.brand}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">Category<span className="star-color"> *</span></label>
                        <Select
                          value={rfq.category ?? ""}
                          onChange={handleCatChange}
                          options={categoryOption}
                          placeholder={rfq.category == "" ? 'Category: All' : categoryOption.find(x => x.value == rfq.category)?.label}
                        />
                        {errors.category && touched.category ? (
                          <div className="invalid-feedback d-block">
                            {errors.category}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">
                          Subcategory<span className="star-color"> *</span>
                        </label>

                        <Select
                          value={rfq.subCategory ?? ""}
                          onChange={handleSubCatChange}
                          options={subCategoryOption}
                          placeholder={rfq.subCategory == "" ? 'Sub-Category: All' : subCategoryOption.find(x => x.value == rfq.subCategory)?.label}
                        />
                        {errors.subCategory && touched.subCategory ? (
                          <div className="invalid-feedback d-block">
                            {errors.subCategory}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">Style No.</label>
                        <input
                          type="text"
                          name="styleNo"
                          className="form-control"
                          onChange={handleChange}
                          value={rfq.styleNo}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">Style Name</label>
                        <input
                          type="text"
                          name="styleName"
                          className="form-control"
                          onChange={handleChange}
                          value={rfq.styleName}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">
                          Product Description<span className="star-color"> *</span>
                        </label>
                        <input
                          type="text"
                          name="productDescription"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.productDescription}
                        />
                        {errors.productDescription &&
                          touched.productDescription ? (
                          <div className="invalid-feedback d-block">
                            {errors.productDescription}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">Quantity</label>
                        <input
                          type="number"
                          min={1}
                          name="quantity"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.quantity}
                          onKeyDown={preventNegativeValues}
                        />

                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">
                          Order Value Projection (INR)<span className="star-color"> *</span>
                        </label>
                        <input
                          type="number"
                          min={1}
                          name="orderValueProjection"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.orderValueProjection}
                          onKeyDown={preventNegativeValues}
                        />
                        {errors.orderValueProjection &&
                          touched.orderValueProjection ? (
                          <div className="invalid-feedback d-block">
                            {errors.orderValueProjection}
                          </div>
                        ) : null}
                      </div>
                      {/* <div className="col-lg-4 col-md-6">
                        <label className="form-label">
                          Groyyo Target/ Factory Cost (INR)<span className="star-color"> *</span>
                        </label>
                        <input
                          type="number"
                          min={1}
                          name="internalCost"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.internalCost}
                          onKeyDown={preventNegativeValues}
                        />
                        {errors.internalCost && touched.internalCost ? (
                          <div className="invalid-feedback d-block">
                            {errors.internalCost}
                          </div>
                        ) : null}
                      </div> */}
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">RFQ Receive date<span className="star-color"> *</span></label>
                        <input
                          type="date"
                          name="rfqRecievedDate"
                          className="form-control"
                          onClick={handleDateClick}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.rfqRecievedDate}
                        />
                        {errors.rfqRecievedDate && touched.rfqRecievedDate ? (
                          <div className="invalid-feedback d-block">
                            {errors.rfqRecievedDate}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">
                          Internal Sampling Cost (INR)<span className="star-color"></span>
                        </label>
                        <input
                          type="number"
                          name="internalSamplingCost"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.internalSamplingCost}
                          onKeyDown={preventNegativeValues}
                        />
                        {/* {errors.internalSamplingCost &&
                          touched.internalSamplingCost ? (
                          <div className="invalid-feedback d-block">
                            {errors.internalSamplingCost}
                          </div>
                        ) : null} */}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">
                          Internal Sampling Time (Man days)<span className="star-color"> </span>
                        </label>
                        <input
                          type="number"
                          min={1}
                          name="internalSamplingTime"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.internalSamplingTime}
                          onKeyDown={preventNegativeValues}
                        />
                        {errors.internalSamplingTime &&
                          touched.internalSamplingTime ? (
                          <div className="invalid-feedback d-block">
                            {errors.internalSamplingTime}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">
                          Internal Production Cost (INR)<span className="star-color"> </span>
                        </label>
                        <input
                          type="number"
                          min={1}
                          name="internalProductionCost"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.internalProductionCost}
                          onKeyDown={preventNegativeValues}
                        />
                        {errors.internalProductionCost &&
                          touched.internalProductionCost ? (
                          <div className="invalid-feedback d-block">
                            {errors.internalProductionCost}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">
                          Internal Production Time (Man days)<span className="star-color"> </span>
                        </label>
                        <input
                          type="number"
                          min={1}
                          name="internalProductionTime"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.internalProductionTime}
                          onKeyDown={preventNegativeValues}
                        />
                        {errors.internalProductionTime &&
                          touched.internalProductionTime ? (
                          <div className="invalid-feedback d-block">
                            {errors.internalProductionTime}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">
                          Fabric Details<span className="star-color"> *</span>
                        </label>
                        <input
                          type="string"
                          name="fabricDetails"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={rfq.fabricDetails}
                        />
                        {errors.fabricDetails &&
                          touched.fabricDetails ? (
                          <div className="invalid-feedback d-block">
                            {errors.fabricDetails}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <div className="form-switch-box">
                          <span className="spn-stch">Sampling Required<span className="star-color"> *</span></span>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              name="samplingRequired"
                              type="checkbox"
                              role="switch"
                              id={"flexSwitchCheckDefault"}
                              value={rfq.samplingRequired}
                              onChange={changeValue}
                              checked={rfq.samplingRequired}
                            />
                            {errors.samplingRequired &&
                              touched.samplingRequired ? (
                              <div className="invalid-feedback d-block">
                                {errors.samplingRequired}
                              </div>
                            ) : null}

                            {/* <input className="form-check-input" type="checkbox" role="switch"
                                                        id="flexSwitchCheckDefault" /> */}
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckDefault"
                            ></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-switch-box">
                          <span className="spn-stch">
                            Production Approval Required<span className="star-color"> *</span>
                          </span>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input "
                              type="checkbox"
                              name="productionApprovalRequired"
                              role="switch"
                              id={"flexSwitchCheckDefaultOne"}
                              checked={rfq.productionApprovalRequired}
                              value={rfq.productionApprovalRequired}
                              onChange={changeValue}
                            />
                            {errors.productionApprovalRequired &&
                              touched.productionApprovalRequired ? (
                              <div className="invalid-feedback d-block">
                                {errors.productionApprovalRequired}
                              </div>
                            ) : null}
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckDefaultOne"
                            ></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">Assignee<span className="star-color"></span></label>
                        <Multiselect
                          options={roleDetail}
                          selectedValues={selectedAssignees}
                          onSelect={handleSelectAssignee}
                          onRemove={handleRemoveAssignee}
                          name="assignee"
                          displayValue="name"
                        />
                        {/* {errors.assignee &&
                              touched.assignee ? (
                              <div className="invalid-feedback d-block">
                                {errors.assignee}
                              </div>
                            ) : null} */}
                      </div>

                      <div className="col-lg-4 col-md-6">
                        &nbsp;
                        <div className="dv-file-lst row">
                          {techPack.length > 0 &&
                            techPack.map(
                              (item, index) =>
                                !item.isDeleted && (
                                  <div className="col-md-4">
                                    <span className="spn-fle">
                                      Sheet {index + 1}
                                    </span>{" "}
                                    &nbsp;
                                    <img
                                      onClick={(e) => {
                                        removeFile(e, index, "tech");
                                      }}
                                      src={deleteImg}
                                      className="smll-dlte"
                                    />
                                  </div>
                                )
                            )}
                        </div>
                        <div className="position-relative custom-upload">
                          <div className="upload">
                            <div className="upload-img">
                              <img src={uploadImg} alt="upload" />
                            </div>
                            <div className="upload-detail">
                              <div>
                                Drop your Tech Pack here or{" "}
                                <span className="blue-txt">Browse</span>
                              </div>
                              <div>Max file size: 100MB</div>
                              <div>File supported: pdf, xlsx, docx, csv, xls, doc,jpeg,jpg</div>
                            </div>
                          </div>
                          <input
                            type="file"
                            className="custom-file"
                            name="techPackFile"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleFileSheetUpload(event, "tech");
                            }}
                          />
                          {errors.techPackFile &&
                            touched.techPackFile ? (
                            <div className="invalid-feedback d-block">
                              {errors.techPackFile}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        &nbsp;
                        <div className="dv-file-lst row">
                          {specSheet.length > 0 &&
                            specSheet.map(
                              (item, index) =>
                                !item.isDeleted && (
                                  <div className="col-md-4">
                                    <span className="spn-fle">
                                      Sheet {index + 1}
                                    </span>{" "}
                                    &nbsp;
                                    <img
                                      onClick={(e) => {
                                        removeFile(e, index, "spec");
                                      }}
                                      src={deleteImg}
                                      className="smll-dlte"
                                    />
                                  </div>
                                )
                            )}
                        </div>
                        <div className="position-relative custom-upload">
                          <div className="upload">
                            <div className="upload-img">
                              <img src={uploadImg} alt="upload" />
                            </div>
                            <div className="upload-detail">
                              <div>
                                Drop your Spec Sheet here or{" "}
                                <span className="blue-txt">Browse</span>
                              </div>
                              <div>Max file size: 100MB</div>
                              <div>File supported: pdf, xlsx, docx, csv, xls, doc,jpeg,jpg</div>
                            </div>

                            <input
                              type="file"
                              className="custom-file"
                              name="specSheetFile"
                              onChange={(event) => {
                                handleFileSheetUpload(event, "spec");
                              }}
                            />
                            {errors.specSheetFile &&
                              touched.specSheetFile ? (
                              <div className="invalid-feedback d-block">
                                {errors.specSheetFile}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        &nbsp;
                        <div className="dv-file-lst row">
                          {costSheet.length > 0 &&
                            costSheet.map(
                              (item, index) =>
                                !item.isDeleted && (
                                  <div className="col-md-4">
                                    <span className="spn-fle">
                                      Sheet {index + 1}
                                    </span>{" "}
                                    &nbsp;
                                    <img
                                      onClick={(e) => {
                                        removeFile(e, index, "cost");
                                      }}
                                      src={deleteImg}
                                      className="smll-dlte"
                                    />
                                  </div>
                                )
                            )}
                        </div>
                        <div className="position-relative custom-upload">
                          <div className="upload">
                            <div className="upload-img">
                              <img src={uploadImg} alt="upload" />
                            </div>
                            <div className="upload-detail">
                              <div>
                                Drop your Cost Sheet here or{" "}
                                <span className="blue-txt">Browse</span>
                              </div>
                              <div>Max file size: 100MB</div>
                              <div>File supported: pdf, xlsx, docx, csv, xls, doc,jpeg,jpg</div>
                            </div>
                          </div>
                          <input
                            type="file"
                            className="custom-file"
                            name="costSheetFile"
                            // onBlur={handleBlur}
                            onChange={(event) => {
                              handleFileSheetUpload(event, "cost");
                            }}
                          />
                          {errors.costSheetFile &&
                            touched.costSheetFile ? (
                            <div className="invalid-feedback d-block">
                              {errors.costSheetFile}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        &nbsp;
                        <div className="dv-file-lst row">
                          {attachImage.length > 0 &&
                            attachImage.map(
                              (item, index) =>
                                !item.isDeleted && (
                                  <div className="col-md-4">
                                    <span className="spn-fle">
                                      Sheet {index + 1}
                                    </span>{" "}
                                    &nbsp;
                                    <img
                                      onClick={(e) => {
                                        removeFile(e, index, "image");
                                      }}
                                      src={deleteImg}
                                      className="smll-dlte"
                                    />
                                  </div>
                                )
                            )}
                        </div>
                        <div className="position-relative custom-upload">
                          <div className="upload">
                            <div className="upload-img">
                              <img src={uploadImg} alt="upload" />
                            </div>
                            <div className="upload-detail">
                              <div>
                                Attach Garment Image or{" "}
                                <span className="blue-txt">Browse</span>
                              </div>
                              <div>Max file size: 100MB</div>
                              <div>File supported: jpeg,jpg,png</div>
                            </div>
                          </div>
                          <input
                            type="file"
                            className="custom-file"
                            name="attachImage"
                            // onBlur={handleBlur}
                            onChange={(event) => {
                              handleImageAttach(event, "image");
                            }}
                          />
                          {/* {errors.costSheetFile &&
                            touched.costSheetFile ? (
                            <div className="invalid-feedback d-block">
                              {errors.costSheetFile}
                            </div>
                          ) : null} */}
                        </div>
                      </div>

                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

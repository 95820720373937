import { createSlice } from "@reduxjs/toolkit";
import { getRFQMessages, getAllUserMessagesByRFQ, getAllChats, getAllChatMessages, saveNewChatMessage , createGroupChat, updateGroupChat, createSingleChat, getChatByRfqBid} from "./action";


export const rfqChat = createSlice({
  name: "rfqChat",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
    rfqMessages : null,
    rfqChats : null,
    rfqAllChats : null,
    rfqChatMessages : null,
    newSavedMessage : null,
    createdSingleChat: null,
    rfqBidChat: null,
    updatedGroupChat: null
  },

  reducers: {
    resetRfqChat: (state, action) => {
      state.success = false
       state.actionName=null;
      state.message = null
    },
  },

  extraReducers: {
   
    [getRFQMessages.pending]: (state) => {
      state.loading = true;
    },
    [getRFQMessages.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.rfqMessages = action.payload.chats;
      state.actionName = "getRFQMessages"
    },
    [getRFQMessages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getAllUserMessagesByRFQ.pending]: (state) => {
      state.loading = true;
    },
    [getAllUserMessagesByRFQ.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.rfqChats = action.payload.rfqChats;
      state.actionName = "getAllUserMessagesByRFQ"
    },
    [getAllUserMessagesByRFQ.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getAllChats.pending]: (state) => {
      state.loading = true;
    },
    [getAllChats.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.rfqAllChats = action.payload;
      state.actionName = "getAllChats"
    },
    [getAllChats.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getAllChatMessages.pending]: (state) => {
      state.loading = true;
    },
    [getAllChatMessages.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.rfqChatMessages = action.payload;
      state.actionName = "getAllChatMessages"
    },
    [getAllChatMessages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [saveNewChatMessage.pending]: (state) => {
      state.loading = true;
    },
    [saveNewChatMessage.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.newSavedMessage = action.payload;
      state.actionName = "saveNewChatMessage"
    },
    [saveNewChatMessage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [createSingleChat.pending]: (state) => {
      state.loading = true;
    },
    [createSingleChat.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.createdSingleChat = action.payload;
      state.actionName = "createSingleChat"
    },
    [createSingleChat.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [createGroupChat.pending]: (state) => {
      state.loading = true;
    },
    [createGroupChat.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "createGroupChat"
    },
    [createGroupChat.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [updateGroupChat.pending]: (state) => {
      state.loading = true;
    },
    [updateGroupChat.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.updatedGroupChat = action.payload.chat
      state.actionName = "updateGroupChat"
    },
    [updateGroupChat.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getChatByRfqBid.pending]: (state) => {
      state.loading = true;
    },
    [getChatByRfqBid.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.rfqBidChat = action.payload
      state.actionName = "getChatByRfqBid"
    },
    [getChatByRfqBid.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    }
  },
});

export default rfqChat.reducer;

export const { resetRfqChat } = rfqChat.actions;

import { CONSTANT } from "../../utils/constant";

export const redirectAfterLogin = (loggedData) => {
    loggedData = JSON.parse(loggedData)
    let role = loggedData?.roles ? loggedData?.roles[0].roleId : "";
    let roleType = loggedData?.roles ? loggedData?.roles[0].roleType : "";
    if (roleType === "Internal") {
        window.location.href = "/rfq-list"
    } else if (role === CONSTANT.USER_ROLE.SUPPLIER) {
        window.location.href = "/rfq-list"
    }else if (role === CONSTANT.USER_ROLE.BUYER) {
        window.location.href = "/rfq-list"
    } else {
        switch (loggedData.department) {
            case CONSTANT.USER_DEPARTMENT.CREDIT_RISK:
                window.location.href = "/credit/dashboard"
                break;
            case CONSTANT.USER_DEPARTMENT.SCF_SALES:
                window.location.href = "/sales/dashboard"
                break;
            case CONSTANT.USER_DEPARTMENT.SC_FINANCE:
                window.location.href = "/scfinance/dashboard"
                break;
            case CONSTANT.USER_DEPARTMENT.OPS:
                window.location.href = "/ops/dashboard"
                break;
            default:
                break;
        }
    }
}

import React, { useEffect, useState } from "react";
import loginBanner1 from "../../assets/images/LoginBanner1.jpg";
import loginBanner2 from "../../assets/images/LoginBanner2.jpg";
import loginBanner3 from "../../assets/images/LoginBanner3.jpg";
import loginBanner4 from "../../assets/images/LoginBanner4.jpg";

export const LoginBannerImage = () => {
  const [currentImage, setCurrentImage] = useState(null);
  const bannerImages = [loginBanner1, loginBanner2, loginBanner3, loginBanner4];

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * bannerImages.length);
    return bannerImages[randomIndex];
  };

  useEffect(() => {
    setCurrentImage(getRandomImage());
  }, []);

  return <>{currentImage && <img src={currentImage} alt="banner-img"></img>}</>;
};

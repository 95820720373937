import { createSlice } from "@reduxjs/toolkit";
import { addUpdateMilestone, getAllMileStone, deleteMilestone, fetchMilestones } from "./action";


export const milestone = createSlice({
  name: "milestone",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
    milestones : null,
    savedAndUpdatedMilestone: null
  },

  reducers: {
    resetMilestone: (state, action) => {
      state.success = false
      state.actionName = null
      state.message = null
    },
  },

  extraReducers: {
    [addUpdateMilestone.pending]: (state) => {
      state.loading = true;
    },
    [addUpdateMilestone.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.data.message;
      state.savedAndUpdatedMilestone = action.payload.data.savedAndUpdatedMilestone;
      state.actionName = "addUpdateMilestone";
    },
    [addUpdateMilestone.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getAllMileStone.pending]: (state) => {
      state.loading = true;
    },
    [getAllMileStone.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.milestoneData = action.payload;
      state.actionName = "getAllMilestone";
    },
    [getAllMileStone.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [fetchMilestones.pending]: (state) => {
      state.loading = true;
    },
    [fetchMilestones.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.milestones = action.payload.milestones;
      state.actionName = "fetchMilestones";
    },
    [fetchMilestones.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [deleteMilestone.pending]: (state) => {
      state.loading = true;
    },
    [deleteMilestone.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.success;
      state.actionName = "deleteMilestone";
    },
    [deleteMilestone.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },

  },
});

export default milestone.reducer;

export const { resetMilestone } = milestone.actions;

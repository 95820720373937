import { createSlice } from "@reduxjs/toolkit";
import {
  saveUpdateInvoice,
  getUploadedInvoices,
  getNotUploadedInvoices,
  getInvoiceById,
  saveUpdateInvoiceActivity,
  sendInvoiceToLender,
  deleteInvoiceActivity,
  getApprovedInvoices
} from "./action";


export const operation = createSlice({
  name: "operation",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
    uploadedInvoices: [],
    notUploadedInvoices: [],
    invoiceDetails: null,
    approvedInvoices: null
  },

  reducers: {
    resetOperation: (state, action) => {
      state.success = false;
      state.actionName = '';
      state.message = null;
      state.loading = false;
      state.error = null;
    },
  },

  extraReducers: {
    [saveUpdateInvoice.pending]: (state) => {
      state.loading = true;
    },
    [saveUpdateInvoice.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "saveUpdateInvoice"
    },
    [saveUpdateInvoice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [sendInvoiceToLender.pending]: (state) => {
      state.loading = true;
    },
    [sendInvoiceToLender.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "sendInvoiceToLender"
    },
    [sendInvoiceToLender.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getUploadedInvoices.pending]: (state) => {
      state.loading = true;
    },
    [getUploadedInvoices.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.uploadedInvoices = action.payload;
      state.actionName = "getUploadedInvoices"
    },
    [getUploadedInvoices.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getNotUploadedInvoices.pending]: (state) => {
      state.loading = true;
    },
    [getNotUploadedInvoices.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.notUploadedInvoices = action.payload;
      state.actionName = "getNotUploadedInvoices"
    },
    [getNotUploadedInvoices.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getInvoiceById.pending]: (state) => {
      state.loading = true;
    },
    [getInvoiceById.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.invoiceDetails = action.payload.invoice;
      state.actionName = "getInvoiceById"
    },
    [getInvoiceById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [saveUpdateInvoiceActivity.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [saveUpdateInvoiceActivity.pending]: (state) => {
      state.loading = true;
    },
    [saveUpdateInvoiceActivity.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      state.actionName = "saveUpdateInvoiceActivity"
    },

    [deleteInvoiceActivity.pending]: (state) => {
      state.loading = true;
    },
    [deleteInvoiceActivity.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      state.actionName = "deleteInvoiceActivity"
    },
    [deleteInvoiceActivity.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getApprovedInvoices.pending]: (state) => {
      state.loading = true;
    },
    [getApprovedInvoices.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.approvedInvoices = action.payload;
      state.actionName = "getApprovedInvoices"
    },
    [getApprovedInvoices.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default operation.reducer;
export const { resetOperation } = operation.actions;

import { createSlice } from "@reduxjs/toolkit";
import { showUser, deleteUser, updateUser, showAllUsers, createUserPin, getUserByRoleName, getUserByNameNRoleType } from "./action";


export const userDetail = createSlice({
  name: "userDetail",
  initialState: {
    allusers: [],
    loading: false,
    error: null,
    searchData: [],
    success: false,
    message: null,
    actionName: null,
    userList: []
  },

  reducers: {
    searchUser: (state, action) => {
  
      state.searchData = action.payload;
    },
    resetUserDetail: (state, action) => {
      state.success = false
      state.actionName = null
    },
  },

  extraReducers: {

    [createUserPin.pending]: (state) => {
      state.loading = true;
    },
    [createUserPin.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "createUserPin"
    },
    [createUserPin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [showAllUsers.pending]: (state) => {
      state.loading = true;
    },
    [showAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.allusers = action.payload;
      state.success = true;
      state.actionName = "showAllUsers"
    },
    [showAllUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [deleteUser.pending]: (state) => {
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.loading = false;
      const { id } = action.payload;
      if (id) {
        state.users = state.users.filter((ele) => ele.id !== id);
      }
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [updateUser.pending]: (state) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "updateUser"
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getUserByRoleName.pending]: (state) => {
      state.loading = true;
    },
    [getUserByRoleName.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.roleDetails = action.payload.roleDetails;
      state.actionName = "userByRoleName"
    },
    [getUserByRoleName.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getUserByNameNRoleType.pending]: (state) => {
      state.loading = true;
    },
    [getUserByNameNRoleType.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.userList = action.payload.userList;
      state.actionName = "getUserByNameNRoleType"
    },
    [getUserByNameNRoleType.rejected]: (state, action) => {
      state.loading = false;
      state.error = "";
    },
  },
});

export default userDetail.reducer;

export const { searchUser, resetUserDetail } = userDetail.actions;

import React, { useEffect, useState } from "react";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field, Formik } from "formik";
import { notify } from "../../features/notification/notify";
import * as Yup from "yup";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { NewMilestoneModal } from "./newMilestoneModal";
import { fetchMilestones } from "../../features/milestone/action";
import { resetMilestone } from "../../features/milestone/slice";
import { addUpdateTnaTemplate } from "../../features/Tna/action";
import { resetTna } from "../../features/Tna/slice";
export const CreateTemplateTNA = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formTitle, setFormTitle] = useState("Add");
  const [allMileStones, setAllMileStones] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedMileStone, setSelectedMileStone] = useState([]);
  const [newMilestone, setNewMilestone] = useState({});
  let userData = JSON.parse(localStorage.getItem("authorization"));

  const mileStoneResponse = useSelector((state) => state.milestone);

  const tnaResponse = useSelector((state) => state.Tna);

  const [showCreateMilestoneModal, setShowCreateMilestoneModal] = useState(
    false
  );

  const [templateTNAData, setTemplateTNAData] = useState({
    id: 0,
    name: "",
    description: "",
    createdBy: userData?.user.id,
    type: "",
  });

  useEffect(() => {
    setFirstLoad(false);
    dispatch(fetchMilestones());
  }, [firstLoad]);

  useEffect(() => {
    if (state) {
      setFormTitle("Edit");
      setTemplateTNAData({
        ...templateTNAData,
        id: state.item.id,
        name: state.item.name,
        description: state.item.description,
        type: state.item.type,
      });
      let temp = [];
      state?.item?.milestones.map((data, index) => {
        temp.push({
          id: data.id,
          name: data.name,
          description: data.discription,
        });
      });
      setSelectedMileStone(temp);
    } else {
      navigate("/create-tna");
    }
  }, []);

  useEffect(() => {
    if (mileStoneResponse.actionName == "fetchMilestones" && mileStoneResponse.success) {
      setAllMileStones(mileStoneResponse.milestones);
      dispatch(resetMilestone());
    }
    if (mileStoneResponse.actionName == "addUpdateMilestone") {
      setNewMilestone(mileStoneResponse.savedAndUpdatedMilestone);

      dispatch(resetMilestone());
    }
  }, [mileStoneResponse]);

  useEffect(() => {
    if (newMilestone && newMilestone.id != null) {
      setSelectedMileStone([...selectedMileStone, newMilestone]);
    }
  }, [newMilestone]);

  useEffect(() => {
    selectedMileStone && console.log("selectedMileStone", selectedMileStone);
  }, [selectedMileStone]);

  useEffect(() => {
    if (tnaResponse.actionName == "addUpdateTnaTemplate") {
      if (tnaResponse.message == "Tna updated successfully") {
        notify(tnaResponse.message, "success");
        navigate("/template-list");
      } else {
        notify(tnaResponse.message, "success");
        setTemplateTNAData({
          id: 0,
          name: "",
          createdBy: userData?.user.id,
          description: "",
          type: "",
        });
        setSelectedMileStone([]);
        setAllMileStones([]);
        setFirstLoad(true);
        dispatch(resetTna());
      }
    }
  }, [tnaResponse]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplateTNAData({
      ...templateTNAData,
      [name]: value,
    });
  };

  const tnaSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
  });

  const handleBack = () => {
    // navigate(-1);
    navigate('/template-list');
  };

  const handleFormSubmit = (data) => {
    const allData = {
      data: data,
      selectedMileStone: selectedMileStone,
    };
    dispatch(addUpdateTnaTemplate(allData));
  };

  const handleSelectMileStone = (selectedList, selectedItem) => {
    setSelectedMileStone(selectedList);
  };

  const handleRemoveMileStone = (selectedList) => {
    setSelectedMileStone(selectedList);
  };

  const handleClickNewMilestone = () => {
    setShowCreateMilestoneModal(true);
  };

  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <Formik
            initialValues={templateTNAData}
            validationSchema={tnaSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}
          >
            {({ errors, touched, isValid, handleBlur }) => (
              <>
                <Form>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/template-list">TNA Template </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a> Create Template</a>
                      </li>
                    </ol>
                  </nav>
                  <div className="top-heading">
                    <div className="d-flex">
                      <a className="back" onClick={handleBack}>
                        &#8249;
                      </a>
                      <h2>{formTitle} Template</h2>
                    </div>

                    <div className="d-flex gap-2">
                      <button
                        onClick={handleBack}
                        type="button"
                        className="btn grey-brdr-btn"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn ornge-brdr-btn">
                        <span className="align-middle">Save</span>
                      </button>
                    </div>
                  </div>
                  <div className="user-wrap">
                    <h6>Template Details</h6>
                    <div className="row row-gap-4">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Template Name<span></span>
                          </label>
                          <input
                            value={templateTNAData.name ?? ""}
                            onChange={handleChange}
                            type="text"
                            name="name"
                            className="form-control"
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback d-block">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Description<span></span>
                          </label>
                          <input
                            value={templateTNAData.description ?? ""}
                            onChange={handleChange}
                            type="text"
                            name="description"
                            className="form-control"
                            onBlur={handleBlur}
                          />
                          {errors.description && touched.description ? (
                            <div className="invalid-feedback d-block">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Type<span></span>
                          </label>
                          <input
                            value={templateTNAData.type ?? ""}
                            onChange={handleChange}
                            type="text"
                            name="type"
                            className="form-control"
                            onBlur={handleBlur}
                          />
                          {errors.type && touched.type ? (
                            <div className="invalid-feedback d-block">
                              {errors.type}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">
                          Milestone<span className="star-color"> *</span>
                        </label>
                        <Multiselect
                          options={allMileStones}
                          selectedValues={selectedMileStone}
                          onSelect={handleSelectMileStone}
                          onRemove={handleRemoveMileStone}
                          name="milestone"
                          displayValue="name"
                        />
                        {errors.milestone && touched.milestone ? (
                          <div className="invalid-feedback d-block">
                            {errors.milestone}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <div className="mt-4">
                          <div
                            className="btn btn-rst-smpl"
                            onClick={handleClickNewMilestone}
                          >
                            + Add new milestone
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>

      {showCreateMilestoneModal && (
        <NewMilestoneModal
          showCreateMilestoneModal={showCreateMilestoneModal}
          setShowCreateMilestoneModal={setShowCreateMilestoneModal}
          setFirstLoad={setFirstLoad}
        />
      )}
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import {  fetchUserSignupValidate } from "./action";


export const userSignup = createSlice({
  name: "userSignup",
  initialState: {
    email : null,
    otp : null,
    pin: null,
    loading: false,
    error: null,
    success: false,
    loginData : null
  },

  reducers: {
    resetSignup: (state, action) => {
      state.success = false
      state.error = null
    },
  },

  extraReducers: {
    // [fetchUserLogin.pending]: (state) => {
    //   state.loading = true;
    //   state.success = false;
    // },
    // [fetchUserLogin.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.success = true;
    //   state.loginData = JSON.stringify(action.payload)
    //   localStorage.setItem('authorization', JSON.stringify(action.payload));
    // },
    // [fetchUserLogin.rejected]: (state, action) => {
    //   console.log("error on login", action)
    //   state.loading = false;
    //   state.error = action.payload;
    // },  
    [fetchUserSignupValidate.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [fetchUserSignupValidate.fulfilled]: (state, action) => {
      state.loading = false;
      //state.email = action.payload.Email;
      // state.otp = action.payload.user.otp;  
      // state.pin = action.payload.user.pin;
      state.success = true;
    },
    [fetchUserSignupValidate.rejected]: (state, action) => {
    
      state.loading = false;
      state.error = "Invalid user";
    }    
  },
});

export default userSignup.reducer;

export const { resetSignup } = userSignup.actions;

import { Modal, Button } from "react-bootstrap";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {handleDateClick} from "../../../../utils/datePickerHelper";
import moment from "moment";
import * as Yup from "yup";
import { addNewDates } from "../../../../features/po/action";
import { resetPo } from "../../../../features/po/slice";
export const RejectPOModal = (props) => {
    const { closeRejectModal, roleId, rejectPo, userId, getDates, showRejectPOModal, setShowRejectPOModal, selectedFactory, selectedMilestone } = props
    const dispatch = useDispatch();
    const poResponse = useSelector((state) => state.po);
    const [newDates, setNewDates] = useState({ startDate: null, endDate: null, reason: null })

    useEffect(() => {
        if ((selectedFactory, selectedMilestone)) {
            let temp = selectedFactory;
        }
    }, [selectedFactory, selectedMilestone]);

    useEffect(() => {
        if (poResponse.actionName == "addNewDates" && poResponse.success) {
            dispatch(resetPo());
            setShowRejectPOModal(false);
            setNewDates({ startDate: null, endDate: null, reason: null });
        }
    }, [poResponse]);

    const closeModal = () => {
        setShowRejectPOModal(false);
        closeRejectModal()
    };

    const preventNegativeValues = (e) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const handleChange = (e) => {
        setNewDates({ ...newDates, [e.target.name]: e.target.value });
    };

    const submitData = () => {
        var payload = {
            startDate: newDates.startDate,
            endDate: newDates.endDate,
            factoryMilestoneId: selectedMilestone.id,
            createdBy: userId,
            reason: newDates.reason,
        };
        dispatch(addNewDates(payload));
        rejectPo(selectedMilestone, null)
    };

    const rejectPoModalSchema = Yup.object().shape({
        startDate: Yup.date()
            .required("Start date is required")
            .typeError("Invalid start date"),
        endDate: Yup.date()
            .required("End date is required")
            .typeError("Invalid end date")
            .min(Yup.ref("startDate"), "End date cannot be before start date"),
        reason: selectedMilestone?.poFactoryMilestoneLogs.length > 0 ? Yup.string()
            .transform((value, originalValue) =>
                originalValue === null ? "" : value
            )
            .required("Reason is required") : null,
    });

    // const handleDateClick = (e) => {
    //     if (e.target && typeof e.target.showPicker === 'function') {
    //         e.target.showPicker();
    //     }
    // };

    return (
        <Modal
            show={showRejectPOModal}
            onHide={closeModal}
            className="custom-modal po-mlsn"

        >
            <Formik
                initialValues={newDates}
                validationSchema={rejectPoModalSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    submitData();
                }}
            >
                {({ errors, touched, isValid, dirty, handleBlur }) => (
                    <>
                        <Form>
                            <Modal.Header>
                                <h1 className="modal-title" id="edit-profile-Label">
                                    Propose new time frame
                                </h1>
                            </Modal.Header>
                            <Modal.Body>
                                <row className="centre">
                                    <div className="col-lg-12 col-md-12">
                                        <label className="form-label">
                                            Default start date:{" "}
                                            <span className="ttlqty-smpl">
                                                {getDates(selectedMilestone, "start")}
                                            </span>
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label className="form-label">
                                            Default end date:{" "}
                                            <span className="ttlqty-smpl">
                                                {getDates(selectedMilestone, "end")}
                                            </span>
                                        </label>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row dv-tmfrm">
                                                <div className="col-md-3  form-label">
                                                    New start date
                                                    <input
                                                        type="date"
                                                        name="startDate"
                                                        value={
                                                            newDates.startDate
                                                                ? moment(newDates.startDate).format(
                                                                    "yyyy-MM-DD"
                                                                )
                                                                : ""
                                                        }
                                                        onClick={handleDateClick}
                                                        onChange={handleChange}
                                                        min={new Date().toISOString().split("T")[0]}
                                                        className="form-control"
                                                    />
                                                    {errors.startDate && touched.startDate ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.startDate}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="col-md-3  form-label">
                                                    New end date
                                                    <input
                                                        type="date"
                                                        name="endDate"
                                                        value={
                                                            newDates.endDate
                                                                ? moment(newDates.endDate).format("yyyy-MM-DD")
                                                                : ""
                                                        }
                                                        onClick={handleDateClick}
                                                        onChange={handleChange}
                                                        min={new Date().toISOString().split("T")[0]}
                                                        className="form-control"
                                                    />
                                                    {errors.endDate && touched.endDate ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.endDate}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {selectedMilestone.poFactoryMilestoneLogs.length > 0 &&
                                                    <div className="col-md-6  form-label">
                                                        Reason
                                                        <input
                                                            onChange={handleChange}
                                                            type="text"
                                                            value={newDates.reason}
                                                            name="reason"
                                                            className="form-control"
                                                        />
                                                        {errors.reason && touched.reason ? (
                                                            <div className="invalid-feedback d-block">
                                                                {errors.reason}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </row>
                            </Modal.Body>
                            <Modal.Footer>
                                <a
                                    type="button"
                                    className="btn inner-blue-border-btn"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </a>
                                <Button type="submit" className="btn inner-blue-btn">
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { notify } from "../../features/notification/notify";
import { CONSTANT } from "../../utils/constant";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import { v1 as uuidv1 } from "uuid";
import { confirmAlert } from "react-confirm-alert";
import { poTemplateBulkUpload } from "../../features/po/action";
import {
  deletePO,
  getAllPOs,
  getAllTemplates,
  addTemplateToPo,
  exportPOTemplate,
} from "../../features/po/action";
import { resetPo } from "../../features/po/slice";
import { AttachTNAModal } from "./attachTNAModal";
import { AssignFactoryModal } from "./assignFactoryModal";
import { Button } from "react-bootstrap";

export const POList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const [attachTnaModal, setAttachTnaModal] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [allPOs, setAllPOs] = useState([]);
  const [allPOsFactory, setAllPOsFactory] = useState([]);
  const [ttlPO, setTtlPO] = useState(0);
  const [ttlPOFactory, setTtlPOFactory] = useState(0);
  const poResponse = useSelector((state) => state.po);
  const [showAssignFactory, setShowAssignFactory] = useState(false);
  const [exportPOIds, setExportPOIds] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [selectedPo, setSelectedPo] = useState();
  const [fileJson, setFileJson] = useState();
  const [templates, setTemplates] = useState({
    template: null,
    poId: null,
  });
  const factoryId = localStorage.getItem("userId");
  const role = localStorage.getItem("roleId");
  const loggedInUser = parseInt(localStorage.getItem("userId"));
  useEffect(() => {
    callAllPO();
    callAllTemplate();
  }, [currentPage, itemCount]);

  const callAllPO = () => {
    dispatch(
      getAllPOs({
        page: currentPage + 1,
        limit: itemCount,
        userId: factoryId,
        roleId: role,
      })
    );
  };

  const callAllTemplate = () => {
    dispatch(getAllTemplates());
  };

  useEffect(() => {
    if (poResponse.actionName == "getAllPOs" && poResponse.success) {
      setAllPOs(poResponse.poList);
      const filteredPOs = poResponse.poList[0].filter(
        (item) => item.status == 1
      );
      setAllPOsFactory(filteredPOs);
      setTtlPOFactory(filteredPOs.length);
      setTtlPO(poResponse.poList[1]);
      if (poResponse.poList[1] !== null && pageCount === 0) {
        setPageCount(Math.ceil(poResponse.poList[1] / itemCount));
      }
    }
    if (poResponse.actionName == "getAllTemplates" && poResponse.success) {
      setAllTemplates(poResponse.templateList);
      setAttachTnaModal(false);
      setTemplates({
        template: null,
        poId: null,
      });
      dispatch(resetPo());
    }
    if (poResponse.actionName == "addTemplateToPo" && poResponse.success) {
      setAttachTnaModal(false);
      notify("Template added to PO", "success");
      callAllPO();
    }
    if (poResponse.actionName == "deletePO" && poResponse.success) {
      notify(poResponse.message, "success");
      callAllPO();
    }

    if (poResponse.actionName == "poTemplateBulkUpload" && poResponse.success) {
      notify("File uploaded successfully", "success");
      setInvoice([]);
      dispatch(resetPo());
      callAllPO();
    }

  }, [poResponse]);

  //   const goToEditPage = (item) => {
  //     navigate("/create-po", { state: { item } });
  //   };
  const handleDeletepo = (id) => {
    dispatch(deletePO({ id: id }));
  };

  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure want to delete this PO?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleDeletepo(id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleAttachTNA = (id) => {
    setAttachTnaModal(true);
    setTemplates({
      template: null,
      poId: id,
    });
  };

  const handleAssignFactory = (po) => {
    setSelectedPo(po);
    setShowAssignFactory(true);
  };

  const handleAttachTNAClose = () => {
    setAttachTnaModal(false);
  };

  const handleTnaAttachSubmit = (values) => {
    dispatch(addTemplateToPo(values));
  };

  const handleChange = (e) => {
    setTemplates({
      ...templates,
      [e.target.name]: e.target.value,
    });
  };
  const handleInfoPage = (po) => {
    if (po.poFactories && po.poFactories.length > 0)
      navigate("/po-info", {
        replace: true,
        state: { poId: po.id },
      });
    else notify("Details can be seen only after assigning Factory!", "info");
  };

  const handleCheckBoxClick = (e, id) => {

    if (e.target.checked === true) {

      setExportPOIds([...exportPOIds, id])
    } else {
      setExportPOIds(exportPOIds.filter(item => item !== id));
    }
  };

  const totalSampleQty = (item) => {
    return item?.poSamples != undefined
      ? item?.poSamples.reduce(
        (accumulator, current) => accumulator + current.quantity,
        0
      )
      : "";
  };

  const handlePOTemplateExport = () => {
    dispatch(exportPOTemplate({
      poIds: exportPOIds
    }))
    notify("Exporting template please wait.", "success")
  }

  const handleFileSheetUpload = (e, type) => {
    const fileExtension = e.target.files[0]?.name
      .split(".")
      .pop()
      .toLowerCase();
    var acceptedFormats = ["xlsx", "xls"];
    if (!acceptedFormats.includes(fileExtension)) {
      notify("File format not supported.", "error");
    } else {
      const { file, newImageName } = getFile(e, fileExtension);
      if (type === "invoice") {
        setInvoice((invoice) => [
          ...invoice,
          { id: 0, file: file, fileName: newImageName, isDeleted: false },
        ]);
      }
    }
  };

  useEffect(() => {
    if (invoice.length != 0) {
      ExcelToJsonConverter();
    }
  }, [invoice]);

  const ExcelToJsonConverter = () => {
    const reader = new FileReader();
    const file = invoice[0].file;

    const excelDateToJSDate = (serial) => {
      const excelEpoch = new Date(1899, 11, 30);
      const jsDate = new Date(excelEpoch.getTime() + serial * 86400000);
      return jsDate;
    };
  
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };
  
    reader.onload = (e) => {
      
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });
      const sheetNames = workbook.SheetNames;
      const allJson = {};
      
      for (let sheet of sheetNames) {
        
        const worksheet = workbook.Sheets[sheet];
        let json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
        const headers = json[0].map((header) =>
          header.replace(/[^a-zA-Z0-9]/g, "")
        );
           
        json =json.filter(row => {
          return Object.values(row).some(value => value !== "");
        });

        json = json.slice(1).map((row) => {
          
          let rowObject = {};
          row.forEach((cell, index) => {
            let formattedCell = cell;
            if (typeof cell === "number" && cell > 40000) {
              const jsDate = excelDateToJSDate(cell);
              formattedCell = formatDate(jsDate);
            }
            rowObject[headers[index]] = formattedCell;
          });
          
          return rowObject;
        });
        
        allJson[sheet] = json;
      }
      
      setFileJson(allJson);
    };
  
    reader.readAsArrayBuffer(file);
  };
  
  useEffect(() => {
    if (fileJson) {
      navigate("/previewBulkpo", { state: { fileJson, loggedInUser } });
    }
  }, [fileJson]);

  const getFile = (e, fileExtension) => {
    const image = e.target.files[0];
    const blob = image.slice(0, image.size);
    const id = uuidv1();
    // const newImageName = id + "." + fileExtension;
    const newImageName = e.target.files[0]?.name;
    const file = new File([blob], newImageName);
    return { file, newImageName };
  };

  const handleFileUpload = () => {
    if (invoice.length != 0) {
      let formData = new FormData();
      const fileNames = [];
      invoice.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({
          id: fileSet.id,
          fileType: "invoice",
          fileName: fileSet.fileName,
          isDeleted: fileSet.isDeleted,
        });
      });
      formData.append("fileList", JSON.stringify(fileNames));
      //formData.append("userType", document.getElementById("UserType").value);
      dispatch(poTemplateBulkUpload(formData));
    }
  };
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <div className="top-heading">
            <div className="d-flex">
              <h2>PO</h2>
            </div>
            <div className="release-button">
              <div>
                <button className="btn ornge-btn" disabled={exportPOIds.length > 0 ? false : true} onClick={handlePOTemplateExport}>Export Template</button>
                &nbsp;&nbsp;
                <input type="file"
                  id="fileUpload"
                  name="invoice"
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  className="form-control"
                  onChange={(event) => {
                    handleFileSheetUpload(event, "invoice");
                  }} />

                <button onClick={handleClick} className="btn ornge-btn" style={{ marginBottom: '4px' }} >Upload File</button>
                  {invoice.length == 0 ? "" : <button
                    type="button"
                    className="small-ornge-brdr-btn"
                    onClick={handleFileUpload}
                  >
                    Save File &#x2B07;
                  </button>}

              </div>
            </div>
            {/* <a className="btn ornge-brdr-btn" href="/create-po">
                <span className="align-middle">&#43; Add New PO</span>
              </a> */}
          </div>
          {allPOs && (
            <div className="rfq-status-table tab-content">
              {ttlPO > 0 && (
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        {/* <th></th> */}
                        <th>Name</th>
                        <th>Description</th>
                        <th>TNA Name</th>
                        <th>RFQ Name</th>
                        <th>Quantity</th>
                        {role != CONSTANT.USER_ROLE.SUPPLIER &&
                          role != CONSTANT.USER_ROLE.BUYER && (
                            <th>Attachments</th>
                          )}
                        <th>Status</th>
                        {role == CONSTANT.USER_ROLE.ADMIN && <th>Action</th>}
                      </tr>
                    </thead>

                    <tbody>
                      {role != CONSTANT.USER_ROLE.BUYER &&
                        role != CONSTANT.USER_ROLE.SUPPLIER
                        ? allPOs[0] &&
                        allPOs[0].map((item, index) => (
                          <tr key={index}>
                            <td className="checkbox" >
                              <input className="me-2  form-check-input" type="checkbox" disabled={item.poFactories.length > 0 ? false : true } onClick={(e) => handleCheckBoxClick(e, item.id)} />

                              <a onClick={() => handleInfoPage(item)}>
                                {item.poName}
                              </a>
                            </td>
                            <td>{item.description}</td>
                            <td>{item.tnaTemplate?.name}</td>
                            <td>{item.rfqDetail?.rFQName}</td>
                            <td>{totalSampleQty(item)}</td>
                            {role != CONSTANT.USER_ROLE.BUYER &&
                              role != CONSTANT.USER_ROLE.SUPPLIER && (
                                <td>
                                  {item.tnaTemplate?.name == null ? (
                                    <div>
                                      <button
                                        className="border-0"
                                        title="Attach TNA"
                                        onClick={(e) =>
                                          handleAttachTNA(item.id)
                                        }
                                      >
                                        Attach TNA
                                      </button>
                                    </div>
                                  ) : (
                                    <div>
                                      {item.poFactories &&
                                        item.poFactories.length > 0 ? (
                                        <div>
                                          {item.poFactories.length +
                                            " Factory(s)"}
                                        </div>
                                      ) : (
                                        <button
                                          className="border-0"
                                          title="Assign Factory"
                                          onClick={(e) =>
                                            handleAssignFactory(item)
                                          }
                                        >
                                          Assign Factory
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </td>
                              )}
                            <td>{item.progress}</td>
                            {role == CONSTANT.USER_ROLE.ADMIN && (
                              <td>
                                <a
                                  title="Delete PO"
                                  onClick={(e) => handleDelete(item.id)}
                                >
                                  <i
                                    className="fa fa-trash px-3"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            )}
                          </tr>
                        ))
                        : allPOsFactory &&
                        allPOsFactory.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <a onClick={() => handleInfoPage(item)}>
                                {item.poName}
                              </a>
                            </td>
                            <td>{item.description}</td>
                            <td>{item.tnaTemplate?.name}</td>
                            <td>{item.rfqDetail?.rFQName}</td>
                            <td>{totalSampleQty(item)}</td>
                            {role != CONSTANT.USER_ROLE.BUYER &&
                              role != CONSTANT.USER_ROLE.SUPPLIER && (
                                <td>
                                  {item.tnaTemplate?.name == null ? (
                                    <div>
                                      <button
                                        className="border-0"
                                        title="Attach TNA"
                                        onClick={(e) =>
                                          handleAttachTNA(item.id)
                                        }
                                      >
                                        Attach TNA
                                      </button>
                                    </div>
                                  ) : (
                                    <div>
                                      {item.poFactories &&
                                        item.poFactories.length > 0 ? (
                                        <div>
                                          {item.poFactories.length +
                                            " Factory(s)"}
                                        </div>
                                      ) : (
                                        <button
                                          className="border-0"
                                          title="Assign Factory"
                                          onClick={(e) =>
                                            handleAssignFactory(item)
                                          }
                                        >
                                          Assign Factory
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </td>
                              )}
                            <td>{item.progress}</td>
                            {role == CONSTANT.USER_ROLE.ADMIN && (
                              <td>
                                <a
                                  title="Delete PO"
                                  onClick={(e) => handleDelete(item.id)}
                                >
                                  <i
                                    className="fa fa-trash px-3"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {ttlPO > CONSTANT.PAGE_LIMIT && (
                    <nav className="pagination-block">
                      <p className="total">{ttlPO} items total</p>

                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        forcePage={currentPage}
                        onPageChange={handlePageChange}
                        containerClassName={
                          "pagination justify-content-center mt-4"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                      <div className="customSelect">
                        <label htmlFor="">Items per page :</label>
                        <div className="selectBox">
                          <select name="" id="" onChange={handleItemsChange}>
                            <option value={CONSTANT.PAGE_LIMIT}>
                              {CONSTANT.PAGE_LIMIT}
                            </option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                          </select>
                        </div>
                      </div>
                    </nav>
                  )}
                </div>
              )}
              {role !== CONSTANT.USER_ROLE.BUYER &&
                role !== CONSTANT.USER_ROLE.SUPPLIER
                ?
                ttlPO === 0 && <div>No record available!</div>
                :
                ttlPOFactory === 0 && <div>No record available!</div>}
            </div>
          )}
        </div>
      </div>
      <AttachTNAModal
        attachTnaModal={attachTnaModal}
        handleAttachTNAClose={handleAttachTNAClose}
        handleTnaAttachSubmit={handleTnaAttachSubmit}
        templates={templates}
        allTemplates={allTemplates}
        handleChange={handleChange}
      ></AttachTNAModal>
      <AssignFactoryModal
        callAllPO={callAllPO}
        selectedPo={selectedPo}
        setSelectedPo={setSelectedPo}
        showAssignFactory={showAssignFactory}
        setShowAssignFactory={setShowAssignFactory}
      ></AssignFactoryModal>
    </>
  );
};

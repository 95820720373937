import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URLS } from "../../utils/api.urls";
import { useDispatch, useSelector } from "react-redux";
import { addSampleToRfq, getSampleByRfqId } from "../../features/rfq/action";
import { resetRfq } from "../../features/rfq/slice";
import commentImg from "../../assets/images/comments.png";
import { notify } from "../../features/notification/notify";
import { ToastContainer } from 'react-toastify';
import { CONSTANT } from "../../utils/constant";
import { addCommentToRfqSample, getRfqSampleComments } from "../../features/rfqComment/action";
import { resetRfqSampleComment } from "../../features/rfqComment/slice";
import moment from 'moment'

export const RFQSamples = (props) => {
    const { rfqId } = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const rfqResponse = useSelector((state) => state.rfq);
    const rfqSampleCommentResponse = useSelector((state) => state.rfqSampleComment);
    const [samples, setSamples] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSampleComment, setSelectedSampleComment] = useState(null);
   
    const [inputValue, setInputValue] = useState();
    const [allRfqSampleComments, setAllRfqSampleComments] = useState([])


    let currentUser = JSON.parse(localStorage.getItem("authorization"));
    const currentUserRoleType = localStorage.getItem("roleType");
    const currentUserRoleName = currentUser.roles[0].roleName;
    const userId = localStorage.getItem("userId");

    let selectedAudience = currentUserRoleType == 'Internal' ? CONSTANT.DEFAULT_AUDIENCE_FOR_INTERNAL : CONSTANT.DEFAULT_AUDIENCE_FOR_OTHER ;
    
    if(currentUserRoleName ==='Buyer' )
       selectedAudience = CONSTANT.USER_ROLE.BUYER;
    else if(currentUserRoleName=='Supplier')
       selectedAudience = CONSTANT.USER_ROLE.SUPPLIER;

    const [commentPayload, setCommentPayload] = useState({
        rfqSampleId: 0,
        fromId: currentUser?.user?.id,
        audience: selectedAudience,
        comment: "",
        id: 0
    })

    useEffect(() => {
        if (rfqId) {
            dispatch(getSampleByRfqId(rfqId))     
        }

    }, [rfqId]);

    const callRfqSampleComments = (rfqSampleId) => {
        let getCommentsParams = {
            userRole: currentUserRoleName,
            rfqSampleId: rfqSampleId,
            userId: userId
        }

        dispatch(getRfqSampleComments(getCommentsParams))
    }

    const fetchSuggestions = async (query) => {
        if (query?.trim().length > 2) {
            const filteredSuggestions = await fetch(`${API_URLS.SAMPLE.GET_SAMPLES_BY_NAME}/${query}`)
                .then((response) => response.json());
            setSuggestions(filteredSuggestions.result);
        } else {
            setSuggestions([]);
        }
    }

    const handleClick = () => {
        navigate("/rfq-sample-list", {
            replace: true,
            state: { rfqId: rfqId },
        });
    }

    const handleSelectSample = (id) => {
        setSuggestions([]);
        setInputValue('');
        var payload = [{ rfqId: rfqId, sampleId: id,isChecked:true }]
        dispatch(addSampleToRfq(payload));
    }

    useEffect(() => {
        if (rfqResponse.actionName == 'addSampleToRfq' && rfqResponse.success) {
            dispatch(getSampleByRfqId(rfqId));           
        }
        if (rfqResponse.actionName === "getSampleByRfqId" && rfqResponse.rfqSampleList) {
            setSamples(rfqResponse.rfqSampleList.rfqSamplesInfo);
            dispatch(resetRfq());
        }

    }, [rfqResponse]);

    useEffect(() => {
        if (rfqSampleCommentResponse.success && rfqSampleCommentResponse.actionName == 'addCommentToRfqSample') {
            notify("Comment added successfully", "success");
            setCommentPayload({
                ...commentPayload,
                comment: ""
            })
            callRfqSampleComments(commentPayload.rfqSampleId);
            dispatch(resetRfqSampleComment());

        }
        if (rfqSampleCommentResponse.success && rfqSampleCommentResponse.actionName == 'getRfqSampleComments') {
            setAllRfqSampleComments(rfqSampleCommentResponse.rfqSampleComments)
        }
    }, [rfqSampleCommentResponse])

    const handleChangeText = (e, rfqSampleId) => {
        setCommentPayload({
            ...commentPayload,
            comment: e.target.value,
            rfqSampleId: selectedSampleComment,
        })

    }

    const handleSaveComment = () => {
        dispatch(addCommentToRfqSample(commentPayload));
    }

    const handleSelectedRadio =(id)=>{
       setCommentPayload({
            ...commentPayload,
            audience:id
        })
    }
   
    const handleCommentClick = (commentSampleId) => {
        
        setSelectedSampleComment(commentSampleId);
        callRfqSampleComments(commentSampleId);
    }
    return (
        <>
            <div className="body-wrap">
                <div className="top-heading">
                    { localStorage.getItem("roleType") == "Internal" &&
                        <>
                            <div className="row">
                                <div className="search-top col-md-8">
                                    <input
                                        type="text"
                                        placeholder="Search samples"
                                        value={inputValue}
                                        onChange={(event) => {
                                            fetchSuggestions(event.target.value);
                                            setInputValue(event.target.value)
                                        }}
                                    />
                                    {suggestions.length > 0 && (
                                        <ul className="ul-sggt">
                                            {suggestions.map((suggestion) => (
                                                <li onClick={(e) => handleSelectSample(suggestion.id)} key={suggestion.id} value={suggestion.id}>{suggestion.sampleName}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="col-md-4">
                                    <a className="btn ornge-brdr-btn btn-smll-orng" onClick={handleClick}>
                                        <span className="align-middle">&#43; Add New Sample</span>
                                    </a>
                                </div>
                            </div>
                        </>}
                </div>
                <div className="row row-gap-4">

                    {samples && samples.length > 0 &&
                        samples.map((sample, Index) => (
                            <div className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6" key={Index}>
                                <div className="category-item">
                                    <div className="category-img" style={{ cursor: 'pointer' }} >
                                        {sample.sampleDetail?.sampleFiles &&
                                            sample.sampleDetail.sampleFiles
                                                .filter((file) => file.fileType === "image")
                                                .slice(0, 1)
                                                .map((image, imageIndex) => (
                                                    <img
                                                        key={imageIndex}
                                                        src={image.filePath}
                                                        alt={`sample-${Index}-main-image`}
                                                    />
                                                ))}
                                        <div className="category-side-img">
                                            {sample.sampleDetail.sampleFiles &&
                                                sample.sampleDetail.sampleFiles
                                                    .filter((file) => file.fileType === "image")
                                                    .slice(1)
                                                    .map((sideFile, sideFileIndex) => (
                                                        <img
                                                            key={sideFileIndex}
                                                            src={sideFile.filePath}
                                                            alt={`sample-${Index}-side-image-${sideFileIndex + 1
                                                                }`}
                                                        />
                                                    ))}
                                        </div>
                                    </div>
                                    <div className="category-detail">
                                        <div className="category-row">
                                            <div style={{ cursor: 'pointer' }}>
                                                <span className="icon">{sample.sampleId}</span>
                                                {sample.sampleDetail.sampleName}
                                            </div>
                                        </div>
                                        <div className="row" style={{ cursor: 'pointer' }} >
                                            <div className="col-4" title={sample.sampleDetail.User ? sample.sampleDetail.User.name : ""}>
                                                <span>Buyer</span>
                                                <div className="category-name">{sample.sampleDetail.User ? sample.sampleDetail.User.name : "--"}</div>
                                            </div>
                                            <div className="col-4" title={sample.sampleDetail ? sample.sampleDetail.sampleName : ""}>
                                                <span>Product</span>
                                                <div className="category-name">
                                                    {sample.sampleDetail ? sample.sampleDetail.sampleName : "--"}
                                                </div>
                                            </div>
                                            <div className="col-4" title={sample.sampleDetail ? sample.sampleDetail.season.sampleName : ""}>
                                                <span>Season</span>
                                                <div className="category-name">{sample.sampleDetail.season ? sample.sampleDetail.season.seasonName:"--"}</div>
                                            </div>
                                        </div>
                                        <div className="showcomment_btn mt-2">
                                            <img onClick={() => handleCommentClick(sample.id)} src={commentImg} alt="comments" />
                                        </div>
                                    </div>


                                    {selectedSampleComment === sample.id && (
                                    <div className="comment_section">
                                        
                                        <div className="body_comment">
                                            <div className="row">
                                                <div className="box_comment col-md-12">
                                                    <textarea
                                                        className="commentar"
                                                        rows={3}
                                                        placeholder="Add a comment..."
                                                        spellcheck="false"
                                                        value={commentPayload.comment}
                                                        onChange={(e) => handleChangeText(e, sample.sampleId)}
                                                    >{commentPayload.comment}</textarea>

                                                    <div className="box_post">
                                                    {currentUserRoleType == 'Internal' &&   <div className="pull-left row">
                                                            {CONSTANT.COMMENTS_AUDIENCE.map((audience, audienceIndex) => (
                                                                <div className="col-6" key={audienceIndex}>
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            onClick={(e)=>handleSelectedRadio(audienceIndex + 1)}
                                                                            name="commentradio"
                                                                            id={"commentradio" + (audienceIndex + 1)}
                                                                            defaultChecked={(audienceIndex + 1) == selectedAudience ? true : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor={"commentradio" + (audienceIndex + 1)}
                                                                        >
                                                                            {audience}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div> }

                                                        <div className="pull-right">
                                                            <button onClick={handleSaveComment} type="button" value="1">
                                                                Post
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <ul id="list_comment" className="col-md-12">

                                                {allRfqSampleComments && allRfqSampleComments.map((comment, commentIndex) => (

                                                <li className="box_result row" key={commentIndex}>
                                                    {/* <div className="avatar_comment col-md-6">
                                                        <img src="https://static.xx.fbcdn.net/rsrc.php/v1/yi/r/odA9sNLrE86.jpg" alt="avatar" />
                                                    </div> */}
                                                    <div className="tools_comment">
                                                        <h4>{comment.User.name}</h4>

                                                        <span>{moment(comment.DateTime).fromNow()}</span>
                                                    </div>
                                                    <div className="result_comment col-md-11">
                                                        <p>
                                                        {comment.Comment}
                                                        </p>

                                                        <ul className="child_replay"></ul>
                                                    </div>
                                                </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    )}

                                </div>
                            </div>
                        ))}
                </div>
                <div className="row row-gap-4 text-center">
                    {!samples || samples.length == 0 && <h3>No sample added yet.</h3>}
                </div>
            </div>
        </>
    );
}
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { notify } from "../../features/notification/notify";
import { Link, useNavigate } from 'react-router-dom';
import OTPInput from "otp-input-react";
import loginImg from "../../assets/images/Login.svg";
import logoImg from "../../assets/images/logo-black.avif";
import dangerImg from "../../assets/images/danger.svg";
import { createUserPin } from "../../features/userDetail/action";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { resetUserDetail } from "../../features/userDetail/slice";
import {useSearchParams} from "react-router-dom";
import { LoginBannerImage } from "./loginBannerImage";

export const LoginSetPin = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pinError, setPinError] = useState("d-none");
  const [submitSetDisabled, setSubmitSetDisabled] = useState(true);
  const [searchParams] = useSearchParams();

  const userResponse = useSelector((state) => state.userDetail);
  const email = searchParams.get("email");

  const handleSetConfirmPin = (value) => {
    setConfirmPin(value);
    setSubmitSetDisabled(true);
    setPinError("d-none");
    if (pin.length == value.length) {
      if (pin != value) {
        setPinError("");
      }
      setSubmitSetDisabled(false);
    }
    
  };

  const handleFinalSubmit = (type) => {
    if (type == "skip") {
      // handleClose();
    } else if (pin == confirmPin && type == "set") {
      let pindata = {
        emailId:email,
        pin: pin,
      };
      dispatch(createUserPin(pindata));
    }
  };

  useEffect(() => {
    if (userResponse.success === true) {
      dispatch(resetUserDetail());
      notify(
        userResponse.message != null ? userResponse.message : "Success",
        "success"
      );
       navigate('/login');
    }
    if (userResponse.error) {
      notify(userResponse.error, "error");
    }
  }, [userResponse]);

  return (
    <>
      <ToastContainer />
        <div className="row section-login-banner login-wrap">
        <div className="col-custom-64 login-banner">
        <LoginBannerImage />
        </div>
        <div className="contain col-custom-36 form-div">

          <div className='logoContainer position-relative'><img src={logoImg} alt="logo" className="login-logo" />
            <span className="position-relative login-logo-text" >Democratising Manufacturing</span>
          </div>
          <div className="form-wrap mt-4">
          <h2>Set PIN</h2>
          <p className="note">Please setup your 6 digit security pin</p>
          <form>
        <h3>Enter PIN</h3>
        <div className="mb-30">
          <div className="enter-otp-input">
            <OTPInput
              className="otp-input-root"
              value={pin}
              onChange={setPin}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              secure
            />
          </div>
        </div>
        <h3>Confirm PIN</h3>
        <div className="mb-30">
          <div className="enter-otp-input">
            <OTPInput
              className="otp-input-root"
              value={confirmPin}
              onChange={handleSetConfirmPin}
              OTPLength={6}
              otpType="number"
              disabled={false}
              secure
            />
          </div>
          <div className={"error-msg " + pinError}>
            <img src={dangerImg} alt="danger" className="align-middle" />
            <span className="align-middle">
              PIN do not match. Try again!
            </span>
          </div>
        </div>
        <button
          type="button"
          className="btn blue-btn"
          disabled={submitSetDisabled}
          onClick={() => {
            handleFinalSubmit("set");
          }}
        >
          Set pin
        </button>
      </form>
        </div>
        </div>
      </div>
    </>
  );
};

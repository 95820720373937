import React, { useEffect, useState } from 'react'
import loginImg from "../../assets/images/login.png";
import logoImg from "../../assets/images/logo.svg";
import { useDispatch, useSelector } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { fetchUserSignupValidate } from '../../features/signup/action';
import { notify } from '../../features/notification/notify';
import { resetSignup } from '../../features/signup/slice';
import { ToastContainer } from 'react-toastify';

export const Signup = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [schemaType, setSchemaType] = useState("credential");
  const [email, setemail] = useState("");
  const [user, setUser] = useState({
    credential: "",
  });

  const signupResponse = useSelector((state) => state.userSignup);

  const handleGetOtp = (data) => {

    let newData = {
      email: email
    }

    dispatch(fetchUserSignupValidate(newData));
  }

  const handleChange = (e) => {
    setUser({
      ...user,
      credential: e.target.value,
    });
    setSchemaType("credential");
    setemail(e.target.value);
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  let credentialschema;

if (email.includes('@')) {
  credentialschema = Yup.object().shape({
    credential: Yup.string().email('Email must be valid').min(2, 'Too Short!').required('Required'),
  });
} else {
  credentialschema = Yup.object().shape({
    credential: Yup.string()
      .required('Enter an email or phone number')
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(10, 'Too short')
      .max(10, 'Too long'),
  });
}



  useEffect(() => {

    if (signupResponse.success === true) {
      notify("An confirmation mail is sent to your email. Please verify it to complete the account creation process", "success");
      dispatch(resetSignup());

      // if (loginResponse.pin == null || loginResponse.pin == "") {
      //   navigate('/login-otp', { replace: true, state: { type: schemaType, email: email } });

      // } else {
      //   navigate('/login-pin', { replace: true, state: { type: schemaType, email: email } });

      // }
    }
    if (signupResponse.error) {
      dispatch(resetSignup())
      notify(signupResponse.error, "error");
    }
  }, [signupResponse]);

  return (
    <>
      <ToastContainer />

      <div className="login-wrap">
        <div className="logo-div">
          <img src={loginImg} alt="LOGIN" />
        </div>
        <div className="form-div">
          <a href="#"><img src={logoImg} alt="logo" className="login-logo" /></a>
          <div className="form-wrap">
            <h2>Sign up </h2>
            <p className="note">Welcome to your account! Please enter your details.</p>
            <Formik
              initialValues={user}
              validationSchema={credentialschema}
              enableReinitialize={true}
              onSubmit={(values) => {
                handleGetOtp(values)
              }}
            >
              {({ errors, touched, isValid, handleBlur }) => (
                <>
                  <Form>
                    <div className="credential">
                      <label className="form-label">Enter Email Or Phone Number</label>
                      <input type="text" value={user.credential}
                        name="credential"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.credential && touched.credential ? (
                        <div className="invalid-feedback d-block">
                          {errors.credential}
                        </div>
                      ) : null}
                    </div>
                    {signupResponse.error ? (
                      <div className="invalid-feedback d-block">
                        {signupResponse.error}
                      </div>
                    ) : null}
                    {user.error ? (
                      <div className="invalid-feedback d-block">
                        {user.error}
                      </div>
                    ) : null}
                    <button className="btn blue-btn " type="submit"
                      disabled={!(isValid)}
                    >Proceed</button>
                    <Link className="" to='/login' >Login</Link>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

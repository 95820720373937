import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Table, Button, Row, Col, Container } from "react-bootstrap";
import LeftMenu from "../layouts/leftMenu";
import * as Yup from "yup";
import { Header } from "../layouts/header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBulkRfq } from "../../features/rfq/action";
import { handleDateClick } from "../../utils/datePickerHelper";
import { poTemplateBulkUpload } from "../../features/po/action";

export const PoBulkUploadPreview = () => {
  const location = useLocation();
  const poResponse = useSelector((state) => state.po);
  const { fileJson, loggedInUser } = location.state;
  const jsonDataObject = typeof fileJson === 'string' ? JSON.parse(fileJson) : fileJson;
  const [editedData, setEditedData] = useState(jsonDataObject);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCellEdit = (templateName, rowIndex, key, value) => {
    const updatedData = { ...editedData };
    updatedData[templateName][rowIndex][key] = value;
    setEditedData(updatedData);
  };

  const handleSubmit = (values) => {
    const data = {
      pos: values,
      createdBy: loggedInUser,
    };
    dispatch(poTemplateBulkUpload(data));
  };

  useEffect(() => {
    if (poResponse.actionName === "poTemplateBulkUpload" && poResponse.success) {
      navigate("/po-list");
    }
  }, [poResponse, navigate]);

  const handleBack = () => {
    navigate("/po-list");
  };

  const dateHandler = (key) => {
    return key.toLowerCase().includes("date");
  };

  const buildValidationSchema = (data) => {
    const schema = Object.keys(data).reduce((acc, templateName) => {
      acc[templateName] = Yup.array().of(
        Yup.object().shape(
          Object.keys(data[templateName][0]).reduce((fields, key) => {
            if (dateHandler(key)) {
              fields[key] = Yup.date().required('Date is required').nullable();
            }
            return fields;
          }, {})
        )
      );
      return acc;
    }, {});
    return Yup.object().shape(schema);
  };

  const validationSchema = buildValidationSchema(jsonDataObject);

  return (
    <>
      <Formik
        initialValues={editedData}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values, errors, touched }) => (
          <Form>
            <LeftMenu />
            <div className="right-column">
              <Header />
              <div className="body-wrap">
                <div className="top-heading">
                  <div className="d-flex gap-2 align-items-center flex-wrap">
                    <h2>POs Bulk Upload</h2>
                  </div>
                  <div className="d-flex gap-2">
                  <button onClick={handleBack} className="btn grey-brdr-btn">
                      Cancel
                    </button>
                    <button type="submit" className="btn ornge-brdr-btn">
                      Next
                    </button>
                  </div>
                </div>
                {Object.keys(values).map((templateName) => (
                  <Container key={templateName} className="mb-5">
                    <h3>{values[templateName][0]?.pt || templateName}</h3>
                    <Row>
                      <Col>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              {Object.keys(values[templateName][0]).map((key) => (
                                <th key={key}>{key}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {values[templateName].map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                {Object.keys(row).map((key) => (
                                  <td key={key}>
                                    {dateHandler(key) ? (
                                      <Field
                                        className={`form-control ${errors[templateName]?.[rowIndex]?.[key] && touched[templateName]?.[rowIndex]?.[key] ? 'field-error-border' : ''}`}
                                        type="date"
                                        name={`${templateName}[${rowIndex}][${key}]`}
                                        value={row[key]}
                                        onClick={handleDateClick}
                                        onChange={(e) =>
                                          handleCellEdit(
                                            templateName,
                                            rowIndex,
                                            key,
                                            e.target.value
                                          )
                                        }
                                      />
                                    ) : (
                                      <Field
                                        className={`form-control ${errors[templateName]?.[rowIndex]?.[key] && touched[templateName]?.[rowIndex]?.[key] ? 'field-error-border' : ''}`}
                                        type="text"
                                        name={`${templateName}[${rowIndex}][${key}]`}
                                        value={row[key]}
                                        onChange={(e) =>
                                          handleCellEdit(
                                            templateName,
                                            rowIndex,
                                            key,
                                            e.target.value
                                          )
                                        }
                                      />
                                    )}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                ))}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PoBulkUploadPreview;

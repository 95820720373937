import React, { useEffect, useState } from "react";
import loginImg from "../../assets/images/Login.svg";
import logoImg from "../../assets/images/logo-black.avif";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { fetchUserLoginValidate } from "../../features/login/action";
import { notify } from "../../features/notification/notify";
import { resetLoginOtp } from "../../features/login/slice";
import { ToastContainer } from "react-toastify";
import { LoginBannerImage } from "./loginBannerImage";

export const LoginResetPin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [schemaType, setSchemaType] = useState("email");
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [user, setUser] = useState({
    email: "",
    mobileNo: "",
    error: "",
  });

  const loginResponse = useSelector((state) => state.userLogin);

  const handleGetOtp = (data) => {
    let newData = {
      emailOrPhone: emailOrPhone,
    };

    dispatch(fetchUserLoginValidate(newData));
  };

  const handleChange = (e) => {
    if (e.target.name == "email") {
      setSchemaType("email");
      setUser({
        ...user,
        email: e.target.value,
        mobileNo: "",
      });
    } else {
      setSchemaType("mobile");
      setUser({
        ...user,
        mobileNo: e.target.value,
        email: "",
      });
    }
    setEmailOrPhone(e.target.value);
  };

  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be valid")
      .min(2, "Too Short!")
      .required("Required"),
  });

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const MobileSchema = Yup.object().shape({
    mobileNo: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Too short")
      .max(10, "Too long"),
  });

  useEffect(() => {
   

    if (loginResponse.success === true) {
      notify("Success", "success");
      dispatch(resetLoginOtp());

      if (loginResponse.pin == null || loginResponse.pin == "") {
        navigate("/login-otp", {
          replace: true,
          state: { type: schemaType, emailOrPhone: emailOrPhone },
        });
      }
    }
  }, [loginResponse]);

  return (
    <>
       <ToastContainer />

      <div className="row section-login-banner login-wrap">
        <div className="col-custom-64 login-banner">
        <LoginBannerImage />
        </div>
        <div className="contain col-custom-36 form-div">

          <div className='logoContainer position-relative'><img src={logoImg} alt="logo" className="login-logo" />
            <span className="position-relative login-logo-text" >Democratising Manufacturing</span>
          </div>
          <div className="form-wrap mt-4">
          <h2>Reset PIN</h2>
          <p className="note">Please enter your details</p>
          <Formik
        initialValues={user}
        validationSchema={
          schemaType == "email" ? EmailSchema : MobileSchema
        }
        enableReinitialize={true}
        onSubmit={(values) => {
       
          handleGetOtp(values);
        }}
      >
        {({ errors, touched, isValid, handleBlur }) => (
          <>
            <Form>
              <div className="email">
                <label className="form-label">Enter Email</label>
                <input
                  type="text"
                  value={user.email}
                  name="email"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.email && touched.email ? (
                  <div className="invalid-feedback d-block">
                    {errors.email}
                  </div>
                ) : null}
              </div>
              <span className="or-txt">OR</span>
              <div>
                <label className="form-label">Enter Mobile Number</label>
                <div className="phn">
                  <span className="country-code">+91-</span>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="mobileNo"
                  />
                  {errors.mobileNo && touched.mobileNo ? (
                    <div className="invalid-feedback d-block">
                      {errors.mobileNo}
                    </div>
                  ) : null}
                </div>
              </div>
              {loginResponse.error ? (
                <div className="invalid-feedback d-block">
                  {loginResponse.error}
                </div>
              ) : null}
              {user.error ? (
                <div className="invalid-feedback d-block">
                  {user.error}
                </div>
              ) : null}
              <button
                className="btn blue-btn "
                type="submit"
                disabled={!isValid}
              >
                Get OTP
              </button>
            </Form>
          </>
        )}
      </Formik>
        </div>
        </div>
      </div>
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchRoles, getCategory, getRfqStatus, getSeason, getSubCategoryByCategoryId
} from "./action";


export const common = createSlice({
  name: "common",
  initialState: {
    role: [],
    actionName: null,
    loading: false,
    error: null,
    success: false,
    category: null,
    subCategory: null,
    season: null,
    rfqStatus: null
  },

  reducers: {
    resetCommon: (state, action) => {
      state.success = false
      state.actionName = null
    },
  },

  extraReducers: {
    [fetchRoles.pending]: (state) => {
      state.loading = true;
    },
    [fetchRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.role = action.payload.roles;
      state.actionName = "fetchRoles";
    },
    [fetchRoles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getCategory.pending]: (state) => {
      state.loading = true;
    },
    [getCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.category = action.payload.data;
      state.actionName = "getCategory";
    },
    [getCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getSeason.pending]: (state) => {
      state.loading = true;
    },
    [getSeason.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.season = action.payload.data;
      state.actionName = "getSeason";
    },
    [getSeason.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getSubCategoryByCategoryId.pending]: (state) => {
      state.loading = true;
    },
    [getSubCategoryByCategoryId.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.subCategory = action.payload.data;
      state.actionName = "getSubCategory";
    },
    [getSubCategoryByCategoryId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getRfqStatus.pending]: (state) => {
      state.loading = true;
    },
    [getRfqStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.rfqStatus = action.payload.data;
      state.actionName = "getRfqStatus";
    },
    [getRfqStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default common.reducer;

export const { resetCommon } = common.actions;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../../utils/api.urls";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

//create-update action
export const saveUpdateInvoice = createAsyncThunk(
    "saveUpdateInvoice", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.OPERATION.SAVE_UPDATE_INVOICE,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getUploadedInvoices = createAsyncThunk(
    "getUploadedInvoices", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.OPERATION.GET_UPLOADED_INVOICES + "?page=" + args.page + "&size=" + args.size + "&searchText=" + args.searchText);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getNotUploadedInvoices = createAsyncThunk(
    "getNotUploadedInvoices", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.OPERATION.GET_NOT_UPLOADED_INVOICES + "?page=" + args.page + "&size=" + args.size);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getApprovedInvoices = createAsyncThunk(
    "getApprovedInvoices", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.OPERATION.GET_APPROVED_INVOICE + "?page=" + args.page + "&size=" + args.size);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getInvoiceById = createAsyncThunk(
    "getInvoiceById", async (Id, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.OPERATION.GET_PENDING_INVOICE_INFO + '/'+ Id);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const saveUpdateInvoiceActivity = createAsyncThunk(
    "saveUpdateInvoiceActivity", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.OPERATION.SAVE_UPDATE_INVOICE_ACTIVITY,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const deleteInvoiceActivity = createAsyncThunk(
    "deleteInvoiceActivity", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.OPERATION.DELETE_INVOICE_ACTIVITY,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const sendInvoiceToLender = createAsyncThunk(
    "sendInvoiceToLender", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.OPERATION.SEND_INVOICE_TO_LENDER,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
import React, { useEffect, useState } from "react";
import LeftMenu from "./layouts/leftMenu";
import { Header } from "./layouts/header";
import { Form, Formik } from "formik";
import { v1 as uuidv1 } from "uuid";
import { uploadInvoice } from "../features/uploadTNA/action";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../features/notification/notify";
export const UploadNewTNA = () => {
  const [invoice, setInvoice] = useState([]);
  const TNAResponse = useSelector((state) => state.uploadTNA);
  const dispatch = useDispatch();
  const getFile = (e, fileExtension) => {
    const image = e.target.files[0];
    const blob = image.slice(0, image.size);
    const id = uuidv1();
    // const newImageName = id + "." + fileExtension;
    const newImageName = e.target.files[0]?.name;
    const file = new File([blob], newImageName);
    return { file, newImageName };
  };
  const handleFileDownload = () => {
    const downloadUrl = `${process.env.PUBLIC_URL}/invoiceTemplate/invoiceTemplate.xlsx`;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "invoiceTemplate.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleFileSheetUpload = (e, type) => {
    const fileExtension = e.target.files[0]?.name
      .split(".")
      .pop()
      .toLowerCase();
    var acceptedFormats = ["xlsx", "xls"];
    if (!acceptedFormats.includes(fileExtension)) {
      notify("File format not supported.", "error");
    } else {
      const { file, newImageName } = getFile(e, fileExtension);
      if (type === "invoice") {
        setInvoice((invoice) => [
          ...invoice,
          { id: 0, file: file, fileName: newImageName, isDeleted: false },
        ]);
    }
    }
  };
  useEffect(() => {
    if(invoice.length != 0){
        let formData = new FormData();
        const fileNames = [];
        invoice.map((fileSet) => {
          formData.append("upload_file", fileSet.file);
          fileNames.push({
            id: fileSet.id,
            fileType: "invoice",
            fileName: fileSet.fileName,
            isDeleted: fileSet.isDeleted,
          });
        });
        formData.append("fileList", JSON.stringify(fileNames));
        dispatch(uploadInvoice(formData));
    }
   
  }, [invoice]);


  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <Formik enableReinitialize={true}>
            {({ errors, touched, isValid, handleBlur }) => (
              <>
                <Form>
                  <div className="top-heading mb-5">
                    <div className="card-header pt-5">
                      <h1 className="font-weight-bold">
                        {" "}
                        <button type="button" className="border-0  bg-white">
                          {"\u2190"}
                        </button>{" "}
                        Upload New Invoice(s)
                      </h1>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <h2 className="font-weight-bold">
                        Upload invoice(s) through template
                      </h2>
                      <ul className="mb-3">
                        <li>Download our excel template.</li>
                        <li>Enter data for each column.</li>
                        <li>Upload template.</li>
                      </ul>
                      <hr></hr>
                      <div className="row">
                        <div className="col-md-6">
                          <button
                            type="button"
                            className="form-control border-2 border-primary text-primary bg-white rounded "
                            onClick={handleFileDownload}
                          >
                            DOWNLOAD TEMPLATE &#x2B07;
                          </button>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="file"
                            name="invoice"
                            className="form-control"
                            onChange={(event) => {
                              handleFileSheetUpload(event, "invoice");
                            }}
                          />{" "}
                          {errors.invoice && touched.invoice ? (
                            <div className="invalid-feedback d-block">
                              {errors.invoice}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h2 className="font-weight-bold">
                        Upload invoice(s) through PDF
                      </h2>
                      <ul className="mb-3">
                        <li>Choosew and upload your .pdf file.</li>
                        <li>Edit file post upload.</li>
                      </ul>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

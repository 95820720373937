import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";


let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');
//create action

export const fetchUserLogin = createAsyncThunk(
  'loginData',
  async (post, { rejectWithValue }) => {
    try {

      const response = await fetch(
        API_URLS.USERS.LOGIN,
         {
           method: "POST",
           headers: myHeaders,
           body: JSON.stringify(post),
         }
       );
       
      if (!response.ok) return rejectWithValue(response.statusText)
      const data = await response.json()
      return data
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
)

export const fetchGrroyoUserLogin = createAsyncThunk(
  'loginData',
  async (post, { rejectWithValue }) => {
    try {

      const response = await fetch(
        API_URLS.USERS.GROYYO_LOGIN,
         {
           method: "POST",
           headers: myHeaders,
           body: JSON.stringify(post),
         }
       );
       
      if (!response.ok) return rejectWithValue(response.statusText)
      const data = await response.json()
      return data
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
)

//create action

export const fetchUserLoginValidate = createAsyncThunk(
  'fetchUserLoginValidate',
  async (newData, { rejectWithValue }) => {
    try {

      const response = await fetch(
        API_URLS.USERS.LOGIN_VALIDATE + "/"+ newData.email,
       );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json()
      return result;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
)

export const fetchGroyyoUserLoginValidate = createAsyncThunk(
  'fetchGroyyoUserLoginValidate',
  async (newData, { rejectWithValue }) => {
    try {

      const response = await fetch(
        API_URLS.USERS.GROYYO_LOGIN_VALIDATE + "/"+ newData.email,
       );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json()
      return result;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
)


export const forgetPin = createAsyncThunk(
  'forgetPin',
  async (post, { rejectWithValue }) => {
    try {

      const response = await fetch(
        API_URLS.USERS.FORGET_PIN,
         {
           method: "POST",
           headers: myHeaders,
           body: JSON.stringify(post),
         }
       );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json()
      return result;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
)

export const resendOtpGroyyo = createAsyncThunk(
  'resendOtpGroyyo',
  async (newData, { rejectWithValue }) => {
    try {

      const response = await fetch(
        API_URLS.USERS.GROYYO_RESEND_OTP + "/"+ newData.email,
       );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json()
      return result;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
)




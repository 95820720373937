import { createSlice } from "@reduxjs/toolkit";
import {
  SaveUpdateGuarantorRepo,
  SaveUpdateGuarantorBusiness,
  SaveUpdateGuarantorDoc,
  fetchGuarantorDocs,
  UpdateGuarantorDocStatus,
  UpdateApplicationStatus,
  updateResendReson,
  fetchAllGuarantorApplication,
  fetchGuarantorApplication,
  AskClientToUpload,
  askForNewDocs,
  getGuarantorInfo,
  getGuarantorBusiness,
  getGuarantorBusinessByInfoId,
  rejectApplication,
  underwriteApplication,
  getUnderwrittenApplications,
  setInterestAndTenure,
  customerRejection,
  downloadReport
} from "./action";


export const guarantor = createSlice({
  name: "guarantor",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message : null,
    guarantorDocs : [],
    guarantorInfoId :"",
    approved : false,
    applicationData: [],
    guarantorReview : [],
    step: 0,
    docApproved: null,
    guarantorPersonalInfo: [],
    guarantorBusinessInfo : [],
    underwrittenApplication: [],
    actionName : null,
    reportExcelFile: null
  },

  reducers: {
    resetGuarantor: (state, action) => {
      state.success = false;
      state.approved = false;
      state.docApproved = false;
      state.approved = false;
      state.step = 0;
      state.actionName = null;
    },
  },

  extraReducers: {
    [fetchAllGuarantorApplication.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllGuarantorApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.applicationData = action.payload;
      state.actionName = "fetchAllGuarantorApplication";
    },
    [fetchAllGuarantorApplication.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [updateResendReson.pending]: (state) => {
      state.loading = true;
    },
    [updateResendReson.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
    },
    [updateResendReson.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [rejectApplication.pending]: (state) => {
      state.loading = true;
    },
    [rejectApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
    },
    [rejectApplication.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [UpdateApplicationStatus.pending]: (state) => {
      state.loading = true;
    },
    [UpdateApplicationStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.step = 4;
    },
    [UpdateApplicationStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [UpdateGuarantorDocStatus.pending]: (state) => {
      state.loading = true;
    },
    [UpdateGuarantorDocStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.approved = true;
      state.message = action.payload.message;
      state.docApproved = action.payload.docApproved;
    },
    [UpdateGuarantorDocStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [fetchGuarantorDocs.pending]: (state) => {
      state.loading = true;
    },
    [fetchGuarantorDocs.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.guarantorDocs = action.payload.guarantorDocs;
      state.docApproved = action.payload.docApproved;
    },
    [fetchGuarantorDocs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [fetchGuarantorApplication.pending]: (state) => {
      state.loading = true;
    },
    [fetchGuarantorApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.guarantorReview = action.payload.guarantorReview;
    },
    [fetchGuarantorApplication.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [SaveUpdateGuarantorRepo.pending]: (state) => {
      state.loading = true;
    },
    [SaveUpdateGuarantorRepo.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.guarantorInfoId = action.payload.guarantorInfoId;
      state.step = 1;
    },
    [SaveUpdateGuarantorRepo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getGuarantorInfo.pending]: (state) => {
      state.loading = true;
    },
    [getGuarantorInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.guarantorPersonalInfo = action.payload.guarantor;
    },
    [getGuarantorInfo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [SaveUpdateGuarantorBusiness.pending]: (state) => {
      state.loading = true;
    },
    [SaveUpdateGuarantorBusiness.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.step = 2;
    },
    [SaveUpdateGuarantorBusiness.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [underwriteApplication.pending]: (state) => {
      state.loading = true;
    },
    [underwriteApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
    },
    [underwriteApplication.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getGuarantorBusiness.pending]: (state) => {
      state.loading = true;
    },
    [getGuarantorBusiness.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.guarantorBusinessInfo = action.payload.guarantorBusiness;
    },
    [getGuarantorBusiness.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getGuarantorBusinessByInfoId.pending]: (state) => {
      state.loading = true;
    },
    [getGuarantorBusinessByInfoId.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.guarantorBusinessInfo = action.payload.guarantorBusiness;
    },
    [getGuarantorBusinessByInfoId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [SaveUpdateGuarantorDoc.pending]: (state) => {
      state.loading = true;
    },
    [SaveUpdateGuarantorDoc.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.step = 3;
    },
    [SaveUpdateGuarantorDoc.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [AskClientToUpload.pending]: (state) => {
      state.loading = true;
    },
    [AskClientToUpload.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
    },
    [askForNewDocs.pending]: (state) => {
      state.loading = true;
    },
    [askForNewDocs.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
    },
    [AskClientToUpload.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [askForNewDocs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getUnderwrittenApplications.pending]: (state) => {
      state.loading = true;
    },
    [getUnderwrittenApplications.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.underwrittenApplication = action.payload.applications
      state.actionName = 'getUnderwrittenApplications'
    },
    [getUnderwrittenApplications.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [setInterestAndTenure.pending]: (state) => {
      state.loading = true;
    },
    [setInterestAndTenure.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = 'setInterestTenure'
    },
    [setInterestAndTenure.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [customerRejection.pending]: (state) => {
      state.loading = true;
    },
    [customerRejection.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
    },
    [customerRejection.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },    
    [downloadReport.pending]: (state) => {
      state.loading = true;
    },
    [downloadReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.reportExcelFile = action.payload;
      state.actionName = 'downloadReport';
    },
    [downloadReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default guarantor.reducer;
export const { resetGuarantor } = guarantor.actions;


import chatImg from "../../assets/images/chat.svg";
import questionCircleImg from "../../assets/images/question-circle.svg";
import bell from "../../assets/images/Bell.svg";
import language from "../../assets/images/language.svg"
import { useEffect, useState } from "react";
export const Header = () => {

    const [loginUser, serLoginUser] = useState()

    useEffect(() => {
        let currentJsonUser = JSON.parse(localStorage.getItem('authorization'))
        serLoginUser(currentJsonUser && currentJsonUser.user.name)
    }, [])
    

    return (
        <>
            
                <header>
                    <div className="header-right">
                        <span>Welcome, {loginUser}</span>
                        <a href="#"><img src={chatImg} alt="question" /></a>
                        <a href="#"><img src={questionCircleImg} alt="question"/></a>
                        <a href="#"><img src={bell} alt="bell"/></a>
                        <a href="#"><img src={language} alt="language"/></a>
                    </div>
                </header>
            
        </>
    );
}
import React from 'react'
import image from '../../assets/images/loader.gif'

export const Loader = (props) => { 
  const {loaderRes} = props;
  return (
    <div  style={{display: loaderRes ? 'block' : 'none'}} className='full-page-loader'>
        <img src={image}
         alt="loader" />
    </div>
  )
}

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import noChat from "../../../../assets/images/No_Chats.png";
import { POChatContactSection } from "./poChatContactSection";
import { POChatMessageSection } from "./poChatMessageSection";
import { getAllPOChats } from "../../../../features/poChats/action";


export const POChatConversation = (props) => {
    const { poId, setCurrentChat, currentChat } = props;
    
    // const socket = useRef();
    const dispatch = useDispatch();
    const [contacts, setContacts] = useState([]);
    const [messages, setMessages] = useState([]);
    const [groupUserType,setGroupUserType] = useState("All");

    const currentJsonUser = JSON.parse(localStorage.getItem("authorization"));
    const poChatResponse = useSelector((state) => state.poChat);


   

    useEffect(() => {
        if (poId) {
            callGetAllPOChats();
        }
    }, [poId, currentChat]);

    const callGetAllPOChats = () => {
        let allChatPayload = {
            poId: poId,
            loginUserId: currentJsonUser.user.id,
            type:groupUserType
        };

        dispatch(getAllPOChats(allChatPayload));
    }

    useEffect(() => {
        if (
            poChatResponse.success === true &&
            poChatResponse.actionName == "getAllPOChats"
        ) {
            setContacts(poChatResponse.poAllChats);
        }
    }, [poChatResponse.poAllChats]);

 
    const handleChatChange = (chat) => {
        setCurrentChat(chat);
        setMessages([]);
    };
    
  return (
   <div className="chat-box">
            <>
                <POChatContactSection
                    contacts={contacts}
                    // socket={socket}
                    changeChat={handleChatChange}
                    setContacts={setContacts}
                    poId={poId}
                    setGroupUserType={setGroupUserType}
                    groupUserType={groupUserType}
                />
                {contacts && contacts.length > 0 && (
                    <POChatMessageSection
                        currentChat={currentChat}
                        // socket={socket}
                        messages={messages}
                        setMessages={setMessages}
                        poId={poId}
                    />
                )}
            </>

            {contacts && contacts.length == 0 && (
                <>
                    <div className="no-conversation">
                        <img src={noChat} alt="No Conversation Available"></img>
                        <span>No conversation available.</span>
                    </div>
                </>
            )}
        </div>
  )
}

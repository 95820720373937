import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

export const fetchRoles = createAsyncThunk(
  "fetchRoles", async (type, { rejectWithValue }) => {
    try {
      const response = await fetch(API_URLS.COMMON.ROLE + '/' + type);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getCategory = createAsyncThunk(
  "getCategory", async (type, { rejectWithValue }) => {
    try {
      const response = await fetch(API_URLS.COMMON.GET_CATEGORY);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getSeason = createAsyncThunk(
  "getSeason", async (type, { rejectWithValue }) => {
    try {
      const response = await fetch(API_URLS.COMMON.GET_SEASON);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getSubCategoryByCategoryId = createAsyncThunk(
  "getSubCategoryByCategoryId", async (categoryId, { rejectWithValue }) => {
    try {
      const response = await fetch(API_URLS.COMMON.GET_SUBCATEGORY_BY_CATEGORYiD + '/' + categoryId);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getRfqStatus = createAsyncThunk(
  "getRfqStatus", async (type, { rejectWithValue }) => {
    try {
      const response = await fetch(API_URLS.COMMON.GET_RFQ_STATUS);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
import React, { useEffect, useState } from 'react'
import logoImg from "../../assets/images/logo-black.avif";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { fetchUserLoginValidate, fetchGroyyoUserLoginValidate } from '../../features/login/action';
import { notify } from '../../features/notification/notify';
import { resetLoginOtp } from '../../features/login/slice';
import { ToastContainer } from 'react-toastify';
import { LoginBannerImage } from './loginBannerImage';

export const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [schemaType, setSchemaType] = useState("phone");
  const [email, setemail] = useState("");
  const [user, setUser] = useState({
    credential: "",
  });


  const loginResponse = useSelector((state) => state.userLogin);


  const handleGetOtp = (data) => {
    let newData = {
      email: email
    }

    if (email.endsWith('groyyo.com')) {
      dispatch(fetchGroyyoUserLoginValidate(newData));
    } else {
      dispatch(fetchUserLoginValidate(newData));
    }

  }

  const handleChange = (e) => {
    setUser({
      ...user,
      credential: e.target.value,
    });
    setemail(e.target.value);
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  let credentialschema;

  credentialschema = Yup.object().shape({
    credential: Yup.string().email('Email must be valid').min(2, 'Too Short!').required('Email Id is Required'),
  });
  // if (email.includes('@')) {
  // } else {
  //   credentialschema = Yup.object().shape({
  //     credential: Yup.string()
  //       .required('Enter a mobile')
  //       .matches(phoneRegExp, 'Mobile is not valid')
  //       .min(10, 'Too short')
  //       .max(10, 'Too long'),
  //   });
  // }

  const handleLoginTab = (type) => {
    setSchemaType(type)
  }


  useEffect(() => {

    if (loginResponse.success === true && loginResponse.actionName == "fetchUserLoginValidate") {
      // notify("", "success");
      //dispatch(resetLoginOtp());
      localStorage.setItem('email', email);
      navigate('/login-pin');
    }
    if (loginResponse.success === true && loginResponse.actionName == "fetchGroyyoUserLoginValidate") {
      // notify("", "success");
      //dispatch(resetLoginOtp());
      localStorage.setItem('email', email);
      navigate('/enter-Otp');
    }
    if (loginResponse.error) {
      dispatch(resetLoginOtp())
      notify(loginResponse.error, "error");
    }
  }, [loginResponse]);

  document.body.style.backgroundColor = "#F4F2F2";
  return (
    <>
      <ToastContainer />

      <div className="row section-login-banner login-wrap">
        <div className="col-sm-7 login-banner">
          <LoginBannerImage />
        </div>
        <div className="contain col-sm-5 form-div">

          <div className='logoContainer position-relative'><img src={logoImg} alt="logo" className="login-logo" />
            <span className="position-relative login-logo-text" >Democratising Manufacturing</span>
          </div>
          <div className="form-wrap">
            <h2>Welcome back</h2>
            <p className="note">Welcome to your account! Please enter your details.</p>
            <Formik
              initialValues={user}
              validationSchema={credentialschema}
              enableReinitialize={true}
              onSubmit={(values) => {
                console.log("viewvalues", values);
                handleGetOtp(values)
              }}
            >
              {({ errors, touched, isValid, handleBlur }) => (
                <>
                  <Form>
                    {schemaType == 'phone' && <div className="form-control login-tab cursor-pointer" onClick={() => { handleLoginTab('email') }}>
                      Login With Email ID
                    </div>}
                    {schemaType == 'email' && <div className="form-control login-tab cursor-pointer" onClick={() => { handleLoginTab('phone') }}>
                      Login With Mobile Number
                    </div>}

                    <span className="or-txt">OR</span>
                    {schemaType == 'phone' && <div>
                      <label className="form-label">Enter Mobile Number</label>
                      <div className="phn">
                        <span className="country-code">+91-</span>
                        <input type="text" className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={true}
                          name="credential" />
                        {/* {errors.credential && touched.credential ? (
                        <div className="invalid-feedback d-block">
                          {errors.credential} 
                        </div>
                      ) : null} */}
                      </div>
                      {loginResponse.error ? (
                        <div className="invalid-feedback d-block">
                          {loginResponse.error}
                        </div>
                      ) : null}
                    </div>}

                    {schemaType == 'email' && <div className="email">
                      <label className="form-label">Enter Email ID</label>
                      <input type="text" value={user.credential}
                        name="credential"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.credential && touched.credential ? (
                        <div className="invalid-feedback d-block">
                          {errors.credential}
                        </div>
                      ) : null}
                    </div>
                    }

                    {user.error ? (
                      <div className="invalid-feedback d-block">
                        {user.error}
                      </div>
                    ) : null}
                    <button className="btn blue-btn " type="submit"
                      disabled={!(isValid && schemaType == 'email')}
                    >{schemaType == 'email' ? "Sign In" : "Get OTP"}</button>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

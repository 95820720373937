
import { Modal, Button } from "react-bootstrap";
import { Form, Formik } from "formik";
export const AttachTNAModal = (props) => {
   const {attachTnaModal, handleAttachTNAClose,handleTnaAttachSubmit, templates, allTemplates, handleChange } = props
   return (
   <Modal
        show={attachTnaModal}
        onHide={handleAttachTNAClose}
        className="custom-modal"
      >
        <Formik
          initialValues={templates}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleTnaAttachSubmit(values);
          }}
        >
          {({ errors, touched, isValid, dirty, handleBlur }) => (
            <>
              <Form>
                <Modal.Header>
                  <h1 className="modal-title" id="edit-profile-Label">
                    Add Template
                  </h1>
                </Modal.Header>
                <Modal.Body>
                  <row className="centre">
                    <div className="col-lg-4 col-md-6">
                      <input type="text" name="poId" hidden></input>
                      {errors.template && touched.template ? (
                        <div className="invalid-feedback d-block">
                          {errors.template}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <label className="form-label">
                        Templates<span className="star-color"> *</span>
                      </label>
                      <select
                        value={templates.template ?? ""}
                        onChange={handleChange}
                        className="form-select"
                        name="template"
                      >
                        <option value="">--Select Template--</option>
                        {allTemplates.length > 0 &&
                          allTemplates.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      {errors.template && touched.template ? (
                        <div className="invalid-feedback d-block">
                          {errors.template}
                        </div>
                      ) : null}
                    </div>
                  </row>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    type="button"
                    className="btn inner-blue-border-btn"
                    onClick={handleAttachTNAClose}
                  >
                    Cancel
                  </a>
                  <Button type="submit" className="btn inner-blue-btn">
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
   )
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { notify } from "../../features/notification/notify";
import { deleteMilestone, getAllMileStone } from "../../features/milestone/action";
import { resetMilestone } from "../../features/milestone/slice";
import moment from "moment";
import { CONSTANT } from "../../utils/constant";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";

export const AllMilestones = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const [allMileStones, setAllMileStones] = useState([])
  const [ttlMilestone, setTtlMilestone] = useState(0);
  const mileStoneResponse = useSelector((state) => state.milestone);

  useEffect(() => {
    callAllMilestone();
  }, [currentPage, itemCount]);

  const callAllMilestone = () => {
    dispatch(getAllMileStone({ page: currentPage + 1, limit: itemCount }));
  };

  useEffect(() => {
    if (mileStoneResponse.actionName == "getAllMilestone") {
      setAllMileStones(mileStoneResponse.milestoneData.milestones);
      setTtlMilestone(mileStoneResponse.milestoneData?.milestones[1]);
      if (mileStoneResponse.milestoneData.milestones[1] !== null && pageCount === 0) {
        setPageCount(Math.ceil(mileStoneResponse.milestoneData.milestones[1] / itemCount));
      }
      dispatch(resetMilestone());
    }
    if (mileStoneResponse.actionName == "deleteMilestone") {
      notify(mileStoneResponse.message, "success");
      callAllMilestone();
    }
  }, [mileStoneResponse]);

  const goToEditPage = (item) => {
    navigate("/create-milestone", { state: { item } });
  };

  const handleDeleteMilestone = (id) => {
    dispatch(deleteMilestone({ id: id }));
  };

  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };

  const handleDelete = (id) => {

    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure want to delete this milestone?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            handleDeleteMilestone(id)
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };

  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <div className="top-heading">
            <div className="d-flex">
              <h2>Milestones</h2>
            </div>
            <a className="btn ornge-brdr-btn" href="/create-milestone">
                <span className="align-middle">&#43; Add New Milestone</span>
              </a>
          </div>
          <div className="rfq-status-table tab-content">
            {ttlMilestone > 0 &&
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Monitoring on Start</th>
                    <th>Monitoring on End</th>
                    <th>Milestone Type</th>
                    <th>Complete</th>
                    <th>Required</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {allMileStones[0] && allMileStones[0].map((item, index) => (
                    <tr >
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                      <td>{item.monitoringOnStart ? "Yes": "No"}</td>
                      <td>{item.monitoringOnEnd ? "Yes": "No"}</td>
                      <td>{item.milestoneType}</td>
                      <td>{item.complete}</td>
                      <td>{item.required ? "Yes": "No"}</td>
                      <td>
                        <a onClick={() => goToEditPage(item)} title="Edit Miestone">
                          <i className="fa-regular fa-pen-to-square mr-2"></i>
                        </a>
                        <a
                          onClick={() => handleDelete(item.id)}
                          title="Delete Milestone"
                        >
                          <i
                            className="fa fa-trash px-3"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {ttlMilestone > CONSTANT.PAGE_LIMIT && (
                <nav className="pagination-block">
                  <p className="total">{ttlMilestone} items total</p>

                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={currentPage}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination justify-content-center mt-4"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                  <div className="customSelect">
                    <label htmlFor="">Items per page :</label>
                    <div className="selectBox">
                      <select name="" id="" onChange={handleItemsChange}>
                        <option value={CONSTANT.PAGE_LIMIT}>
                          {CONSTANT.PAGE_LIMIT}
                        </option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>
                </nav>
              )}
            </div> }
            {ttlMilestone == 0 && <div>No record available!</div>}
          </div>
        </div>
      </div>
    </>
  );
};

import "./assets/css/style.css";
import "./assets/css/media.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./assets/css/development.css";
import MyRoutes from './myroutes';
import { ToastContainer } from "react-toastify";
import { Loader } from "./views/layouts/loader";
import { useSelector } from "react-redux";

function App() {
  const rfq = useSelector((state) => state.rfq);
  const userDetail = useSelector((state) => state.userDetail);
  const userLogin = useSelector((state) => state.userLogin);
  const userSignup = useSelector((state) => state.userSignup);
  const common = useSelector((state) => state.common);
  const sample = useSelector((state) => state.sample);
  const chats = useSelector((state) => state.chats);
  const rfqSampleComment = useSelector((state) => state.rfqSampleComment);
  const uploadTNA = useSelector((state) => state.uploadTNA);
  const userManagememnt = useSelector((state) => state.userManagememnt);
  const product = useSelector((state) => state.product);
  
  const loading = rfq.loading || userDetail.loading || userLogin.loading || userSignup.loading
                 || common.loading || sample.loading || chats.loading || rfqSampleComment.loading
                  || uploadTNA.loading || userManagememnt.loading || product.loading
  return (
    <div className="App">
      <ToastContainer></ToastContainer>
      <Loader loaderRes={loading}></Loader>
       <MyRoutes/>
    </div>
  );
}

export default App;

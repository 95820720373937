import { useEffect, useRef, useState } from "react";
import { CONSTANT } from "../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import dangerImg from "../../assets/images/danger.svg";
import { Modal, Button } from "react-bootstrap";
import OTPInput from "otp-input-react";
import {createUserPin} from "../../features/userDetail/action";



export const SetPinModal = (props) => {
    const {show,setShow,clickedUser} = props;
  const dispatch = useDispatch();
  const [pinError, setPinError] = useState("d-none");
  const usrMngmntResponse = useSelector((state) => state.userManagememnt);
  const [pin, setPin] = useState("");
  const [pinTitle, setPinTitle] = useState("Set Pin");
  const [confirmPin, setConfirmPin] = useState("");
  const [submitSetDisabled, setSubmitSetDisabled] = useState(true);


  const handleSetConfirmPin = (value) => {
    setConfirmPin(value);
    setSubmitSetDisabled(true);
    setPinError("d-none");
    if (pin.length == value.length) {
      if (pin != value) {
        setPinError("");
      }
      setSubmitSetDisabled(false);
    }
  
  };
  useEffect(() => {
    if (
        usrMngmntResponse.actionName == "createUserPin" &&
        usrMngmntResponse.success
      ) {
        setConfirmPin(null);
      }
  }, [usrMngmntResponse]);
  const handlePinSubmit = (type) => {
    if (type == "skip") {
      handleClose();
    } else if (pin == confirmPin && type == "set") {
      let pindata = {
        emailId: clickedUser.emailId,
        pin: pin,
      };
      dispatch(createUserPin(pindata));
      handleClose();
    }
  };

  const handleClose = () => setShow(false);
  return (
    <>
         <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal pin-modal"
      >
        <Modal.Header>
          <h1 className="modal-title" id="set-pin-Label">
            Set for {clickedUser.name}
          </h1>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label className="form-label">
                Enter PIN<span>*</span>
              </label>
              <div className="pin-wrap">
                <OTPInput
                  className="otp-input-root"
                  value={pin}
                  onChange={setPin}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">
                Confirm PIN<span>*</span>
              </label>
              <div className="pin-wrap error">
                <OTPInput
                  className="otp-input-root"
                  value={confirmPin}
                  onChange={handleSetConfirmPin}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure
                />
              </div>
              <div className={"error-msg " + pinError}>
                <img src={dangerImg} alt="danger" className="align-middle" />
                <span className="align-middle">
                  PIN do not match. Try again!
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn inner-blue-border-btn"
            onClick={() => {
              handlePinSubmit("skip");
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn inner-blue-btn"
            disabled={submitSetDisabled}
            onClick={() => {
              handlePinSubmit("set");
            }}
          >
            Set
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

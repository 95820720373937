import { Modal, Button } from "react-bootstrap";
import { Form, Formik } from "formik"
import { addFactoryToPo, getAcceptedFactory, getMilestoneByTempId } from "../../features/po/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { notify } from "../../features/notification/notify";
export const AssignFactoryModal = (props) => {
    const { callAllPO, selectedPo, setSelectedPo, showAssignFactory, setShowAssignFactory } = props
    const dispatch = useDispatch();
    const poResponse = useSelector((state) => state.po);
    const [factoryList, setFactoryList] = useState([]);
    const [milestoneList, setMilestoneList] = useState([]);
    const [selectedFactories, setSelectedFactories] = useState([]);
    const [factoryWithMilestone, setFactoryWithMilestone] = useState([]);
    const [sampleWithFactory, setSampleWithFactory] = useState([]);

    useEffect(() => {
        debugger
        if (selectedPo && selectedPo.id > 0)
    
            dispatch(getAcceptedFactory(selectedPo.id))

        if (selectedPo && selectedPo.tnaTemplate?.id > 0)
            dispatch(getMilestoneByTempId(selectedPo.tnaTemplate?.id))
    }, [selectedPo]);

    useEffect(() => {
        debugger
        if (poResponse.actionName == "getAcceptedFactory" && poResponse.success) {
            setFactoryList(poResponse.factories)
        }
        if (poResponse.actionName == "getMilestoneByTempId" && poResponse.success) {
            setMilestoneList(poResponse.milestone?.milestones)

        }
        if (poResponse.actionName == "addFactoryToPo" && poResponse.success) {
            closeModal();
            callAllPO();
        }
    }, [poResponse]);

    const closeModal = () => {
        setShowAssignFactory(false)
        setSelectedPo(null)
        setSelectedFactories([])
        setFactoryWithMilestone([])
    }
    const handleSelectedFactory = (list) => {
        setSelectedFactories(list);
        let fact = []
        let mlstne = []
        let smples = []
        milestoneList.map(item => {
            mlstne.push({ id: item.id, name: item.name, isChecked: true })
        })
        list.map(item => {
            fact.push({ id: item.id, name: item.name, milestone: mlstne, quantity: 0 })
        })
        setFactoryWithMilestone(fact);

        selectedPo.poSamples.map(item => {
            smples.push({ id: item.id, quantity: item.quantity, factory: fact, sampleDetail: item.sampleDetail })
        })
        setSampleWithFactory(smples)
    }
    const handleRemovedFactory = (selected, removed) => {
        setSelectedFactories(selected);
        let fact = []
        let mlstne = []
        let smples = []
        milestoneList.map(item => {
            mlstne.push({ id: item.id, name: item.name, isChecked: true })
        })
        selected.map(item => {
            fact.push({ id: item.id, name: item.name, milestone: mlstne, quantity: 0 })
        })
        setFactoryWithMilestone(fact);

        selectedPo.poSamples.map(item => {
            smples.push({ id: item.id, quantity: item.quantity, factory: fact, sampleDetail: item.sampleDetail })
        })
        setSampleWithFactory(smples)
    }
    const handleMlstnClick = (e, factory, mile, smplInfo) => {
        let smpl = [...sampleWithFactory]
        let sampleWithFact = []
        smpl.map(s => {
            let fact = []
            if (s.id == smplInfo.id) {
                s.factory.map(f => {
                    let mlstne1 = []
                    let mlstne2 = []
                    milestoneList.map(item => {
                        if (item.id == mile.id) {
                            //  if (e.target.checked) {
                            mlstne1.push({ id: item.id, name: item.name, isChecked: e.target.checked })
                            //mlstne2.push({ id: item.id, name: item.name, isChecked: true })
                            // } else if (!e.target.checked) {
                            //     mlstne1.push({ id: item.id, name: item.name, isChecked: e.target.checked })
                            //     mlstne2.push({ id: item.id, name: item.name, isChecked: false })
                            // }

                        } else {
                            let milsnInfo = f.milestone.find(x => x.id == item.id)
                            if (milsnInfo) {
                                mlstne1.push({ id: milsnInfo.id, name: milsnInfo.name, isChecked: milsnInfo.isChecked })
                              //  mlstne2.push({ id: milsnInfo.id, name: milsnInfo.name, isChecked: milsnInfo.isChecked })
                                // } else {
                                //     mlstne1.push({ id: item.id, name: item.name, isChecked: false })
                                //     mlstne2.push({ id: item.id, name: item.name, isChecked: false })
                            }
                        }
                    })
                    if (f.id == factory.id)
                        fact.push({ id: f.id, name: f.name, milestone: mlstne1, quantity: f.quantity })
                    else
                        fact.push({ id: f.id, name: f.name, milestone: f.milestone, quantity: f.quantity })
                })

                selectedPo.poSamples.map(poSmpl => {
                    if (smplInfo.id == poSmpl.id) {
                        sampleWithFact.push({ id: poSmpl.id, quantity: poSmpl.quantity, factory: fact, sampleDetail: poSmpl.sampleDetail })
                    }
                })
            } else {
                sampleWithFact.push({ id: s.id, quantity: s.quantity, factory: s.factory, sampleDetail: s.sampleDetail })
            }

        })

        setSampleWithFactory(sampleWithFact)
    }
console.log("sampleWithFact",sampleWithFactory)
    const submitFactoryAndMilestone = () => {
        let valid = true;
        let msg = '';
        if (factoryWithMilestone.length > 1) {
            // sampleWithFactory.map(s => {
            //     s.factory.map(f => {
            //         f.milestone.map(item => {
            //             if (!item.isChecked) {
            //                 valid = false;
            //                 msg = 'Please select milestones for any factory!'
            //             }
            //         })
            //     })
            // })
            sampleWithFactory.map(s => {
                let ttlQty = s.factory.reduce((accumulator, current) => accumulator + parseInt(current.quantity), 0);
                if (ttlQty == 0 || isNaN(ttlQty)) {
                    valid = false;
                    msg = 'Please enter quantity for ' + s.sampleDetail.sampleName
                }
            })

        }

        if (!valid) {
            notify(msg, 'info')
        } else {
            if (factoryWithMilestone.length != 0) {
                var payload = {
                    poId: selectedPo.id,
                    sampleWithFactory: sampleWithFactory
                }
                dispatch(addFactoryToPo(payload))
            }
        }
    }

    const handleChangeQty = (e, factInfo, smple) => {
        let smpl = [...sampleWithFactory]
        let sampleWithFact = []
        smpl.map(s => {
            let fact = []
            if (s.id == smple.id) {
                s.factory.map(f => {
                    if (f.id == factInfo.id)
                        fact.push({ id: f.id, name: f.name, milestone: f.milestone, quantity: parseInt(e.target.value) })
                    else
                        fact.push({ id: f.id, name: f.name, milestone: f.milestone, quantity: f.quantity })
                })

                selectedPo.poSamples.map(poSmpl => {
                    if (smple.id == poSmpl.id) {
                        sampleWithFact.push({ id: poSmpl.id, quantity: poSmpl.quantity, factory: fact, sampleDetail: poSmpl.sampleDetail })
                    }
                })
            } else {
                sampleWithFact.push({ id: s.id, quantity: s.quantity, factory: s.factory, sampleDetail: s.sampleDetail })
            }

        })

        setSampleWithFactory(sampleWithFact)
    }
    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()

    return (
        <Modal
            show={showAssignFactory}
            onHide={setShowAssignFactory}
            className="custom-modal po-mlsn"
        >
            <Formik
                initialValues={""}
                enableReinitialize={true}
                onSubmit={(values) => {
                    submitFactoryAndMilestone();
                }}
            >
                {({ errors, touched, isValid, dirty, handleBlur }) => (
                    <>
                        <Form>
                            <Modal.Header>
                                <h1 className="modal-title" id="edit-profile-Label">
                                    Assign Factories
                                </h1>
                            </Modal.Header>
                            <Modal.Body >
                                <row className="centre">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="form-label">
                                            Factories<span className="star-color"> *</span>
                                        </label>
                                        <Multiselect
                                            options={factoryList}
                                            name="notifyTo"
                                            id="factory-mail"
                                            displayValue="name"
                                            onSelect={handleSelectedFactory}
                                            onRemove={handleRemovedFactory}
                                        />
                                    </div>
                                    <br />
                                    {factoryWithMilestone.length > 1 && 
                                    <div class="po-list-table">
                                        
                                            <div className="row po-list-header" >
                                                <div className="col-md-2  form-label">Factory</div>
                                                <div className="col-md-1  form-label">Quantity</div>
                                                <div className="col-md-8 po-list-flex" >
                                                    {milestoneList.map(mlsn => (
                                                        <div className="form-label"> &nbsp;&nbsp;{mlsn.name} </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {sampleWithFactory.map((smpl, smplIndex) => (
                                                <div className="dv-fct-list"> 
                                                <i className="form-label"> 
                                                Sample 
                                                <span className="ttlqty-smpl">
                                                     {smplIndex + 1} ({smpl.sampleDetail.sampleName}, {smpl.quantity} quantity)
                                                </span> 
                                                 </i>
                                                 <br/>
                                                 <br/>
                                                    {smpl.factory.map((item, index) => (
                                                        <div className="row">
                                                            <div className="col-md-2">{item.name}</div>
                                                            <div className="col-md-1" style={{ marginBottom: '5px' }}><input onKeyDown={preventNegativeValues} onChange={(e) => handleChangeQty(e, item, smpl)} type="number" min={0} className="form-control" value={item.quantity == 0 ? null : item.quantity} /></div>
                                                            <div className="col-md-8 po-list-flex" >
                                                                {item.milestone && item.milestone.map((mlsn, mlstnIndex) => (
                                                                    <div className="" style={{ textAlign: 'center' }}>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp; <input name={mlsn.name} value={mlsn.isChecked} onClick={(e) => handleMlstnClick(e, item, mlsn, smpl)} type="checkbox" defaultChecked="true" className="form-check-input" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </div>
                                                                ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                            ))}
                                      
                                    </div>
                                    }
                                </row>
                            </Modal.Body>
                            <Modal.Footer>
                                <a
                                    type="button"
                                    className="btn inner-blue-border-btn"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </a>
                                <Button onClick={submitFactoryAndMilestone} className="btn inner-blue-btn">
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                )}
            </Formik>
        </Modal>
    )
}
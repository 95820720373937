import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userImg from "../../../../assets/images/user.webp";
import { POChatInputSection } from "./poChatInputSection";
import { io } from "socket.io-client";
import { ViewPOChatGroup } from "./poChatModals/viewPOChatGroup";
import moment from "moment";
import { resetPOChat } from "../../../../features/poChats/slice";
import { getAllPOChatMessages, saveNewPOChatMessage } from "../../../../features/poChats/action";

export const POChatMessageSection = (props) => {
  const {
    currentChat,
    messages,
    setMessages,
    poId
  } = props;
  const scrollRef = useRef();
  const dispatch = useDispatch();

  const socket = useRef();
  const ENDPOINT = process.env.REACT_APP_API_URL
  const currentJsonUser = JSON.parse(localStorage.getItem("authorization"));

  const poChatResponse = useSelector((state) => state.poChat);
  const [socketConnected, setSocketConnected] = useState(false);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [showViewGroupModal, setShowViewGroupModal] = useState(false);
  const [dateList, setDateList] = useState([]);


  useEffect(() => {
    socket.current = io(ENDPOINT);
    socket.current.on("connected", () => setSocketConnected(true));
    socket.current.emit("setup", currentJsonUser.user);
    // socket.on("typing", () => setIsTyping(true));
    // socket.on("stop typing", () => setIsTyping(false));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentChat) {
      let getPayload = {
        chatId: currentChat && currentChat.id,
        loginUserId: currentJsonUser.user.id,
      };
      dispatch(getAllPOChatMessages(getPayload));

      socket.current.emit("join chat", currentChat.id);
    }
  }, [currentChat]);

  useEffect(() => {
    if (poChatResponse.actionName == "getAllPOChatMessages") {
      
      setMessages(poChatResponse.poChatMessages);
      let temp = [];
      poChatResponse.poChatMessages.map((message, index) => {
        let msgDate = new Date(message.timestamp);
        let exist = temp.find(x => x.date == msgDate.toDateString());
        if (!exist) {
          temp.push({ date: msgDate.toDateString(), index: index });
          setDateList(temp)
        }
      })
    }
    if (poChatResponse.actionName == "saveNewPOChatMessage") {
      socket.current.emit("new message", poChatResponse.newSavedMessage);

    }
    dispatch(resetPOChat())
  }, [poChatResponse]);


  useEffect(() => {
    scrollRef.current?.scrollTo(0, scrollRef.current.scrollHeight)
  }, [messages]);

  useEffect(() => {

    if (socket.current) {
      socket.current.on("message recieved", (newMessageRecieved) => {
        setArrivalMessage({ fromSelf: false, message: newMessageRecieved.content, senderName: newMessageRecieved.sender.name });

        if (
          !currentChat || // if chat is not selected or doesn't match current chat
          currentChat.id !== newMessageRecieved.chats.id
        ) {
          // if (!notification.includes(newMessageRecieved)) {
          //   setNotification([newMessageRecieved, ...notification]);
          //   setFetchAgain(!fetchAgain);
          // }
        } else {
          // setMessages([...messages, newMessageRecieved]);

        }
      });
    }

  }, [socket.current]);

  useEffect(() => {

    let temp = dateList;
    let msgDate = new Date();
    let exist = temp.find(x => x.date == msgDate.toDateString());
    if (!exist) {
      temp.push({ date: msgDate.toDateString(), index: messages.length });
      setDateList(temp)
    }
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);

  }, [arrivalMessage]);

  const handleSendMsg = async (msg) => {
    let savePayload = {
      chatId: currentChat.id,
      loginUserId: currentJsonUser.user.id,
      content: msg,
    };
    dispatch(saveNewPOChatMessage(savePayload));
    const msgs = [...messages];
    msgs.push({ fromSelf: true, message: msg, senderName: currentJsonUser.user.name });

    let temp = dateList;
    let msgDate = new Date();
    let exist = temp.find(x => x.date == msgDate.toDateString());
    if (!exist) {
      temp.push({ date: msgDate.toDateString(), index: msgs.length - 1 });
      setDateList(temp)
    }
    setMessages(msgs);

  };


  const handleShowViewGroup = () => {
    currentChat.isGroupChat == true ? setShowViewGroupModal(true) : setShowViewGroupModal(false)

  }
  const getMsgTime = (message) => {
    let msgDate = message.timestamp ? new Date(message.timestamp) : new Date();
    msgDate = moment(msgDate).add(30, 'm').add(5, 'h').toDate();
    let hr = ('0' + msgDate.getHours()).slice(-2);
    let min = ('0' + msgDate.getMinutes()).slice(-2);
    return hr + ":" + min;
  }

  const getDate = (message) => {
    let msgDate = message.timestamp ? new Date(message.timestamp) : new Date();
    let today = new Date()
    if (msgDate.getDate() == today.getDate())
      return 'Today';
    else
      return moment(msgDate).format(
        "DD MMMM, YYYY"
      )
  }

  return (
    <div className="right-chat-pannel" >
      <div className="chat-user" style={{ cursor: currentChat && currentChat.isGroupChat ? "pointer" : "default" }}>
        <div className="chat-user-left">
          <img src={userImg} alt="user" />
        </div>
        <div className="chat-user-title" onClick={currentChat && handleShowViewGroup}>
          <div>
            <div className="name">{currentChat && currentChat.chatName}</div>
          </div>
        </div>
      </div>
      <div className="chat-body" ref={scrollRef}>
        {messages &&
          messages.map((message, index) => (
            <div key={index}>
              <div className="dv-date">
                {dateList.find(x => x.index == index) != null && <small >{getDate(message)}</small>}
              </div>
              <div className={`message ${message.fromSelf ? "outgoing-msg" : "incoming-msg"}`} >
                <div className={` ${message.fromSelf ? "message-out" : "message-in"}`}>
                  <span className="chat-username">{message.senderName}</span>
                  <span className="cht-tme">{getMsgTime(message)}</span>

                  <div dangerouslySetInnerHTML={{ __html: message.message }}></div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {currentChat && (
        <POChatInputSection
          handleSendMsg={handleSendMsg}
          // socket={socket}
          currentChat={currentChat}
        />
      )}

      {currentChat && currentChat.isGroupChat == true && (
        <ViewPOChatGroup
          poId={poId}
          currentChat={currentChat}
          showViewGroupModal={showViewGroupModal}
          setShowViewGroupModal={setShowViewGroupModal}
        />
      )}
    </div>
  );
};

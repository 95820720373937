export const CONSTANT = {
    HOST: "http://localhost:3004",
    COMMENTS_AUDIENCE  : [
         "All",
         "Internal",
         "Only Buyers",
         "Only Suppliers"
    ],
    DEFAULT_AUDIENCE_FOR_INTERNAL : 2,
    DEFAULT_AUDIENCE_FOR_OTHER : 1,
    PAGE_LIMIT : 10,
    SAMPLE_PAGE_LIMIT:14,
    FORM_CREATE: "create",
    FORM_EDIT: "edit",
    SUPPORTED_FORMAT: ['application/pdf', 'application/msword'],
    SUPPORTED_DOC_SIZE: 5 * 1024 * 1024,
   

    USER_ROLE : {
        ADMIN : 1,
        LEADERSHIP: 2,
        BUYER: 3,
        SUPPLIER: 4,
        OPERATIONS: 5,
        DESIGN: 6
    },  

}
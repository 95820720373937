import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import noChat from "../../../assets/images/No_Chats.png";
import { getAllChats } from "../../../features/chats/action";
import { ChatContactSection } from "./chatContactSection";
import { ChatMessageSection } from "./chatMessageSection";


export const ChatConversation = (props) => {
    const { rfqId, setCurrentChat, currentChat } = props;
    
    // const socket = useRef();
    const dispatch = useDispatch();
    const [contacts, setContacts] = useState([]);
    const [messages, setMessages] = useState([]);
    const [groupUserType,setGroupUserType] = useState("All");

    const currentJsonUser = JSON.parse(localStorage.getItem("authorization"));
    const chatsResponse = useSelector((state) => state.chats);
    const rfqResponse = useSelector((state) => state.rfq);

   

    useEffect(() => {
        if (rfqId) {
            callGetAllChats();
        }
    }, [rfqId, currentChat]);

    const callGetAllChats = () => {
        let allChatPayload = {
            rfqId: rfqId,
            loginUserId: currentJsonUser.user.id,
            type:groupUserType
        };

        dispatch(getAllChats(allChatPayload));
    }

    useEffect(() => {
        if (
            chatsResponse.success === true &&
            chatsResponse.actionName == "getAllChats"
        ) {
            setContacts(chatsResponse.rfqAllChats);
        }
    }, [chatsResponse.rfqAllChats]);

    useEffect(() => {
        if (
            chatsResponse.success === true &&
            chatsResponse.actionName == "getChatByRfqBid"
        ) {
            setCurrentChat(chatsResponse.rfqBidChat);
        }
    }, [chatsResponse.rfqBidChat]);

    useEffect(() => {

     if (rfqResponse.actionName === "releaseRFQ") {
        callGetAllChats()
     }
    }, [rfqResponse]);

 
    const handleChatChange = (chat) => {
        setCurrentChat(chat);
        setMessages([]);
    };
    
  return (
   <div className="chat-box">
            <>
                <ChatContactSection
                    contacts={contacts}
                    // socket={socket}
                    changeChat={handleChatChange}
                    setContacts={setContacts}
                    rfqId={rfqId}
                    setGroupUserType={setGroupUserType}
                    groupUserType={groupUserType}
                />
                {contacts && contacts.length > 0 && (
                    <ChatMessageSection
                        currentChat={currentChat}
                        // socket={socket}
                        messages={messages}
                        setMessages={setMessages}
                        rfqId={rfqId}
                    />
                )}
            </>

            {contacts && contacts.length == 0 && (
                <>
                    <div className="no-conversation">
                        <img src={noChat} alt="No Conversation Available"></img>
                        <span>No conversation available.</span>
                    </div>
                </>
            )}
        </div>
  )
}

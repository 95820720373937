import { createSlice } from "@reduxjs/toolkit";
import { addUpdateTnaTemplate, getAllTnaTemplate, deleteTNATemplate, addBulkTemplate } from "./action";


export const Tna = createSlice({
  name: "Tna",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
  },

  reducers: {
    resetTna: (state, action) => {
      state.success = false
      state.actionName = null
      state.message = null
    },
  },

  extraReducers: {
    [addUpdateTnaTemplate.pending]: (state) => {
      state.loading = true;
    },
    [addUpdateTnaTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.tnaData = action.payload;
      state.actionName = "addUpdateTnaTemplate";
    },
    [addUpdateTnaTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getAllTnaTemplate.pending]: (state) => {
      state.loading = true;
    },
    [getAllTnaTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.tnaTemplateData = action.payload;
      state.actionName = "getAllTnaTemplate";
    },
    [getAllTnaTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [deleteTNATemplate.pending]: (state) => {
      state.loading = true;
    },
    [deleteTNATemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.success;
      state.actionName = "deleteTNATemplate";
    },
    [deleteTNATemplate.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [addBulkTemplate.pending]: (state) => {
      state.loading = true;
    },
    [addBulkTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "addBulkTemplate";
    },
    [addBulkTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default Tna.reducer;

export const { resetTna } = Tna.actions;

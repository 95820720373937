import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

export const addUpdateTnaTemplate = createAsyncThunk(
    "addUpdateTnaTemplate",
    async (allData, { rejectWithValue }) => {
      try {
        const response = await fetch(
          API_URLS.TNA.ADD_UPDATE_TNA_TEMPLATE,
          {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(allData),
            }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );


  export const getAllTnaTemplate = createAsyncThunk(
    "getAllTnaTemplate",
    async ( data,{ rejectWithValue }) => {
      try {
        let url = API_URLS.TNA.GET_ALL_TNA_TEMPLATE;
        url = url + "?page=" + data.page + "&limit=" + data.limit;
        const response = await fetch(url);
        // const response = await fetch(
        //   API_URLS.TNA.GET_ALL_TNA_TEMPLATE,
        //   {
        //       method: "GET",
        //       headers: {
        //         "Content-Type": "application/json",
        //       },
        //       //body: JSON.stringify(data),
        //     }
        // );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  // delete tna template 
  export const deleteTNATemplate = createAsyncThunk(
    "deleteTNATemplate",
    async (data, { rejectWithValue }) => {
      try {
        const response = await fetch(
          API_URLS.TNA.DELETE_TNA_TEMPLATE,
          {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const addBulkTemplate = createAsyncThunk(
    "addBulkTemplate",
    async (data, { rejectWithValue }) => {
      try {
        const response = await fetch(
          API_URLS.TNA.ADD_BULK_TEMPLATE,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
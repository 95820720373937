import React, { useEffect, useRef, useState } from "react";
import plusWhiteImg from "../../assets/images/plus-white.svg";
import optionDotImg from "../../assets/images/option-dot.svg";
import dangerImg from "../../assets/images/danger.svg";
import {
  createUserPin,
  showAllUsers,
  updateUser,
  updateUserStatus,
} from "../../features/userDetail/action";
import { useDispatch, useSelector } from "react-redux";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";
import { Modal, Button } from "react-bootstrap";
import { resetUserDetail } from "../../features/userDetail/slice";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { notify } from "../../features/notification/notify";
import { ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { CONSTANT } from "../../utils/constant";

const Dashboard = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userResponse = useSelector((state) => state.userDetail);

  const [pin, setPin] = useState("");
  const [pinTitle, setPinTitle] = useState("Set Pin");
  const [confirmPin, setConfirmPin] = useState("");
  const [clickedUser, setClickedUser] = useState("");
  const [pinError, setPinError] = useState("d-none");
  const [submitSetDisabled, setSubmitSetDisabled] = useState(true);
  const isFirstRender = useRef(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEdit, setShowEdit] = useState(false);
  const handleEditClose = () => setShowEdit(false);
  const handleEditShow = () => setShowEdit(true);

  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);

  const [userData, setUserData] = useState({
    userId: "",
    emailId: "",
    name: "",
    mobileNo: "",
    pin: "",
    department: "",
    role: "",
  });

  useEffect(() => {
    callAllUsers();
    setTimeout(() => {
      navigate(state, {});
    }, 2000);
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      callAllUsers();
    }
    isFirstRender.current = false;
  }, [currentPage, itemCount]);

  const callAllUsers = () => {
    dispatch(
      showAllUsers({
        page: currentPage + 1,
        size: itemCount,
      })
    ).then(() => {
      if (currentPage === 0) setPageCount(0);
    });
  };

  const handleSetConfirmPin = (value) => {
    setConfirmPin(value);
    setSubmitSetDisabled(true);
    setPinError("d-none");
    if (pin.length == value.length) {
      if (pin != value) {
        setPinError("");
      }
      setSubmitSetDisabled(false);
    }
   
  };

  const handlePinSubmit = (type) => {
    if (type == "skip") {
      handleClose();
    } else if (pin == confirmPin && type == "set") {
      let pindata = {
        userId: clickedUser.id,
        pin: pin,
      };
   
      dispatch(createUserPin(pindata));
    }
  };

  const handleShowSetPin = (userdata) => {
    setPinTitle("Set Pin");
    setClickedUser(userdata);
    handleShow();
  };

  const handleShowResetPin = (userdata) => {
    setPinTitle("Reset Pin");
    setClickedUser(userdata);
    handleShow();
  };

  const handleActive = (e, id) => {
    // let value = e.target.checked ? 1 : 0;
    dispatch(updateUserStatus(id));
  };

  const handleOpenEdit = (userData) => {
   

    setUserData({
      userId: userData.id,
      emailId: userData.emailId,
      name: userData.name,
      mobileNo: userData.mobileNo,
      pin: userData.pin,
      department: userData.departmentId,
      role: userData.rolesId,
    });
    handleEditShow();
  };

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormUpdateSubmit = () => {

    dispatch(updateUser(userData));
  };

  useEffect(() => {
    if (userResponse.success === true && userResponse.actionName != "showAllUsers" ) {
      callAllUsers()
      handleClose();
      handleEditClose();
    }
  }, [userResponse]);

  const ProfileSchema = Yup.object().shape({
    emailId: Yup.string()
      .email("Email must be valid")
      .min(2, "Too Short!")
      .required("Required"),
    name: Yup.string().required("Required"),
    mobileNo: Yup.string().required("Required"),
    department: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
  });

  useEffect(() => {
  

    if (userResponse.success === true) {
      dispatch(resetUserDetail());
      if(userResponse.actionName != "showAllUsers") {
        notify(
          userResponse.message != null ? userResponse.message : "Success",
          "success"
        );
      }
    }
    if (userResponse.error) {
      notify(userResponse.error, "error");
    }
  }, [userResponse]);

  if (userResponse?.allusers?.totalCount !== null && pageCount === 0) {
    setPageCount(Math.ceil(userResponse?.allusers?.totalCount / itemCount));
  }

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <LeftMenu/>
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <div className="top-heading">
            <h2>User Management</h2>

            <Link to="/admin/add-user" className="btn inner-blue-btn">
              {" "}
              <img src={plusWhiteImg} alt="add" className="align-middle" />
              <span className="align-middle">Add New User</span>
            </Link>
          </div>
          {state?.message && (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              {state.message}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          <div className="user-table">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Mobile No</th>
                    <th scope="col">Email ID</th>
                    <th scope="col">Role</th>
                    <th scope="col">Pin</th>
                    <th scope="col" className="text-end">
                      Status (Active/Inactive)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userResponse?.allusers?.users &&
                    userResponse?.allusers?.users.map((user, Index) => (
                      <tr
                        key={Index}
                        className={Index % 2 ? "inactive-state" : ""}
                      >
                        <td>{user.name}</td>
                        <td>{user.mobileNo}</td>
                        <td>{user.emailId}</td>
                        <td>{user.roles}</td>
                        <td>
                          {user.pin != "" && user.pin != null ? (
                            <a
                              className="reset-pin"
                              type="button"
                              onClick={() => {
                                handleShowResetPin(user);
                              }}
                            >
                              Reset PIN
                            </a>
                          ) : (
                            <a
                              className="reset-pin"
                              type="button"
                              onClick={() => {
                                handleShowSetPin(user);
                              }}
                            >
                              Set PIN
                            </a>
                          )}
                        </td>
                        <td className="text-end">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              onChange={(event) => {
                                handleActive(event, user.id);
                              }}
                              defaultChecked={user.status == 1 ? true : false}
                              id={"active-state" + Index}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"active-state" + Index}
                            ></label>
                          </div>
                          <button
                            type="button"
                            className="btn"
                            title="edit"
                            onClick={() => {
                              handleOpenEdit(user);
                            }}
                          >
                            <img src={optionDotImg} alt="edit" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <nav className="pagination-block">
              <p className="total">
                {userResponse?.allusers?.totalCount} items total
              </p>

              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                forcePage={currentPage}
                onPageChange={handlePageChange}
                containerClassName={"pagination justify-content-center mt-4"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
              <div className="customSelect">
                <label htmlFor="">Items per page :</label>
                <div className="selectBox">
                  <select name="" id="" onChange={handleItemsChange}>
                    <option value={CONSTANT.PAGE_LIMIT}>{CONSTANT.PAGE_LIMIT}</option>
                    <option value="15">15</option>

                    <option value="20">20</option>
                  </select>
                </div>
              </div>
            </nav>
            </div>
            
          </div>
        </div>
      </div>

      <Modal show={showEdit} onHide={handleEditClose} className="custom-modal">
        <Modal.Header>
          <h1 className="modal-title" id="edit-profile-Label">
            Edit
          </h1>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={userData}
            validationSchema={ProfileSchema}
            enableReinitialize={true}
          >
            {({ errors, touched, isValid, dirty, handleBlur }) => (
              <>
                <Form>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Name<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          onChange={handleChange}
                          value={userData.name}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <div className="invalid-feedback d-block">
                            {errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Phone No<span>*</span>
                        </label>
                        <div className="phone-input-wrap position-relative">
                          <span className="country-code">+91</span>
                          <input
                            type="text"
                            className="form-control"
                            name="mobileNo"
                            onChange={handleChange}
                            value={userData.mobileNo}
                            onBlur={handleBlur}
                            disabled
                          />
                          {errors.mobileNo && touched.mobileNo ? (
                            <div className="invalid-feedback d-block">
                              {errors.mobileNo}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Email ID<span>*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="emailId"
                          onChange={handleChange}
                          value={userData.emailId}
                          onBlur={handleBlur}
                          disabled
                        />
                        {errors.emailId && touched.emailId ? (
                          <div className="invalid-feedback d-block">
                            {errors.emailId}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Department<span>*</span>
                        </label>
                        <select
                          className="form-select"
                          name="department"
                          onChange={handleChange}
                          value={userData.department}
                          onBlur={handleBlur}
                        >
                          <option>Open this select menu</option>
                          <option value="1">SCF Sales</option>
                          <option value="2">SCF Ops</option>
                          <option value="3">Credit Risk</option>
                          <option value="4">Finance & Payments</option>
                          <option value="5">Client</option>
                          <option value="6">Technical</option>
                        </select>
                        {errors.department && touched.department ? (
                          <div className="invalid-feedback d-block">
                            {errors.department}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Role<span>*</span>
                        </label>
                        <select
                          className="form-select"
                          name="role"
                          onChange={handleChange}
                          value={userData.role}
                          onBlur={handleBlur}
                        >
                          <option>Open this select menu</option>
                          <option value="1">SuperAdmin</option>
                          <option value="2">Admin</option>
                          <option value="3">Member</option>
                          <option value="4">Buyer</option>
                          <option value="5">Seller</option>
                        </select>
                        {errors.role && touched.role ? (
                          <div className="invalid-feedback d-block">
                            {errors.role}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <a
            type="button"
            className="btn inner-blue-border-btn"
            onClick={handleEditClose}
          >
            Cancel
          </a>
          <Button
            type="submit"
            className="btn inner-blue-btn"
            onClick={handleFormUpdateSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <div className="modal fade custom-modal pin-modal modal-height" id="reset-pin" aria-labelledby="reset-pin-Label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title" id="reset-pin-Label">
                Reset PIN for Bhavit Khan
              </h1>
            </div>
            <div className="modal-body">
              <label className="form-label">Enter Mobile Number</label>
              <div className="phone-input-wrap position-relative">
                <span className="country-code">+91</span>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn inner-blue-border-btn" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn inner-blue-btn" data-bs-toggle="modal" data-bs-target="#reset-pin-otp">
                Get OTP
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="modal fade custom-modal pin-modal modal-height" id="reset-pin-otp" aria-labelledby="reset-pin-otp-Label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title" id="reset-pin-otp-Label">
                Reset PIN for Bhavit Khan
              </h1>
            </div>
            <div className="modal-body">
              <p className="otp-text">
                A 4 digit OTP has been sent to your mobile number +91 ********98
              </p>

              <div className="pin-wrap mb-10">
                <input type="text" maxLength="1" className="form-control" />
                <input type="text" maxLength="1" className="form-control" />
                <input type="text" maxLength="1" className="form-control" />
                <input type="text" maxLength="1" className="form-control" />
              </div>
              <div className="resend-otp-wrap">
                Didn’t recieve OTP? <span>Resend in 00:30</span>
                <a href="#" style={{ display: "none" }}>Resend</a>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn inner-blue-border-btn" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn inner-blue-btn">Submit</button>
            </div>
          </div>
        </div>
      </div> */}
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal pin-modal"
      >
        <Modal.Header>
          <h1 className="modal-title" id="set-pin-Label">
            {pinTitle} for {clickedUser.name}
          </h1>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label className="form-label">
                Enter PIN<span>*</span>
              </label>
              <div className="pin-wrap">
                <OTPInput
                  className="otp-input-root"
                  value={pin}
                  onChange={setPin}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">
                Confirm PIN<span>*</span>
              </label>
              <div className="pin-wrap error">
                <OTPInput
                  className="otp-input-root"
                  value={confirmPin}
                  onChange={handleSetConfirmPin}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure
                />
              </div>
              <div className={"error-msg " + pinError}>
                <img src={dangerImg} alt="danger" className="align-middle" />
                <span className="align-middle">
                  PIN do not match. Try again!
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn inner-blue-border-btn"
            onClick={() => {
              handlePinSubmit("skip");
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn inner-blue-btn"
            disabled={submitSetDisabled}
            onClick={() => {
              handlePinSubmit("set");
            }}
          >
            Set
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Dashboard;

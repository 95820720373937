import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSampleById } from "../../features/sample/action";
import { resetSample } from "../../features/sample/slice";
import { useLocation, useNavigate } from "react-router-dom";
import trashImg from "../../assets/images/trash.svg";
import importImg from "../../assets/images/import.svg";
import {
  deleteSampleFile,
  addSampleImage,
  editSampleFile,
} from "../../features/sample/action";
import { notify } from "../../features/notification/notify";
import { v1 as uuidv1 } from "uuid";
import { confirmAlert } from "react-confirm-alert";
import edit from "../../assets/images/edit.svg";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Formik } from "formik";
import { Checkbox } from "semantic-ui-react";

export const EditImage = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [imageData, setImageData] = useState({
    id: "",
    sampleName: "",
    description: "",
    isFront: null,
    display: null,
  });
  const [imgList, setImgList] = useState([]);
  const [sampleImage, setSampleImage] = useState([]);
  const sampleResponse = useSelector((state) => state.sample);
  const [sampleId, setSampleId] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (state && state.sampleId) {
      dispatch(getSampleById(state.sampleId));
      setSampleId(state.sampleId);
    }
  }, []);

  useEffect(() => {
    if (
      sampleResponse.actionName == "getSampleById" &&
      sampleResponse.sample &&
      sampleResponse.sample.sampleFiles?.length > 0
    ) {
      setSampleImage(sampleResponse.sample.sampleFiles);
      dispatch(resetSample());
    } else if (sampleResponse.actionName == "deleteSampleFile") {
      dispatch(getSampleById(sampleId));
    } else if (sampleResponse.actionName == "addSampleImage") {
      dispatch(getSampleById(state.sampleId));
    } else if (sampleResponse.actionName == "editSampleFile") {
      dispatch(getSampleById(state.sampleId));
    }
  }, [sampleResponse]);

  const handleDelete = (data) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteSampleFile(data));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  let imgExtension = "";
  const onOpenModal = (file) => {
    setOpen(true);
    const imageName = file.fileName.split(".");
    imgExtension = imageName[1];
    setImageData((prevImg) => ({
      ...prevImg,
      id: file.id,
      fileName: imageName[0],
      imageExtension: imgExtension,
      description: file.description,
      isFront: file.isFront,
      display: file.display,
    }));
  };

  const onCloseModal = () => setOpen(false);

  const getFile = (e, fileExtension) => {
    let  originalFileName,index;
    const image = e.target.files[0];
    const blob = image.slice(0, image.size);
    if (e.target.files[0]?.name.indexOf("AM") != -1)
      index = e.target.files[0]?.name.indexOf("AM");

    else if (e.target.files[0]?.name.indexOf("PM") != -1)
      index = e.target.files[0]?.name.indexOf("PM");
    if (index) {
      originalFileName =
        e.target.files[0]?.name.slice(0, index - 1) + "_" + e.target.files[0]?.name.slice(index);
    }
    else
      originalFileName = e.target.files[0]?.name;
    const lastDotIndex = originalFileName.lastIndexOf('.');
    const filenameWithoutExtension = originalFileName.substring(0, lastDotIndex);
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().replace(/:/g, '').replace(/\..+/, '').replace(/-/g, '');
    const newImageName = filenameWithoutExtension + "_" + formattedDate + "." + fileExtension;
    const file = new File([blob], newImageName);
    return { file, newImageName };
  };
 
  const handleImgUpload = (e) => {
    const fileExtension = e.target.files[0]?.name
      .split(".")
      .pop()
      .toLowerCase();
    var acceptedFormats = ["png", "jpeg", "jpg", "webp"];
    if (!acceptedFormats.includes(fileExtension)) {
      return;
    }
    const { file, newImageName } = getFile(e, fileExtension);

    let formData = new FormData();
    const fileList = [];
    formData.append("upload_file", file);
    fileList.push({
      fileType: "image",
      fileName: newImageName,
      sampleId: sampleId,
    });

    formData.append("fileList", JSON.stringify(fileList));
    e.target.value="";
    dispatch(addSampleImage(formData));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // For text input fields
    if (type === "text") {
      setImageData({
        ...imageData,
        [name]: value,
      });
    }

    // For checkboxes
    if (type === "checkbox") {
      setImageData({
        ...imageData,
        [name]: checked,
      });
    }
  };
  const handleFormSubmit = (data) => {
    data.fileName = data.fileName.concat(".", data.imageExtension);
    dispatch(editSampleFile(data));
    setOpen(false);
  };

  const handleBack = () => {
    navigate("/sample-detail", {
      replace: true,
      state: { sampleId: sampleId },
    });
  };
  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          {/* <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">User Management </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#"> Add User</a>
              </li>
            </ol>
          </nav> */}
          <div className="top-heading">
            <div onClick={handleBack} className="d-flex">
              <a href="" className="back">
                &#8249;
              </a>
              <h2>Sample Images</h2>
            </div>
            <div className="d-flex gap-2">
              <button className="btn grey-brdr-btn" onClick={handleBack}>
                Cancel
              </button>
              {/* <button className="btn ornge-brdr-btn">
                                <span className="align-middle">Next</span>
                            </button> */}
            </div>
          </div>
          <div className="user-wrap">
            <div className="row row-gap-5">
              {sampleImage &&
                sampleImage
                  .filter((file) => file.fileType === "image")
                  .map((file, i) => (
                    <div
                      className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6"
                      key={i}
                    >
                      <div className="sample-img ">
                        <img
                          src={file?.filePath}
                          alt="img"
                        />
                        <div className="document-detail">
                          <div className="document-row" >
                            <div>
                              Name: <div>{file.fileName}</div>
                            </div>
                            <a>
                              <img
                                src={edit}
                                alt="edit"
                                onClick={(e) => onOpenModal(file)}
                              />
                            </a>

                          </div>
                          <div className="document-row">
                            <div>
                              Description: <span>{file.description || "-"}</span>
                            </div>
                            <a>
                              <img
                                src={trashImg}
                                alt="trash"
                                onClick={(e) => handleDelete(file.id)}
                              />
                            </a>
                          </div>
                          <div className="document-row">
                            <div>Display Image: <span>{file.display == true ? "Yes" : "No"}</span></div>
                          </div>
                          <div className="document-row">
                            <div>Front Image: <span>{file.isFront == true ? "Yes" : "No"}</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

              <div className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6">
                <div className="image-upload">
                  <input
                    id="fpImages"
                    type="file"
                    className="custom-file"
                    onChange={(event) => {
                      handleImgUpload(event);
                    }}
                  />
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal open={open} onClose={onCloseModal} center>
          <Formik
            initialValues={imageData}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}
          >
            {({ errors, touched, isValid, handleBlur }) => (
              <>
                <Form>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">
                          File Name<span></span>
                        </label>
                        <input type="hidden" id="fileId" value={imageData.id} />
                        <div> 
                          <label className="form-label">{imageData.fileName ?? ""}</label></div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Description
                        </label>
                        <input
                          value={imageData.description ?? ""}
                          onChange={handleChange}
                          type="text"
                          name="description"
                          className="form-control"
                          onBlur={handleBlur}
                        />
                        {errors.description && touched.description ? (
                          <div className="invalid-feedback d-block">
                            {errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3 checkbox">
                        <label className="form-label col-sm-4">
                          Display Image
                        </label>
                        <input
                          type="checkbox"
                          checked={imageData.display}
                          onChange={handleChange}
                          name="display"
                          className="form-check-input"
                        />
                        {errors.display && touched.display ? (
                          <div className="invalid-feedback d-block">
                            {errors.display}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3 checkbox">
                        <label className="form-label col-sm-4">
                          Is Front
                        </label>
                        <input
                          type="checkbox"
                          name="isFront"
                          checked={imageData.isFront}
                          onChange={handleChange}
                          className="form-check-input float-right"
                        />
                        {errors.isFront && touched.isFront ? (
                          <div className="invalid-feedback d-block">
                            {errors.isFront}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn grey-brdr-btn mr-20"
                      onClick={onCloseModal}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn ornge-brdr-btn">
                      Save
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Modal>
      </div>
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import {
  getAllApplication,
  saveUpdateSupplier,
  getReportingInfo,
  getPendingDaysReport,
  getSuppliers,
  deleteSupplier,
  getCreditLimit,
  getAllInsight,
  getWeeklyReport
} from "./action";


export const dashboard = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message : null,
    actionName : "",
    allApplications: [],
    suppliers: [],
    reportingInfo: null,
    pendingDays : null,
    suppliers: [],
    creditLimitData : null,
    insights: null,
    weeklyreport:[],
  },

  reducers: {
    resetDashboard: (state, action) => {
      state.success = false;
      state.actionName = "";
    },
  },

  extraReducers: {
    [getAllApplication.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.allApplications = action.payload.applications
      state.actionName = 'getallapplication'
    },
    [getAllApplication.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
    [saveUpdateSupplier.pending]: (state, action) => {
      state.loading = true;
    },
    [saveUpdateSupplier.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.suppliers = action.payload.suppliers
      state.actionName = 'saveupdatesupplier'
    },
    [saveUpdateSupplier.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
    [getReportingInfo.pending]: (state, action) => {
      state.loading = true;
    },
    [getReportingInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.reportingInfo = action.payload
      state.actionName = 'getreportinginfo'
    },
    [getReportingInfo.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
    [getPendingDaysReport.pending]: (state, action) => {
      state.loading = true;
    },
    [getPendingDaysReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.pendingDays = action.payload.pendingDays
      state.actionName = 'getPendingDaysReport'
    },
    [getPendingDaysReport.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
    [getCreditLimit.pending]: (state, action) => {
      state.loading = true;
    },
    [getCreditLimit.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.creditLimitData = action.payload
      state.actionName = 'getCreditLimit'
    },
    [getCreditLimit.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
    [getSuppliers.pending]: (state, action) => {
      state.loading = true;
    },
    [getSuppliers.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.suppliers = action.payload.supplier
      state.actionName = 'getSuppliers'
    },
    [getSuppliers.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
    [deleteSupplier.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteSupplier.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = 'deleteSupplier'
    },
    [deleteSupplier.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
    [getAllInsight.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllInsight.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = 'getAllInsight'
      state.insights = action.payload;
    },
    [getAllInsight.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
    [getWeeklyReport.pending]: (state, action) => {
      state.loading = true;
    },
    [getWeeklyReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = 'getWeeklyReport'
      state.weeklyreport = action.payload;
    },
    [getWeeklyReport.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    },
  },
});

export default dashboard.reducer;
export const { resetDashboard } = dashboard.actions;


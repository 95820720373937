import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";


export const getAllPOs = createAsyncThunk(
  "getAllPOs",
  async (data,{ rejectWithValue }) => {
    try {
      let url = API_URLS.PO.GET_ALL_PO;
            url = url + "?page=" + data.page + "&limit=" + data.limit + "&userId=" + data.userId + "&roleId=" + data.roleId
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          //body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPoByIdAndRole = createAsyncThunk(
  "getPoByIdAndRole",
  async (data,{ rejectWithValue }) => {
    try {
      let url = API_URLS.PO.GET_PO_BY_ID;
      const response = await fetch(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addUpdatePo = createAsyncThunk(
  "addUpdatePo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.ADD_UPDATE_PO,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePO = createAsyncThunk(
  "deletePO",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.DELETE_PO,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllTemplates = createAsyncThunk(
  "getAllTemplates",
  async (data,{ rejectWithValue }) => {
    try {
      let url = API_URLS.PO.GET_ALL_Templates;
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addTemplateToPo = createAsyncThunk(
  "addTemplateToPo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.ADD_TEMPLATE_TO_PO,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)
export const getAcceptedFactory = createAsyncThunk(
  "getAcceptedFactory",
  async (poId,{ rejectWithValue }) => {
    try {
      let url = API_URLS.PO.GET_ACCEPTED_FACTORY + '?poId=' + poId;
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMilestoneByTempId = createAsyncThunk(
  "getMilestoneByTempId",
  async (templateId,{ rejectWithValue }) => {
    try {
      let url = API_URLS.PO.GET_MILESTONE_BY_TEMPLATEID + '?templateId=' + templateId;
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addFactoryToPo = createAsyncThunk(
  "addFactoryToPo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.ADD_FACTORY_TO_PO,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const addDatePoFactoryMilestones = createAsyncThunk(
  "addDatePoFactoryMilestones",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.ADD_DATE_TO_POFACTORY_MILESTONE,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const releasePo = createAsyncThunk(
  "releasePo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.RELEASE_PO,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const setPoFactoryEditable = createAsyncThunk(
  "setPoFactoryEditable",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.SET_PO_FACTORY_EDITABLE,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const addNewDates = createAsyncThunk(
  "addNewDates",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.ADD_NEW_DATES,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const acceptOrRejectPoDates = createAsyncThunk(
  "acceptOrRejectPoDates",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.ACCEPT_OR_REJECT_PODATES,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)


export const addActualDates = createAsyncThunk(
  "addActualDates",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.ADD_ACTUAL_DATES,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const exportPOTemplate = createAsyncThunk(
  "exportPOTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.EXPORT_PO_TEMPLATE,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      // Start the download
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'po_template.xlsx'; // Filename suggestion
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const poTemplateBulkUpload = createAsyncThunk(
  "poTemplateBulkUpload",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.PO.PO_TEMPLATE_BULK_UPLOAD,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
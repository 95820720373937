import moment from "moment";
import { CONSTANT } from "../../../../utils/constant";

const userId = localStorage.getItem("userId");
const roleId = localStorage.getItem("roleId");

export const isShow = (poFact, mile, type) => {
    if (mile.startDate && mile.endDate) {
        if (type == 'both_accepted') {
            return true
        }
    } else {
        //First time date add
        if (roleId == CONSTANT.USER_ROLE.ADMIN && type == 'first_time_date_add') {
            if (mile.poFactoryMilestoneLogs && mile.poFactoryMilestoneLogs.length > 0) {
                return false
            } else {
                return true
            }
        }
        //End First time date add

        //Show factory rejects or accepts button
        if (type == 'reject_factory' || type == 'accept_factory') {
            if (roleId != CONSTANT.USER_ROLE.ADMIN) {
                if ((mile.poFactoryMilestoneLogs.length == 1 && mile.poFactoryMilestoneLogs[0].isAccepted == null && mile.poFactoryMilestoneLogs[0].isRejected == null)) {
                    return true
                } else {
                    if ((mile.poFactoryMilestoneLogs.length > 1
                        && mile.poFactoryMilestoneLogs[0].isAccepted == null
                        && mile.poFactoryMilestoneLogs[0].isRejected == null)) {

                        // if (mile.poFactoryMilestoneLogs[0].reason) {
                        if (mile.poFactoryMilestoneLogs[1].rejectedBy == userId) {
                            return false
                        } else
                            return true
                        // } else {
                        //   return true
                        //   }
                    } else {
                        return false
                    }
                }
            }
        }
        //End show factory rejects or accepts btton

        //Show rejected message to admin
        if (type == 'show_rejected_admin') {
            if (roleId == CONSTANT.USER_ROLE.ADMIN) {
                if ((mile.poFactoryMilestoneLogs.length == 1 && mile.poFactoryMilestoneLogs[0].isAccepted == null && mile.poFactoryMilestoneLogs[0].isRejected == true)) {
                    return true
                } else {
                    if ((mile.poFactoryMilestoneLogs.length > 1
                        && mile.poFactoryMilestoneLogs[0].isAccepted == null
                        && mile.poFactoryMilestoneLogs[0].isRejected == true)) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        }
        if (type == 'show_rejected_factory') {
            if (roleId != CONSTANT.USER_ROLE.ADMIN) {
                if ((mile.poFactoryMilestoneLogs.length > 0 && mile.poFactoryMilestoneLogs[0].isRejected == true)) {
                    return true
                }
            }
        }
        //End show rejected message to admin
        //Show propose new dates btn to admin
        if (type == 'propose_new_date_admin') {
            if (roleId == CONSTANT.USER_ROLE.ADMIN) {
                if ((mile.poFactoryMilestoneLogs.length == 1 && mile.poFactoryMilestoneLogs[0].isAccepted == null && mile.poFactoryMilestoneLogs[0].isRejected == true)) {
                    return true
                } else {
                    if ((mile.poFactoryMilestoneLogs.length > 1
                        && mile.poFactoryMilestoneLogs[0].isAccepted == null
                        && mile.poFactoryMilestoneLogs[0].isRejected == true)) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        }
        //End show propose new dates btn to admin
        if (type == 'reject_admin' || type == 'accept_admin') {
            if (roleId == CONSTANT.USER_ROLE.ADMIN) {
                if ((mile.poFactoryMilestoneLogs.length > 1
                    && mile.poFactoryMilestoneLogs[0].isAccepted == null
                    && mile.poFactoryMilestoneLogs[0].isRejected == null)) {
                    if (mile.poFactoryMilestoneLogs[1].rejectedBy == userId) {
                        return false
                    } else
                        return true
                } else if ((mile.poFactoryMilestoneLogs.length == 1
                    && mile.poFactoryMilestoneLogs[0].isAccepted == null
                    && mile.poFactoryMilestoneLogs[0].isRejected == null)) {
                    return true
                } else {
                    return false
                }

            }
        }

        if (type == 'show_pending_status_admin') {
            if (roleId == CONSTANT.USER_ROLE.ADMIN && mile.poFactoryMilestoneLogs.length > 0 && mile.poFactoryMilestoneLogs[0].isAccepted == null && mile.poFactoryMilestoneLogs[0].isRejected == null && mile.poFactoryMilestoneLogs[0].isRejected == null && mile.poFactoryMilestoneLogs[0].createdBy == userId) {
                return true
            }
        }

        if (type == 'show_pending_status_factory') {
            if (roleId != CONSTANT.USER_ROLE.ADMIN && (mile.poFactoryMilestoneLogs.length > 0 && mile.poFactoryMilestoneLogs[0].isAccepted == null && mile.poFactoryMilestoneLogs[0].isRejected == null && mile.poFactoryMilestoneLogs[0].createdBy == userId)) {
                return true
            }
        }
    }
}

export const getDates = (mile, type) => {
    if (mile.poFactoryMilestoneLogs.length > 0) {
        if (type == 'start' && mile.poFactoryMilestoneLogs[0].startDate)
            return moment(
                mile.poFactoryMilestoneLogs[0].startDate
            ).format("DD-MM-yyyy")
        else if (type == 'end' && mile.poFactoryMilestoneLogs[0].endDate)
            return moment(
                mile.poFactoryMilestoneLogs[0].endDate
            ).format("DD-MM-yyyy")
        else return 'NA'
    }
    else return 'NA'
}

export const getExecutionDates = (mile, type) => {
    if (mile.poExecutions.length > 0) {
        if (type == 'start' && mile.poExecutions[0].actualStartDate)
            return moment(
                mile.poExecutions[0].actualStartDate
            ).format("DD-MM-yyyy")
        else if (type == 'end' && mile.poExecutions[0].actualEndDate)
            return moment(
                mile.poExecutions[0].actualEndDate
            ).format("DD-MM-yyyy")
        return 'NA'
    }
    else return 'NA'
}


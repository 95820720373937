import React, { useEffect, useState } from "react";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field, Formik } from "formik";
import { notify } from "../../features/notification/notify";
import * as Yup from "yup";
import { useLocation } from "react-router";
import { resetRfq } from "../../features/rfq/slice";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { getSampleByRfqId } from "../../features/rfq/action";
import { addUpdatePo } from "../../features/po/action";

export const SampleForPoModal = (props) => {
  const { setOpenSampleForPo, openSampleForPo, rfqId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("userId");
  const [samples, setSamples] = useState([]);
  const [copyOfSamples, setCopyOfSamples] = useState([]);
  const [render, setRender] = useState(false);
  const rfqResponse = useSelector((state) => state.rfq);
  const poResponse = useSelector((state) => state.po);
  const [poList, setPoList] = useState([{ name: "", description: "" }]);
  const [currentTab, setCurrentTab] = useState("sample-list");
  useEffect(() => {
    if (rfqId) {
      dispatch(getSampleByRfqId(rfqId));
    }
  }, [rfqId]);
  useEffect(() => {
    if (
      rfqResponse.actionName === "getSampleByRfqId" &&
      rfqResponse.rfqSampleList
    ) {
      setSamples(rfqResponse.rfqSampleList.rfqSamplesInfo);
      let temp = [];
        rfqResponse.rfqSampleList.rfqSamplesInfo.map((item) => {
          let exist = temp.find(x => x.sampleId == item.id)
          if(!exist)
          temp.push({ sampleId: item.sampleId, isChecked: true, quantity: 0 });
        });
        setCopyOfSamples(temp);
      setRender(true);
      dispatch(resetRfq());
    }
  }, [rfqResponse]);

  useEffect(() => {
    if (poResponse.actionName === "addUpdatePo" && poResponse.success && poResponse.message=="PO Created Successfully."  ) {
      notify("PO added successfully", "success");
      navigate("/po-list");
      dispatch(resetRfq());
      setCopyOfSamples([]);
    }
    else if(poResponse.message=="PO not created."){
      notify("PO is not created", "info");
    }
  }, [poResponse]);

  const handleCloseModal = () => {
    setOpenSampleForPo(false);
    setCopyOfSamples([]);
  };

  const handleCheckBox = (e, index) => {
    let temp = [...copyOfSamples];
    temp[index].isChecked = e.target.checked;
    setCopyOfSamples(temp);

    if (e.target.checked == false) {
      let temp = [...copyOfSamples];
      temp[index].quantity = 0;
      setCopyOfSamples(temp);
    }
  };

  const handleInputChange = (e, index, sampleId) => {
        let temp = [...copyOfSamples];
        temp[index].quantity = e.target.value;
        setCopyOfSamples(temp);
  };
  const addMorePOs = () => {
    if (poList.length < 5) {
      let temp = [...poList];
      temp.push({ name: "", description: "" });
      setPoList(temp);
    } else notify("Max 5 PO(s) can be added!", "info");
  };
  const removePOs = (index) => {
    let temp = [...poList];
    temp.splice(index, 1);
    setPoList(temp);
  };
  const handleChangeInput = (e, index) => {
    let temp = [...poList];
    temp[index][e.target.name] = e.target.value;
    setPoList(temp);
  };
  const goToPoCreatoin = () => {
    let temp = copyOfSamples.filter((x) => x.isChecked == true);
    if (temp.length == 0) notify("Please select a sample!", "info");
    else {
      temp = copyOfSamples.filter(
        (x) => x.quantity == 0 && x.isChecked == true
      );
      if (temp.length > 0)
        notify("Please enter quantity for all selected samples!", "info");
      else setCurrentTab("add-po");
    }
  };

  const submit = () => {
    let temp = poList.filter((x) => x.name == "" || x.description == "");
    if (temp.length > 0)
      notify("Please enter name and description values!", "info");
    else {
      var payload = {
        sampleList: copyOfSamples,
        poList: poList,
        rfqId: rfqId,
        createdBy: loggedInUser,
      };
      dispatch(addUpdatePo(payload));
    }
  };
  return (
    <Modal show={openSampleForPo} className="custom-modal" centered size="xl">
      <Modal.Header>
        <h2>
          <button type="button" onClick={handleCloseModal} className="btn">
            &#8249;
          </button>
          Select Samples
        </h2>
      </Modal.Header>

      <Modal.Body>
        <div className="body-wrap">
          <div className="sample-detail-wrap">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    currentTab === "sample-list" ? "active" : ""
                  }`}
                  id="sampleList-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#sampleList-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="sampleList-tab-pane"
                  aria-selected="true"
                  onClick={(e) => setCurrentTab("sample-list")}
                >
                  Add Samples
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    currentTab === "add-po" ? "active" : ""
                  }`}
                  id="po-tab"
                  role="tab"
                  aria-controls="po-tab-pane"
                  aria-selected="false"
                >
                  Add PO
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <br />
              <div
                className={`tab-pane fade ${
                  currentTab === "sample-list" ? "show active" : ""
                }`}
                id="sampleList-tab-pane"
                role="tabpanel"
                aria-labelledby="sampleList-tab"
                tabIndex="0"
              >
                <div className="row row-gap-4">
                  {samples &&
                    samples.length > 0 &&
                    samples.map((sample, Index) => (
                      <div
                        className="col-xl-3 col-md-3 col-lg-3 col-sm-6"
                        key={Index}
                      >
                        <div className="category-item">
                          <div
                            className="category-img"
                            style={{ cursor: "pointer" }}
                          >
                            {sample.sampleDetail?.sampleFiles &&
                              sample.sampleDetail.sampleFiles
                                .filter((file) => file.fileType === "image")
                                .slice(0, 1)
                                .map((image, imageIndex) => (
                                  <img
                                    key={imageIndex}
                                    src={image.filePath}
                                    alt={`sample-${Index}-main-image`}
                                  />
                                ))}
                            <div className="category-side-img">
                              {sample.sampleDetail.sampleFiles &&
                                sample.sampleDetail.sampleFiles
                                  .filter((file) => file.fileType === "image")
                                  .slice(1)
                                  .map((sideFile, sideFileIndex) => (
                                    <img
                                      key={sideFileIndex}
                                      src={sideFile.filePath}
                                      alt={`sample-${Index}-side-image-${
                                        sideFileIndex + 1
                                      }`}
                                    />
                                  ))}
                            </div>
                          </div>
                          <div className="category-detail">
                            <div className="category-row">
                              <div className="col-xl-3 col-md-3 col-lg-3 col-sm-6">
                                <span className="icon">{sample.sampleId}</span>
                                {sample.sampleDetail.sampleName}
                              </div>
                              <div className="col-xl-3 col-md-3 col-lg-3 col-sm-6">
                                <div className="col d-flex justify-content-end" style={{ cursor: "pointer" }}>
                                  <input
                                    type="checkbox"
                                    defaultChecked
                                    name={sample.sampleId}
                                    onClick={(e) => handleCheckBox(e, Index)}
                                    className="form-check-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="d-flex gap-3 row"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="col-xl-3 col-md-3 col-lg-3 col-sm-6">
                                <span>Buyer</span>
                                <div className="category-name">
                                  {sample.sampleDetail.buyer}
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-3 col-lg-3 col-sm-6">
                                <span>Product</span>
                                <div className="category-name">
                                  {sample.sampleDetail.sampleName}
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-3 col-lg-3 col-sm-6">
                                <span>Season</span>
                                <div className="category-name">
                                  {sample.sampleDetail.season.seasonName}
                                </div>
                              </div>
                            </div>
                          </div>
                          {copyOfSamples.find((x) => x.sampleId == sample.sampleId)
                            .isChecked && (
                            <div className="comment_section">
                              <div className="body_comment">
                                <div className="row">
                                  <div className="box_comment col-md-12">
                                    <input
                                      placeholder="Enter Quantity"
                                      type="number"
                                      name="Quantity"
                                      onChange={(e) =>
                                        handleInputChange(e, Index, sample.sampleId)
                                      }
                                      className="commentar"
                                      id={sample.sampleId}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div
                className={`tab-pane fade ${
                  currentTab === "add-po" ? "show active" : ""
                }`}
                id="po-tab-pane"
                role="tabpanel"
                aria-labelledby="po-tab"
                tabIndex="1"
              >
                {poList.map((item, index) => (
                  <div className="row align-items-center mb-3 ">
                    <div className="col-lg-3 col-md-3 col-sm-3">
                      <lablel className="form-label">Name: </lablel> &nbsp;
                      <input
                        onChange={(e) => handleChangeInput(e, index)}
                        type="text"
                        name="name"
                        className="dv-po"
                        value={item.name}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-3">
                      <lablel className="form-label">Description: </lablel>
                      &nbsp;
                      <input
                        onChange={(e) => handleChangeInput(e, index)}
                        type="text"
                        name="description"
                        className="dv-po"
                        value={item.description}
                      />{" "}
                    </div>
                    {index !== 0 && (
                      <div className="col-lg-3 col-md-3 col-sm-3">
                        <a
                          onClick={(e) => removePOs(index)}
                          className="dv-add-more"
                        >
                          Remove
                        </a>
                      </div>
                    )}
                  </div>
                ))}
                <div className="col-lg-12 col-md-12 col-sm-12 dv-add-more">
                  <a onClick={addMorePOs}>
                    Add More <i className="fa fa-plus"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn grey-brdr-btn"
          onClick={handleCloseModal}
        >
          Discard
        </button>
        {currentTab == "sample-list" && (
          <button
            onClick={goToPoCreatoin}
            type="submit"
            className="btn ornge-brdr-btn"
          >
            Next
          </button>
        )}
        {currentTab == "add-po" && (
          <button type="submit" onClick={submit} className="btn ornge-brdr-btn">
            Submit
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getUserByRoleType } from "../../../../features/rfq/action";
import { updateGroupChat } from "../../../../features/chats/action";

export const ViewChatGroup = (props) => {
  const {
    rfqId,
    currentChat,
    setShowViewGroupModal,
    showViewGroupModal,
  } = props;

  const dispatch = useDispatch();

  const rfqResponse = useSelector((state) => state.rfq);
  const loginUser = JSON.parse(localStorage.getItem("authorization"));

  const [assigneeList, setAssigneeList] = useState([]);
  const [assineeOption, setAssineeOption] = useState([]);
  const [selectedGroupUser, setSelectedGroupUser] = useState([]);

  const [groupPayload, setGroupPayload] = useState({
    chatId: currentChat.id,
    groupName: currentChat.chatName,
    recieverIds: [],
  });

  useEffect(() => {
    dispatch(getUserByRoleType({ rollType: "Internal", userId: 0 }));

    let defSel = [];
    let selectedReceiverIds = [];
    currentChat.users.map((user) => {
      if (currentChat.groupAdminId != user.id) {
        let dList = {
          value: user.id,
          label: user.name,
        };
        defSel.push(dList);
      }
      selectedReceiverIds.push(user.id);
    });
    setGroupPayload({
      ...groupPayload,
      recieverIds: selectedReceiverIds,
    });
    setSelectedGroupUser(defSel);
  }, []);

  useEffect(() => {
    if (rfqResponse.actionName == "userByRoleType" && rfqResponse.success) {
      setAssigneeList(rfqResponse.roleDetails);
    }
  }, [rfqResponse]);

  useEffect(() => {
    let assigneeArr = [];
    assigneeList &&
      assigneeList.map((assinee) => {
      
        if (
          assinee.id != loginUser.user.id &&
          assinee.id != currentChat.groupAdminId
        ) {
          let list = {
            value: assinee.id,
            label: assinee.name,
          };
          assigneeArr.push(list);
        }
      });
    setAssineeOption(assigneeArr);
  }, [assigneeList]);
  const handleCloseModal = () => {
    setShowViewGroupModal(false);
  };

  const groupPayloadSchema = Yup.object().shape({
    groupName: Yup.string().required("Required"),
    recieverIds: Yup.array()
      .min(2, "please select at least 1 user")
      .required("Required"),
  });

  const handleChange = (e) => {
    setGroupPayload({
      ...groupPayload,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserChange = (selectedAssignee) => {
    let selectedUser =
      currentChat.groupAdminId == loginUser.user.id
        ? [loginUser.user.id]
        : [currentChat.groupAdminId, loginUser.user.id];

    selectedAssignee.map((assignee) => {
      selectedUser.push(assignee.value);
    });
    setGroupPayload({
      ...groupPayload,
      recieverIds: [...new Set(selectedUser)],
    });

    setSelectedGroupUser(selectedAssignee);
  };

  const handleSubmit = (data) => {
    console.log("updated data", data);
    //create groupchat api
    dispatch(updateGroupChat(data));
    handleCloseModal();
  };
  
  return (
    <Modal
      show={showViewGroupModal}
      className="custom-modal"
      centered
      size="lg"
    >
      <Modal.Header>
        <h2>
          <button type="button" className="btn" onClick={handleCloseModal}>
            &#8249;
          </button>
          View/Update Chat Group
        </h2>
      </Modal.Header>
      <Formik
        initialValues={groupPayload}
        validationSchema={groupPayloadSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ errors, touched, isValid, handleBlur }) => (
          <Form>
            <Modal.Body>
              <div className="row align-items-center mb-4">
                <label className="form-label col-lg-2 col-md-3 col-sm-4">
                  Group Name :
                </label>
                <div className="col-lg-10 col-md-9 col-sm-8">
                  <input
                    type="text"
                    name="groupName"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={groupPayload.groupName}
                  />
                  {errors.groupName && touched.groupName ? (
                    <div className="invalid-feedback d-block">
                      {errors.groupName}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row align-items-center mb-4">
                <label className="form-label col-lg-2 col-md-3 col-sm-4">
                  Group Admin :
                </label>
                <div className="col-lg-10 col-md-9 col-sm-8">
                  <label className="form-label col-lg-2 col-md-3 col-sm-4">
                    <h6 style={{ color: "#000" }}>
                      {currentChat.groupAdmin.name}
                    </h6>
                  </label>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <label className="form-label col-lg-2 col-md-3 col-sm-4">
                  Select Users :
                </label>
                <div className="col-lg-10 col-md-9 col-sm-8">
                  <Select
                    onChange={handleUserChange}
                    options={assineeOption}
                    isMulti={true}
                    value={selectedGroupUser}
                    // isClearable={selectedGroupUser.some((v) => !v.isFixed)}
                    // className="basic-multi-select"
                    // classNamePrefix="select"
                    name="recieverIds"
                  />
                  {errors.recieverIds && touched.recieverIds ? (
                    <div className="invalid-feedback d-block">
                      {errors.recieverIds}
                    </div>
                  ) : null}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn grey-brdr-btn"
                onClick={handleCloseModal}
              >
                Discard
              </button>
              <button type="submit" className="btn ornge-brdr-btn">
                Update Group
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

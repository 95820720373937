import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../../../utils/api.urls";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

//create-update action
export const SaveUpdateGuarantorRepo = createAsyncThunk(
    "SaveUpdateGuarantorRepo", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.SAVE_UPDATE_GURANTOR_REPO,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//read action
export const getGuarantorInfo = createAsyncThunk(
    "getGuarantorInfo", async (gid, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_GURANTOR_INFO + "/" + gid);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//create-update action
export const SaveUpdateGuarantorBusiness = createAsyncThunk(
    "SaveUpdateGuarantorBusiness", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.SAVE_UPDATE_GURANTOR_BUSINESS,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//read action
export const getGuarantorBusiness = createAsyncThunk(
    "getGuarantorBusiness", async (gid, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_GURANTOR_BUSINESS + "/" + gid);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//read action
export const getGuarantorBusinessByInfoId = createAsyncThunk(
    "getGuarantorBusinessByInfoId", async (gid, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_GURANTOR_BUSINESS_BYINFOID + "/" + gid);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//create-update action
export const SaveUpdateGuarantorDoc = createAsyncThunk(
    "SaveUpdateGuarantorDoc", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.SAVE_UPDATE_GURANTOR_DOC,
                {
                    method: 'POST',
                    body: data,
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//update action
export const UpdateApplicationStatus = createAsyncThunk(
    "UpdateApplicationStatus", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.UPDATE_APPLICATION_STATUS,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const AskClientToUpload = createAsyncThunk(
    "AskClientToUpload", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.ASK_CLIENT_TO_UPLOAD,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//update action
export const UpdateGuarantorDocStatus = createAsyncThunk(
    "UpdateGuarantorDocStatus", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.UPDATE_GUARANTOR_DOC_STATUS,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//update action
export const updateResendReson = createAsyncThunk(
    "updateResendReson", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.UPDATE_RESEND_REASON,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//read action
export const fetchGuarantorDocs = createAsyncThunk(
    "fetchGuarantorDocs", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.GET_GUARANTOR_DOC,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


//read action
export const fetchGuarantorApplication = createAsyncThunk(
    "fetchGuarantorApplication", async (gid, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_GUARANTOR_APPLICATION + "/" + gid);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


//read action 
export const fetchAllGuarantorApplication = createAsyncThunk(
    "fetchAllGuarantorApplication", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_ALL_GUARANTOR_APPLICATION + "/" + args.type + "?page=" + args.page + "&size=" + args.size);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const askForNewDocs = createAsyncThunk(
    "askForNewDocs", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.ASK_FOR_NEW_DOC,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const rejectApplication = createAsyncThunk(
    "rejectApplication", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.REJECT_APPLICATION,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const underwriteApplication = createAsyncThunk(
    "underwriteApplication", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.UNDERWRITING_APPLICATION,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
//read action
export const getUnderwrittenApplications = createAsyncThunk(
    "getUnderwrittenApplications", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_UNDERWRITING_APPLICATION);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const setInterestAndTenure = createAsyncThunk(
    "setInterestAndTenure", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.SET_INTEREST_TENURE,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const customerRejection = createAsyncThunk(
    "customerRejection", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.FINANCE.CUSTOMER_REJECTION,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchReportingInfo = createAsyncThunk(
    "fetchReportingInfo", async (gid, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.FINANCE.GET_REPORTING_INFO + "/" + gid);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const downloadReport = createAsyncThunk(
    "downloadReport", async (data, { rejectWithValue }) => {
      try {
        const response = await fetch(
            API_URLS.FINANCE.DOWNLOAD_REPORT,
            {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(data),
            });
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
    } catch (error) {
        return rejectWithValue(error);
    }
    }
  );

  export const SaveUpdateInvoice = createAsyncThunk(
    "SaveUpdateInvoice", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.SC_FINANCE.SAVE_UPDATE_INVOICE,
                {
                    method: 'POST',
                    body: data,
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
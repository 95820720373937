import React, { useEffect, useState } from "react";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { Form, Formik } from "formik";
import { v1 as uuidv1 } from "uuid";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../features/notification/notify";
import { useNavigate } from "react-router-dom";

import downloaingIcon from "../../assets/images/downloading.svg";
import arrowBackIcon from "../../assets/images/arrow-back.svg";

export const AddBulkRFQ = () => {
  const [rfq, setRfq] = useState([]);
  const [fileJson, setFileJson] = useState();
  const navigate = useNavigate();
  const TNAResponse = useSelector((state) => state.uploadTNA);
  const dispatch = useDispatch();
  const loggedInUser = parseInt(localStorage.getItem("userId"));
  const getFile = (e, fileExtension) => {
    const image = e.target.files[0];
    const blob = image.slice(0, image.size);
    const id = uuidv1();
    // const newImageName = id + "." + fileExtension;
    const newImageName = e.target.files[0]?.name;
    const file = new File([blob], newImageName);
    return { file, newImageName };
  };
  const handleFileDownload = () => {
    const downloadUrl = `${process.env.PUBLIC_URL}/rfqTemplate/rfqTemplate.xlsx`;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "rfqTemplate.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleFileSheetUpload = (e, type) => {
    const fileExtension = e.target.files[0]?.name
      .split(".")
      .pop()
      .toLowerCase();
    var acceptedFormats = ["xlsx", "xls"];
    if (!acceptedFormats.includes(fileExtension)) {
      notify("File format not supported.", "error");
    } else {
      const { file, newImageName } = getFile(e, fileExtension);
      if (type === "rfq") {
        setRfq((rfq) => [
          ...rfq,
          { id: 0, file: file, fileName: newImageName, isDeleted: false },
        ]);
      }
    }
  };

  useEffect(() => {
    if (rfq.length != 0) {
      ExcelToJsonConverter();
    }
  }, [rfq]);

  const ExcelToJsonConverter = () => {
    const reader = new FileReader();
    const file = rfq[0].file;

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelDateToJSDate = (serial) => {
        const excelEpoch = new Date(1899, 11, 30);
        const jsDate = new Date(excelEpoch.getTime() + serial * 86400000);
        return jsDate;
      };
      const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
      };

      let json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const headers = json[0].map((header) =>
        header.replace(/[^a-zA-Z0-9]/g, "")
      );

      json = json.slice(1).map((row) => {
        let rowObject = {};
        row.forEach((cell, index) => {
          let formattedCell = cell;
          if (typeof cell === "number" && cell > 40000) {
            const jsDate = excelDateToJSDate(cell);
            formattedCell = formatDate(jsDate);
          }
          rowObject[headers[index]] = formattedCell;
        });
        return rowObject;
      });

      const jsonDataString = JSON.stringify(json);
      setFileJson(jsonDataString);
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (fileJson) {
      navigate("/previewBulkRfq", { state: { fileJson, loggedInUser } });
    }
  }, [fileJson]);

  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <Formik enableReinitialize={true}>
            {({ errors, touched, isValid, handleBlur }) => (
              <>
                <Form>
                  <div className="top-heading">
                    <div className="d-flex">
                      <a
                        className="back cursor-pointer"
                        onClick={() => navigate(-1)}
                      >
                        <img src={arrowBackIcon} alt="back" />
                      </a>
                      <h2>Upload Bulk rfq(s)</h2>
                    </div>
                  </div>

                  <div className="finance-wrap">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="finance-upload-invoice">
                          <h4>Download template</h4>
                          <ul>
                            <li>Download our excel template.</li>
                            <li>Enter data for each column.</li>
                          </ul>
                          <div className="button-wrap">
                            <div
                              className="btn inner-blue-border-btn w-100"
                              onClick={handleFileDownload}
                            >
                              <span className="align-middle">
                                Download Template
                              </span>
                              <img
                                src={downloaingIcon}
                                alt="downloading"
                                className="align-middle ms-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="finance-upload-invoice">
                          <h4>Upload template</h4>
                          <ul>
                            <li>Choose downloaded template.</li>
                            <li>Upload template.</li>
                          </ul>
                          <div className="position-relative custom-upload">
                            <div className="upload">
                              <div className="upload-detail">
                                <div>
                                  Drop Template here or{" "}
                                  <span className="blue-txt">Browse</span>
                                </div>
                                <div>Max file size: 5MB</div>
                              </div>
                            </div>
                            <input
                              type="file"
                              name="rfq"
                              className="custom-file"
                              onChange={(event) => {
                                handleFileSheetUpload(event, "rfq");
                              }}
                            />
                            {errors.rfq && touched.rfq ? (
                              <div className="invalid-feedback d-block">
                                {errors.rfq}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="manual-dwnld">
                      Your download should start automatically, if it doesn’t
                      please{" "}
                      <a
                        className="cursor-pointer"
                        onClick={handleFileDownload}
                      >
                        click here
                      </a>
                    </p>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../../utils/api.urls";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

export const getPendingInvoices = createAsyncThunk(
    "getPendingInvoices", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.SC_FINANCE.GET_PENDING_INVOICES + "?page=" + args.page + "&size=" + args.size);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getInvoiceStatusCount = createAsyncThunk(
    "getInvoiceStatusCount", async (args, { rejectWithValue }) => {
        try {
            const response = await fetch(API_URLS.SC_FINANCE.GET_INVOICE_STATUS_COUNT);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const approveOrRejectInvoice = createAsyncThunk(
    "approveOrRejectInvoice", async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.SC_FINANCE.APPROVE_REJECT_INVOICE,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

import { createSlice } from "@reduxjs/toolkit";
import { getAllPOChats, getAllPOChatMessages, saveNewPOChatMessage , createGroupPOChat, updateGroupPOChat, createSinglePOChat} from "./action";


export const poChat = createSlice({
  name: "poChat",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
    poAllChats : null,
    poChatMessages : null,
    newSavedMessage : null,
    createdSingleChat: null,
    updatedGroupChat: null
  },

  reducers: {
    resetPOChat: (state, action) => {
      state.success = false
       state.actionName=null;
      state.message = null
    },
  },

  extraReducers: {
    [getAllPOChats.pending]: (state) => {
      state.loading = true;
    },
    [getAllPOChats.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.poAllChats = action.payload;
      state.actionName = "getAllPOChats"
    },
    [getAllPOChats.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getAllPOChatMessages.pending]: (state) => {
      state.loading = true;
    },
    [getAllPOChatMessages.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.poChatMessages = action.payload;
      state.actionName = "getAllPOChatMessages"
    },
    [getAllPOChatMessages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [saveNewPOChatMessage.pending]: (state) => {
      state.loading = true;
    },
    [saveNewPOChatMessage.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.newSavedMessage = action.payload;
      state.actionName = "saveNewPOChatMessage"
    },
    [saveNewPOChatMessage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [createSinglePOChat.pending]: (state) => {
      state.loading = true;
    },
    [createSinglePOChat.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.createdSingleChat = action.payload;
      state.actionName = "createSinglePOChat"
    },
    [createSinglePOChat.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [createGroupPOChat.pending]: (state) => {
      state.loading = true;
    },
    [createGroupPOChat.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "createGroupPOChat"
    },
    [createGroupPOChat.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [updateGroupPOChat.pending]: (state) => {
      state.loading = true;
    },
    [updateGroupPOChat.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.updatedGroupChat = action.payload.chat
      state.actionName = "updateGroupPOChat"
    },
    [updateGroupPOChat.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    }
  },
});

export default poChat.reducer;

export const { resetPOChat } = poChat.actions;

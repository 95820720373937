import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

export const getRfqSampleComments = createAsyncThunk(
    "getRfqSampleComments",
    async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.RFQ.GET_ALL_RFQSAMPLE_COMMENTS + "/" + data.userRole + "/" + data.rfqSampleId + "/" + data.userId;
            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addCommentToRfqSample = createAsyncThunk(
    "addCommentToRfqSample",
    async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(
                API_URLS.RFQ.SAVE_RFQSAMPLE_COMMENTS,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            );
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
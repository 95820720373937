import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

export const addUpdateBuyer = createAsyncThunk(
    "addUpdateBuyer",
    async (formData, { rejectWithValue }) => {
        try {

            const response = await fetch(
                API_URLS.MANAGEMENT.ADD_UPDATE_BUYER,
                {
                    method: "POST",
                    body: formData
                }
            );
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addUpdateSupplier = createAsyncThunk(
  "addUpdateSupplier",
  async (formData, { rejectWithValue }) => {
      try {

          const response = await fetch(
              API_URLS.MANAGEMENT.ADD_UPDATE_SUPPLIER,
              {
                  method: "POST",
                  body: formData
              }
          );
          if (!response.ok) return rejectWithValue(response.statusText)
          const result = await response.json();
          return result;
      } catch (error) {
          return rejectWithValue(error);
      }
  }
);


export const getAllBuyer = createAsyncThunk(
    "getAllBuyer",
    async (data, { rejectWithValue }) => {

        try {
            let url = API_URLS.MANAGEMENT.GET_ALL_BUYER;
            url = url + "?page=" + data.page + "&size=" + data.limit+"&search="+ data.filters.search;
            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBuyerById = createAsyncThunk(
    "getBuyerById",
    async (buyerId, { rejectWithValue }) => {

        try {
            let url = API_URLS.MANAGEMENT.GET_BUYER_BYID;
            url = url + "?buyerId=" + buyerId;
            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const getAllUsers = createAsyncThunk(
    "getAllUsers",
    async (data, { rejectWithValue }) => {

        try {
            let url = API_URLS.MANAGEMENT.GET_ALL_USER;
            url = url + "?page=" + data.page + "&size=" + data.limit+"&search="+ data.filters.search;
            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const getAllSuppliers = createAsyncThunk(
    "getAllSuppliers",
    async (data, { rejectWithValue }) => {

        try {
            let url = API_URLS.MANAGEMENT.GET_ALL_SUPPLIER;
            url = url + "?page=" + data.page + "&size=" + data.limit+"&search="+ data.filters.search;
            const response = await fetch(url);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getLocationDetail = createAsyncThunk(
  "getLocationDetail",
  async (data, { rejectWithValue }) => {

      try {
          let url = API_URLS.MANAGEMENT.GET_ALL_LOCATION;
          const response = await fetch(url);
          if (!response.ok) return rejectWithValue(response.statusText)
          const result = await response.json();
          return result;
      } catch (error) {
          return rejectWithValue(error);
      }
  }
);

export const addUpdateUser = createAsyncThunk(
    "addUpdateUser",
    async (data, { rejectWithValue }) => {      
      try {
        const response = await fetch(
         API_URLS.MANAGEMENT.ADMIN_ADD_UPDATE_USER,
          {
            method: "POST",
            headers:myHeaders,
            body: JSON.stringify(data),
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  export const updateUserStatus = createAsyncThunk(
    "updateUserStatus",
    async (id, { rejectWithValue }) => {
      
      try {
        const response = await fetch(
         API_URLS.MANAGEMENT.ADMIN_UPDATE_USER_STATUS + "/" + id
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  export const getSupplierById = createAsyncThunk(
    "getSupplierById",
    async (id,{rejectWithValue})=>{
      
      try{
        const response = await fetch(
          API_URLS.MANAGEMENT.GET_SUPPLIER_BY_ID + "/"+ id,
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json()
        return result;
        
      }catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  );
  export const getUserById = createAsyncThunk(
    "getUserById",
    async (id,{rejectWithValue})=>{
      
      try{
        const response = await fetch(
          API_URLS.MANAGEMENT.GET_USER_BY_ID + "/"+ id,
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json()
        return result;
        
      }catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  );



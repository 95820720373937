import { createSlice } from "@reduxjs/toolkit";
import { getPendingInvoices, approveOrRejectInvoice, getInvoiceStatusCount } from "./action";


export const scFinance = createSlice({
  name: "scFinance",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
    pendingInvoices: [],
    invoiceReportingInfo: null,
  },

  reducers: {
    resetSCFinance: (state, action) => {
      state.success = false;
      state.actionName = '';
      state.message = null;
      state.loading = false;
      state.error = null;
    },
  },

  extraReducers: {
    [getPendingInvoices.pending]: (state) => {
      state.loading = true;
    },
    [getPendingInvoices.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.pendingInvoices = action.payload;
      state.actionName = "getPendingInvoices"
    },
    [getPendingInvoices.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [approveOrRejectInvoice.pending]: (state) => {
      state.loading = true;
    },
    [approveOrRejectInvoice.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "approveOrRejectInvoice";
      state.message = action.payload.message;
    },
    [approveOrRejectInvoice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }, 
    [getInvoiceStatusCount.pending]: (state) => {
      state.loading = true;
    },
    [getInvoiceStatusCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "getInvoiceStatusCount";
      state.invoiceReportingInfo = action.payload;
    },
    [getInvoiceStatusCount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },    
  },
});

export default scFinance.reducer;
export const { resetSCFinance } = scFinance.actions;

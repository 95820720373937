import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

//create action
export const addUpdateMilestone = createAsyncThunk(
  "addUpdateMilestone",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.MILESTONE.ADD_UPDATE_MILESTONE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllMileStone = createAsyncThunk(
  "getAllMileStone",
  async (data,{ rejectWithValue }) => {
    try {
      let url = API_URLS.MILESTONE.GET_ALL_MILESTONE;
            url = url + "?page=" + data.page + "&limit=" + data.limit
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          //body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMilestones = createAsyncThunk(
  "fetchMilestones",
  async (data,{ rejectWithValue }) => {
    try {
      let url = API_URLS.MILESTONE.FETCH_MILESTONES;
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          //body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMilestone = createAsyncThunk(
  "deleteMilestone",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.MILESTONE.DELETE_MILESTONE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
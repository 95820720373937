import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";



export const uploadInvoice = createAsyncThunk(
    "uploadInvoice",
    async (data, { rejectWithValue }) => {
      try {
        const response = await fetch(
          API_URLS.PO.UPLOAD_INVOICE,
          {
            method: "POST",
            body:data,
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );


  export const userBulkUpload = createAsyncThunk(
    "userBulkUpload",
    async (data, { rejectWithValue }) => {
      try {
        const response = await fetch(
          API_URLS.USERS.USER_BULK_UPLOAD,
          {
            method: "POST",
            body:data,
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const supplierBulkUpload = createAsyncThunk(
    "supplierBulkUpload",
    async (data, { rejectWithValue }) => {
      try {
        const response = await fetch(
          API_URLS.SUPPLIER.SUPPLIER_BULK_UPLOAD,
          {
            method: "POST",
            body:data,
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
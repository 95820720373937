import { useEffect, useRef, useState } from "react";
import { addSampleToRfq, getSampleByRfqId } from "../../features/rfq/action";
import { useDispatch, useSelector } from "react-redux";
import { resetRfq } from "../../features/rfq/slice";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { notify } from "../../features/notification/notify";
import { Form, Formik } from "formik";
import { releaseRfq } from "../../features/rfq/action";
import { useNavigate } from "react-router-dom";
import { getUserByRoleName } from "../../features/userDetail/action";
import { resetUserDetail } from "../../features/userDetail/slice";
import { getBidByRfqId } from "../../features/rfq/action";
import Multiselect from "multiselect-react-dropdown";
export const ReleaseBuyer = (props) => {
    const { rfqId } = props;
    const dispatch = useDispatch();
    const btnRef = useRef(null);
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    const [userInfoBuyer, setUserInfoBuyer] = useState([]);

    const [samples, setSamples] = useState([]);
    const [buyerDetail, setBuyerDetail] = useState({
        notifyTo: "", subject: "", regard: "", type: "", rfqId: "", bidType: "Buyer", createdBy: userId,
    })
    const [sampleElement, setSampleElement] = useState([{ fieldName: 'Quantity', isShow: true, isCstm: false },
    { fieldName: 'Price', isShow: true, isCstm: false }, { fieldName: 'leadTime', isShow: true, isCstm: false },
    { fieldName: 'MOQ', isShow: true, isCstm: false }]);
    const [customFieldList, setCustomFieldList] = useState([]);
    const [cstmField, setCstmField] = useState();

    const [storeSampleInfo, setStoreSampleInfo] = useState([]);

    const rfqResponse = useSelector((state) => state.rfq);

    const userResponse = useSelector((state) => state.userDetail);
    const [selectedNotify, setSelectedNotify] = useState([]);

    useEffect(() => {
        setBuyerDetail({
            ...buyerDetail,
            rfqId: rfqId,
        });
        if (rfqId) {
            dispatch(getSampleByRfqId(rfqId))
        }

    }, [rfqId]);

    useEffect(() => {
        dispatch(getUserByRoleName('Buyer'));
    }, []);


    useEffect(() => {
        if (rfqResponse.actionName === "getSampleByRfqId" && rfqResponse.rfqSampleList) {
            setSamples(rfqResponse.rfqSampleList.rfqSamplesInfo);
            dispatch(resetRfq());
        }

        else if (rfqResponse.actionName === "releaseRFQ" && rfqResponse.message == "Released Buyer") {
            notify('Release RFQ successfully', 'success');
            setBuyerDetail({ notifyTo: "", subject: "", regard: "", type: "", rfqId: rfqId, bidType: "Buyer", createdBy: userId })
            setSamples([]);
            setStoreSampleInfo([]);
            dispatch(getSampleByRfqId(rfqId));
            dispatch(getUserByRoleName('Buyer'));
            dispatch(getBidByRfqId({ page: 1, limit: 10, rfqId }));
            dispatch(resetRfq());
            setCstmField();
            setCustomFieldList([]);
            setSampleElement([{ fieldName: 'Quantity', isShow: true, isCstm: false },
            { fieldName: 'Price', isShow: true, isCstm: false }, { fieldName: 'leadTime', isShow: true, isCstm: false },
            { fieldName: 'MOQ', isShow: true, isCstm: false }])
            btnRef.current.click();
        }

    }, [rfqResponse]);

    useEffect(() => {
        if (userResponse.actionName === "userByRoleName" && userResponse.success) {
            if (userResponse?.roleDetails?.[0]?.Roles?.[0]?.role == 'Buyer') {
                setUserInfoBuyer(userResponse.roleDetails);
            }
            dispatch(resetUserDetail())
        }
    }, [userResponse]);

    const handleChange = (e, sampleId, sampleName, element) => {
        var { name, value, checked } = e.target;
        let checkBoxName = "show";
        if (name.startsWith("show")) {
            name = name.split("_")[0] + name.split("_").pop();
        } else {
            checkBoxName = checkBoxName + name;
        }
        setStoreSampleInfo((prevData) => ({
            ...prevData,
            [sampleId]: {
                ...prevData[sampleId],
                sampleName: sampleName,
                [name]: name.startsWith("show") ? checked : value,
                [checkBoxName]: element.isShow
            },
        }));
        if (name.startsWith("show")) updateSampleElementCheckbox(element, checked)
    };

    const handleSelectedNotify = (selectedList) => {
        setSelectedNotify(selectedList)
    }

    const handleRemoveAssignee=(selectedList, removedItem)=>{
        const updatedRemovedAssignees = [...selectedNotify];
        updatedRemovedAssignees.push(removedItem);
        //setRemoveAssignee(updatedRemovedAssignees);
      }

    const handleInput = (e) => {
        const { name, type, value, checked } = e.target;
        if (type == 'checkbox') {
            setBuyerDetail({
                ...buyerDetail,
                [name]: checked,
            });
        }
        else {
            if (e.target.name == "notifyTo") {
                const values = e.target.value.split(",");
                setBuyerDetail({
                    ...buyerDetail,
                    [e.target.name]: values[0],
                    emailIdOfFactory: values[1],
                    nameOfFactoryOrBuyer: values[2]
                });
            }

            else {
                setBuyerDetail({
                    ...buyerDetail,
                    [e.target.name]: e.target.value,
                });
            }
        }

    }
    const handleSubmit = () => {
        let formData = new FormData();
        let bidSample = {};
        Object.keys(storeSampleInfo).forEach((key) => {
            var data = customFieldList.filter(x => x.bidSampleId == key)
            var result = {
                ...storeSampleInfo[key],
                customField: data
            }
            bidSample[key] = result;
        });

        const allDetails = {
            bidDetails: buyerDetail,
            bidSamples: bidSample,
            selectedNotify:selectedNotify,
            showHideRfqDetails:null
        };
        setSelectedNotify([]);
        dispatch(releaseRfq(allDetails));
        setUserInfoBuyer([]);
    }

    const buyerSchema = Yup.object().shape({
        type: Yup.string().required("Required"),
        subject: Yup.string().required("Required"),
        regard: Yup.string().required("Required"),
    });

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
    const addCustomField = () => {
        let temp = sampleElement;
        temp.push({ fieldName: cstmField, isShow: true, isCstm: true })
        setSampleElement(temp)

        let temp1 = customFieldList;
        samples.map(element => {
            temp1.push({ bidSampleId: element.sampleDetail.id, label: cstmField, value: null, isShow: true })
        });

        setCustomFieldList(temp1)
        setCstmField('')
    }

    const handleCheckBox = (e, index, sampleId, element) => {
        var temp = customFieldList
        temp.map(x => {
            if (x.bidSampleId == sampleId && x.label == element.fieldName) {
                x['isShow'] = e.target.checked
            }
        })
        setCustomFieldList(temp)
        updateSampleElementCheckbox(element, e.target.checked)
        setStoreSampleInfo((prevData) => ({
            ...prevData
        }));
    }

    const updateSampleElementCheckbox = (element, checkedValue) => {
        let temp = sampleElement
        temp.map(x => {
            if (x.fieldName == element.fieldName) {
                x['isShow'] = checkedValue
            }
        })
        setSampleElement(temp)
    }

    const handleCustomField = (e, sampleId, element) => {
        var temp = customFieldList
        temp.map(x => {
            if (x.bidSampleId == sampleId && x.label == element.fieldName) {
                x['value'] = e.target.value
            }
        })
        setCustomFieldList(temp)
    }

    return (
        <>

            <Formik
                initialValues={buyerDetail}
                validationSchema={buyerSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                {({ errors, touched, isValid, handleBlur }) => (
                    <>

                        <Form>
                            <div className="modal fade rfq" id="release-buyer" tabIndex="-1" aria-labelledby="release-buyerLabel"
                                aria-hidden="true">
                                <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button ref={btnRef} type="button" className="btn" data-bs-dismiss="modal">&#8249;</button>
                                            <h2>Release For Bid(Buyer)</h2>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row align-items-center mb-3">
                                                <label className="form-label col-lg-2 col-md-3 col-sm-4">Notify To :</label>
                                                <div className="col-lg-10 col-md-9 col-sm-8">
                                                    <Multiselect
                                                        options={userInfoBuyer}
                                                        name="notifyTo"
                                                        onBlur={handleBlur}
                                                        id="factory-mail"
                                                        displayValue="name"
                                                        onSelect={handleSelectedNotify}
                                                        onRemove={handleRemoveAssignee}
                                                    />
                                                    {/* {errors.notifyTo && touched.notifyTo ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.notifyTo}
                                                        </div>
                                                    ) : null} */}
                                                </div>
                                            </div>
                                            <div className="row align-items-center mb-3">
                                                <label className="form-label col-lg-2 col-md-3 col-sm-4">Subject :</label>
                                                <div className="col-lg-10 col-md-9 col-sm-8">
                                                    <input
                                                        type="text"
                                                        name="subject"
                                                        className="form-control"
                                                        onChange={handleInput}
                                                        onBlur={handleBlur}
                                                        value={buyerDetail.subject}
                                                    />
                                                    {errors.subject && touched.subject ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.subject}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="row align-items-center mb-3">
                                                <label className="form-label col-lg-2 col-md-3 col-sm-4">Type:</label>
                                                <div className="col-lg-10 col-md-9 col-sm-8">
                                                    <input
                                                        type="text"
                                                        name="type"
                                                        className="form-control"
                                                        onChange={handleInput}
                                                        onBlur={handleBlur}
                                                        value={buyerDetail.type}
                                                    />
                                                    {errors.type && touched.type ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            {samples && samples.length > 0 &&
                                                samples.map((sample, index) => (
                                                    <div>
                                                        <div className="separate-label" >{sample.sampleDetail.sampleName} | Product | {sample.sampleDetail.id}</div>
                                                        {sampleElement.map((element, idx) => (
                                                            <div className="row align-items-center mb-3">
                                                                <label className="form-label col-lg-2 col-md-3 col-sm-4">
                                                                    {element.fieldName}:
                                                                </label>

                                                                <div style={{ display: 'flex' }} className="col-lg-10 col-md-9 col-sm-8">
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" role="switch" name={'show_Buyer_' + sample.sampleDetail.id + "_" + element.fieldName} id={'show_Buyer_' + sample.sampleDetail.id + "_" + element.fieldName} defaultChecked={element.isShow == true ? true : false} onChange={(e) => {
                                                                            if (!element.isCstm)
                                                                                handleChange(e, sample.sampleDetail.id, sample.sampleDetail.sampleName, element)
                                                                            else
                                                                                handleCheckBox(e, idx, sample.sampleDetail.id, element);
                                                                        }} />
                                                                    </div>
                                                                    {/* <div className="checkbox-wrapper-10">
                                                                    
                                                                        <input className="tgl tgl-flip" name={'show_Buyer_' + sample.sampleDetail.id + "_" + element.fieldName} id={'show_Buyer_' + sample.sampleDetail.id + "_" + element.fieldName} type="checkbox" onChange={(e) => {
                                                                            if (!element.isCstm)
                                                                                handleChange(e, sample.sampleDetail.id, sample.sampleDetail.sampleName, element)
                                                                            else
                                                                                handleCheckBox(e, idx, sample.sampleDetail.id, element);
                                                                        }} />
                                                                        <label className="tgl-btn" data-tg-off="Hide" data-tg-on="Show" for={'show_Buyer_' + sample.sampleDetail.id + "_" + element.fieldName}></label>
                                                                    </div> */}
                                                                    &nbsp;&nbsp; <input
                                                                        type={element.isCstm == true ? 'string' : 'number'}
                                                                        min={1}
                                                                        name={element.fieldName}
                                                                        className="form-control"
                                                                        onChange={(e) => {
                                                                            if (!element.isCstm)
                                                                                handleChange(e, sample.sampleDetail.id, sample.sampleDetail.sampleName, element)
                                                                            else
                                                                                handleCustomField(e, sample.sampleDetail.id, element)
                                                                        }
                                                                        }
                                                                        onBlur={handleBlur}
                                                                        onKeyDown={preventNegativeValues}
                                                                        required={element.isShow == true ? "required" : ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            <div className="row-md-12 text-center mb-3" >

                                                <button
                                                    type="button"
                                                    className=" dropdown-toggle btn ornge-brdr-btn"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Add Field
                                                </button>
                                                <div className="dropdown-menu dv-drpdn">
                                                    <label className="form-label">
                                                        Field Name
                                                    </label>

                                                    <div className="dv-cstmfld">

                                                        <input
                                                            value={cstmField}
                                                            onChange={(e) => setCstmField(e.target.value.replace(' ', '_'))}
                                                            type="text"
                                                            name="fieldName"
                                                            className="form-control"
                                                        />
                                                        &nbsp;
                                                        <div style={{ textAlign: 'center' }}>
                                                            <button onClick={addCustomField} disabled={!cstmField || cstmField?.trim().length == 0}
                                                                type="submit"
                                                                className="btn btn-rst-smpl"
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="modal-textarea">
                                                <span>Hello,</span>
                                                <textarea rows="5"
                                                    type="text"
                                                    name="regard"
                                                    className="form-control"
                                                    onChange={handleInput}
                                                    onBlur={handleBlur}
                                                    value={buyerDetail.regard}
                                                />
                                                {errors.regard && touched.regard ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.regard}
                                                    </div>
                                                ) : null}
                                                <span>Thanks and Regard</span>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn ornge-brdr-btn" data-bs-dismiss="modal">Discard</button>
                                            <button type="submit" className="btn ornge-brdr-btn">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik >
        </>
    );
}
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateMilestone } from "../../features/milestone/action";
import { notify } from "../../features/notification/notify";
import { resetMilestone } from "../../features/milestone/slice";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

export const CreateMilestone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [integrationOption, setIntegrationOption]= useState([{ value: '1', label: 'Cargo Ready Date' }])
  const createMilestone_Response = useSelector((state) => state.milestone);
  const [milestoneData, setMilestoneData] = useState(
    {
      id: 0,
      name: '',
      description: '',
      monitoringOnStart: false,
      monitoringOnEnd: false,
      milestoneType: '',
      integration: '',
      complete: '',
      freezePlanned: false,
      required: true
    }
  );

  useEffect(() => {
    if (state && state.item) {
      setMilestoneData({
        id: state.item.id,
        name: state.item.name,
        description: state.item.description,
        monitoringOnStart: state.item.monitoringOnStart,
        monitoringOnEnd: state.item.monitoringOnEnd,
        milestoneType: state.item.milestoneType,
        integration: state.item.integration,
        complete: state.item.complete,
        freezePlanned: state.item.freezePlanned,
        required: state.item.required
      })
    }
  }, [state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMilestoneData({
      ...milestoneData,
      [name]: value,
    });
  };

  const handleIntegrationChange = (data) => {
    setMilestoneData({
      ...milestoneData,
      integration: data.value,
    });
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.checked ? true : false;
    setMilestoneData({
      ...milestoneData,
      [e.target.name]: value,
    });
  };

  const milestoneSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    milestoneType: Yup.string().required("Required"),
    integration: Yup.number().nullable().required("Required"),
    complete: Yup.string().required("Required"),
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleFormSubmit = (data) => {
    dispatch(addUpdateMilestone(data));
  };

  useEffect(() => {
    if (createMilestone_Response.actionName == "addUpdateMilestone" && createMilestone_Response.success) {
      notify(createMilestone_Response.message, "success");
      dispatch(resetMilestone());
      navigate(-1);
    }
  }, [createMilestone_Response]);

  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          {milestoneData.id == 0 &&
            <Formik
              initialValues={milestoneData}
              validationSchema={milestoneSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                handleFormSubmit(values);
              }}
            >
              {({ errors, touched, isValid, handleBlur }) => (
                <>
                  <Form>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a>Milestone </a>
                        </li>
                        <li className="breadcrumb-item">
                          <a>Create Milestone</a>
                        </li>
                      </ol>
                    </nav>
                    <div className="top-heading">
                      <div className="d-flex">
                        <a className="back" onClick={handleBack}>
                          &#8249;
                        </a>
                        <h2>Add Milestone</h2>
                      </div>

                      <div className="d-flex gap-2">
                        <button
                          onClick={handleBack}
                          type="button"
                          className="btn grey-brdr-btn"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn ornge-brdr-btn">
                          <span className="align-middle">Save</span>
                        </button>
                      </div>
                    </div>
                    <div className="user-wrap">
                      <h6>Milestone Details</h6>
                      <div className="row row-gap-4 dv-mlstn">
                        <div className="col-lg-12 col-md-12">
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                <label className="form-label">
                                  Milestone Name<span className="star-color">*</span>
                                </label>
                              </div>
                              <div className="col-md-6 col-md-6form-check form-switch input-group-sm dv-swtch">
                                Required?  <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  onChange={handleCheckboxChange}
                                  name="required"
                                  defaultChecked="true"
                                  value={milestoneData?.required} />
                              </div>
                            </div>
                            <input
                              value={milestoneData?.name ?? ""}
                              onChange={handleChange}
                              type="text"
                              name="name"
                              className="form-control"
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name ? (
                              <div className="invalid-feedback d-block">
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="mb-3">
                            <label className=
                              "form-label">
                              Description
                            </label>
                            <textarea
                              value={milestoneData?.description ?? ""}
                              onChange={handleChange}
                              type="text"
                              name="description"
                              className="form-control"
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2">
                          <div className="mb-3">
                            <label className="form-label">
                              Monitoring On<span></span>
                            </label>
                            <p> <input
                              value={milestoneData?.monitoringOnStart}
                              defaultChecked={milestoneData?.monitoringOnStart ? true : false}
                              onChange={handleCheckboxChange}
                              type="checkbox"
                              name="monitoringOnStart"
                              className="form-check-input"
                              onBlur={handleBlur}
                            />&nbsp;<label className="form-label">Start Date</label>
                            </p>
                            <p>
                              <input
                                value={milestoneData?.monitoringOnEnd}
                                defaultChecked={milestoneData?.monitoringOnEnd ? true : false}
                                onChange={handleCheckboxChange}
                                type="checkbox"
                                name="monitoringOnEnd"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />&nbsp;<label className="form-label">End Date</label>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div className="mb-3">
                            <label className="form-label">
                              Milestone Type<span className="star-color">*</span>
                            </label>
                            <p>
                              <input
                                value={"Production Milestone"}
                                onChange={handleChange}
                                defaultChecked={milestoneData?.milestoneType == 'Production Milestone' ? true : false}
                                type="radio"
                                name="milestoneType"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />&nbsp;<label className="form-label">Production Milestone</label>
                            </p>
                            <p>
                              <input
                                value={"Process Milestone"}
                                defaultChecked={milestoneData?.milestoneType == 'Process Milestone' ? true : false}
                                onChange={handleChange}
                                type="radio"
                                name="milestoneType"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />&nbsp;<label className="form-label">Process Milestone</label>
                            </p>
                            {errors.milestoneType && touched.milestoneType ? (
                              <div className="invalid-feedback d-block">
                                {errors.milestoneType}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div className="mb-3">
                            <label className="form-label">
                              Complete<span className="star-color">*</span>
                            </label>
                            <p>
                              <input
                                value={"Manually"}
                                defaultChecked={milestoneData?.complete == 'Manually' ? true : false}
                                onChange={handleChange}
                                type="radio"
                                name="complete"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />
                              &nbsp;<label className="form-label">Manually</label>
                            </p>
                            <p>
                              <input
                                value={"By Integration"}
                                defaultChecked={milestoneData?.complete == 'By Integration' ? true : false}
                                onChange={handleChange}
                                type="radio"
                                name="complete"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />&nbsp;<label className="form-label">By Integration</label>
                            </p>
                            {errors.complete && touched.complete ? (
                              <div className="invalid-feedback d-block">
                                {errors.complete}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">
                              Integration Settings<span className="star-color">*</span>
                            </label>
                            <Select
                              onChange={handleIntegrationChange}
                              options={integrationOption}
                              placeholder={milestoneData?.integration == "" ? 'Integration: All' : integrationOption.find(x=> x.value == milestoneData?.integration)?.label}
                            />
                            {errors.integration && touched.integration ? (
                              <div className="invalid-feedback d-block">
                                {errors.integration}
                              </div>
                            ) : null}
                            <p >
                              <input
                                value={milestoneData?.freezePlanned}
                                defaultChecked={milestoneData?.freezePlanned ? true : false}
                                onChange={handleCheckboxChange}
                                type="checkbox"
                                name="freezePlanned"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />&nbsp;
                              <small className="form-label" >Freeze Planned Ship Begin Date With Completion</small>
                            </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          }
          {milestoneData.id != 0 &&
            <Formik
              initialValues={milestoneData}
              validationSchema={milestoneSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                handleFormSubmit(values);
              }}
            >
              {({ errors, touched, isValid, handleBlur }) => (
                <>
                  <Form>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a>Milestone </a>
                        </li>
                        <li className="breadcrumb-item">
                          <a>Create Milestone</a>
                        </li>
                      </ol>
                    </nav>
                    <div className="top-heading">
                      <div className="d-flex">
                        <a className="back" onClick={handleBack}>
                          &#8249;
                        </a>
                        <h2>Add Milestone</h2>
                      </div>

                      <div className="d-flex gap-2">
                        <button
                          onClick={handleBack}
                          type="button"
                          className="btn grey-brdr-btn"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn ornge-brdr-btn">
                          <span className="align-middle">Save</span>
                        </button>
                      </div>
                    </div>
                    <div className="user-wrap">
                      <h6>Milestone Details</h6>
                      <div className="row row-gap-4 dv-mlstn">
                        <div className="col-lg-12 col-md-12">
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                <label className="form-label">
                                  Milestone Name<span className="star-color">*</span>
                                </label>
                              </div>
                              <div className="col-md-6 col-md-6form-check form-switch input-group-sm dv-swtch">
                                Required?  <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  onChange={handleCheckboxChange}
                                  name="required"
                                  defaultChecked={milestoneData?.required ? true : false}
                                  value={milestoneData?.required} />
                              </div>
                            </div>
                            <input
                              value={milestoneData?.name ?? ""}
                              onChange={handleChange}
                              type="text"
                              name="name"
                              className="form-control"
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name ? (
                              <div className="invalid-feedback d-block">
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="mb-3">
                            <label className=
                              "form-label">
                              Description
                            </label>
                            <textarea
                              value={milestoneData?.description ?? ""}
                              onChange={handleChange}
                              type="text"
                              name="description"
                              className="form-control"
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2">
                          <div className="mb-3">
                            <label className="form-label">
                              Monitoring On<span></span>
                            </label>
                            <p> <input
                              value={milestoneData?.monitoringOnStart}
                              defaultChecked={milestoneData?.monitoringOnStart ? true : false}
                              onChange={handleCheckboxChange}
                              type="checkbox"
                              name="monitoringOnStart"
                              className="form-check-input"
                              onBlur={handleBlur}
                            />&nbsp;<label className="form-label">Start Date</label>
                            </p>
                            <p>
                              <input
                                value={milestoneData?.monitoringOnEnd}
                                defaultChecked={milestoneData?.monitoringOnEnd ? true : false}
                                onChange={handleCheckboxChange}
                                type="checkbox"
                                name="monitoringOnEnd"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />&nbsp;<label className="form-label">End Date</label>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div className="mb-3">
                            <label className="form-label">
                              Milestone Type<span className="star-color">*</span>
                            </label>
                            <p>
                              <input
                                value={"Production Milestone"}
                                onChange={handleChange}
                                defaultChecked={milestoneData?.milestoneType == 'Production Milestone' ? true : false}
                                type="radio"
                                name="milestoneType"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />&nbsp;<label className="form-label">Production Milestone</label>
                            </p>
                            <p>
                              <input
                                value={"Process Milestone"}
                                defaultChecked={milestoneData?.milestoneType == 'Process Milestone' ? true : false}
                                onChange={handleChange}
                                type="radio"
                                name="milestoneType"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />&nbsp;<label className="form-label">Process Milestone</label>
                            </p>
                            {errors.milestoneType && touched.milestoneType ? (
                              <div className="invalid-feedback d-block">
                                {errors.milestoneType}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div className="mb-3">
                            <label className="form-label">
                              Complete<span className="star-color">*</span>
                            </label>
                            <p>
                              <input
                                value={"Manually"}
                                defaultChecked={milestoneData?.complete == 'Manually' ? true : false}
                                onChange={handleChange}
                                type="radio"
                                name="complete"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />
                              &nbsp;<label className="form-label">Manually</label>
                            </p>
                            <p>
                              <input
                                value={"By Integration"}
                                defaultChecked={milestoneData?.complete == 'By Integration' ? true : false}
                                onChange={handleChange}
                                type="radio"
                                name="complete"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />&nbsp;<label className="form-label">By Integration</label>
                            </p>
                            {errors.complete && touched.complete ? (
                              <div className="invalid-feedback d-block">
                                {errors.complete}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">
                              Integration Settings<span className="star-color">*</span>
                            </label>
                            <Select
                              onChange={handleIntegrationChange}
                              options={integrationOption}
                              placeholder={milestoneData?.integration == "" ? 'Integration: All' : integrationOption.find(x=> x.value == milestoneData?.integration)?.label}
                            />

                            {errors.integration && touched.integration ? (
                              <div className="invalid-feedback d-block">
                                {errors.integration}
                              </div>
                            ) : null}
                            <p >
                              <input
                                value={milestoneData?.freezePlanned}
                                defaultChecked={milestoneData?.freezePlanned ? true : false}
                                onChange={handleCheckboxChange}
                                type="checkbox"
                                name="freezePlanned"
                                className="form-check-input"
                                onBlur={handleBlur}
                              />&nbsp;
                              <small className="form-label" >Freeze Planned Ship Begin Date With Completion</small>
                            </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          }
        </div>
      </div>
    </>
  );
};

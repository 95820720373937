import { createSlice } from "@reduxjs/toolkit";
import { getLocationDetail, addUpdateBuyer, getAllUsers, getUserById, getAllBuyer, getAllSuppliers, addUpdateUser, getBuyerById, addUpdateSupplier, updateUserStatus, getSupplierById } from "./action";

export const userManagememnt = createSlice({
    name: "userManagememnt",
    initialState: {
        loading: false,
        error: null,
        success: false,
        buyers: null,
        actionName: null,
        message: null,
        buyerDetail: null,
        userDetail: null,
    },
    name: "userManagememnt",
    initialState: {
        loading: false,
        error: null,
        success: false,
        buyers: null,
        actionName: null,
        message: null,
        users: null,
        suppliers: null,
    },

    reducers: {
        resetUserManagememnt: (state, action) => {
            state.success = false;
            state.error = null;
            state.actionName = null;
        },
    },

    extraReducers: {
        [addUpdateBuyer.pending]: (state) => {
            state.loading = true;
        },
        [addUpdateBuyer.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.message = action.payload.data
            state.actionName = "addUpdateBuyer";
        },
        [addUpdateBuyer.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        [getAllBuyer.pending]: (state) => {
            state.loading = true;
        },
        [getAllBuyer.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.buyers = action.payload.data;
            state.actionName = "getAllBuyer"
        },
        [getAllBuyer.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        [getAllUsers.pending]: (state) => {
            state.loading = true;
        },
        [getAllUsers.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.users = action.payload.usersData;
            state.actionName = "getAllUser"
        },
        [getAllUsers.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        [getAllSuppliers.pending]: (state) => {
            state.loading = true;
        },
        [getAllSuppliers.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.suppliers = action.payload.data;
            state.actionName = "getAllSuppliers"
        },
        [getAllSuppliers.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        [addUpdateUser.pending]: (state) => {
            state.loading = true;
        },
        [addUpdateUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.actionName = "addUpdateUser";
            state.message=action.payload.message;
        },
        [addUpdateUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        [addUpdateSupplier.pending]: (state) => {
            state.loading = true;
        },
        [addUpdateSupplier.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.actionName = "addUpdateSupplier";
        },
        [addUpdateSupplier.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        [getBuyerById.pending]: (state) => {
            state.loading = true;
        },
        [getBuyerById.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.actionName = "getBuyerById";
            state.buyerDetail = action.payload.data
        },
        [getBuyerById.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        [getSupplierById.pending]: (state) => {
            state.loading = true;
        },
        [getSupplierById.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.supplierDetail = action.payload.supplierDetail;
            state.actionName = "getSupplierById";
        },
        [getSupplierById.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        [getUserById.pending]: (state) => {
            state.loading = true;
        },
        [getUserById.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.userDetail = action.payload.userDetail;
            state.actionName = "getUserById";
        },
        [getUserById.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        [updateUserStatus.pending]: (state) => {
            state.loading = true;
        },
        [updateUserStatus.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.message = action.payload.message;
            state.actionName = "updateUserStatus"
        },
        [updateUserStatus.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        [getLocationDetail.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getLocationDetail.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.actionName = "getLocationDetail"
            state.data = action.payload
        },
        [getLocationDetail.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    }
});

export default userManagememnt.reducer;

export const { resetUserManagememnt } = userManagememnt.actions;
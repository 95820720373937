import React, { useEffect, useState } from "react";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { Form, Formik } from "formik";
import { v1 as uuidv1 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../features/notification/notify";
import { supplierBulkUpload } from "../../features/uploadTNA/action";
import { resetTNA } from "../../features/uploadTNA/slice";
export const AddBulkSupplier = () => {
    const [invoice, setInvoice] = useState([]);
    const dispatch = useDispatch();
    const[userType,setUserType]=useState("Supplier");
    const TNAResponse = useSelector((state) => state.uploadTNA);

    const handleChange=(e)=>{
        setUserType(e.target.value);
    }
    useEffect(() => {
        if (TNAResponse.actionName == "supplierBulkUpload") {
            notify("Data saved successfully ", "success");
            setInvoice([]);
            dispatch(resetTNA())
        }

    }, [TNAResponse])

    const getFile = (e, fileExtension) => {
        const image = e.target.files[0];
        const blob = image.slice(0, image.size);
        const id = uuidv1();
        // const newImageName = id + "." + fileExtension;
        const newImageName = e.target.files[0]?.name;
        const file = new File([blob], newImageName);
        return { file, newImageName };
    };

    const handleFileSheetUpload = (e, type) => {
        const fileExtension = e.target.files[0]?.name
            .split(".")
            .pop()
            .toLowerCase();
        var acceptedFormats = ["xlsx", "xls"];
        if (!acceptedFormats.includes(fileExtension)) {
            notify("File format not supported.", "error");
        } else {
            const { file, newImageName } = getFile(e, fileExtension);
            if (type === "invoice") {
                setInvoice((invoice) => [
                    ...invoice,
                    { id: 0, file: file, fileName: newImageName, isDeleted: false },
                ]);
            }
        }
    };

    const handleFileUpload = () => {
        if (invoice.length != 0) {
            let formData = new FormData();
            const fileNames = [];
            invoice.map((fileSet) => {
                formData.append("upload_file", fileSet.file);
                fileNames.push({
                    id: fileSet.id,
                    fileType: "invoice",
                    fileName: fileSet.fileName,
                    isDeleted: fileSet.isDeleted,
                });
            });
            formData.append("fileList", JSON.stringify(fileNames));
            formData.append("userType", JSON.stringify(userType));
            //formData.append("userType", document.getElementById("UserType").value);
            let inputInfo = document.getElementById("fileUpload");
            inputInfo.value = "";
            dispatch(supplierBulkUpload(formData));
        }
    };

    return (
        <>
            <LeftMenu />
            <div className="right-column">
                <Header />
                <div className="body-wrap">

                    <div className="row" >
                        <div className="col-md-6">
                            <h2 className="font-weight-bold">
                                Upload Supplier/Buyer excel sheet
                            </h2>
                            <br />
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="form-label">Select User Type</label>
                                    <select className="form-select" id="UserType" name="UserType" onChange={handleChange} >
                                        <option value="Supplier">Supplier</option>
                                        <option value="Buyer">Buyer</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Choose File</label>
                                    <input
                                        type="file"
                                        id="fileUpload"
                                        name="invoice"
                                        className="form-control"
                                        onChange={(event) => {
                                            handleFileSheetUpload(event, "invoice");
                                        }}
                                    />

                                </div>

                            </div><br />
                            {invoice.length != 0 &&
                                <button
                                    type="button"
                                    style={{ width: "130px" }}
                                    className="form-control border-2 border-primary text-primary bg-white rounded"
                                    onClick={handleFileUpload}
                                >
                                    Save Data &#x2B07;
                                </button>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
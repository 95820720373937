import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateMilestone } from "../../features/milestone/action";
import { notify } from "../../features/notification/notify";
import { resetMilestone } from "../../features/milestone/slice";
import Select from "react-select";

export const NewMilestoneModal = (props) => {
  const {
    setShowCreateMilestoneModal,
    showCreateMilestoneModal,
    setFirstLoad,
  } = props;
  const dispatch = useDispatch();

  const createMilestone_Response = useSelector((state) => state.milestone);
  const [integrationOption, setIntegrationOption]= useState([{ value: '1', label: 'Cargo Ready Date' }])
  const [milestoneData, setMilestoneData] = useState({
    name: "",
    description: "",
    monitoringOnStart: false,
    monitoringOnEnd: false,
    milestoneType: "",
    integration: "",
    complete: "",
    freezePlanned: false,
    required: true
  });

  const handleCloseModal = () => {
    setShowCreateMilestoneModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMilestoneData({
      ...milestoneData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.checked ? true : false;
    setMilestoneData({
      ...milestoneData,
      [e.target.name]: value,
    });
  };

  const milestoneSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    milestoneType: Yup.string().required("Required"),
    integration: Yup.number().nullable().required("Required"),
    complete: Yup.string().required("Required"),
  });

  const handleSubmit = (data) => {
    console.log("milestonedata", data);
    dispatch(addUpdateMilestone(data));
  };

  const handleIntegrationChange = (data) => {
    setMilestoneData({
      ...milestoneData,
      integration: data.value,
    });
  };

  useEffect(() => {
    if (createMilestone_Response.actionName == "addUpdateMilestone") {
      setShowCreateMilestoneModal(false);
      setMilestoneData({
        name: "",
        description: "",
        monitoringOnStart: false,
        monitoringOnEnd: false,
        milestoneType: "",
        integration: "",
        complete: "",
        freezePlanned: false,
        required: true
      });
      setFirstLoad(true);
      notify(createMilestone_Response.message, "success");
      dispatch(resetMilestone());
    }
  }, [createMilestone_Response]);

  return (
    <Modal
      show={showCreateMilestoneModal}
      className="custom-modal"
      centered
      size="lg"
    >
      <Modal.Header>
        <h2>
          <button type="button" className="btn" onClick={handleCloseModal}>
            &#8249;
          </button>
          Create New Milestone
        </h2>
      </Modal.Header>
      <Formik
        initialValues={milestoneData}
        validationSchema={milestoneSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ errors, touched, isValid, handleBlur }) => (
          <Form>
            <Modal.Body>
              <div className="row row-gap-4 dv-mlstn">
                <div className="col-lg-12 col-md-12">
                  <div className="mb-3">
                    <div className="row">
                    <div className="col-md-6">
                      <label className="form-label">
                        Milestone Name<span className="star-color">*</span>
                      </label>
                      </div>
                      <div className="col-md-6 col-md-6form-check form-switch input-group-sm dv-swtch">
                      Required?  <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          onChange={handleCheckboxChange}
                          name="required"
                          defaultChecked="true"
                          value={milestoneData.required} />
                      </div>
                    </div>
                    <input
                      value={milestoneData.name ?? ""}
                      onChange={handleChange}
                      type="text"
                      name="name"
                      className="form-control"
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <div className="invalid-feedback d-block">
                        {errors.name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="mb-3">
                    <label className=
                      "form-label">
                      Description
                    </label>
                    <textarea
                      value={milestoneData.description ?? ""}
                      onChange={handleChange}
                      type="text"
                      name="description"
                      className="form-control"
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2">
                  <div className="mb-3">
                    <label className="form-label">
                      Monitoring On<span></span>
                    </label>
                    <p> <input
                      value={milestoneData.monitoringOnStart}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      name="monitoringOnStart"
                      className="form-check-input"
                      onBlur={handleBlur}
                    />&nbsp;<label className="form-label">Start Date</label>
                    </p>
                    <p>
                      <input
                        value={milestoneData.monitoringOnEnd}
                        onChange={handleCheckboxChange}
                        type="checkbox"
                        name="monitoringOnEnd"
                        className="form-check-input"
                        onBlur={handleBlur}
                      />&nbsp;<label className="form-label">End Date</label>
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="mb-3">
                    <label className="form-label">
                      Milestone Type<span className="star-color">*</span>
                    </label>
                    <p>
                      <input
                        value={"Production Milestone"}
                        onChange={handleChange}
                        type="radio"
                        name="milestoneType"
                        className="form-check-input"
                        onBlur={handleBlur}
                      />&nbsp;<label className="form-label">Production Milestone</label>
                    </p>
                    <p>
                      <input
                        value={"Process Milestone"}
                        onChange={handleChange}
                        type="radio"
                        name="milestoneType"
                        className="form-check-input"
                        onBlur={handleBlur}
                      />&nbsp;<label className="form-label">Process Milestone</label>
                    </p>
                    {errors.milestoneType && touched.milestoneType ? (
                      <div className="invalid-feedback d-block">
                        {errors.milestoneType}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="mb-3">
                    <label className="form-label">
                      Complete<span className="star-color">*</span>
                    </label>
                    <p>
                      <input
                        value={"Manually"}
                        onChange={handleChange}
                        type="radio"
                        name="complete"
                        className="form-check-input"
                        onBlur={handleBlur}
                      />
                      &nbsp;<label className="form-label">Manually</label>
                    </p>
                    <p>
                      <input
                        value={"By Integration"}
                        onChange={handleChange}
                        type="radio"
                        name="complete"
                        className="form-check-input"
                        onBlur={handleBlur}
                      />&nbsp;<label className="form-label">By Integration</label>
                    </p>
                    {errors.complete && touched.complete ? (
                      <div className="invalid-feedback d-block">
                        {errors.complete}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Integration Settings<span className="star-color">*</span>
                    </label>
                    <Select
                              onChange={handleIntegrationChange}
                              options={integrationOption}
                              placeholder={milestoneData?.integration == "" ? 'Integration: All' : integrationOption.find(x=> x.value == milestoneData?.integration)?.label}
                            />
                    {errors.integration && touched.integration ? (
                      <div className="invalid-feedback d-block">
                        {errors.integration}
                      </div>
                    ) : null}
                    <p >
                      <input
                        value={milestoneData.freezePlanned}
                        onChange={handleCheckboxChange}
                        type="checkbox"
                        name="freezePlanned"
                        className="form-check-input"
                        onBlur={handleBlur}
                      />&nbsp;
                      <small className="form-label" >Freeze Planned Ship Begin Date With Completion</small>
                    </p>

                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn grey-brdr-btn"
                onClick={handleCloseModal}
              >
                Discard
              </button>
              <button type="submit" className="btn ornge-brdr-btn">
                Create Milestone
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

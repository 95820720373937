import React, { useEffect, useState } from "react";

export const POChatInputSection = (props) => {
   const { handleSendMsg, socket , currentChat} = props;

  const [msg, setMsg] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const sendChat = (event) => {
    event.preventDefault();
    if (msg.length > 0) {
      handleSendMsg(msg);
      setMsg("");
    }
  };

  const handleTyping = (msg) => {
    setMsg(msg)
    // if (!isTyping) {
    //   socket.current.emit("typing", {
    //     to: currentChat.id,
    //     typing: true,
    //   });
    
    // }
  
  };


  return (
    <div className="chat-footer">
      <div>
        {isTyping ? "Typing..." : ""}
      </div>
        <form className="input-container" onSubmit={(event) => sendChat(event)}>
        <input
          type="text"
          placeholder="type your message here"
          onChange={(e) => handleTyping(e.target.value)}
          className="chat-input"
          value={msg}
        />
        <button type="submit" className="btn ornge-btn">
          Send
        </button>
      </form> 
    </div>
  )
}

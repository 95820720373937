import { Modal, Button } from "react-bootstrap";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDateClick } from "../../../../utils/datePickerHelper";
import moment from "moment";
import * as Yup from "yup";
import { addActualDates, addNewDates, getActualDates } from "../../../../features/po/action";
import { resetPo } from "../../../../features/po/slice";
import { notify } from "../../../../features/notification/notify";
export const ActualDatesEntryModal = (props) => {
    const { userId, getDates, showActualDateModal, setShowActualDateModal, selectedFactory, selectedMilestone } = props
    const dispatch = useDispatch();
    const poResponse = useSelector((state) => state.po);
    const [newDates, setNewDates] = useState({ actualStartDate: null, actualEndDate: null, quantity: null, remarks: null })


    useEffect(() => {
        if (poResponse.actionName == "addActualDates" && poResponse.success) {
            setNewDates({ actualStartDate: null, actualEndDate: null, quantity: null, remarks: null });
        }

    }, [poResponse]);

    const closeModal = () => {
        setShowActualDateModal(false);
    };

    const preventNegativeValues = (e) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const handleChange = (e) => {
        setNewDates({ ...newDates, [e.target.name]: e.target.value });
    };

    const submitData = () => {
        if (parseInt(newDates.quantity) > parseInt(selectedMilestone.quantity)) {
            notify('Cumulative qty should not exceed planned qty!','info')
        } else {
            var payload = {
                ...newDates,
                poFactoryMilestoneId: selectedMilestone.id,
                startDate: selectedMilestone.startDate,
                endDate: selectedMilestone.endDate,
                createdBy: userId
            };
            dispatch(addActualDates(payload));
        }
    };

    const rejectPoModalSchema = Yup.object().shape({
        actualStartDate: Yup.date()
            .required("Start date is required")
            .typeError("Invalid start date"),
        quantity: Yup.number().nullable().required("Quantity is required"),
        remarks: Yup.string().nullable().required("Required")
    });



    return (
        <Modal
            show={showActualDateModal}
            onHide={closeModal}
            className="custom-modal po-mlsn"

        >
            <Formik
                initialValues={newDates}
                validationSchema={rejectPoModalSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    submitData();
                }}
            >
                {({ errors, touched, isValid, dirty, handleBlur }) => (
                    <>
                        <Form>
                            <Modal.Header>
                                <h1 className="modal-title" id="edit-profile-Label">
                                    Propose new time frame
                                </h1>
                            </Modal.Header>
                            <Modal.Body>
                                <row className="centre">
                                    <div className="col-lg-12 col-md-12">
                                        <label className="form-label">
                                            Planned start date:{" "}
                                            <span className="ttlqty-smpl">
                                                {getDates(selectedMilestone, "start")}
                                            </span>
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label className="form-label">
                                            Planned end date:{" "}
                                            <span className="ttlqty-smpl">
                                                {getDates(selectedMilestone, "end")}
                                            </span>
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label className="form-label">
                                            Planned Quantity:{" "}
                                            <span className="ttlqty-smpl">
                                                {selectedMilestone.quantity}
                                            </span>
                                        </label>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row dv-tmfrm">
                                                <div className="col-md-3  form-label">
                                                    Actual start date
                                                    <input
                                                        type="date"
                                                        name="actualStartDate"
                                                        value={
                                                            newDates.actualStartDate
                                                                ? moment(newDates.actualStartDate).format(
                                                                    "yyyy-MM-DD"
                                                                )
                                                                : ""
                                                        }
                                                        onClick={handleDateClick}
                                                        onChange={handleChange}
                                                        min={new Date().toISOString().split("T")[0]}
                                                        className="form-control"
                                                    />
                                                    {errors.actualStartDate && touched.actualStartDate ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.actualStartDate}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="col-md-3  form-label">
                                                    Actual end date
                                                    <input
                                                        type="date"
                                                        name="actualEndDate"
                                                        value={
                                                            newDates.actualEndDate
                                                                ? moment(newDates.actualEndDate).format("yyyy-MM-DD")
                                                                : ""
                                                        }
                                                        onClick={handleDateClick}
                                                        onChange={handleChange}
                                                        min={new Date().toISOString().split("T")[0]}
                                                        className="form-control"
                                                    />
                                                    {errors.actualEndDate && touched.actualEndDate ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.actualEndDate}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="col-md-3  form-label">
                                                    Cumulative Qty Completed
                                                    <input
                                                        onChange={handleChange}
                                                        type="number"
                                                        value={newDates.quantity}
                                                        name="quantity"
                                                        className="form-control"
                                                        min={1}
                                                        onKeyDown={preventNegativeValues}
                                                    />
                                                    {errors.quantity && touched.quantity ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.quantity}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col-md-3  form-label">
                                                    Remarks
                                                    <input
                                                        onChange={handleChange}
                                                        type="text"
                                                        value={newDates.remarks}
                                                        name="remarks"
                                                        className="form-control"
                                                    />
                                                    {errors.remarks && touched.remarks ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.remarks}
                                                        </div>
                                                    ) : null}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </row>
                            </Modal.Body>
                            <Modal.Footer>
                                <a
                                    type="button"
                                    className="btn inner-blue-border-btn"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </a>
                                <Button type="submit" className="btn inner-blue-btn">
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

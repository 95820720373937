import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

//read action
export const showAllUsers = createAsyncThunk(
  "showAllUsers",
  async (args, { rejectWithValue }) => {

    try {
      const response = await fetch(
        API_URLS.MANAGEMENT.ADMIN_GET_USERS + "?page=" + args.page + "&size=" + args.size
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
   
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//update status action
export const updateUserStatus = createAsyncThunk(
  "updateUserStatus",
  async (id, { rejectWithValue }) => {

    try {
      const response = await fetch(
        API_URLS.MANAGEMENT.ADMIN_UPDATE_USER_STATUS + "/" + id
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
   
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


//create action


//set pin action
export const createUserPin = createAsyncThunk(
  "createUserPin",
  async (data, { rejectWithValue }) => {
 

    try {
      const response = await fetch(
        API_URLS.USERS.ADMIN_SET_PIN,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//update action
export const updateUser = createAsyncThunk(
  "updateUser",
  async (data, { rejectWithValue }) => {
   

    try {
      const response = await fetch(
        API_URLS.USERS.ADMIN_UPDATE_USER,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//read action
export const showUser = createAsyncThunk(
  "showUser",
  async (args, { rejectWithValue }) => {
    const response = await fetch(
      "https://641dd63d945125fff3d75742.mockapi.io/crud"
    );

    try {
      const result = await response.json();
     
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//delete action
export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (id, { rejectWithValue }) => {
    const response = await fetch(
      `https://641dd63d945125fff3d75742.mockapi.io/crud/${id}`,
      { method: "DELETE" }
    );

    try {
      const result = await response.json();
  
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUserByRoleName = createAsyncThunk(
  "getUserByRoleName",
  async (name, { rejectWithValue }) => {

    try {
      const response = await fetch(
        API_URLS.RFQ.GET_USER_BY_ROLE_NAME + "/" + name,
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json()
      return result;

    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
);

export const getUserByNameNRoleType = createAsyncThunk(
  "getUserByNameNRoleType",
  async (args, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.USERS.GET_USER_BY_NAME_ROLE_TYPE + "/" + args.roleType + "/" + args.name,
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json()
      return result;

    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
);


import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

export const getRFQMessages = createAsyncThunk(
  "getRFQMessages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.GET_RFQ_MESSAGES,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveRFQMessages = createAsyncThunk(
  "saveRFQMessages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.SAVE_RFQ_MESSAGES,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllUserMessagesByRFQ = createAsyncThunk(
  "getAllUserMessagesByRFQ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.GET_ALL_MESSAGES_BY_RFQ,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


//new chat and group chats apis

export const getAllChats = createAsyncThunk(
  "getAllChats",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ_CHAT.GET_ALL_RFQCHATS,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const getAllChatMessages = createAsyncThunk(
  "getAllChatMessages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ_CHAT.GET_ALL_CHAT_MESSAGES,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveNewChatMessage = createAsyncThunk(
  "saveNewChatMessage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ_CHAT.SAVE_NEW_CHAT_MESSAGE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSingleChat = createAsyncThunk(
  "createSingleChat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ_CHAT.CREATE_SINGLE_CHAT,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createGroupChat = createAsyncThunk(
  "createGroupChat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ_CHAT.CREATE_CHAT_GROUP,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateGroupChat = createAsyncThunk(
  "updateGroupChat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ_CHAT.UPDATE_CHAT_GROUP,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getChatByRfqBid = createAsyncThunk(
  "getChatByRfqBid",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ_CHAT.GET_CHATBY_RFQ_BID,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";


let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');
//create action

// export const fetchUserLogin = createAsyncThunk(
//   'loginData',
//   async (post, { rejectWithValue }) => {
//     try {

//       const response = await fetch(
//         API_URLS.LOGIN,
//          {
//            method: "POST",
//            headers: myHeaders,
//            body: JSON.stringify(post),
//          }
//        );
       
//       if (!response.ok) return rejectWithValue(response.statusText)
//       const data = await response.json()
//       return data
//     } catch (err) {
//       // You can choose to use the message attached to err or write a custom error
//       return rejectWithValue(err)
//     }
//   }
// )

//create action

export const fetchUserSignupValidate = createAsyncThunk(
  'signup',
  async (post, { rejectWithValue }) => {
    try {

      const response = await fetch(
        API_URLS.USERS.SIGNUP_VALIDATE,
         {
           method: "POST",
           headers: myHeaders,
           body: JSON.stringify(post),
         }
       );
      if (!response.ok) return rejectWithValue(response.statusText)
      const data = await response.json()
      return data
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
)



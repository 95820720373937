import React, { useEffect, useState } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getUserByRoleType } from "../../../../features/rfq/action";
import { createGroupChat } from "../../../../features/chats/action";

export const CreateChatGroup = (props) => {
  const { rfqId, setShowCreateGroupModal, showCreateGroupModal } = props;
  const dispatch = useDispatch();

  const rfqResponse = useSelector((state) => state.rfq);
  const loginUser = JSON.parse(localStorage.getItem("authorization"));

  const [assigneeList, setAssigneeList] = useState([]);
  const [assineeOption, setAssineeOption] = useState([]);
  const [groupPayload, setGroupPayload] = useState({
    rfqId: rfqId,
    groupName: "",
    loginUserId: loginUser.user.id,
    recieverIds: [],
  });

  const handleCloseModal = () => {
    setShowCreateGroupModal(false);
  };
  
  useEffect(() => {
    dispatch(getUserByRoleType({ rollType: "Internal", userId: 0 }));
  }, []);

  useEffect(() => {
    if (rfqResponse.actionName == "userByRoleType" && rfqResponse.success) {
      setAssigneeList(rfqResponse.roleDetails);
    }
  }, [rfqResponse]);

  useEffect(() => {
    let assigneeArr = [];
    assigneeList &&
      assigneeList.map((assinee) => {
        if (assinee.id != loginUser.user.id) {  
          let list = {
            value: assinee.id,
            label: assinee.name,
          };
          assigneeArr.push(list);
        }
      });
    setAssineeOption(assigneeArr);
  }, [assigneeList]);

  const groupPayloadSchema = Yup.object().shape({
    groupName: Yup.string().required("Required"),
    recieverIds: Yup.array().min(2, "please select at least 1 user").required("Required"),
  });

  const handleChange = (e) => {
    setGroupPayload({
      ...groupPayload,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserChange = (selectedAssignee) => {
   

    let selectedUser = [loginUser.user.id];
    selectedAssignee.map((assignee) => {
     
      selectedUser.push(assignee.value);
    });
    setGroupPayload({
      ...groupPayload,
      recieverIds: selectedUser,
    });
  };

  const handleSubmit = (data) => {
   

    //create groupchat api
    dispatch(createGroupChat(data));
    handleCloseModal();
  };

  return (
    <Modal
      show={showCreateGroupModal}
      className="custom-modal"
      centered
      size="lg"
    >
      <Modal.Header>
        <h2>
          <button type="button" className="btn" onClick={handleCloseModal}>
            &#8249;
          </button>
          Create Chat Group
        </h2>
      </Modal.Header>
      <Formik
        initialValues={groupPayload}
        validationSchema={groupPayloadSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ errors, touched, isValid, handleBlur }) => (
          <Form>
            <Modal.Body>
              <div className="row align-items-center mb-4">
                <label className="form-label col-lg-2 col-md-3 col-sm-4">
                  Group Name :
                </label>
                <div className="col-lg-10 col-md-9 col-sm-8">
                  <input
                    type="text"
                    name="groupName"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.groupName && touched.groupName ? (
                    <div className="invalid-feedback d-block">
                      {errors.groupName}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <label className="form-label col-lg-2 col-md-3 col-sm-4">
                  Select Users :
                </label>
                <div className="col-lg-10 col-md-9 col-sm-8">
                  <Select
                    // value={filters.assignee}
                    onChange={handleUserChange}
                    options={assineeOption}
                    isMulti={true}
                    name="recieverIds"
                    // placeholder={filters.assignee == "" ? placeholderText : filters.assignee}
                  />
                  {errors.recieverIds && touched.recieverIds ? (
                    <div className="invalid-feedback d-block">
                      {errors.recieverIds}
                    </div>
                  ) : null}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn grey-brdr-btn"
                onClick={handleCloseModal}
              >
                Discard
              </button>
              <button type="submit" className="btn ornge-brdr-btn">
                Create Group
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

import { useEffect, useRef, useState } from "react";
import { addSampleToRfq, getRfqById, getSampleByRfqId } from "../../features/rfq/action";
import { useDispatch, useSelector } from "react-redux";
import { resetRfq } from "../../features/rfq/slice";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { notify } from "../../features/notification/notify";
import { Form, Formik } from "formik";
import { releaseRfq } from "../../features/rfq/action";
import { useNavigate } from "react-router-dom";
import { getUserByRoleName } from "../../features/userDetail/action";
import { getAllUserMessagesByRFQ } from "../../features/chats/action";
import { getBidByRfqId } from "../../features/rfq/action";
import { Modal } from "react-responsive-modal";
import Multiselect from "multiselect-react-dropdown";
import { resetUserDetail } from "../../features/userDetail/slice";

export const ReleaseFactory = (props) => {
  const { rfqId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [userInfoFactory, setUserInfoFactory] = useState([]);
  const btnRef = useRef(null);
  const [addFieldModal, setAddFieldModal] = useState(false);
  const [samples, setSamples] = useState([]);
  let currentUser = JSON.parse(localStorage.getItem("authorization"));
  const loginUserName = currentUser.user.name;
  const [cstmField, setCstmField] = useState();
  const [factoryDetail, setFactoryDetail] = useState({
    notifyTo: "",
    subject: "",
    paymentTerms: "",
    domesticOrInternational: 1,
    regard: "",
    type: "",
    rfqId: "",
    bidType: "Factory",
    createdBy: userId,
    loginUser: loginUserName,
  });
  const [storeSampleInfo, setStoreSampleInfo] = useState([]);
  const [sampleElement, setSampleElement] = useState([
    { fieldName: "Quantity", isShow: true, isCstm: false },
    { fieldName: "Price", isShow: true, isCstm: false },
    { fieldName: "Target", isShow: true, isCstm: false },
    { fieldName: "MOQ", isShow: true, isCstm: false },
  ]);
  const [customFieldList, setCustomFieldList] = useState([]);
  const rfqResponse = useSelector((state) => state.rfq);
  const userResponse = useSelector((state) => state.userDetail);
  const [selectedNotify, setSelectedNotify] = useState([]);
  
  
  useEffect(() => {
    if (rfqId) {
      setFactoryDetail({
        ...factoryDetail,
        rfqId: rfqId,
      });
      dispatch(getSampleByRfqId(rfqId));
    }
  }, [rfqId]);

  useEffect(() => {
    dispatch(getUserByRoleName("Supplier"));
  }, []);

  useEffect(() => {
   
    if (
      rfqResponse.actionName === "getSampleByRfqId" &&
      rfqResponse.rfqSampleList
    ) {
      setSamples(rfqResponse.rfqSampleList.rfqSamplesInfo);
      dispatch(resetRfq());
    } else if (
      rfqResponse.actionName === "releaseRFQ" &&
      rfqResponse.message == "Released Factory"
    ) {
      setFactoryDetail({
        notifyTo: "",
        subject: "",
        paymentTerms: "",
        domesticOrInternational: 1,
        regard: "",
        type: "",
        rfqId: rfqId,
        bidType: "Factory",
        createdBy: userId,
        userId,
        loginUser: loginUserName,
      });
      notify("Release RFQ successfully", "success");
      let currentJsonUser = JSON.parse(localStorage.getItem("authorization"));
      let userPayload = {
        rfqId: rfqId,
        senderId: currentJsonUser.user.id,
        userType: "All",
      };
      dispatch(getAllUserMessagesByRFQ(userPayload));
      setSamples([]);
      setStoreSampleInfo([]);
      dispatch(getSampleByRfqId(rfqId));
      dispatch(getUserByRoleName("Supplier"));
      dispatch(getBidByRfqId({ page: 1, limit: 10, rfqId }));
      dispatch(resetRfq());
      setCstmField();
      setCustomFieldList([]);
      setSampleElement([
        { fieldName: "Quantity", isShow: true, isCstm: false },
        { fieldName: "Price", isShow: true, isCstm: false },
        { fieldName: "Target", isShow: true, isCstm: false },
        { fieldName: "MOQ", isShow: true, isCstm: false },
      ]);
      btnRef.current.click();      
    } else if (
      rfqResponse.actionName === "getBidByRfqId" &&
      rfqResponse.success
    ) {
      dispatch(resetRfq());
    }

  }, [rfqResponse]);

  useEffect(() => {
    if (userResponse.actionName === "userByRoleName") {
      if (userResponse?.roleDetails?.[0]?.Roles?.[0]?.role == "Supplier") {
        setUserInfoFactory(userResponse.roleDetails);
      }
      dispatch(resetUserDetail());
    }
  }, [userResponse]);

  const handleSelectedNotify = (selectedList) => {
    setSelectedNotify(selectedList)
  }

  const handleRemoveAssignee = (selectedList, removedItem) => {
    const updatedRemovedAssignees = [...selectedNotify];
    updatedRemovedAssignees.push(removedItem);
  }

  const handleChange = (e, sampleId, sampleName, element) => {
    let { name, value, checked } = e.target;
    let checkBoxName = "show";
    if (name.startsWith("show")) {
      name = name.split("_")[0] + name.split("_").pop();
    } else {
      checkBoxName = checkBoxName + name;
    }
    setStoreSampleInfo((prevData) => ({
      ...prevData,
      [sampleId]: {
        ...prevData[sampleId],
        sampleName: sampleName,
        [name]: name.startsWith("show") ? checked : value,
        [checkBoxName]: element.isShow
      },
    }));
    if (name.startsWith("show")) updateSampleElementCheckbox(element, checked);
  };

  const handleInput = (e) => {
    const { name, type, value, checked } = e.target;
    if (type == "checkbox") {
      setFactoryDetail({
        ...factoryDetail,
        [name]: checked,
      });
    } else {
      if (e.target.name == "notifyTo") {
        const values = e.target.value.split(",");
        setFactoryDetail({
          ...factoryDetail,
          [e.target.name]: values[0],
          emailIdOfFactory: values[1],
          nameOfFactoryOrBuyer: values[2],
        });
      } else {
        setFactoryDetail({
          ...factoryDetail,
          [e.target.name]: e.target.value,
        });
      }
    }
  };
 
  const handleSubmit = () => {
    releaseBid()
    setUserInfoFactory([]);
    setSelectedNotify([]);
  };

  const handleAddField = () => {
    setAddFieldModal(true);
  };
  // const handleFieldAddedChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   setFieldAdded({
  //     ...fieldAdded,
  //     [name]: value,
  //   });
  // };
  // const handleAddFieldSubmit = (data) => {

  //   setAddFieldModal(false);
  // };
  const onCloseModal = () => setAddFieldModal(false);
  const factorySchema = Yup.object().shape({
    type: Yup.string().required("Required"),
    subject: Yup.string().required("Required"),
    // paymentTerms: Yup.string().required("Required"),
    domesticOrInternational: Yup.string().required("Required"),
    regard: Yup.string().required("Required"),
  });

  const preventNegativeValues = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const addCustomField = () => {
    let temp = sampleElement;
    temp.push({ fieldName: cstmField, isShow: true, isCstm: true });
    setSampleElement(temp);

    let temp1 = customFieldList;
    samples.map((element) => {
      temp1.push({
        bidSampleId: element.sampleDetail.id,
        label: cstmField,
        value: null,
        isShow: true,
      });
    });

    setCustomFieldList(temp1);
    setCstmField("");
  };

  const handleCheckBox = (e, index, sampleId, element) => {
    let temp = customFieldList;
    temp.map((x) => {
      if (x.bidSampleId == sampleId && x.label == element.fieldName) {
        x["isShow"] = e.target.checked;
      }
    });
    setCustomFieldList(temp);

    updateSampleElementCheckbox(element, e.target.checked);
    setStoreSampleInfo((prevData) => ({
      ...prevData,
    }));
  };

  const updateSampleElementCheckbox = (element, checkedValue) => {
    let sampleElementTemp = sampleElement;
    sampleElementTemp.map((y) => {
      if (y.fieldName == element.fieldName) {
        y["isShow"] = checkedValue;
        return;
      }
    });
    setSampleElement(sampleElementTemp);
  };

  const handleCustomField = (e, sampleId, element) => {
    let temp = customFieldList;
    temp.map((x) => {
      if (x.bidSampleId == sampleId && x.label == element.fieldName) {
        x["value"] = e.target.value;
      }
    });
    setCustomFieldList(temp);
  };

  const releaseBid = () => {
    let bidSample = {};
    Object.keys(storeSampleInfo).forEach((key) => {
      let data = customFieldList.filter((x) => x.bidSampleId == key);
      let result = {
        ...storeSampleInfo[key],
        customField: data,
      };
      bidSample[key] = result;
    });
    const allDetails = {
      bidDetails: factoryDetail,
      bidSamples: bidSample,
      selectedNotify: selectedNotify
    };
    dispatch(releaseRfq(allDetails));
  }



  return (
    <>
      <div>Hello </div>
      <Formik
        initialValues={factoryDetail}
        validationSchema={factorySchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ errors, touched, isValid, handleBlur }) => (
          <>
            <Form>
              <div
                className="modal fade rfq"
                id="release-factory"
                tabIndex="-1"
                data-backdrop="false"
                aria-labelledby="release-factoryLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        ref={btnRef}
                        type="button"
                        className="btn"
                        data-bs-dismiss="modal"
                      >
                        &#8249;
                      </button>
                      <h2>Release For Bid(Factory)</h2>
                    </div>
                    <div className="modal-body">                    
                          <div className="row align-items-center mb-3">
                            <label className="form-label col-lg-2 col-md-3 col-sm-4">
                              Notify To :
                            </label>
                            <div className="col-lg-10 col-md-9 col-sm-8">
                              <Multiselect
                                options={userInfoFactory}
                                name="notifyTo"
                                onBlur={handleBlur}
                                id="factory-mail"
                                displayValue="name"
                                onSelect={handleSelectedNotify}
                                onRemove={handleRemoveAssignee}
                              />

                              {/* {errors.notifyTo && touched.notifyTo ? (
                            <div className="invalid-feedback d-block">
                              {errors.notifyTo}
                            </div>
                          ) : null} */}
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <label className="form-label col-lg-2 col-md-3 col-sm-4">
                              Subject :
                            </label>
                            <div className="col-lg-10 col-md-9 col-sm-8">
                              <input
                                type="text"
                                name="subject"
                                className="form-control"
                                onChange={handleInput}
                                onBlur={handleBlur}
                                value={factoryDetail.subject}
                              />
                              {errors.subject && touched.subject ? (
                                <div className="invalid-feedback d-block">
                                  {errors.subject}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <label className="form-label col-lg-2 col-md-3 col-sm-4">
                              Type:
                            </label>
                            <div className="col-lg-10 col-md-9 col-sm-8">
                              <input
                                type="text"
                                name="type"
                                className="form-control"
                                onChange={handleInput}
                                onBlur={handleBlur}
                                value={factoryDetail.type}
                              />
                              {errors.type && touched.type ? (
                                <div className="invalid-feedback d-block">
                                  {errors.type}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {samples &&
                            samples.length > 0 &&
                            samples.map((sample, index) => (
                              <div>
                                <div className="separate-label">
                                  {sample.sampleDetail.sampleName} |{" "}
                                  {sample.sampleDetail.product} |
                                  {sample.sampleDetail.id}
                                </div>
                                {sampleElement.map((element, idx) => (
                                  <div className="row align-items-center mb-3">
                                    <label className="form-label col-lg-2 col-md-3 col-sm-4">
                                      {element.fieldName}:
                                    </label>
                                    <div
                                      style={{ display: "flex" }}
                                      className="col-lg-10 col-md-9 col-sm-8"
                                    >
                                      <div className="form-check form-switch input-group-sm ">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          name={
                                            "show_Factory_" +
                                            sample.sampleDetail.id +
                                            "_" +
                                            element.fieldName
                                          }
                                          id={
                                            "show_Factory_" +
                                            sample.sampleDetail.id +
                                            "_" +
                                            element.fieldName
                                          }
                                          defaultChecked={
                                            element.isShow == true ? true : false
                                          }
                                          onChange={(e) => {
                                            if (!element.isCstm)
                                              handleChange(
                                                e,
                                                sample.sampleDetail.id,
                                                sample.sampleDetail.sampleName,
                                                element
                                              );
                                            else
                                              handleCheckBox(
                                                e,
                                                idx,
                                                sample.sampleDetail.id,
                                                element
                                              );
                                          }}
                                        />
                                      </div>
                                      {/* <div className="checkbox-wrapper-10">
                                    <input className="tgl tgl-flip" name={'show_Factory_' + sample.sampleDetail.id + "_" + element.fieldName} id={'show_Factory_' + sample.sampleDetail.id + "_" + element.fieldName} type="checkbox" onChange={(e) => {
                                      if (!element.isCstm)
                                        handleChange(e, sample.sampleDetail.id, sample.sampleDetail.sampleName, element)
                                      else
                                        handleCheckBox(e, idx, sample.sampleDetail.id, element);
                                    }} />
                                    <label className="tgl-btn" data-tg-off="Hide" data-tg-on="Show" for={'show_Factory_' + sample.sampleDetail.id + "_" + element.fieldName}></label>
                                  </div> */}
                                      &nbsp;&nbsp;{" "}
                                      <input
                                        type={
                                          element.isCstm == true
                                            ? "string"
                                            : "number"
                                        }
                                        min={1}
                                        name={element.fieldName}
                                        className="form-control"
                                        onChange={(e) => {
                                          if (!element.isCstm)
                                            handleChange(
                                              e,
                                              sample.sampleDetail.id,
                                              sample.sampleDetail.sampleName,
                                              element
                                            );
                                          else
                                            handleCustomField(
                                              e,
                                              sample.sampleDetail.id,
                                              element
                                            );
                                        }}
                                        onBlur={handleBlur}
                                        onKeyDown={preventNegativeValues}
                                        required={
                                          element.isShow == true ? "required" : ""
                                        }
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          <div className="row-md-12 text-center mb-3">
                            <button
                              type="button"
                              className=" dropdown-toggle btn ornge-brdr-btn"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Add Field
                            </button>
                            <div className="dropdown-menu dv-drpdn">
                              <label className="form-label">Field Name</label>

                              <div className="dv-cstmfld">
                                <input
                                  value={cstmField}
                                  onChange={(e) =>
                                    setCstmField(e.target.value.replace(" ", "_"))
                                  }
                                  type="text"
                                  name="fieldName"
                                  className="form-control"
                                />
                                &nbsp;
                                <div style={{ textAlign: "center" }}>
                                  <button
                                    onClick={addCustomField}
                                    disabled={
                                      !cstmField || cstmField?.trim().length == 0
                                    }
                                    type="submit"
                                    className="btn btn-rst-smpl"
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center mb-3">
                            <label className="form-label col-lg-2 col-md-3 col-sm-4">
                              Payment terms:
                            </label>
                            <div className="col-lg-10 col-md-9 col-sm-8">
                              <input
                                type="text"
                                name="paymentTerms"
                                className="form-control"
                                onChange={handleInput}
                                onBlur={handleBlur}
                                value={factoryDetail.paymentTerms}
                              />
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <label className="form-label col-lg-3 col-md-3 col-sm-4">
                              Domestic/International:{" "}
                            </label>
                            <div className="col-lg-9 col-md-9 col-sm-8">
                              <select
                                name="domesticOrInternational"
                                className="form-control"
                                onChange={handleInput}
                                onBlur={handleBlur}
                              >
                                <option value="1">Domestic</option>
                                <option value="0">International</option>
                              </select>
                            </div>
                            {errors.domesticOrInternational &&
                              touched.domesticOrInternational ? (
                              <div className="invalid-feedback d-block">
                                {errors.domesticOrInternational}
                              </div>
                            ) : null}
                          </div>
                          <div className="modal-textarea">
                            <span>Hello,</span>
                            <textarea
                              rows="5"
                              type="text"
                              name="regard"
                              className="form-control"
                              onChange={handleInput}
                              onBlur={handleBlur}
                              value={factoryDetail.regard}
                            />
                            {errors.regard && touched.regard ? (
                              <div className="invalid-feedback d-block">
                                {errors.regard}
                              </div>
                            ) : null}
                            <span>Thanks and Regard</span>
                          </div>
                      </div>
                    
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn ornge-brdr-btn"
                        data-bs-dismiss="modal"
                      >
                        Discard
                      </button>
                      
                        <button type="submit"  className="btn ornge-brdr-btn">
                          Submit
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

import { API_URLS } from "../../utils/api.urls";
import { createAsyncThunk } from "@reduxjs/toolkit";

let myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');


export const getAllCategories = createAsyncThunk(
    "getAllCategories", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.PRODUCT.GET_All_CATEGORIES + "/?";
            let page =  data.page ?  "page=" + data.page : "page=1";
            let limit =  data.limit ?  "&pageSize=" + data.limit : "&pageSize=100";
            const response = await fetch(url + page + limit);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;  
        } catch (error) {
            return rejectWithValue(error);
        }
    }
  );

  export const addUpdateCategory = createAsyncThunk(
    'addUpdateCategory',
    async (data, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
            API_URLS.PRODUCT.ADD_UPDATE_Category,
           {
             method: "POST",
             headers: myHeaders,
             body: JSON.stringify(data),
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json()
        return result
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )


  export const getAllSubCategories = createAsyncThunk(
    "getAllSubCategories", async (data, { rejectWithValue }) => {
        try {
            let url = API_URLS.GET_All_SUB_CATEGORIES + "/?";
            let page =  data.page ?  "page=" + data.page : "page=1";
            let limit =  data.limit ?  "&pageSize=" + data.limit : "&pageSize=100";
            const response = await fetch(url + page + limit);
            if (!response.ok) return rejectWithValue(response.statusText)
            const result = await response.json();
            return result;  
        } catch (error) {
            return rejectWithValue(error);
        }
    }
  );
import uploadImage from "../../assets/images/upload-image.svg";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { v1 as uuidv1 } from "uuid";
import deleteImg from "../../assets/images/deleteicon.png";
import { addUpdateBuyer, getBuyerById } from "../../features/user_management/action";
import { notify } from "../../features/notification/notify";
import { useDispatch, useSelector } from "react-redux";
import { resetUserManagememnt, userManagememnt } from "../../features/user_management/slice";
import moment from 'moment';

export const AddBuyer = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [gstDoc, setGstDoc] = useState();
    const [panDoc, setPanDoc] = useState();
    const [kycDoc, setKycDoc] = useState();
    const [incorporationDoc, setIncorporationDoc] = useState();
    const [buyerId, setBuyerId] = useState();
    const [buyerDetail, setBuyerDetail] = useState();
    const userId = parseInt(localStorage.getItem('userId'));
    const dispatch = useDispatch();
    const usrMngmntResponse = useSelector((state) => state.userManagememnt);
    const [buyer, setBuyer] = useState({
        id: 0,
        buyerName: '',
        pocEmail: '',
        contactPersonGroyyo: '',
        natureOfBusiness: '',
        gstTreatment: '',
        gstNumber: '',
        pan: '',
        contactNumber: '',
        alternatePhoneNumber: '',
        sourceOfSupply: '',
        currency: '',
        paymentTerms: '',
        createdBy: userId,
        address: '',
        billAddress: '',
        shipAddress: '',
        pincode: '',
        billPincode: '',
        shipPincode: ''
    })

    useEffect(() => {
        if (state && state.buyerId) {
            setBuyerId(state.buyerId);
        }
    }, []);
    useEffect(() => {
        if (buyerId) {
            dispatch(getBuyerById(buyerId));
        }
    }, [buyerId]);

    useEffect(() => {
        if (buyerDetail) {
            setBuyer({
                id: buyerDetail.id,
                buyerName: buyerDetail.buyerName,
                pocEmail: buyerDetail.pocEmail,
                contactPersonGroyyo: buyerDetail.contactPersonGroyyo,
                natureOfBusiness: buyerDetail.natureOfBusiness,
                gstTreatment: moment(buyerDetail.gstTreatment).format("YYYY-MM-DD"),
                gstNumber: buyerDetail.gstNumber,
                pan: buyerDetail.pan,
                contactNumber: buyerDetail.contactNumber,
                alternatePhoneNumber: buyerDetail.alternatePhoneNumber,
                sourceOfSupply: buyerDetail.sourceOfSupply,
                currency: buyerDetail.currency,
                paymentTerms: buyerDetail.paymentTerms,
                createdBy: userId,
                address: buyerDetail.User.addresses.find(x => x.addressType == 'address')?.firstLine,
                billAddress: buyerDetail.User.addresses.find(x => x.addressType == 'billing')?.firstLine,
                shipAddress: buyerDetail.User.addresses.find(x => x.addressType == 'shipping')?.firstLine,
                pincode: buyerDetail.User.addresses.find(x => x.addressType == 'address')?.zipCode,
                billPincode: buyerDetail.User.addresses.find(x => x.addressType == 'billing')?.zipCode,
                shipPincode: buyerDetail.User.addresses.find(x => x.addressType == 'shipping')?.zipCode
            })
            if (buyerDetail.buyerFiles && buyerDetail.buyerFiles.length > 0) {
                buyerDetail.buyerFiles.map((item) => {
                    if (item.fileType.toLowerCase() == 'gst') {
                        setGstDoc({ file: null, fileName: item.fileName })
                    }
                    if (item.fileType.toLowerCase() == 'pan') {
                        setPanDoc({ file: null, fileName: item.fileName })
                    }
                    if (item.fileType.toLowerCase() == 'kyc') {
                        setKycDoc({ file: null, fileName: item.fileName })
                    }
                    if (item.fileType.toLowerCase() == 'incorporate') {
                        setIncorporationDoc({ file: null, fileName: item.fileName })
                    }
                })
            }
        }
    }, [buyerDetail]);

    const handleBack = () => {
        navigate('/user-dashboard')
    }

    const rfqSchema = Yup.object().shape({
        buyerName: Yup.string().required("Required"),
        pocEmail: Yup.string().required("Required"),
        contactPersonGroyyo: Yup.string().required("Required"),
        natureOfBusiness: Yup.string().required("Required"),
        gstTreatment: Yup.string().required("Required"),
        gstNumber: Yup.string().required("Required"),
        pan: Yup.string().required("Required"),
        contactNumber: Yup.string().required("Required"),
        sourceOfSupply: Yup.string().required("Required"),
        currency: Yup.string().required("Required"),
        paymentTerms: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        pincode: Yup.number().nullable().required("Required"),
        shipAddress: Yup.string().required("Required"),
        shipPincode: Yup.number().nullable().required("Required"),
        billAddress: Yup.string().required("Required"),
        billPincode: Yup.number().nullable().required("Required"),
    });

    useEffect(() => {
        if (usrMngmntResponse.actionName === "addUpdateBuyer" && usrMngmntResponse.success) {
            dispatch(resetUserManagememnt());
            if (usrMngmntResponse.message == 'Exist') {
                notify("Email address already exist!", "info");
            } else if(usrMngmntResponse.message == 'Created') {
                if (!buyerId)
                    notify("Email sent to user for pin generation", "success");
                handleBack();
            }
            else if (usrMngmntResponse.message =="Cannot add buyer"){
                notify("Cannot add buyer!", "info");
            }
            else if (usrMngmntResponse.message =="Not Found"){
                notify("Not Found!", "info");
            }
            else if (usrMngmntResponse.message =="Updated"){
                notify("Updated Successfully!", "success");
                handleBack();
            }
        }
        if (usrMngmntResponse.actionName === "getBuyerById" && usrMngmntResponse.success) {
            setBuyerDetail(usrMngmntResponse.buyerDetail)
            dispatch(resetUserManagememnt());
        }
    }, [usrMngmntResponse]);

    const handleSubmit = () => {

        if (!gstDoc || !kycDoc || !panDoc || !incorporationDoc) {
            notify("Please add all files", "info");
        } else {
            let formData = new FormData();
            const fileNames = [];
            formData.append("upload_file", gstDoc.file);
            fileNames.push({ id: 0, fileType: "gst", fileName: gstDoc.fileName, isDeleted: false });

            formData.append("upload_file", kycDoc.file);
            fileNames.push({ id: 0, fileType: "kyc", fileName: kycDoc.fileName, isDeleted: false });

            formData.append("upload_file", panDoc.file);
            fileNames.push({ id: 0, fileType: "pan", fileName: panDoc.fileName, isDeleted: false });

            formData.append("upload_file", incorporationDoc.file);
            fileNames.push({ id: 0, fileType: "incorporate", fileName: incorporationDoc.fileName, isDeleted: false });

            formData.append("content", JSON.stringify(buyer));
            formData.append("fileList", JSON.stringify(fileNames));
            dispatch(addUpdateBuyer(formData));
        }
    }

    const getFile = (e, fileExtension) => {
        const filInfo = e.target.files[0];
        const blob = filInfo.slice(0, filInfo.size);
        const id = uuidv1();
        const fileName = id + "." + fileExtension;
        const file = new File([blob], fileName);
        return { file, fileName };
    };

    const handleFileUpload = (e, fileType) => {
        const fileExtension = e.target.files[0]?.name
            .split(".")
            .pop()
            .toLowerCase();
        var acceptedFormats = ["pdf", "docx", "doc"];
        if (!acceptedFormats.includes(fileExtension)) {
            return;
        }
        const { file, fileName } = getFile(e, fileExtension);
        if (fileType == 'gst') {
            setGstDoc({ file: file, fileName: fileName })
        }
        if (fileType == 'pan') {
            setPanDoc({ file: file, fileName: fileName })
        }
        if (fileType == 'kyc') {
            setKycDoc({ file: file, fileName: fileName })
        }
        if (fileType == 'incor') {
            setIncorporationDoc({ file: file, fileName: fileName })
        }
    }

    const removeFile = (e, fileType) => {
        if (fileType == 'gst') {
            setGstDoc()
        }
        if (fileType == 'pan') {
            setPanDoc()
        }
        if (fileType == 'kyc') {
            setKycDoc()
        }
        if (fileType == 'incor') {
            setIncorporationDoc()
        }
    }

    const handleChange = (e) => {
        if (e.target.name == "contactNumber" || e.target.name=="alternatePhoneNumber") {
            const newValue = e.target.value.slice(0, 10);
            setBuyer({
                ...buyer,
                [e.target.name]: newValue
            })
        }
        else{
            setBuyer({
                ...buyer,
                [e.target.name]: e.target.value,
            });
        }
        

    };
    return (
        <>
            {/* <ToastContainer /> */}
            <LeftMenu />
            <div className="right-column">
                <Header />
                <div className="body-wrap">
                    <nav
                        aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/user-dashboard">User Management </a></li>
                            <li className="breadcrumb-item"><a href="#"> Add Buyer</a></li>
                        </ol>
                    </nav>
                    <Formik
                        initialValues={buyer}
                        validationSchema={rfqSchema}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            handleSubmit(values);
                        }}
                    >
                        {({ errors, touched, isValid, handleBlur }) => (
                            <>
                                <Form>
                                    <div className="top-heading">
                                        <div className="d-flex">
                                            <a onClick={handleBack} className="back">&#8249;</a>

                                            {buyerId && buyerId > 0 && <h2>Edit Buyer</h2>}
                                            {!buyerId && <h2>Add Buyer</h2>}
                                        </div>
                                        <div className="d-flex gap-2">
                                            <button onClick={handleBack} className="btn grey-brdr-btn">
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn ornge-brdr-btn">
                                                <span className="align-middle">Save</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="user-wrap">
                                        <h6>Buyer Details</h6>
                                        <div className="row row-gap-4">
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Buyer Name</label>
                                                <input onChange={handleChange} value={buyer.buyerName} name="buyerName" type="text" className="form-control" />
                                                {errors.buyerName &&
                                                    touched.buyerName ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.buyerName}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">POC Email</label>
                                                <input onChange={handleChange} value={buyer.pocEmail} name="pocEmail" type="email" className="form-control" />
                                                {errors.pocEmail &&
                                                    touched.pocEmail ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.pocEmail}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Contact Person From Groyyo</label>
                                                <input onChange={handleChange} value={buyer.contactPersonGroyyo} name="contactPersonGroyyo" type="email" className="form-control" />
                                                {errors.contactPersonGroyyo &&
                                                    touched.contactPersonGroyyo ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.contactPersonGroyyo}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Nature of Business</label>
                                                <input onChange={handleChange} value={buyer.natureOfBusiness} name='natureOfBusiness' type="text" className="form-control" />
                                                {errors.natureOfBusiness &&
                                                    touched.natureOfBusiness ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.natureOfBusiness}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">GST Treatment</label>
                                                <input type='text' onChange={handleChange} value={buyer.gstTreatment} name="gstTreatment" className="form-control" />
                                                {errors.gstTreatment &&
                                                    touched.gstTreatment ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.gstTreatment}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">GST Number</label>
                                                <input onChange={handleChange} value={buyer.gstNumber} name="gstNumber" type="text" className="form-control" />
                                                {errors.gstNumber &&
                                                    touched.gstNumber ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.gstNumber}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">PAN</label>
                                                <input onChange={handleChange} value={buyer.pan} name="pan" type="text" className="form-control" />
                                                {errors.pan &&
                                                    touched.pan ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.pan}
                                                    </div>
                                                ) : null}
                                            </div>


                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Contact Number</label>
                                                <div className="phone-input-wrap position-relative">
                                                    <span className="country-code">+91-</span>
                                                    <input onChange={handleChange} value={buyer.contactNumber} name="contactNumber" type="number" className="form-control" />
                                                    {errors.contactNumber &&
                                                        touched.contactNumber ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.contactNumber}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Alternate Phone Number</label>
                                                <div className="phone-input-wrap position-relative">
                                                    <span className="country-code">+91-</span>
                                                    <input onChange={handleChange} value={buyer.alternatePhoneNumber} name="alternatePhoneNumber" type="number" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Source of Supply</label>
                                                <input onChange={handleChange} value={buyer.sourceOfSupply} name="sourceOfSupply" type="text" className="form-control" />
                                                {errors.sourceOfSupply &&
                                                    touched.sourceOfSupply ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.sourceOfSupply}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Currency</label>
                                                <input onChange={handleChange} value={buyer.currency} name="currency" type="text" className="form-control" />
                                                {errors.currency &&
                                                    touched.currency ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.currency}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Payment terms</label>
                                                <input onChange={handleChange} value={buyer.paymentTerms} name="paymentTerms" type="text" className="form-control" />
                                                {errors.paymentTerms &&
                                                    touched.paymentTerms ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.paymentTerms}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-8 col-md-6">
                                                <label className="form-label">Address</label>
                                                <input onChange={handleChange} value={buyer.address} name="address" type="text" className="form-control" />
                                                {errors.address &&
                                                    touched.address ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.address}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Zip Code</label>
                                                <input onChange={handleChange} value={buyer.pincode} name="pincode" type="text" className="form-control" />
                                                {errors.pincode &&
                                                    touched.pincode ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.pincode}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-8 col-md-6">
                                                <label className="form-label">Billing Address</label>
                                                <input onChange={handleChange} value={buyer.billAddress} name="billAddress" type="text" className="form-control" />
                                                {errors.billAddress &&
                                                    touched.billAddress ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.billAddress}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Zip Code</label>
                                                <input onChange={handleChange} value={buyer.billPincode} name="billPincode" type="text" className="form-control" />
                                                {errors.billPincode &&
                                                    touched.billPincode ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.billPincode}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-8 col-md-6">
                                                <label className="form-label">Shipping Address</label>
                                                <input onChange={handleChange} value={buyer.shipAddress} name="shipAddress" type="text" className="form-control" />
                                                {errors.shipAddress &&
                                                    touched.shipAddress ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.shipAddress}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Zip Code</label>
                                                <input onChange={handleChange} value={buyer.shipPincode} name="shipPincode" type="text" className="form-control" />
                                                {errors.shipPincode &&
                                                    touched.shipPincode ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.shipPincode}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                {gstDoc && <div> <span className="spn-fle">
                                                    GST Certificate
                                                </span> <img
                                                        onClick={(e) => {
                                                            removeFile(e, "gst");
                                                        }}
                                                        src={deleteImg}
                                                        className="dlte-icon"
                                                    /> </div>}
                                                {!gstDoc && <div className="position-relative custom-upload">

                                                    <div className="upload">
                                                        <div className="upload-img">
                                                            <img src={uploadImage} alt="upload" />
                                                        </div>
                                                        <div className="upload-detail">
                                                            <div>Drop your GST certificate here or <span className="blue-txt">Browse</span></div>
                                                            <div>Max file size: 5MB</div>
                                                        </div>
                                                    </div>
                                                    <input onChange={(e) => handleFileUpload(e, 'gst')} type="file" className="custom-file" />
                                                </div>}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                {panDoc && <div> <span className="spn-fle">
                                                    Pan card
                                                </span> <img
                                                        onClick={(e) => {
                                                            removeFile(e, "pan");
                                                        }}
                                                        src={deleteImg}
                                                        className="dlte-icon"
                                                    /> </div>}
                                                {!panDoc && <div className="position-relative custom-upload">
                                                    <div className="upload">
                                                        <div className="upload-img">
                                                            <img src={uploadImage} alt="upload" />
                                                        </div>
                                                        <div className="upload-detail">
                                                            <div>Drop your pan of identity here or <span className="blue-txt">Browse</span></div>
                                                            <div>Max file size: 5MB</div>
                                                        </div>
                                                    </div>
                                                    <input onChange={(e) => handleFileUpload(e, 'pan')} type="file" className="custom-file" />
                                                </div>}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                {kycDoc && <div> <span className="spn-fle">
                                                    KYC Doc
                                                </span> <img
                                                        onClick={(e) => {
                                                            removeFile(e, "kyc");
                                                        }}
                                                        src={deleteImg}
                                                        className="dlte-icon"
                                                    /> </div>}
                                                {!kycDoc && <div className="position-relative custom-upload">
                                                    <div className="upload">
                                                        <div className="upload-img">
                                                            <img src={uploadImage} alt="upload" />
                                                        </div>
                                                        <div className="upload-detail">
                                                            <div>Drop your buyer KYC here or <span className="blue-txt">Browse</span></div>
                                                            <div>Max file size: 5MB</div>
                                                        </div>
                                                    </div>
                                                    <input onChange={(e) => handleFileUpload(e, 'kyc')} type="file" className="custom-file" />
                                                </div>}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                {incorporationDoc && <div> <span className="spn-fle">
                                                    Incorporation Certificate
                                                </span> <img
                                                        onClick={(e) => {
                                                            removeFile(e, "incor");
                                                        }}
                                                        src={deleteImg}
                                                        className="dlte-icon"
                                                    /> </div>}
                                                {!incorporationDoc && <div className="position-relative custom-upload">
                                                    <div className="upload">
                                                        <div className="upload-img">
                                                            <img src={uploadImage} alt="upload" />
                                                        </div>
                                                        <div className="upload-detail">
                                                            <div>Drop ypur incorporation certificate here or <span className="blue-txt">Browse</span></div>
                                                            <div>Max file size: 5MB</div>
                                                        </div>
                                                    </div>
                                                    <input onChange={(e) => handleFileUpload(e, 'incor')} type="file" className="custom-file" />
                                                </div>}
                                            </div>

                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";

//create action
export const addUpdateRfq = createAsyncThunk(
  "addUpdateRfq",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.ADD_UPDATE_RFQ,
        {
          method: "POST",
          body: data,
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllRfq = createAsyncThunk(
  "getAllRfq",
  async (data, { rejectWithValue }) => {
    try {
      let url = API_URLS.RFQ.GET_ALL_RFQ;
      url = url + "?page=" + data.page + "&limit=" + data.limit + "&status=" + data.filters.status + "&category=" + data.filters.category + "&createDate=" + data.filters.createDate + "&styleCode=" + data.filters.styleCode + "&assignee=" + data.filters.assignee + "&factoryId=" + data.factoryId + "&buyer=" + data.filters.buyer + "&search=" + data.filters.search + "&lastUpdatedSort=" + data.filters.lastUpdatedSort + '&location=' + data.filters.location ;
      const response = await fetch(url);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllFactoryRfq = createAsyncThunk(
  "getAllFactoryRfq",
  async (data, { rejectWithValue }) => {
    try {
      let url = API_URLS.RFQ.GET_ALL_FACTORY_RFQ;
      let page = data.page ? "/" + data.page : "/1";
      let limit = data.limit ? "/" + data.limit : "/10";
      url = url + page + limit + "/" + data.userId;
      const response = await fetch(url);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSampleByRfqId = createAsyncThunk(
  "getSampleByRfqId",
  async (rfqId, { rejectWithValue }) => {
    try {
      let url = API_URLS.RFQ.GET_SAMPLE_BY_RFQID;
      const response = await fetch(url + '/' + rfqId);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addSampleToRfq = createAsyncThunk(
  "addSampleToRfq",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.ADD_SAMPLETO_RFQ,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const getRfqById = createAsyncThunk(
  "getRfqById",
  async (id, { rejectWithValue }) => {

    try {
      const response = await fetch(
        API_URLS.RFQ.GET_RFQ_BY_ID + "/" + id,
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json()
      return result;

    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
);

export const getUserByRoleType = createAsyncThunk(
  "getRoleByName",
  async (data, { rejectWithValue }) => {

    try {
      const response = await fetch(
        API_URLS.USERS.GET_USER_BY_ROLE_TYPE + "/" + data.rollType + '/' + data.userId,
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json()
      return result;

    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
);

export const getRfqStatusByRfqId = createAsyncThunk(
  "getRfqStatusByRfqId",
  async (id, { rejectWithValue }) => {

    try {
      const response = await fetch(
        API_URLS.RFQ.GET_RFQ_STATUS_BY_RFQ_ID + "/" + id,
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json()
      return result;

    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err)
    }
  }
);

export const setRfqStatus = createAsyncThunk(
  "setRfqStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.SET_RFQ_STATUS,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const releaseRfq = createAsyncThunk(
  "releaseRfq",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.RELEASE_RFQ,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const replyBid = createAsyncThunk(
  "replyBid",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.REPLY_RFQ,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRfqFile = createAsyncThunk(
  "deleteRfqFile",
  async (data, { rejectWithValue }) => {
    try {
      const id = { id: data }
      const response = await fetch(
        API_URLS.RFQ.DELETE_RFQ_FILE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(id),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBidByRfqId = createAsyncThunk(
  "getBidByRfqId",
  async (data, { rejectWithValue }) => {
    try {
      let url = API_URLS.RFQ.GET_BID_BY_RFQID;
      let page = data.page ? "/" + data.page : "/1";
      let limit = data.limit ? "/" + data.limit : "/10";
      url = url + page + limit + "/" + data.rfqId;
      const response = await fetch(url);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRfqBySampleId = createAsyncThunk(
  "getRfqBySampleId",
  async (data, { rejectWithValue }) => {
    try {
      let url = API_URLS.RFQ.GET_RFQ_BY_SAMPLEID;
      url = url + "?page=" + data.page + "&limit=" + data.limit + "&sampleId=" + data.sampleId;
      const response = await fetch(url);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const isRfqAssignee = createAsyncThunk(
  "isRfqAssignee",
  async (data, { rejectWithValue }) => {
    try {
      let url = API_URLS.RFQ.IS_RFQ_ASSIGNEE;
      url = url + "?rfqId=" + data.rfqId + "&userId=" + data.senderId
      const response = await fetch(url);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBidReplyDetails = createAsyncThunk(
  "getBidReplyDetails",
  async (data, { rejectWithValue }) => {
    try {
      let url = API_URLS.RFQ.GET_BID_REPLY_DETAILS;
      url = url + "?bidId=" + data.bidId + "&roleType=" + data.roleType + "&bidTo=" + data.bidTo
      const response = await fetch(url);
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const acceptOneBid = createAsyncThunk(
  "acceptOneBid",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.ACCEPT_ONE_BID,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGeneratePo = createAsyncThunk(
  "getGeneratePo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.GET_GENERATE_PO + "/" + data,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          //body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addBulkRfq = createAsyncThunk(
  "addBulkRfq",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.ADD_BULK_RFQ,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const showRfqDetailsToFactory = createAsyncThunk(
  "showRfqDetailsToFactory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.SHOW_RFQ_DETAILS_TOFACTORY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getShowRfqDetailsData = createAsyncThunk(
  "getShowRfqDetailsData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        API_URLS.RFQ.GET_SHOW_RFQ_DETAILS_DATA + "?rfqId=" + data.rfqId + '&userType=' + data.openModalFor,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) return rejectWithValue(response.statusText)
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
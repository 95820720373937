import React, { useEffect, useState } from 'react'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { notify } from '../../features/notification/notify';
import loginImg from "../../assets/images/Login.svg";
import logoImg from "../../assets/images/logo-black.avif";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { fetchGrroyoUserLogin, forgetPin,resendOtpGroyyo } from '../../features/login/action';
import OTPInput from "otp-input-react";
import { CONSTANT } from '../../utils/constant';
import { resetLoginOtp } from '../../features/login/slice';
import { redirectAfterLogin } from './redirectAfterLogin';
import { LoginBannerImage } from './loginBannerImage';

export const OTPLogin = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [otp, setOtp] = useState("");
  const loginOtpResponse = useSelector((state) => state.userLogin);
  // useEffect(() => {
  //     state == null &&  navigate('/login')
  //     // console.log(state)
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let sendData = {
      email: localStorage.getItem('email'),
      otp: otp
    }

    dispatch(fetchGrroyoUserLogin(sendData));
  }

  const resendOtp = () => {
   debugger
    let sendData = {
      email: localStorage.getItem('email')
    }
    dispatch(resendOtpGroyyo(sendData));
  }

  useEffect(() => {


    if (loginOtpResponse.error) {
      dispatch(resetLoginOtp())
      notify(loginOtpResponse.error, "error")
    }

    else if (loginOtpResponse.actionName == " fetchGroyyoUserLoginValidate" && loginOtpResponse.success === true) {
      dispatch(resetLoginOtp())
    }

    else if (loginOtpResponse.actionName == " resendOtpGroyyo" && loginOtpResponse.success === true) {
      dispatch(resetLoginOtp());
      notify("OTP Sent To Your Email", "success");
    }

    else if (loginOtpResponse.success === true && loginOtpResponse.loginData != null) {
      //navigate('/admin/dashboard');
      redirectAfterLogin(loginOtpResponse.loginData)
    }
  }, [loginOtpResponse]);

  document.body.style.backgroundColor = "#F4F2F2";
  return (
    <>
      <>
        <ToastContainer />
        <div className="row section-login-banner login-wrap">
          <div className="col-sm-7 login-banner">
            <LoginBannerImage />
          </div>
          <div className="contain col-sm-5 form-div">

            <div className='logoContainer position-relative'><img src={logoImg} alt="logo" className="login-logo" />
              <span className="position-relative login-logo-text" >Democratising Manufacturing</span>
            </div>
            <div className="form-wrap">
              <h2>Enter OTP to continue</h2>
              <p className="note">Please enter your 6 digit OTP sent to your mail.</p>
              <form>
                <div className="enter-otp-input">
                  <OTPInput className="otp-input-root" value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} secure />

                </div>
                <div className="d-flex justify-content-end mb-50">
                   <a className="resend-otp" onClick={resendOtp}>Resend OTP?</a>
                </div>
                <button type="submit" className="btn blue-btn" onClick={handleSubmit}>Submit</button>
                {/* <a href="#" className="btn blue-border-btn w-100">Log In with Mobile Number</a> */}
              </form>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

import React, { useEffect, useRef, useState } from "react";
import {showAllUsers} from "../../features/userDetail/action";
import { useDispatch, useSelector } from "react-redux";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { BuyerList } from "./buyerList";
import { SupplierList } from "./supplierList";
import { UserList } from "./userList";
import { useLocation, useNavigate } from "react-router-dom";


export const UserDashboard = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState("User");
 

  const goToTab = () => {
    if (tabName == "User") navigate("/add-user");
    else if (tabName == "Buyer") navigate("/add-buyer");
    else if (tabName == "Supplier") navigate("/add-supplier");
  };
  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <div className="top-heading">
            <h2>User management</h2>
            <button
              className="btn ornge-brdr-btn"
              onClick={goToTab}
              type="button"
            >
              <span className="align-middle">&#43; Add New {tabName}</span>
            </button>
          </div>

          

          <div className="mt-5">
            <ul className="nav nav-pills mb-5" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  onClick={(e) => setTabName("User")}
                  className="nav-link active"
                  id="user-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#user-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="user-tab-pane"
                  aria-selected="true"
                >
                  User
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  onClick={(e) => setTabName("Buyer")}
                  className="nav-link"
                  id="buyer-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#buyer-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="buyer-tab-pane"
                  aria-selected="false"
                >
                  Buyer
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  onClick={(e) => setTabName("Supplier")}
                  className="nav-link"
                  id="supplier-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#supplier-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="supplier-tab-pane"
                  aria-selected="false"
                >
                  Supplier
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="user-tab-pane"
                role="tabpanel"
                aria-labelledby="user-tab"
                tabIndex="0"
              >
                <UserList /> 
              
                </div>
                <div
                  className="tab-pane fade"
                  id="buyer-tab-pane"
                  role="tabpanel"
                  aria-labelledby="buyer-tab"
                  tabIndex="0"
                >
                  { tabName == "Buyer" &&  <BuyerList /> }
                 
                </div>
             
              <div
                className="tab-pane fade"
                id="supplier-tab-pane"
                role="tabpanel"
                aria-labelledby="supplier-tab"
                tabIndex="0"
              >
               { tabName == "Supplier" && <SupplierList /> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { useEffect, useRef, useState } from "react";
import { CONSTANT } from "../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import optionDotImg from "../../assets/images/edit.svg";
import { getAllUsers } from "../../features/user_management/action";
import { resetUserManagememnt } from "../../features/user_management/slice";
import ReactPaginate from "react-paginate";
import { updateUserStatus } from "../../features/userDetail/action";
import { SetPinModal } from "./setPinModal";
import { notify } from "../../features/notification/notify";
import { useNavigate } from "react-router-dom";

export const UserList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const isFirstRender = useRef(true);
  const [userList, setUserList] = useState([]);
  const [ttluser, setTtluser] = useState(0);
  const [pinTitle, setPinTitle] = useState("Set Pin");
  const [clickedUser, setClickedUser] = useState("");
  const usrMngmntResponse = useSelector((state) => state.userManagememnt);
  const userRoleId = localStorage.getItem('roleId');
  const [filters, setFilters] = useState({
    search: ""
  })

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    callAllUsers();
  }, [filters])


  const [userData, setUserData] = useState({
    userId: "",
    emailId: "",
    name: "",
    mobileNo: "",
    pin: "",
    department: "",
    role: "",
  });
  useEffect(() => {
    if (!isFirstRender.current) {
      callAllUsers();
    }
    isFirstRender.current = false;
  }, [currentPage, itemCount]);

  const callAllUsers = () => {
    dispatch(getAllUsers({ page: currentPage + 1, limit: itemCount, filters: filters }));
  };

  useEffect(() => {
    if (
      usrMngmntResponse.actionName == "createUserPin" &&
      usrMngmntResponse.success
    ) {
      notify("Set pin Successfull", "success");
      dispatch(getAllUsers({ page: currentPage + 1, limit: itemCount }));
    }
  }, [usrMngmntResponse]);

  useEffect(() => {
    if (
      usrMngmntResponse.actionName == "getAllUser" &&
      usrMngmntResponse.success
    ) {
      setUserList(usrMngmntResponse.users[0]);
      setTtluser(usrMngmntResponse.users[1]);
      dispatch(resetUserManagememnt());
      if (usrMngmntResponse.users[1] !== null && pageCount === 0) {
        setPageCount(Math.ceil(usrMngmntResponse.users[1] / itemCount));
      }
    }

  }, [usrMngmntResponse]);
  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const handleShowResetPin = (userdata) => {
    setClickedUser(userdata);
    setShow(true);
  };

  const handleActive = (e, id) => {
    dispatch(updateUserStatus(id));
  };
  const handleOpenEdit = (userId) => {
    navigate("/add-user", {
      replace: true,
      state: { userId: userId },
    });
  };

  return (
    <>


      <div className="search-top">
        <input type="search" name="search" onChange={handleChange} placeholder="Search by name" />
      </div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Mobile No</th>
              <th scope="col">Email ID</th>
              <th scope="col">Location</th>
              <th scope="col">Role</th>
              {parseInt(userRoleId) == 1 && <th scope="col">Status (Active/Inactive)</th>}
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {userList.length > 0 &&
              userList.map((item, index) => (
                <tr key={index} style={{ cursor: "pointer" }}>
                  <td>{item.name} </td>
                  <td>{item.mobileNo}</td>
                  <td>{item.emailId}</td>
                  <td>
                    <p key={index}>{item.locationDetail != null ? item.locationDetail.locationName : "--"}</p>
                  </td>

                  <td>
                    {item.Roles.length > 0 &&
                      item.Roles.map((role, innerIndex) => (
                        <p key={innerIndex}>{role.role}</p>
                      ))}
                  </td>
                  {parseInt(userRoleId) == 1 && <td>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        onChange={(event) => {
                          handleActive(event, item.id);
                        }}
                        defaultChecked={item.status == 1 ? true : false}
                        id={"active-state" + index}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"active-state" + index}
                      ></label>
                    </div>
                  </td>}

                  <td>
                    <button
                      type="button"
                      className="btn"
                      title="edit"
                      onClick={() => {
                        handleOpenEdit(item.id);
                      }}
                    >
                      <img src={optionDotImg} alt="edit" />
                    </button>
                  </td>
                </tr>
              ))}
            {userList.length == 0 && (
              <tr>
                <td colSpan={12} className="text-center">
                  No User Found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {ttluser > 10 && (
          <nav className="pagination-block">
            <p className="total">{ttluser} items total</p>

            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              forcePage={currentPage}
              onPageChange={handlePageChange}
              containerClassName={"pagination justify-content-center mt-4"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
            <div className="customSelect">
              <label htmlFor="">Items per page :</label>
              <div className="selectBox">
                <select name="" id="" onChange={handleItemsChange}>
                  <option value={CONSTANT.PAGE_LIMIT}>
                    {CONSTANT.PAGE_LIMIT}
                  </option>
                  <option value="15">15</option>

                  <option value="20">20</option>
                </select>
              </div>
            </div>
          </nav>
        )}
      </div>

      <SetPinModal show={show} setShow={setShow} clickedUser={clickedUser} />
    </>
  );
};  

import React, { useEffect, useState } from 'react';
import loginImg from "../../assets/images/Login.svg";
import logoImg from "../../assets/images/logo-black.avif";
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserLogin, fetchUserLoginValidate } from '../../features/login/action';
import OTPInput, { ResendOTP } from "otp-input-react";
import { useLocation, useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { notify } from '../../features/notification/notify';
import { CONSTANT } from '../../utils/constant';
import { redirectAfterLogin } from './redirectAfterLogin';
import { LoginBannerImage } from './loginBannerImage';

const LoginOtp = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [otp, setOtp] = useState("");

    const loginOtpResponse = useSelector((state) => state.userLogin);

    useEffect(() => {
        state == null &&  navigate('/login')
        // console.log(state)
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        let sendData = {
            email:loginOtpResponse.email,
            otp: otp
        }
       
        dispatch(fetchUserLogin(sendData));
    }

    const handleResend = () => {
        if (state) {
            let newData = {
                emailOrPhone: state.loginDetail
            }
            dispatch(fetchUserLoginValidate(newData));
        }
    }

    useEffect(() => {
   

        if(loginOtpResponse.error) {
          notify(loginOtpResponse.error, "error")
        }

        if (loginOtpResponse.success === true && loginOtpResponse.loginData != null) {
            redirectAfterLogin(loginOtpResponse.loginData)
        }
    }, [loginOtpResponse]);


    return (
      <>
        <ToastContainer />
        <div className="row section-login-banner login-wrap">
        <div className="col-custom-64 login-banner">
        <LoginBannerImage />
        </div>
        <div className="contain col-custom-36 form-div">

          <div className='logoContainer position-relative'><img src={logoImg} alt="logo" className="login-logo" />
            <span className="position-relative login-logo-text" >Democratising Manufacturing</span>
          </div>
          <div className="form-wrap mt-4">
          <h2>Enter PIN to continue</h2>
          {
          state?.type == 'mobile' ? <p className="note">A 6 digit OTP has been sent to your mobile <br /> number <span className="bold-600">{state?.emailOrPhone}</span></p>
              :
              <p className="note">A 6 digit OTP has been sent to your <br /> email <span className="bold-600">{state?.emailOrPhone}</span></p>
            }
          <form>
          <div className="enter-otp-input">
              <OTPInput className="otp-input-root" value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} secure />
          </div>
          <div className="d-flex justify-content-between mb-50">
              <ResendOTP className="resend-otp-input" onResendClick={handleResend} />
              {/* <span className="timer">00:59</span>
              <a href="#" className="resend-otp">Resend OTP?</a> */}
          </div>
          <button type="submit" className="btn blue-btn" onClick={handleSubmit}>Submit</button>
            </form>
        </div>
        </div>
      </div>
        </>
    )
}

export default LoginOtp
import { createSlice } from "@reduxjs/toolkit";
import {
  uploadInvoice,userBulkUpload,supplierBulkUpload
} from "./action";


export const uploadTNA = createSlice({
  name: "uploadTNA",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
  },

  reducers: {
    resetTNA: (state, action) => {
      state.success = false
      state.actionName = null;
      state.message = null
    },
  },

  extraReducers: {
    [uploadInvoice.pending]: (state) => {
      state.loading = true;
    },
    [uploadInvoice.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "uploadInvoice";
    },
    [uploadInvoice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [userBulkUpload.pending]: (state) => {
      state.loading = true;
    },
    [userBulkUpload.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "userBulkUpload";
    },
    [userBulkUpload.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [supplierBulkUpload.pending]: (state) => {
      state.loading = true;
    },
    [supplierBulkUpload.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "supplierBulkUpload";
    },
    [supplierBulkUpload.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    }

  },
});

export default uploadTNA.reducer;

export const { resetTNA } = uploadTNA.actions;

import uploadImage from "../../assets/images/upload-image.svg";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import moment from 'moment';
import { v1 as uuidv1 } from "uuid";
import { notify } from "../../features/notification/notify";
import deleteImg from "../../assets/images/deleteicon.png";
import { addUpdateSupplier } from "../../features/user_management/action";
import { resetUserManagememnt } from "../../features/user_management/slice";
import { getSupplierById } from "../../features/user_management/action";
export const AddSupplier = () => {

    const navigate = useNavigate();
    const [gstDoc, setGstDoc] = useState();
    const [panDoc, setPanDoc] = useState();
    const [cheque, setCheque] = useState();
    const [supplierId, setSupplierId] = useState("");
    const { state } = useLocation();
    const [inputValue, setInputValue] = useState('');
    const [msme, setMsme] = useState();
    const [formTitle, setFormTitle] = useState("Add");
    const [vendor, setVendor] = useState();
    const [supplierDetailById, setsupplierDetailById] = useState("");
    const userId = parseInt(localStorage.getItem('userId'));
    const dispatch = useDispatch();
    const usrMngmntResponse = useSelector((state) => state.userManagememnt);

    const [supplierDetails, setSupplierDetails] = useState({
        id: 0, supplierName: "", pocEmail: "", contactPersonGroyyo: "", natureOfBusiness: "", gstTreatment: "", gstNumber: "", pan: "",
        contactNumber: "", alternatePhoneNumber: "", sourceOfSupply: "", currency: "", paymentTerms: "", address: "", addressZipCode: "", billingAddress: "",
        billingZipCode: "", shippingZipCode: "", shippingAddress: "", bankName: "", accountName: "", ifscCode: "", msmeRegistered: 0, createdBy: userId
    })
    useEffect(() => {
        if (state && state.suppId) {
            setSupplierId(state.suppId);
        }
    }, []);
    useEffect(() => {
        if (supplierId) {
            dispatch(getSupplierById(supplierId));
        }
    }, [supplierId]);


    useEffect(() => {
        if (supplierDetailById) {
            setFormTitle("Edit")
            const dateString = supplierDetailById.gstTreatment;
            const formattedDate = moment(dateString).format("YYYY-MM-DD");
            setSupplierDetails({
                id: supplierDetailById.id,
                supplierName: supplierDetailById.supplierName,
                pocEmail: supplierDetailById.pocEmail,
                contactPersonGroyyo: supplierDetailById.contactPersonGroyyo,
                natureOfBusiness: supplierDetailById.natureOfBusiness,
                gstTreatment: formattedDate,
                gstNumber: supplierDetailById.gstNumber,
                pan: supplierDetailById.pan,
                contactNumber: supplierDetailById.contactNumber,
                alternatePhoneNumber: supplierDetailById.alternatePhoneNumber,
                sourceOfSupply: supplierDetailById.sourceOfSupply,
                currency: supplierDetailById.currency,
                paymentTerms: supplierDetailById.paymentTerms,
                createdBy: userId,
                address: supplierDetailById.User.addresses.find(x => x.addressType == 'address')?.firstLine,
                billingAddress: supplierDetailById.User.addresses.find(x => x.addressType == 'billing')?.firstLine,
                shippingAddress: supplierDetailById.User.addresses.find(x => x.addressType == 'shipping')?.firstLine,
                addressZipCode: supplierDetailById.User.addresses.find(x => x.addressType == 'address')?.zipCode,
                billingZipCode: supplierDetailById.User.addresses.find(x => x.addressType == 'billing')?.zipCode,
                shippingZipCode: supplierDetailById.User.addresses.find(x => x.addressType == 'shipping')?.zipCode,
                bankName: supplierDetailById.bankName,
                accountName: supplierDetailById.accountName,
                ifscCode: supplierDetailById.ifscCode,
                msmeRegistered: supplierDetailById.msmeRegistered,
            })



            if (supplierDetailById.supplierFiles && supplierDetailById.supplierFiles.length > 0) {
                supplierDetailById.supplierFiles.map((item) => {
                    if (item.fileType == 'gst') {
                        setGstDoc({ file: null, fileName: item.fileName })
                    }
                    if (item.fileType == 'pan') {
                        setPanDoc({ file: null, fileName: item.fileName })
                    }
                    if (item.fileType == 'cheque') {
                        setCheque({ file: null, fileName: item.fileName })
                    }
                    if (item.fileType == 'msme') {
                        setMsme({ file: null, fileName: item.fileName })
                    }
                    if (item.fileType == 'vendor') {
                        setVendor({ file: null, fileName: item.fileName })
                    }

                })
            }
        }
    }, [supplierDetailById]);

    useEffect(() => {
        if (usrMngmntResponse.actionName === "addUpdateSupplier" && usrMngmntResponse.success) {
            dispatch(resetUserManagememnt());
            handleBack();
        }
        else if (usrMngmntResponse.actionName === "getSupplierById") {
            setsupplierDetailById(usrMngmntResponse.supplierDetail);
            dispatch(resetUserManagememnt());
        }

    }, [usrMngmntResponse]);

    const handleBack = () => {
        navigate('/user-dashboard')
    }
    const supplierSchema = Yup.object().shape({
        supplierName: Yup.string().required("Required"),
        pocEmail: Yup.string().required("Required"),
        contactPersonGroyyo: Yup.string().required("Required"),
        natureOfBusiness: Yup.string().required("Required"),
        gstTreatment: Yup.string().required("Required"),
        gstNumber: Yup.string().nullable().required("Required"),
        pan: Yup.string().required("Required"),
        contactNumber: Yup.number()
            .required("Required")
            .test("len", "Phone number must be exactly 10 digits", (val) => {
                if (val) {
                    return val.toString().length === 10;
                }
                return true;
            }),
        alternatePhoneNumber: Yup.number()
            .required("Required")
            .test("len", "Phone number must be exactly 10 digits", (val) => {
                if (val) {
                    return val.toString().length === 10;
                }
                return true;
            }),
        sourceOfSupply: Yup.string().required("Required"),
        currency: Yup.string().required("Required"),
        paymentTerms: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        addressZipCode: Yup.number().required("Required"),
        billingAddress: Yup.string().required("Required"),
        billingZipCode: Yup.number().required("Required"),
        shippingAddress: Yup.string().required("Required"),
        shippingZipCode: Yup.number().required("Required"),
        bankName: Yup.string().required("Required"),
        accountName: Yup.string().required("Required"),
        ifscCode: Yup.string().required("Required")

    })

    const handleChange = (e) => {
        if (e.target.name == "contactNumber" || e.target.name == "alternatePhoneNumber") {
            const newValue = e.target.value.slice(0, 10);
            setSupplierDetails({
                ...supplierDetails,
                [e.target.name]: newValue
            })
        }
        else {
            setSupplierDetails({
                ...supplierDetails,
                [e.target.name]: e.target.value
            })
        }

    }

    const handleFormSubmit = () => {
        if (!gstDoc || !cheque || !panDoc || !msme & supplierDetails.msmeRegistered != 0 || !vendor) {
            notify("Please add all files", "info");
        } else {
            let formData = new FormData();
            const fileNames = [];
            formData.append("upload_file", gstDoc.file);
            fileNames.push({ id: 0, fileType: "gst", fileName: gstDoc.fileName, isDeleted: false });

            formData.append("upload_file", cheque.file);
            fileNames.push({ id: 0, fileType: "cheque", fileName: cheque.fileName, isDeleted: false });

            formData.append("upload_file", panDoc.file);
            fileNames.push({ id: 0, fileType: "pan", fileName: panDoc.fileName, isDeleted: false });

            if (supplierDetails.msmeRegistered == 1) {
                formData.append("upload_file", msme.file);
                fileNames.push({ id: 0, fileType: "msme", fileName: msme.fileName, isDeleted: false });
            }

            formData.append("upload_file", vendor.file);
            fileNames.push({ id: 0, fileType: "vendor", fileName: vendor.fileName, isDeleted: false });

            formData.append("content", JSON.stringify(supplierDetails));
            formData.append("fileList", JSON.stringify(fileNames));
            dispatch(addUpdateSupplier(formData));
        }
    }

    const getFile = (e, fileExtension) => {
        const filInfo = e.target.files[0];
        const blob = filInfo.slice(0, filInfo.size);
        const id = uuidv1();
        const fileName = id + "." + fileExtension;
        const file = new File([blob], fileName);
        return { file, fileName };
    };

    const handleFileUpload = (e, fileType) => {
        const fileExtension = e.target.files[0]?.name
            .split(".")
            .pop()
            .toLowerCase();
        var acceptedFormats = ["pdf", "docx", "doc"];
        if (!acceptedFormats.includes(fileExtension)) {
            notify("File type not supported", "error")
            e.target.value = "";
            return;
        }
        const { file, fileName } = getFile(e, fileExtension);
        if (fileType == 'gst') {
            setGstDoc({ id: 0, file: file, fileName: fileName, isDeleted: false })
        }
        if (fileType == 'pan') {
            setPanDoc({ id: 0, file: file, fileName: fileName, isDeleted: false })
        }
        if (fileType == 'cheque') {
            setCheque({ id: 0, file: file, fileName: fileName, isDeleted: false })
        }
        if (fileType == 'vendor') {
            setVendor({ id: 0, file: file, fileName: fileName, isDeleted: false })
        }
        if (fileType == 'msme') {
            setMsme({ id: 0, file: file, fileName: fileName, isDeleted: false })
        }
    }

    const removeFile = (e, fileType) => {
        if (fileType == 'gst') {
            setGstDoc()
        }
        if (fileType == 'pan') {
            setPanDoc()
        }
        if (fileType == 'cheque') {
            setCheque()
        }
        if (fileType == 'msme') {
            setMsme()
        }
        if (fileType == 'vendor') {
            setVendor()
        }
    }

    return (
        <>
            <LeftMenu />
            <div className="right-column">
                <Header />
                <div className="body-wrap">
                    <Formik
                        initialValues={supplierDetails}
                        validationSchema={supplierSchema}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            handleFormSubmit(values);
                        }}
                    >
                        {({ errors, touched, isValid, handleBlur }) => (
                            <>
                                <Form>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/user-dashboard">User Management </a></li>
                                            <li className="breadcrumb-item"><a > Add Supplier</a></li>
                                        </ol>
                                    </nav>

                                    <div className="top-heading">
                                        <div className="d-flex">
                                            <a onClick={() => handleBack()} className="back">&#8249;</a>
                                            <h2>{formTitle} Supplier</h2>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <button onClick={() => handleBack()} className="btn grey-brdr-btn">
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn ornge-brdr-btn">
                                                <span className="align-middle">Next</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="user-wrap">
                                        <h6>Supplier Details</h6>


                                        <div className="row row-gap-4">
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Supplier Name</label>
                                                <input type="text" name="supplierName" value={supplierDetails.supplierName} onChange={handleChange} className="form-control" onBlur={handleBlur} />
                                                {errors.supplierName && touched.supplierName ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.supplierName}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">POC Email</label>
                                                <input type="email" name="pocEmail" onChange={handleChange} value={supplierDetails.pocEmail} className="form-control" onBlur={handleBlur} />
                                                {errors.pocEmail && touched.pocEmail ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.pocEmail}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Contact Person From Groyyo (Email)</label>
                                                <input onChange={handleChange} value={supplierDetails.contactPersonGroyyo ?? ""} name="contactPersonGroyyo" type="email" className="form-control" />
                                                {errors.contactPersonGroyyo && touched.contactPersonGroyyo ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.contactPersonGroyyo}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Nature of Business</label>
                                                <input type="text" name="natureOfBusiness" onChange={handleChange} value={supplierDetails.natureOfBusiness} onBlur={handleBlur} className="form-control" />
                                                {errors.natureOfBusiness && touched.natureOfBusiness ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.natureOfBusiness}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">GST Treatment</label>
                                                <input type="text" name="gstTreatment" value={supplierDetails.gstTreatment} onChange={handleChange} onBlur={handleBlur} className="form-control" />
                                                {errors.gstTreatment && touched.gstTreatment ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.gstTreatment}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">GST Number</label>
                                                <input type="text" name="gstNumber" value={supplierDetails.gstNumber} onChange={handleChange} onBlur={handleBlur} className="form-control" />
                                                {errors.gstNumber && touched.gstNumber ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.gstNumber}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">PAN</label>
                                                <input type="text" name="pan" value={supplierDetails.pan} onChange={handleChange} onBlur={handleBlur} className="form-control" />
                                                {errors.pan && touched.pan ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.pan}
                                                    </div>
                                                ) : null}
                                            </div>


                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Contact Number</label>
                                                <div className="phone-input-wrap position-relative">
                                                    <span className="country-code">+91-</span>
                                                    <input type="number" name="contactNumber" value={supplierDetails.contactNumber} onChange={handleChange} onBlur={handleBlur} className="form-control" />
                                                    {errors.contactNumber && touched.contactNumber ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.contactNumber}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Alternate Phone Number</label>
                                                <div className="phone-input-wrap position-relative">
                                                    <span className="country-code">+91-</span>
                                                    <input type="number" name="alternatePhoneNumber" value={supplierDetails.alternatePhoneNumber} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                    {errors.alternatePhoneNumber && touched.alternatePhoneNumber ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.alternatePhoneNumber}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Source of Supply</label>
                                                <input onChange={handleChange} value={supplierDetails.sourceOfSupply} name="sourceOfSupply" type="text" className="form-control" />
                                                {errors.sourceOfSupply && touched.sourceOfSupply ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.sourceOfSupply}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Currency</label>
                                                <input type="text" className="form-control" name="currency" value={supplierDetails.currency} onBlur={handleBlur} onChange={handleChange} />

                                                {errors.currency && touched.currency ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.currency}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Payment terms</label>
                                                <input onChange={handleChange} value={supplierDetails.paymentTerms} name="paymentTerms" type="text" className="form-control" />
                                                {errors.paymentTerms && touched.paymentTerms ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.paymentTerms}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-8 col-md-6">
                                                <label className="form-label">Address</label>
                                                <input type="text" name="address" onChange={handleChange} value={supplierDetails.address} onBlur={handleBlur} className="form-control" />
                                                {errors.address && touched.address ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.address}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Zip Code</label>
                                                <input type="number" name="addressZipCode" value={supplierDetails.addressZipCode} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                {errors.addressZipCode && touched.addressZipCode ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.addressZipCode}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-8 col-md-6">
                                                <label className="form-label">Billing Address</label>
                                                <input type="text" name="billingAddress" value={supplierDetails.billingAddress} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                {errors.billingAddress && touched.billingAddress ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.billingAddress}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Zip Code</label>
                                                <input type="number" name="billingZipCode" value={supplierDetails.billingZipCode} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                {errors.billingZipCode && touched.billingZipCode ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.billingZipCode}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-8 col-md-6">
                                                <label className="form-label">Shipping Address</label>
                                                <input type="text" name="shippingAddress" value={supplierDetails.shippingAddress} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                {errors.shippingAddress && touched.shippingAddress ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.shippingAddress}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Zip Code</label>
                                                <input type="number" name="shippingZipCode" value={supplierDetails.shippingZipCode} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                {errors.shippingZipCode && touched.shippingZipCode ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.shippingZipCode}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Bank name</label>
                                                    <input type="text" name="bankName" value={supplierDetails.bankName} onChange={handleChange} onBlur={handleBlur} className="form-control" />
                                                    {errors.bankName && touched.bankName ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.bankName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Account name</label>
                                                    <input type="text" name="accountName" onChange={handleChange} value={supplierDetails.accountName} onBlur={handleBlur} className="form-control" />
                                                    {errors.accountName && touched.accountName ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.accountName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">IFSC code</label>
                                                    <input type="text" name="ifscCode" onChange={handleChange} value={supplierDetails.ifscCode} onBlur={handleBlur} className="form-control" />
                                                    {errors.ifscCode && touched.ifscCode ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.ifscCode}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">MSME registered ?</label>

                                                    <select className="form-select" name="msmeRegistered" value={supplierDetails.msmeRegistered == true ? "1" : "0"} onBlur={handleBlur} onChange={handleChange}>
                                                        <option value="">--select--</option>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>

                                                    </select>
                                                    {errors.msmeRegistered && touched.msmeRegistered ? (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.msmeRegistered}
                                                        </div>
                                                    ) : null}
                                                </div>

                                            </div>
                                            <div className="col-lg-8 col-md-6">
                                                <div className="row row-gap-4">

                                                    <div className="col-md-6">
                                                        {gstDoc && <div> <span className="spn-fle">
                                                            GST Certificate
                                                        </span> <img
                                                                onClick={(e) => {
                                                                    removeFile(e, "gst");
                                                                }}
                                                                src={deleteImg}
                                                                className="dlte-icon"
                                                            /> </div>}
                                                        {!gstDoc && <div className="position-relative custom-upload">
                                                            <div className="upload">
                                                                <div className="upload-img">
                                                                    <img src={uploadImage} alt="upload" />
                                                                </div>
                                                                <div className="upload-detail">
                                                                    <div>Drop your GST certificate here or <span className="blue-txt">Browse</span></div>
                                                                    <div>Max file size: 5MB</div>
                                                                    <div>File supported: Docx,Pdf</div>
                                                                </div>
                                                            </div>
                                                            <input onChange={(e) => handleFileUpload(e, 'gst')} type="file" className="custom-file" />
                                                        </div>}
                                                    </div>

                                                    <div className="col-md-6">
                                                        {panDoc && <div> <span className="spn-fle">
                                                            Pan card
                                                        </span> <img
                                                                onClick={(e) => {
                                                                    removeFile(e, "pan");
                                                                }}
                                                                src={deleteImg}
                                                                className="dlte-icon"
                                                            /> </div>}
                                                        {!panDoc && <div className="position-relative custom-upload">
                                                            <div className="upload">
                                                                <div className="upload-img">
                                                                    <img src={uploadImage} alt="upload" />
                                                                </div>
                                                                <div className="upload-detail">
                                                                    <div>Drop your PAN of entity here or <span className="blue-txt">Browse</span></div>
                                                                    <div>Max file size: 5MB</div>
                                                                    <div>File supported: Docx,Pdf</div>
                                                                </div>
                                                            </div>
                                                            <input onChange={(e) => handleFileUpload(e, 'pan')} type="file" className="custom-file" />
                                                        </div>}
                                                    </div>

                                                    <div className="col-md-6">
                                                        {cheque && <div> <span className="spn-fle">
                                                            CHEQUE Doc
                                                        </span> <img
                                                                onClick={(e) => {
                                                                    removeFile(e, "cheque");
                                                                }}
                                                                src={deleteImg}
                                                                className="dlte-icon"
                                                            /> </div>}
                                                        {!cheque && <div className="position-relative custom-upload">
                                                            <div className="upload">
                                                                <div className="upload-img">
                                                                    <img src={uploadImage} alt="upload" />
                                                                </div>
                                                                <div className="upload-detail">
                                                                    <div>Drop your Cancelled cheque here or <span className="blue-txt">Browse</span></div>
                                                                    <div>Max file size: 5MB</div>
                                                                    <div>File supported: Docx,Pdf</div>
                                                                </div>
                                                            </div>
                                                            <input onChange={(e) => handleFileUpload(e, 'cheque')} type="file" className="custom-file" />
                                                        </div>}
                                                    </div>
                                                    {supplierDetails.msmeRegistered == 1 &&
                                                        <div className="col-md-6">
                                                            {msme && <div> <span className="spn-fle">
                                                                MSME Certificate
                                                            </span> <img
                                                                    onClick={(e) => {
                                                                        removeFile(e, "msme");
                                                                    }}
                                                                    src={deleteImg}
                                                                    className="dlte-icon"
                                                                /> </div>}
                                                            {!msme && <div className="position-relative custom-upload">

                                                                <div className="upload">
                                                                    <div className="upload-img">
                                                                        <img src={uploadImage} alt="upload" />
                                                                    </div>
                                                                    <div className="upload-detail">
                                                                        <div>Drop your MSME here or <span className="blue-txt">Browse</span></div>
                                                                        <div>Max file size: 5MB</div>
                                                                        <div>File supported: Docx,Pdf</div>
                                                                    </div>
                                                                </div>

                                                                <input onChange={(e) => handleFileUpload(e, 'msme')} type="file" className="custom-file" />
                                                            </div>}
                                                        </div>
                                                    }
                                                    <div className="col-md-6">
                                                        {vendor && <div> <span className="spn-fle">
                                                            VENDOR Certificate
                                                        </span> <img
                                                                onClick={(e) => {
                                                                    removeFile(e, "vendor");
                                                                }}
                                                                src={deleteImg}
                                                                className="dlte-icon"
                                                            /> </div>}
                                                        {!vendor && <div className="position-relative custom-upload">
                                                            <div className="upload">
                                                                <div className="upload-img">
                                                                    <img src={uploadImage} alt="upload" />
                                                                </div>
                                                                <div className="upload-detail">
                                                                    <div>Drop your Vendor registration from here or <span className="blue-txt">Browse</span></div>
                                                                    <div>Max file size: 5MB</div>
                                                                    <div>File supported: Docx,Pdf</div>
                                                                </div>
                                                            </div>
                                                            <input onChange={(e) => handleFileUpload(e, 'vendor')} type="file" className="custom-file" />
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Form>
                            </>
                        )}

                    </Formik>
                </div>

            </div>

        </>
    );
}


// datePickerHelper.js
export const handleDateClick = (e) => {
    if (e.target && typeof e.target.showPicker === 'function') {
      e.target.showPicker();
    } else {
      console.error('showPicker method is not available on this element');
    }
  };
  
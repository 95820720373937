import { createSlice } from "@reduxjs/toolkit";
import { getAllCategories, addUpdateCategory,getAllSubCategories } from "./action";

export const product = createSlice({
    name: "product",
    initialState: {
        loading: false,
        error: null,
        success: false,
        actionName: null,
        products: null,
        categories: null,
        cart: null,
        badge: null,
        orders: null,
        message: null,
        product: null,
        allCategories: null,
        allProducts: null,
        orderNumber: null,
        payment: null
    },

    reducers: {
        updateBadge: (state, action) => {
            state.badge = action.payload
        },
        resetProduct: (state, action) => {
            state.success = false;
            state.actionName = "";
            state.message = null
        },
    },
    extraReducers: {
        [getAllCategories.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getAllCategories.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "getAllCategories"
            state.allCategories = action.payload.allcateg
            state.success = true;
        },
        [getAllCategories.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.error = "Internal server error";
        },
        [addUpdateCategory.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [addUpdateCategory.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "addUpdateCategory"
            state.message = action.payload.message
            state.success = true;
        },
        [addUpdateCategory.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.success = false;
            state.error = "Internal server error";
        },
        [getAllSubCategories.pending]: (state) => {
            state.loading = true;
            state.success = false;
        },
        [getAllSubCategories.fulfilled]: (state, action) => {
            state.loading = false;
            state.actionName = "getAllSubCategories"
            state.allSubCategories = action.payload.allsubcateg
            state.success = true;
        },
        [getAllSubCategories.rejected]: (state, action) => {
            console.log("error", action)
            state.loading = false;
            state.error = "Internal server error";
        },
    },
});

export default product.reducer;
export const { updateBadge, resetProduct } = product.actions;
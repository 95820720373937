import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CreatePOChatGroup } from "./poChatModals/createPOChatGroup";
import { getUserByNameNRoleType } from "../../../../features/userDetail/action";
import userImg from "../../../../assets/images/user.webp";
import { createSinglePOChat, getAllPOChats } from "../../../../features/poChats/action";

export const POChatContactSection = ({
  contacts,
  socket,
  changeChat,
  setContacts,
  poId,
  setGroupUserType,
  groupUserType,
}) => {
  const dispatch = useDispatch();
  const regex = /(<([^>]+)>)/gi;
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showChatList, setShowChatList] = useState(true);
  const [searchName, setSearchName] = useState();
  const [users, setUsers] = useState([]);
  const currentJsonUser = JSON.parse(localStorage.getItem("authorization"));
  const poChatResponse = useSelector((state) => state.poChat);
  const userResponse = useSelector((state) => state.userDetail);

  const currentUserRoleType = localStorage.getItem("roleType");
  const handleChangeChat = (chat) => {
    changeChat(chat);
  };

  const handleCreateGroup = () => {
    setShowCreateGroupModal(true);
  };
  let types = "";
  let newUser = [];
  useEffect(() => {
    newUser = users;
  }, [users]);
  useEffect(() => {
    types = groupUserType;
    if (poId) {
      callGetAllPOChats();
    }
  }, [groupUserType]);

  useEffect(() => {
    if (searchName && searchName.length > 0) {
      setShowChatList(false);
    } else {
      setUsers([]);
      setShowChatList(true);
    }
  }, [searchName]);

  useEffect(() => {
    if (userResponse.actionName == "getUserByNameNRoleType") {
      userResponse.userList &&
        setUsers(
          userResponse.userList.filter((x) => x.id != currentJsonUser.user.id)
        );
    }
  }, [userResponse]);

  const handleSendHiMsg = async (receiverData) => {
    // // save message to database
    let savePayload = {
      poId: poId,
      loginUserId: currentJsonUser.user.id,
      recieverId: receiverData.id,
    };
    dispatch(createSinglePOChat(savePayload));
    setUsers([]);
    setSearchName("");
  };

  const callGetAllPOChats = () => {
    let allChatPayload = {
      poId: poId,
      loginUserId: currentJsonUser.user.id,
      type: types,
    };
    dispatch(getAllPOChats(allChatPayload));
  };
  useEffect(() => {
    if (
      (poChatResponse.success === true &&
        poChatResponse.actionName == "createGroupPOChat") ||
      poChatResponse.actionName == "updateGroupPOChat"
    ) {
      callGetAllPOChats();
    }
    if (
      poChatResponse.success === true &&
      poChatResponse.actionName == "createSinglePOChat"
    ) {
      callGetAllPOChats();
      changeChat(poChatResponse.createdSingleChat);
    }
  }, [poChatResponse]);

  const handleSearchContact = (e) => {
    setSearchName(e.target.value);
    if (e.target.value.length > 0)
      dispatch(
        getUserByNameNRoleType({ roleType: "Internal", name: e.target.value })
      );
  };

  const handleRoleGroup = async (e) => {
    let type = e.target.value;
    setGroupUserType(type);
  };
  return (
    <div className="left-chat-pannel">
      <div className="top-search-filter">
        <input
          type="text"
          onChange={handleSearchContact}
          value={searchName}
          className="search form-control"
        />
        <svg
          viewBox="0 0 24 24"
          height="30"
          width="30"
          preserveAspectRatio="xMidYMid meet"
          className=""
          version="1.1"
          x="0px"
          y="0px"
          enableBackground="new 0 0 24 24"
          style={{ cursor: "pointer" }}
          onClick={handleCreateGroup}
        >
          <title>Create Group</title>
          <path
            fill="#FD4900"
            d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M17,13h-4v4h-2v-4H7v-2h4V7h2v4h4V13z"
          ></path>
        </svg>
      </div>
      {currentUserRoleType == "Internal" && (
        <div className="chat_group">
          <button
            onClick={handleRoleGroup}
            value="All"
            className={
              groupUserType == "All"
                ? "btn btn-tab-chat active"
                : "btn btn-tab-chat"
            }
          >
            All
          </button>
          <button
            onClick={handleRoleGroup}
            value="Factory"
            className={
              groupUserType == "Factory"
                ? "btn btn-tab-chat active"
                : "btn btn-tab-chat"
            }
          >
            Factory
          </button>
          <button
            onClick={handleRoleGroup}
            value="Internal"
            className={
              groupUserType == "Internal"
                ? "btn btn-tab-chat active"
                : "btn btn-tab-chat"
            }
          >
            Internal
          </button>
        </div>
      )}
      {showChatList && contacts.length > 0 ? (
        groupUserType === "All" ? (
          contacts.map((contact, index) => (
            <div className="chat-user" key={index}>
              <div className="chat-user-left">
                <div className="menu.icon7 icon" data-initial="H"></div>
              </div>
              <div
                className="chat-user-right"
                onClick={() => handleChangeChat(contact)}
              >
                <div>
                  <div className="name">{contact.chatName}</div>
                  <div className="chat">
                    {contact.latestMessage &&
                      contact.latestMessage.content
                        .replace(regex, "")
                        .substring(0, 10) + "..."}
                  </div>
                </div>
                <span className="day">
                  {contact.latestMessage &&
                    moment(contact.latestMessage.timestamp).fromNow()}
                </span>
              </div>
            </div>
          ))
        ) : contacts.filter((contact) => contact.chatGroup === groupUserType)
            .length > 0 ? (
          contacts
            .filter((contact) => contact.chatGroup === groupUserType)
            .map((contact, index) => (
              <>
                <div className="chat-user" key={index}>
                  <div className="chat-user-left">
                    <div className="menu.icon7 icon" data-initial="H"></div>
                  </div>
                  <div
                    className="chat-user-right"
                    onClick={() => handleChangeChat(contact)}
                  >
                    <div>
                      <div className="name">{contact.chatName}</div>
                      <div className="chat">
                        {contact.latestMessage &&
                          contact.latestMessage.content
                            .replace(regex, "")
                            .substring(0, 10) + "..."}
                      </div>
                    </div>
                    <span className="day">
                      {contact.latestMessage &&
                        moment(contact.latestMessage.timestamp).fromNow()}
                    </span>
                  </div>
                </div>
              </>
            ))
        ) : (
          <div>
            <h3 style={{ textAlign: "center", marginTop: "5%" }}>No {groupUserType} chats available</h3>
          </div>
        )
      ) : (
        <div className="mt-3 text-center">
          <h3>No {groupUserType} chats available</h3>
        </div>
      )}

      {users &&
        users.map((contact, index) => (
          <div className="chat-user" key={index}>
            <div className="chat-user-left">
              <img src={userImg} alt="user" />
            </div>
            <div
              className="chat-user-right"
              // onClick={() =>
              //   changeCurrentChat(
              //     index,
              //     contact?.name == undefined
              //       ? contact?.emailId
              //       : contact.name
              //   )
              // }
            >
              <div>
                <div className="name">{contact?.name}</div>
                <div className="chat">{contact?.emailId}</div>
              </div>
              <button
                onClick={() => handleSendHiMsg(contact)}
                className="btn btn-tab-chat"
              >
                Say hi!
              </button>
            </div>
          </div>
        ))}

      {showCreateGroupModal && (
        <CreatePOChatGroup
          poId={poId}
          setShowCreateGroupModal={setShowCreateGroupModal}
          showCreateGroupModal={showCreateGroupModal}
        />
      )}
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { getRfqSampleComments, addCommentToRfqSample } from "./action";


export const rfqSampleComment = createSlice({
    name: "rfqSampleComment",
    initialState: {
        loading: false,
        error: null,
        success: false,
        message: null,
        actionName: null,
        rfqSampleComments: null,
    },

    reducers: {
        resetRfqSampleComment: (state, action) => {
            state.success = false
            state.actionName = null;
            state.message = null
        },
    },

    extraReducers: {

        [getRfqSampleComments.pending]: (state) => {
            state.loading = true;
        },
        [getRfqSampleComments.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.rfqSampleComments = action.payload.data;
            state.actionName = "getRfqSampleComments"
        },
        [getRfqSampleComments.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        [addCommentToRfqSample.pending]: (state) => {
            state.loading = true;
        },
        [addCommentToRfqSample.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            state.message = action.payload;
            state.actionName = "addCommentToRfqSample"
        },
        [addCommentToRfqSample.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        }
    },
});

export default rfqSampleComment.reducer;

export const { resetRfqSampleComment } = rfqSampleComment.actions;

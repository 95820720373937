import React from "react";

export const DefaultLayout = (props) => {
  const { children } = props;

  return (
    <>
      <div className="body">{children}</div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    acceptOrRejectPoDates,
    addDatePoFactoryMilestones,
    addNewDates,
    getPoByIdAndRole,
    releasePo,
    setPoFactoryEditable,
} from "../../../../features/po/action";
import { resetPo } from "../../../../features/po/slice";
import moment from "moment";
import { notify } from "../../../../features/notification/notify";
import { CONSTANT } from "../../../../utils/constant";
import { RejectPOModal } from "./rejectPOModal";
import { Accordion } from "react-bootstrap";
import { getDates, getExecutionDates, isShow } from "./poBasicInfoFunction";
import { confirmAlert } from "react-confirm-alert";
import { POInfoActionBtn } from "./poInfoActionBtn";
import { ActualDatesEntryModal } from "./actualDatesEntryModal";

export const POFinalizedTNA = (props) => {
    const { poId } = props;
    const dispatch = useDispatch();
    const [poDetail, setPoDetail] = useState();
    const [factMilstn, setFactMilstn] = useState();
    const [showRelaseButton, setShowReleaseButton] = useState(true);
    const [showActualDateModal, setShowActualDateModal] = useState(false);
    const [selectedFactory, setSelectedFactory] = useState();
    const [selectedMilestone, setSelectedMilestone] = useState();
    const [rejectType, setRejectType] = useState();
    const [inputName, setInputName] = useState();
    const [actualDates, setActualDates] = useState();
    const userId = localStorage.getItem("userId");
    const roleId = localStorage.getItem("roleId");

    useEffect(() => {
        if (poId) callPoDetails(poId);
    }, [poId]);

    const callPoDetails = (poId) => {
        let poPayload = {
            poId: poId,
            userId: userId,
            roleId: roleId,
        };
        dispatch(getPoByIdAndRole(poPayload));
    };

    const poResponse = useSelector((state) => state.po);

    useEffect(() => {
        if (poResponse.actionName == "getPoByIdAndRole" && poResponse.success) {
            setPoDetail(poResponse.poData.poDetail);
            setFactMilstn(poResponse.poData.factoryMilestone);
            dispatch(resetPo());
        }


        if (poResponse.actionName == "addNewDates" && poResponse.success) {
            notify("New dates suggested!", "success");
            callPoDetails(poId);
        }
        if (poResponse.actionName == "addActualDates" && poResponse.success) {
            dispatch(resetPo());
            setShowActualDateModal(false);
            callPoDetails(poId)
        }

    }, [poResponse]);

    useEffect(() => {
        if (factMilstn) {
            if (poDetail.status !== 0) {
                setShowReleaseButton(false);
            } else {
                const hasEmptyDates = factMilstn.some((poFactory) =>
                    poFactory?.poFactoryMilestonesLogs?.some(
                        (milestone) => !milestone.startDate || !milestone.endDate
                    )
                );

                if (hasEmptyDates) {
                    setShowReleaseButton(false);
                } else {
                    setShowReleaseButton(true);
                }
            }
        }
    }, [factMilstn]);


    const handleAddActualDates = (e, poFact, milstn, type) => {
        e.stopPropagation();
        setSelectedFactory(poFact);
        setSelectedMilestone(milstn);
        setShowActualDateModal(true);
    };

    const showButton = (factMilestone) => {
        if (roleId != CONSTANT.USER_ROLE.ADMIN) {
            if (!factMilestone.poExecutions || factMilestone.poExecutions.length == 0) {
                return true
            } else if (factMilestone.poExecutions[0]?.createdBy == userId) {
                return true
            } else return false
        }
    }

    return (
        poDetail && (
            <div
                className="tab-pane fade show active"
                id="finalTna-tab-pane"
                role="tabpanel"
                aria-labelledby="finalTna-tab"
                tabIndex="0"
            >
                <div className="row row-gap-2 mt-3">
                    <div className="col-xxl-8 col-xl-9 col-lg-8 col-sm-12">
                        <b className="dvrfaName">PO Name</b> :{" "}
                        <b className="rfavalue">{poDetail?.poName} </b>
                        <div className="row row-gap-2">
                            <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                                <label className="form-label">PO Name</label>
                                <input
                                    type="text"
                                    readOnly
                                    value={poDetail.poName}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                                <label className="form-label">Description</label>
                                <input
                                    type="text"
                                    readOnly
                                    value={poDetail.description}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                                <label className="form-label">RFQ Name</label>
                                <input
                                    type="text"
                                    readOnly
                                    value={poDetail.rfqDetail.rFQName}
                                    className="form-control"
                                />
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                                <label className="form-label">PO created Date</label>
                                <input
                                    type="text"
                                    readOnly
                                    value={moment(poDetail.createdDate).format("YYYY-MM-DD")}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-gap-4 mt-5">
                    <div className="col-xxl-8 col-xl-9 col-lg-8 col-sm-12">
                        <b className="dvrfaName">TNA Template</b> :{" "}
                        <b className="rfavalue">{poDetail.tnaTemplate.name} </b>
                    </div>
                </div>

                <div className="row row-gap-4 mt-5">
                    {poDetail.poFactories.map((poFactories, index) => (
                        <div key={index}>
                            <div className="row pb-3">
                                <div className="col-md-3 col-xxl-3 col-xl-3 col-lg-4 col-sm-12">
                                    <b className="dvrfaName">PO Factories</b> :{" "}
                                    <b className="rfavalue">{poFactories.factory.name} </b>
                                </div>

                            </div>

                            <Accordion
                                className="factory-milestone-list"
                                defaultActiveKey="0"
                            >
                                {factMilstn
                                    .filter((x) => x.poFactoryId == poFactories.id)
                                    .map((factoryMilestones, index2) => (
                                        <Accordion.Item eventKey={index2} key={index2} className={(parseInt(factoryMilestones.sampleDetail.id) % 2 == 1) ? 'dv-grouped ' + (factoryMilestones.poExecutions.length == 0 ? "disabled" : "") : ' ' + (factoryMilestones.poExecutions.length == 0 ? "disabled" : "")}>

                                            <Accordion.Header>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <label className="form-label">MileStone Name</label>
                                                                <div className="form-control">
                                                                    {factoryMilestones.milestone.name}
                                                                </div>
                                                            </td>
                                                            <td style={{ width: "70px" }}>
                                                                <label className="form-label">Sample</label>
                                                                <div className="form-control">
                                                                    {
                                                                        factoryMilestones.sampleDetail.id
                                                                            ? factoryMilestones.sampleDetail.sampleName
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td style={{ width: "70px" }}>
                                                                <label className="form-label">Quantity</label>
                                                                <div className="form-control">
                                                                    {
                                                                        factoryMilestones.quantity ? factoryMilestones.quantity : ""
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td style={{ width: "100px" }}>
                                                                <label className="form-label">Actual Quantity</label>
                                                                <div className="form-control">
                                                                    {
                                                                        factoryMilestones?.poExecutions[0]?.quantity ? factoryMilestones?.poExecutions[0]?.quantity : 'NA'
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <label className="form-label">Planned Start Date</label>
                                                                <div className="form-control">
                                                                    {getDates(factoryMilestones, "start")}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <label className="form-label">Planned End Date</label>
                                                                <div className="form-control ">
                                                                    {getDates(factoryMilestones, "end")}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <label className="form-label">Actual Start Date</label>
                                                                <div className="form-control">
                                                                    <span className="ttlqty-smpl"> {getExecutionDates(factoryMilestones, "start")} </span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <label className="form-label">Actual End Date</label>
                                                                <div className="form-control ">
                                                                    <span className="ttlqty-smpl"> {getExecutionDates(factoryMilestones, "end")} </span>
                                                                </div>
                                                            </td>
                                                            {roleId == CONSTANT.USER_ROLE.ADMIN &&
                                                                <td>
                                                                    <button
                                                                        className="btn btn-date"
                                                                        onClick={(e) => handleAddActualDates(e, poFactories, factoryMilestones, 'add_dates')}
                                                                        style={{ pointerEvents: 'auto', width: '160px' }}
                                                                    >
                                                                      Add Actual Dates
                                                                    </button>
                                                                </td>
                                                            }
                                                            {showButton(factoryMilestones) &&
                                                                <td>
                                                                    <button
                                                                        className="btn btn-date"
                                                                        onClick={(e) => handleAddActualDates(e, poFactories, factoryMilestones, 'add_dates')}
                                                                        style={{ pointerEvents: 'auto', width: '160px' }}
                                                                    >
                                                                        Add Actual Dates
                                                                    </button>
                                                                </td>
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Accordion.Header>

                                            <Accordion.Body style={{ background: "#f8f9fa" }}>
                                                {/* -------start ADMIN show button ---------- */}
                                                <table className="mLogExecute_Table">
                                                    <tbody>
                                                        {roleId == CONSTANT.USER_ROLE.ADMIN &&
                                                            factoryMilestones.poExecutions.map(
                                                                (poExecutions, index) => (
                                                                    <tr key={index}>
                                                                        <td className="td-accrdn">

                                                                        </td>
                                                                        {poExecutions.createdUser?.Roles[0]?.roleType ? (
                                                                            <td>
                                                                                <label className="form-label">
                                                                                    Proposed By
                                                                                </label>
                                                                                <p className="form-control">
                                                                                    {poExecutions.createdUser?.name ? poExecutions.createdUser?.name : poExecutions.createdUser?.emailId}
                                                                                </p>
                                                                            </td>
                                                                        ) : <td></td>}
                                                                        <td>
                                                                            <label className="form-label">
                                                                                Start Date
                                                                            </label>
                                                                            <p className="form-control">
                                                                                {poExecutions.actualStartDate ? moment(poExecutions.actualStartDate).format(
                                                                                    "DD-MM-yyyy"
                                                                                ) : 'NA'}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <label className="form-label">
                                                                                End Date
                                                                            </label>
                                                                            <p className="form-control">
                                                                                {poExecutions.actualEndDate ? moment(poExecutions.actualEndDate).format(
                                                                                    "DD-MM-yyyy"
                                                                                ) : 'NA'}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <label className="form-label">
                                                                                Quantity
                                                                            </label>
                                                                            <p className="form-control">
                                                                                {poExecutions.quantity}
                                                                            </p>
                                                                        </td>

                                                                        <td>
                                                                            <label className="form-label">
                                                                                Remarks
                                                                            </label>
                                                                            <p className="form-control">
                                                                                {poExecutions.remarks}
                                                                            </p>
                                                                        </td>

                                                                    </tr>
                                                                )
                                                            )}
                                                    </tbody>
                                                </table>

                                                {/* -------END ADMIN show button ---------- */}

                                                {/*------------------------------------------------------------------------------------------------------- */}

                                                {/* -------start FACTORY show button ---------- */}
                                                <table className="mLogExecute_Table">
                                                    <tbody>
                                                        {roleId != CONSTANT.USER_ROLE.ADMIN &&
                                                            factoryMilestones.poExecutions.map(
                                                                (poExecutions, index) => (
                                                                    <tr key={index}>
                                                                        <td className="td-accrdn">

                                                                        </td>
                                                                        {poExecutions.createdUser?.Roles[0]?.roleType ? (
                                                                            <td>
                                                                                <label className="form-label">
                                                                                    Proposed By
                                                                                </label>
                                                                                <p className="form-control">
                                                                                {poExecutions.createdUser?.name ? poExecutions.createdUser?.name : poExecutions.createdUser?.emailId}
                                                                                </p>
                                                                            </td>
                                                                        ) : <td></td>}
                                                                        <td>
                                                                            <label className="form-label">
                                                                                Start Date
                                                                            </label>
                                                                            <p className="form-control">
                                                                                {poExecutions.actualStartDate ? moment(poExecutions.actualStartDate).format(
                                                                                    "DD-MM-yyyy"
                                                                                ) : 'NA'}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <label className="form-label">
                                                                                End Date
                                                                            </label>
                                                                            <p className="form-control">
                                                                                {poExecutions.actualEndDate ? moment(poExecutions.actualEndDate).format(
                                                                                    "DD-MM-yyyy"
                                                                                ) : 'NA'}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <label className="form-label">
                                                                                Quantity
                                                                            </label>
                                                                            <p className="form-control">
                                                                                {poExecutions.quantity}
                                                                            </p>
                                                                        </td>

                                                                        <td>
                                                                            <label className="form-label">
                                                                                Remarks
                                                                            </label>
                                                                            <p className="form-control">
                                                                                {poExecutions.remarks}
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                    </tbody>
                                                </table>
                                                {/* -------END FACTORY show button ---------- */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        </div>
                    ))}
                </div>
                <ActualDatesEntryModal
                    userId={userId}
                    getDates={getDates}
                    showActualDateModal={showActualDateModal}
                    setShowActualDateModal={setShowActualDateModal}
                    selectedFactory={selectedFactory}
                    selectedMilestone={selectedMilestone}
                ></ActualDatesEntryModal>
            </div >
        )
    );
};

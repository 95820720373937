import { createSlice } from "@reduxjs/toolkit";
import { fetchUserLogin, resendOtpGroyyo, fetchUserLoginValidate, fetchGroyyoUserLoginValidate, forgetPin } from "./action";


export const userLogin = createSlice({
  name: "userLogin",
  initialState: {
    email: null,
    otp: null,
    pin: null,
    loading: false,
    error: null,
    success: false,
    loginData: null,
    actionName: null,
    message: null
  },

  reducers: {
    resetLoginOtp: (state, action) => {
      state.success = false
      state.error = null
      state.actionName = null
      state.message = null
    },
  },

  extraReducers: {
    [fetchUserLogin.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [fetchUserLogin.fulfilled]: (state, action) => {
      let userData = JSON.stringify(action.payload);
      state.loading = false;
      state.success = true;
      state.loginData = userData;
      let userId = action.payload?.user ? action.payload?.user.id : 0;
      let roles = action.payload?.roles[0];
      localStorage.setItem('roleId', roles.roleId);
      localStorage.setItem('roleType', roles.roleType);
      localStorage.setItem('roleName', roles.roleName);
      localStorage.setItem('authorization', userData);
      localStorage.setItem('userId', userId);
    },
    [fetchUserLogin.rejected]: (state, action) => {
    
      state.loading = false;
      state.error = "Incorrect Pin  ";
    },
    [fetchUserLoginValidate.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [fetchUserLoginValidate.fulfilled]: (state, action) => {
      state.loading = false;
      //state.email = action.payload.Email;
      // state.otp = action.payload.user.otp;  
      // state.pin = action.payload.user.pin;
      state.actionName = "fetchUserLoginValidate";
      state.success = true;
    },
    [fetchUserLoginValidate.rejected]: (state, action) => {

      state.loading = false;
      state.error = "User not exist";
    },
    [fetchGroyyoUserLoginValidate.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [fetchGroyyoUserLoginValidate.fulfilled]: (state, action) => {
      state.loading = false;
      //state.email = action.payload.Email;
      // state.otp = action.payload.user.otp;  
      // state.pin = action.payload.user.pin;
      state.actionName = "fetchGroyyoUserLoginValidate";
      state.success = true;
    },
    [fetchGroyyoUserLoginValidate.rejected]: (state, action) => {

      state.loading = false;
      state.error = "User not exist";
    },
    [resendOtpGroyyo.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [resendOtpGroyyo.fulfilled]: (state, action) => {
      state.loading = false;
      //state.email = action.payload.Email;
      // state.otp = action.payload.user.otp;  
      // state.pin = action.payload.user.pin;
      state.actionName = "resendOtpGroyyo";
      state.success = true;
    },
    [resendOtpGroyyo.rejected]: (state, action) => {

      state.loading = false;
      state.error = "User not exist";
    },
    [forgetPin.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [forgetPin.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = "Mail sent to you email for verification.";
      state.success = true;
    },
    [forgetPin.rejected]: (state, action) => {
      state.loading = false;
      state.error = "User not exist";
    }
  },
});

export default userLogin.reducer;

export const { resetLoginOtp } = userLogin.actions;

import React, { useState, useEffect } from "react";
import LeftMenu from "../layouts/leftMenu";
import { Form, Field, Formik } from "formik";
import * as Yup from "yup";
import { notify } from "../../features/notification/notify";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../layouts/header";
import { fetchRoles } from "../../features/common/action";
import Select from "react-select";

import {
  addUpdateUser,
  getLocationDetail,
  getUserById,
} from "../../features/user_management/action";

import { resetUserManagememnt } from "../../features/user_management/slice";


export const AddUser = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [roles, setRoles] = useState([]);
  const [userId, setUserId] = useState();
  const [userDetail, setUserDetail] = useState();
  const [location, setLocation] = useState([]);
  const [userData, setUserData] = useState({
    id: 0,
    name: "",
    email: "",
    phone: "",
    role: "",
    creator: "",
    location: ""
  });
  const [roleOption, setRoleOption] = useState([]);
  const [locationOption, setLocationOption] = useState([])
  const roleResponse = useSelector((state) => state.common);
  const managementResponse = useSelector((state) => state.userManagememnt);
  const loggedInUser = localStorage.getItem("userId");
  useEffect(() => {
    dispatch(fetchRoles("Internal"));
  }, []);
  useEffect(() => {
    if (state && state.userId) {
      setUserId(state.userId);
    }
    dispatch(getLocationDetail(1));
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(getUserById(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (userDetail) {
      setUserData({
        id: userDetail.id,
        name: userDetail.name,
        email: userDetail.emailId,
        phone: userDetail.mobileNo,
        location: userDetail.locationDetail != null ? userDetail.locationDetail.id : "",
        role: userDetail.Roles[0].id,
        creator: loggedInUser,
      });
    }
  }, [userDetail]);

  useEffect(() => {
    if (roleResponse.actionName == "fetchRoles") {
      roleResponse.role && setRoles(roleResponse.role);
      let roleArr = [];
      if (roleResponse.role.length > 0)
        roleResponse.role.map((item) => {
          let list = {
            value: item.id,
            label: item.role,
          };
          roleArr.push(list);
        })
      setRoleOption(roleArr.sort((a, b) => a.label.localeCompare(b.label)));
    }
  }, [roleResponse]);


  useEffect(() => {
    if (
      managementResponse.actionName == "addUpdateUser" &&
      managementResponse.success == true
    ) {
      if (managementResponse.message == "User already exist" || managementResponse.message == "Unable to create") {
        notify(managementResponse.message, "success");
        dispatch(resetUserManagememnt());
      }
      else {
        dispatch(resetUserManagememnt());
        notify(managementResponse.message, "success");
        handleBack();
      }
    }
    if (
      managementResponse.actionName === "getUserById" &&
      managementResponse.success
    ) {
      setUserDetail(managementResponse.userDetail);
      dispatch(resetUserManagememnt());
    }

    if (managementResponse.actionName === "getLocationDetail" &&
      managementResponse.success) {
      setLocation(managementResponse.data.detail)
      let locArr = [];
      if (managementResponse.data.detail.length > 0)
        managementResponse.data.detail.map((item) => {
          let list = {
            value: item.id,
            label: item.locationName,
          };
          locArr.push(list);
        })
      setLocationOption(locArr.sort((a, b) => a.label.localeCompare(b.label)));
    }
  }, [managementResponse]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
      creator: loggedInUser,
    });
  };
  const handleRoleChange = (role) => {
    setUserData({
      ...userData,
      role: role.value,
      creator: loggedInUser,
    });
  };

  const handleLocChange = (role) => {
    setUserData({
      ...userData,
      location: role.value,
      creator: loggedInUser,
    });
  };

  const userSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
    phone: Yup.number()
      .required("Required")
      .test("len", "Phone number must be exactly 10 digits", (val) => {
        if (val) {
          return val.toString().length === 10;
        }
        return true;
      }),
    email: Yup.string()
      .required("Email is required")
      .matches(/@groyyo\.com$/, "Email should end with @groyyo.com"),
    location: Yup.string()
      .required("Required"),

  });
  const handleFormSubmit = (data) => {
    dispatch(addUpdateUser(data));
  };
  const handleBack = () => {
    navigate("/user-dashboard");
  };
  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <Formik
            initialValues={userData}
            validationSchema={userSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}
          >
            {({ errors, touched, isValid, handleBlur }) => (
              <>
                <Form>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/user-dashboard">User Management </a>
                      </li>
                      <li className="breadcrumb-item">
                        {userId != null && userId > 0 ? (
                          <a> Edit User</a>
                        ) : (
                          <a> Add User</a>
                        )}

                      </li>
                    </ol>
                  </nav>
                  <div className="top-heading">
                    {userId != null && userId > 0 ? (
                      <div className="d-flex">
                        <a className="back" onClick={handleBack}>
                          &#8249;
                        </a>
                        <h2>Edit User</h2>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <a className="back" onClick={handleBack}>
                          &#8249;
                        </a>
                        <h2>Add User</h2>
                      </div>
                    )}

                    <div className="d-flex gap-2">
                      <button
                        onClick={handleBack}
                        type="button"
                        className="btn grey-brdr-btn"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn ornge-brdr-btn">
                        <span className="align-middle">Next</span>
                      </button>
                    </div>
                  </div>
                  <div className="user-wrap">
                    <h6>User Details</h6>
                    <div className="row row-gap-4">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            User Name<span></span>
                          </label>
                          <input
                            value={userData.name ?? ""}
                            onChange={handleChange}
                            type="text"
                            name="name"
                            className="form-control"
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback d-block">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Role</label>

                          <Select
                            onChange={handleRoleChange}
                            options={roleOption}
                            placeholder={userData.role == "" ? 'Role: All' : roleOption.find(x => x.value == userData.role).label}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Email ID<span></span>
                          </label>
                          <input
                            value={userData.email ?? ""}
                            onChange={handleChange}
                            type="email"
                            name="email"
                            className="form-control"
                            onBlur={handleBlur}
                          />
                          {errors.email && touched.email ? (
                            <div className="invalid-feedback d-block">
                              {errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Phone Number<span></span>
                          </label>
                          <input
                            value={userData.phone ?? ""}
                            onChange={handleChange}
                            type="number"
                            name="phone"
                            className="form-control"
                            onBlur={handleBlur}
                          />
                          {errors.phone && touched.phone ? (
                            <div className="invalid-feedback d-block">
                              {errors.phone}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Location</label>
                          <Select
                            name="location"
                            onChange={handleLocChange}
                            options={locationOption}
                            placeholder={userData.location == "" ? 'Location: All' : locationOption.find(x => x.value == userData.location).label}
                          />
                          {errors.location && touched.location ? (
                            <div className="invalid-feedback d-block">
                              {errors.location}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

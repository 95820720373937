import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import circleImg from "../../assets/images/circle.svg"
import { RFQSamples } from "./rfqSamples";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BasicInfo } from "./rfqBasicInfo";
import { useDispatch, useSelector } from "react-redux";
import { ReleaseFactory } from "./releaseFactory";
import { ReleaseBuyer } from "./releaseBuyer";
import { RfqStatus } from "./rfqStatus";
import { Bid } from "./bid";
import { Conversation } from "./conversation/conversation";
import { ReplyFactory } from "./replyFactory";
import { getGeneratePo } from "../../features/rfq/action";
import { resetRfq } from "../../features/rfq/slice";
import { ChatConversation } from "./chat/chatConversation";
import { SampleForPoModal } from "./sampleForPoModal";
import { ShowHideRfq } from "./rfqModal/showHideRfq";

export const RfqInfo = () => {
    const dispatch = useDispatch();
    const [statusCount, setStatusCount] = useState(0);
    const [assigneeId, setAssigneeId] = useState("");
    const { state } = useLocation();
    const [rfqId, setRfqId] = useState();
    const navigate = useNavigate();
    const [defaultBid, setDefaultBid] = useState();
    const [bidTo, setBidTo] = useState();
    const [createdBidBy, setCreatedBidBy] = useState();
    const [currentChat, setCurrentChat] = useState();
    const [showGeneratePO, setGeneratePO] = useState("");
    const [activeTab, setActiveTab] = useState('basic-tab');
    const [bidToBe, setBidToBe] = useState(0);
    const [openSampleForPo, setOpenSampleForPo] = useState(false);
    const [rfqName, setRfqName] = useState("");
    const [acceptFrom, setAcceptFrom] = useState();

    useEffect(() => {
        if (state && state.rfqId) {
            setRfqId(state.rfqId)
        }
        if (state && state.chat == true) {
            setActiveTab('conversation-tab-pane')
        } else {
            setActiveTab('basic-tab')
        }

        //add loader for conversation page

    }, []);

    useEffect(() => {
        if (rfqId)
            dispatch(getGeneratePo(rfqId));
    }, [rfqId]);

    const rfqResponse = useSelector((state) => state.rfq);

    useEffect(() => {
        if (rfqResponse.actionName == "getGeneratePo") {

            if (rfqResponse.checkStatus == true) {
                setGeneratePO(true);
            }
            else
                setGeneratePO(false);

            dispatch(resetRfq());
        }
    }, [rfqResponse]);

    const handleBack = () => {
        if (state?.from == "sampleDetail") {
            navigate("/sample-detail", {
                replace: true,
                state: { sampleId: state.sampleId },
            });
        } else {
            navigate('/rfq-list')
        }

    }



    const handleOpenTab = (tabId) => {
        setActiveTab(tabId);
    };

    const handleOpenSampleForPo = () => {
        setOpenSampleForPo(true);
    }


    return (
        <>
            <LeftMenu />
            <div className="right-column">
                <Header />
                <div className="body-wrap">
                    <div>
                        <small>RFQLIST &gt; RFQ Details </small>
                    </div>
                    <div className="top-heading">
                        <div className="d-flex">
                            <a onClick={handleBack} className="back">&#8249;</a>
                            <h2>{rfqName}</h2>
                        </div>

                        {(localStorage.getItem("roleType") == "Internal") ?

                            showGeneratePO == "" || showGeneratePO == "false" ?
                                (<div className="release-button">
                                    <div style={{ display: 'flex' }}>
                                        <button className="btn ornge-brdr-btn release-button" style={{ marginBottom: '4px' }} data-bs-toggle="modal" data-bs-target="#release-factory">Release RFQ(Factory)</button>
                                        &nbsp; <button className="btn ornge-brdr-btn" data-bs-toggle="modal" data-bs-target="#release-buyer">Release RFQ(Buyer)</button>
                                    </div>
                                </div>)
                                :
                                (<div className="release-button">
                                    <div>
                                        <button className="btn ornge-brdr-btn" onClick={handleOpenSampleForPo} >Generate PO</button>
                                    </div>
                                </div>)
                            : ""

                        }
                        {/* {(localStorage.getItem("roleId") == 4 || localStorage.getItem("roleId") == 3) &&
                            <div className="release-button">
                                <div>
                                    <button data-bs-toggle="modal" data-bs-target="#reply-factory" className="btn ornge-brdr-btn">Reply</button>
                                </div>
                            </div>
                        } */}
                    </div>
                    <div className="sample-detail-wrap">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${activeTab === 'basic-tab' ? 'active' : ''}`} id="basic-tab" data-bs-toggle="tab" data-bs-target="#basic-tab-pane" type="button" role="tab" aria-controls="basic-tab-pane" aria-selected="true" onClick={() => handleOpenTab('home')}>Basic Information</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${activeTab === 'sample-tab-pane' ? 'active' : ''}`} id="sample-tab" data-bs-toggle="tab" data-bs-target="#sample-tab-pane" type="button" role="tab" aria-controls="sample-tab-pane" aria-selected="false" onClick={() => handleOpenTab('sample-tab-pane')}>Samples</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${activeTab === 'bids-tab-pane' ? 'active' : ''}`} id="bids-tab" data-bs-toggle="tab" data-bs-target="#bids-tab-pane" type="button" role="tab" aria-controls="bids-tab-pane" aria-selected="false" onClick={() => handleOpenTab('bids-tab-pane')}>Bids</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${activeTab === 'conversation-tab-pane' ? 'active' : ''}`} id="conversation-tab" data-bs-toggle="tab" data-bs-target="#conversation-tab-pane" type="button" role="tab" aria-controls="conversation-tab-pane" aria-selected="false" onClick={() => handleOpenTab('conversation-tab-pane')}>Conversations</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${activeTab === 'basic-tab' ? 'show active' : ''}`} id="basic-tab-pane" role="tabpanel" aria-labelledby="basic-tab" tabIndex="0">
                                <BasicInfo rfqId={rfqId} setAssigneeId={setAssigneeId} setRfqName={setRfqName} />
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'sample-tab-pane' ? 'show active' : ''}`} id="sample-tab-pane" role="tabpanel" aria-labelledby="sample-tab" tabIndex="0">
                                <RFQSamples rfqId={rfqId} />
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'bids-tab-pane' ? 'show active' : ''}`} id="bids-tab-pane" role="tabpanel" aria-labelledby="bids-tab" tabIndex="0">
                                <Bid acceptFrom={acceptFrom} setAcceptFrom={setAcceptFrom} rfqId={rfqId} setDefaultBid={setDefaultBid} setBidToBe={setBidToBe} bidToBe={bidToBe} setGeneratePO={setGeneratePO} setCreatedBidBy={setCreatedBidBy} setBidTo={setBidTo} setActiveTab={setActiveTab} setCurrentChat={setCurrentChat} />
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'conversation-tab-pane' ? 'show active' : ''}`} id="conversation-tab-pane" role="tabpanel" aria-labelledby="v-tab" tabIndex="0">
                                {/* <Conversation rfqId={rfqId} setCurrentChat={setCurrentChat} currentChat={currentChat} /> */}
                                <ChatConversation rfqId={rfqId} setCurrentChat={setCurrentChat} currentChat={currentChat} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(localStorage.getItem("userId") == assigneeId || localStorage.getItem("roleType") == "Internal") &&
                <div className="count-drop" data-bs-toggle="modal" data-bs-target="#rfq-status">
                    <span className="count">{statusCount}</span>
                    <img src={circleImg} alt="circle" />
                </div>
            }
            <ReleaseFactory rfqId={rfqId} />
            <ReleaseBuyer rfqId={rfqId} />
            <RfqStatus rfqId={rfqId} setStatusCount={setStatusCount} />
            <ReplyFactory acceptFrom={acceptFrom} rfqId={rfqId} createdBidBy={createdBidBy} setBidToBe={setBidToBe} bidToBe={bidToBe} defaultBid={defaultBid} setDefaultBid={setDefaultBid} bidTo={bidTo} />
            {openSampleForPo ?
                <SampleForPoModal setOpenSampleForPo={setOpenSampleForPo} openSampleForPo={openSampleForPo} rfqId={rfqId} />
                : ""}

        </>
    );
}
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { useLocation, useNavigate } from "react-router";
import { Form, Formik } from "formik";
import { notify } from "../../features/notification/notify";
import { getAllCategories } from "../../features/product/action";
import { resetProduct } from "../../features/product/slice";
import { addUpdateCategory } from "../../features/product/action";
import * as Yup from "yup";
export const AddUpdateCategory = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [formTitle, setFormTitle] = useState("Add");
    const [categories, setCategories] = useState([]);
    const categoryResponse = useSelector((state) => state.product);
    const [newCategoryData, newCategory] = useState({
        id: "",
        name: "",
        parentId: 0,
        isDelete: 0,
    });

    const sampleSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
    });
    useEffect(() => {
        dispatch(getAllCategories(0, 100))
    }, []);

    useEffect(() => {
        if (categoryResponse.actionName == 'getAllCategories')
            categoryResponse.allCategories?.allCat && setCategories(categoryResponse.allCategories.allCat);

        if (categoryResponse.actionName == 'addUpdateCategory') {
            notify(categoryResponse.message, "success");
            dispatch(resetProduct());
            newCategory({ id: "", name: "", parentId: 0, isDelete: 0, })
            navigate('/all-categories')

        }
    }, [categoryResponse]);

    useEffect(() => {
        if (state) {
            setFormTitle("Edit");
            newCategory({
                ...newCategoryData,
                id: state.item.id,
                parentId: state.item.parentId,
                name: state.item.name,
                isDelete: state.item.isDelete,
                state: state.item,
            });
        } else {
            navigate("/category/add");
        }
    }, []);

    const handleChange = (e) => {
        newCategory({
            ...newCategoryData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFormSubmit = (data) => {
        dispatch(addUpdateCategory({ data }));
    };

    const handleCancel = () => {
        navigate("/all-categories");
    }
    console.log("addcat is", newCategoryData);
    return (
        <>
            <LeftMenu />

            <div className="right-column">
                <Header />
                <div className="body-wrap">
                    <Formik
                        initialValues={newCategoryData}
                        validationSchema={sampleSchema}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            console.log("viewvalues", values);
                            handleFormSubmit(values);
                        }}
                    >
                        {({ errors, touched, handleBlur }) => (
                            <>
                                <Form>
                                    <div className="top-heading">
                                        <div className="d-flex">
                                            <a onClick={handleCancel} className="back">
                                                &#8249;
                                            </a>
                                            <h2>{formTitle} Category</h2>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <button className="btn grey-brdr-btn" onClick={handleCancel}>Cancel</button>
                                            <button type="submit" className="btn ornge-brdr-btn">Save</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6"><label className="form-label">Name</label>
                                            <input type="text" name="name" value={newCategoryData.name ?? ""} onChange={handleChange} onBlur={handleBlur} className="form-control" />
                                            {errors.name && touched.name ? (
                                                <div className="invalid-feedback d-block">
                                                    {errors.name}
                                                </div>
                                            ) : null}</div>
                                        {newCategoryData.id == "" || newCategoryData?.state?.parentId != "" ?
                                            <div className="col-sm-6">
                                                <label className="form-label">Parent Category</label>
                                                <select className="form-select" name="parentId" value={newCategoryData.parentId ?? ""} onChange={handleChange} >

                                                              <option value='' >--Select--</option>
                                                                
                                                                {categories?.map((cat, index) => (
                                                                 <option value={cat.id} key={index} >
                                                                   {cat.categoryName}
                                                                  </option>))}


                                                </select>
                                            </div> 
                                            
                                            : ""
                                        }
                                    </div>

                                </Form>
                            </>
                        )}
                    </Formik>

                </div>
            </div>
        </>
    );
}
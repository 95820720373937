import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Table, Button, Row, Col } from "react-bootstrap";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBulkRfq } from "../../features/rfq/action";
import {handleDateClick} from "../../utils/datePickerHelper";

export const PreviewBulkRfq = () => {
  const location = useLocation();
  const rfqResponse = useSelector((state) => state.rfq);
  const { fileJson, loggedInUser } = location.state;
  const jsonDataObject = JSON.parse(fileJson);
  const [editedData, setEditedData] = useState(jsonDataObject);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCellEdit = (rowIndex, key, value) => {
    const updatedData = [...editedData];
    updatedData[rowIndex][key] = value;
    setEditedData(updatedData);
  };

  const handleSubmit = (values) => {
    const data = {
      rfqs: values,
      createdBy: loggedInUser,
    };
    dispatch(addBulkRfq(data));
  };

  useEffect(() => {
    if (rfqResponse.actionName == "addBulkRfq" && rfqResponse.success) {
      navigate("/rfq-list");
    }
  }, [rfqResponse]);

  const handleBack = () => {
    navigate("/addbulkRfq");
  };

  const startsWithHttps = (value) => {
    return typeof value === "string" && value.startsWith("https://");
  };
  return (
    <>
      <Formik initialValues={editedData} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <LeftMenu />
            <div className="right-column">
              <Header />
              <div className="body-wrap">
                <div className="top-heading">
                  <div className="d-flex gap-2 align-items-center flex-wrap">
                    <h2>RFQs Bulk Upload</h2>
                  </div>
                  <div className="d-flex gap-2">
                    <button onClick={handleBack} className="btn grey-brdr-btn">
                      Cancel
                    </button>
                    <button type="submit" className="btn ornge-brdr-btn">
                      Next
                    </button>
                  </div>
                </div>
                <div className="table-container">
                  <Row>
                    <Col>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            {Object.keys(editedData[0]).map((key) => (
                              <th key={key}>{key}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {editedData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {Object.keys(row).map((key) => (
                                <td key={key}>
                                  {key === "RFQDate" ? (
                                    <Field
                                      className="form-control"
                                      type="date"
                                      name={`${rowIndex}[${key}]`}
                                      value={row[key]}
                                      onClick={handleDateClick}
                                      onChange={(e) =>
                                        handleCellEdit(
                                          rowIndex,
                                          key,
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : startsWithHttps(row[key]) ? (
                                    <Field
                                      className="form-control"
                                      type="text"
                                      name={`${rowIndex}[${key}]`}
                                      value={row[key]}
                                      onChange={(e) =>
                                        handleCellEdit(
                                          rowIndex,
                                          key,
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    <Field
                                      className="form-control"
                                      type="text"
                                      name={`${rowIndex}[${key}]`}
                                      value={row[key]}
                                      onChange={(e) =>
                                        handleCellEdit(
                                          rowIndex,
                                          key,
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

import React, { useEffect, useState } from "react";

export const ChatInput = ({ handleSendMsg, socket , currentChat, isAssignee}) => {
  const [msg, setMsg] = useState("");

  const sendChat = (event) => {
    event.preventDefault();
    if (msg.length > 0) {
      handleSendMsg(msg);
      setMsg("");
    }
  };

  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (socket.current) {
     
      socket.current.on("typing", (msg) => {
    
        setIsTyping(true);
      });
    }
  }, [socket.current]);
 

  const handleTyping = (msg) => {
    setMsg(msg)
    if (!isTyping) {
      socket.current.emit("typing", {
        to: currentChat.id,
        typing: true,
      });
    
    }
    // clearTimeout(timeout);
   
   

  };

  const timeout = setTimeout(() => {
    setIsTyping(false);
    // socket.current.emit("typing", false);
  }, 1500);

  return (
    <div className="chat-footer">
      <div>
        {isTyping ? "Typing..." : ""}
      </div>
      {!isAssignee && <form className="input-container" onSubmit={(event) => sendChat(event)}>
        <input
          type="text"
          placeholder="type your message here"
          onChange={(e) => handleTyping(e.target.value)}
          className="chat-input"
          value={msg}
        />
        <button type="submit" className="btn ornge-btn">
          Send
        </button>
      </form> }
    </div>
  );
};

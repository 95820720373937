import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import logoImg from "../../assets/images/logo.svg";
import filter from "../../assets/images/filter.svg";
import { Button, Confirm } from "semantic-ui-react";
import {
  getAllSamples,
  deleteSelectedSamples,
  exportAsPPT,
} from "../../features/sample/action";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import React, { useEffect, useState, useRef } from "react";
import searchRed from "../../assets/images/search-red.svg";
import trash from "../../assets/images/trash.svg";
import importGreen from "../../assets/images/import-green.svg";
import { confirmAlert } from "react-confirm-alert";
import { notify } from "../../features/notification/notify";
import { sendSamplesToSelectedMail } from "../../features/sample/action";
import { useNavigate } from "react-router-dom";
import { getUserByRoleName } from "../../features/userDetail/action";
import { getAllUsers } from "../../features/user_management/action";
import { getCategory, getSeason } from "../../features/common/action";
import { resetCommon } from "../../features/common/slice";
import { CONSTANT } from "../../utils/constant";
import ReactPaginate from "react-paginate";
import { resetSample } from "../../features/sample/slice";
import { SupplierList } from "../userManagement/supplierList";
import Select from "react-select";
import { getUserByRoleType } from "../../features/rfq/action";

export const SampleListing = () => {
  const [ids, setIds] = useState([]);
  const [check, setCheck] = useState(ids.length > 0);
  const [samples, setSamples] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [factoryList, setFactoryList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [allInternalUsersList, setInternalUsersList] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const sampleResponse = useSelector((state) => state.sample);
  const userResponse = useSelector((state) => state.userDetail);
  const commonResponse = useSelector((state) => state.common)
  const managementResponse = useSelector((state) => state.userManagememnt);
  const [sendMail, setSendMail] = useState({
    name: "", email: ""
  });
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.SAMPLE_PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const [ttlSample, setTtlSample] = useState(0);
  const isFirstRender = useRef(true);
  const userData = JSON.parse(localStorage.getItem("authorization"));
  const role = userData?.roles.length > 0 ? userData?.roles[0] : "";
  const [factoryOption, setFactoryOption] = useState([]);
  const [millOption, setMillOption] = useState([]);
  const [seasonOption, setSeasonOption] = useState([]);
  const [buyerOption, setBuyerOption] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const supplierId = (role.roleId === CONSTANT.USER_ROLE.SUPPLIER || role.roleId === CONSTANT.USER_ROLE.BUYER) && userData?.user ? userData.user.id : 0;
  const roleId = localStorage.getItem("roleId");
  const location = roleId == (CONSTANT.USER_ROLE.DESIGN || CONSTANT.USER_ROLE.OPERATIONS) ? userData.user.location : 0;
  const [openShare, setOpenShare] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeOption, setEmployeeOption] = useState([]);
  const [filters, setFilters] = useState({
    search: '', category: '', employee: '',
    factory: '', mill: '', season: '', buyer: '', supplierId: supplierId, location: location
  })
  const rfqResponse = useSelector((state) => state.rfq);

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getSeason());
    dispatch(getUserByRoleName('Supplier,Buyer'));
    dispatch(getAllUsers({ page: currentPage + 1, limit: itemCount }));
    dispatch(getUserByRoleType({ rollType: "Internal", userId: 0 }));
    callAllSample();
  }, [currentPage, itemCount]);

  useEffect(() => {
    callAllSample();
  }, [filters]);

  const callAllSample = () => {
    dispatch(getAllSamples({ page: currentPage + 1, limit: itemCount, filters: filters }));
  }

  useEffect(() => {
    if (rfqResponse.actionName == "userByRoleType" && rfqResponse.success) {
      setEmployeeList(rfqResponse.roleDetails);
      let employeeArr = [];
      if (rfqResponse.roleDetails.length > 0) {
        rfqResponse.roleDetails.map((item) => {
          let list = {
            value: item.id,
            label: item.name ? item.name : item.emailId,
          };
          employeeArr.push(list);
        })
        setEmployeeOption(employeeArr.sort((a, b) => a.label.localeCompare(b.label)));
      }
    }
  }, [rfqResponse]);

  useEffect(() => {
    if (userResponse.actionName === "userByRoleName") {
      let factory = userResponse.roleDetails.filter(x => x.Roles[0].role == 'Supplier')
      let buyer = userResponse.roleDetails.filter(x => x.Roles[0].role == 'Buyer')
      setFactoryList(factory);
      setBuyerList(buyer);
      let factoryArr = [];
      let buyerArr = [];
      if (factory.length > 0) {
        factory.map((item) => {
          let list = {
            value: item.id,
            label: item.name ? item.name : item.emailId,
          };
          factoryArr.push(list);
        })
        setFactoryOption(factoryArr.sort((a, b) => a.label.localeCompare(b.label)));
        setMillOption(factoryArr.sort((a, b) => a.label.localeCompare(b.label)))
      }
      if (buyer.length > 0) {
        buyer.map((item) => {
          let list = {
            value: item.id,
            label: item.name ? item.name : item.emailId,
          };
          buyerArr.push(list);
        })
        setBuyerOption(buyerArr.sort((a, b) => a.label.localeCompare(b.label)));
      }
    }
  }, [userResponse]);

  useEffect(() => {
    if (managementResponse.actionName === "getAllUser") {
      setInternalUsersList(managementResponse.users[0]);
    }

  }, [managementResponse]);

  useEffect(() => {
    if (commonResponse.actionName === "getCategory" && commonResponse.success) {
      setCategoryList(commonResponse.category)
      if (commonResponse.category.length > 0) {
        let catArr = []
        commonResponse.category.map((item) => {
          let list = {
            value: item.id,
            label: item.categoryName,
          };
          catArr.push(list);
        })
        setCategoryOption(catArr.sort((a, b) => a.label.localeCompare(b.label)));
      }
      dispatch(resetCommon());
    }

    if (commonResponse.actionName === "getSeason" && commonResponse.success) {
      setSeasons(commonResponse.season)
      if (commonResponse.season.length > 0) {
        let seasonArr = []
        commonResponse.season.map((item) => {
          let list = {
            value: item.id,
            label: item.seasonName,
          };
          seasonArr.push(list);
        })
        setSeasonOption(seasonArr.sort((a, b) => a.label.localeCompare(b.label)));
      }
      dispatch(resetCommon());
    }
  }, [commonResponse]);


  useEffect(() => {
    if (sampleResponse.actionName === "getAllSamples" && sampleResponse.success) {
      sampleResponse.allsamples[0] && setSamples(sampleResponse.allsamples[0]);
      setTtlSample(sampleResponse.allsamples[1])
      dispatch(resetSample())
      if (sampleResponse.allsamples[1] !== null && pageCount === 0) {
        setPageCount(Math.ceil(sampleResponse.allsamples[1] / itemCount));
      }
    }
    if (sampleResponse.actionName === "deleteSelectedSamples" && sampleResponse.success == true) {
      callAllSample();
      notify('Sample deleted successfully!', 'success');
    }
    if (sampleResponse.actionName === "sendSamples" && sampleResponse.success) {
      notify(sampleResponse.message, "success");
      dispatch(resetSample())
    }

    if (sampleResponse.actionName === "exportAsPPT" && sampleResponse.success) {
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_API_URL}/samplePPT/samplePPTs.pptx`;
      link.setAttribute(
        'download',
        `samplePPTs.pptx`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      dispatch(resetSample())
    }
  }, [sampleResponse]);

  const handleCheckBox = (id) => {
    setIds((prevIds) => {
      const index = prevIds.indexOf(id);
      const newIds =
        index !== -1
          ? [...prevIds.slice(0, index), ...prevIds.slice(index + 1)]
          : [...prevIds, id];

      setCheck(newIds.length > 0);
      return newIds;
    });
  };

  const handleSampleClick = (id) => {
    navigate("/sample-detail", {
      replace: true,
      state: { sampleId: id },
    });
  }

  const handleDelete = (id) => {

    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(deleteSelectedSamples(id));
            setCheck(false);
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };
  const onCloseModal = () => setOpenShare(false);
  const resetFilter = () => {
    setFilters({
      search: '', category: '', employee: '',
      factory: '', mill: '', season: '', buyer: '', supplierId: supplierId
    })
  }
  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  }

  const handleSelectChange = (data, type) => {
    setFilters({
      ...filters,
      [type]: data.value,
    });
  }

  const handleSelectEmail = (e) => {
    const values = e.target.value.split(',');
    setSendMail({
      ...sendMail,
      name: values[0],
      email: values[1]
    })
  }

  const sendSamples = () => {
    setOpenShare(false);
    dispatch(sendSamplesToSelectedMail(sendMail));
  }

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const handleShareOpenModal = () => {
    setOpenShare(true);
  }

  const exportAsPPt = () => {
    notify('Download will begin shortly!', 'info')
    dispatch(exportAsPPT(ids))
  }

  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <div className="top-heading">
            <div className="d-flex gap-2 align-items-center flex-wrap">
              <h2>Samples</h2>
              <div className="search-top">
                <input onChange={handleChange} value={filters.search} name="search" type="text" placeholder="Search by Sample name, size, color, style" />
              </div>
            </div>
            {check ? (
              <div className="right-top-btn">
                <div className="export-dropdown">
                  <button className="btn export-btn-top">
                    <img src={importGreen} alt="import" />
                    Export
                  </button>
                  <ul className="export-dropdown-menu">
                    <li>
                      <a >As PDF</a>
                    </li>
                    <li>
                      <a onClick={exportAsPPt}>As PPT</a>
                    </li>
                    <li>
                      <a onClick={exportAsPPt}>As Excel</a>
                    </li>
                  </ul>
                </div>
                <button
                  className="btn trash-btn-top"
                  onClick={() => handleDelete(ids)}
                >
                  <img src={trash} alt="trash" />
                  Delete
                </button>
                <button className="btn search-btn-top" onClick={handleShareOpenModal} >
                  <img src={searchRed} alt="search" />
                  Share
                </button>
              </div>
            ) : (
              <a className="btn ornge-brdr-btn" href="/addsample">
                <span className="align-middle">&#43; Add New Sample</span>
              </a>
            )}
          </div>
          <div className="category-wrap">
            <div className=" row">
              <div className="col-md-2">
                <div>

                  <Select
                    value={filters.factory}
                    onChange={(e) => { handleSelectChange(e, 'factory') }}
                    options={factoryOption}
                    placeholder={filters.factory == "" ? 'Factory: All' : factoryOption.find(x => x.value == filters.factory)?.label}
                  />
                </div>
              </div>
              <div className="col-md-1">
                <div>

                  <Select
                    value={filters.mill}
                    onChange={(e) => { handleSelectChange(e, 'mill') }}
                    options={millOption}
                    placeholder={filters.mill == "" ? 'Mill' : millOption.find(x => x.value == filters.mill)?.label}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div>

                  <Select
                    value={filters.employee}
                    onChange={(e) => { handleSelectChange(e, 'employee') }}
                    options={employeeOption}
                    placeholder={filters.employee == "" ? 'Employee: All' : employeeOption.find(x => x.value == filters.employee)?.label}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div>

                  <Select
                    value={filters.season}
                    onChange={(e) => { handleSelectChange(e, 'season') }}
                    options={seasonOption}
                    placeholder={filters.season == "" ? 'Season: All' : seasonOption.find(x => x.value == filters.season)?.label}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div>

                  <Select
                    value={filters.buyer}
                    onChange={(e) => { handleSelectChange(e, 'buyer') }}
                    options={buyerOption}
                    placeholder={filters.buyer == "" ? 'Buyer: All' : buyerOption.find(x => x.value == filters.buyer)?.label}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div>

                  <Select
                    value={filters.category}
                    onChange={(e) => { handleSelectChange(e, 'category') }}
                    options={categoryOption}
                    placeholder={filters.category == "" ? 'Category: All' : categoryOption.find(x => x.value == filters.category)?.label}
                  />
                </div>
              </div>
              <div className="col-md-1">
                <button className="btn btn-rst-smpl" onClick={resetFilter}>Reset</button>
              </div>
            </div>
            <br />
            <div className="row row-gap-4">
              {samples &&
                samples.map((sample, index) => (
                  <div key={index} className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6" >
                    <div className="category-item">
                      <div className="category-img" style={{ cursor: 'pointer' }} onClick={(e) => handleSampleClick(sample.id)}>
                        {sample.sampleFiles &&
                          sample.sampleFiles
                            .filter((file) => file.fileType === "image" && file.isFront === true)
                            .slice(0, 1)
                            .map((image, imageIndex) => (
                              <img
                                key={imageIndex}
                                src={image.filePath}
                                alt={`sample-${index}-main-image`}
                              />
                            ))}
                        <div className="category-side-img">
                          {sample.sampleFiles &&
                            sample.sampleFiles
                              .filter((file) => file.fileType === "image" && file.display === true)
                              .slice(1)
                              .map((sideFile, sideFileIndex) => (
                                <img
                                  key={sideFileIndex}
                                  src={sideFile.filePath}
                                  alt={`sample-${index}-side-image-${sideFileIndex + 1
                                    }`}
                                />
                              ))}
                        </div>
                      </div>
                      <div className="category-detail">
                        <div className="category-row">
                          <div style={{ cursor: 'pointer' }} onClick={(e) => handleSampleClick(sample.id)}>
                            <span className="icon">{sample.id}</span>
                            {sample.sampleName}
                          </div>
                          <input
                            type="checkbox"
                            value={check}
                            checked={ids.includes(sample.id)}
                            onChange={() => handleCheckBox(sample.id)}
                            className="form-check-input"
                          />
                        </div>
                        <div className="row" style={{ cursor: 'pointer' }} onClick={(e) => handleSampleClick(sample.id)}>
                          <div className="col-4" title={sample.User ? sample.User.name : ""}>
                            <span>Buyer</span>
                            <div className="category-name">{sample.User ? sample.User.name : "--"}</div>
                          </div>
                          <div className="col-4" title={sample.product ? sample.product : "--"}>
                            <span>Product</span>
                            <div className="category-name">
                              {sample.product ? sample.product : "--"}
                            </div>
                          </div>
                          <div className="col-4" title={sample.season.seasonName}>
                            <span>Season</span>
                            <div className="category-name">{sample.season.seasonName}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {ttlSample == 0 && (
              <div className="row text-center" >
                <h3>No Samples Found!</h3>
              </div>
            )}
            {ttlSample > 10 && (
              <nav className="pagination-block">
                <p className="total">
                  {ttlSample} items total
                </p>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination justify-content-center mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
                <div className="customSelect">
                  <label htmlFor="">Items per page :</label>
                  <div className="selectBox">
                    <select name="" id="" onChange={handleItemsChange}>
                      <option value={CONSTANT.SAMPLE_PAGE_LIMIT}>{CONSTANT.SAMPLE_PAGE_LIMIT}</option>
                      <option value="28">28</option>

                      <option value="42">42</option>
                    </select>
                  </div>
                </div>
              </nav>
            )}
          </div>
        </div>
      </div>

      <Modal open={openShare} onClose={onCloseModal} center >
        <div className="row" >
          <div className="col-sm-12">
            <div className="mb-5">
              <label className="form-label">
                Email Options<span></span>
              </label>
              <select
                //value={ imageData.fileName ?? ""}
                onChange={handleSelectEmail}
                name="sendSamples"
                className="form-control"
              //onBlur={handleBlur}
              >
                <option>--Select email--</option>
                {allInternalUsersList.length > 0 && allInternalUsersList.map((item, index) => (
                  <option value={`${item.name}, ${item.emailId}`}>{item.emailId}</option>

                ))}
                {factoryList.length > 0 && factoryList.map((item, index) => (
                  <option value={`${item.name}, ${item.emailId}`}>{item.emailId}</option>
                ))}
                {buyerList.length > 0 && buyerList.map((item, index) => (
                  <option value={`${item.name}, ${item.emailId}`}>{item.emailId}</option>
                ))}
              </select>
            </div>
            <button type="button" onClick={sendSamples} className="btn ornge-brdr-btn">
              Send
            </button>
          </div>
        </div>
      </Modal>

    </>
  );
};

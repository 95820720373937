import { useEffect, useState, useCallback, useRef } from "react";
import { getBidByRfqId, getBidReplyDetails } from "../../features/rfq/action";
import { useDispatch, useSelector } from "react-redux";
import { resetRfq } from "../../features/rfq/slice";
import { getChatByRfqBid, getRFQMessages } from "../../features/chats/action";
import { CONSTANT } from "../../utils/constant";
import ReactPaginate from "react-paginate";
import { Modal } from "react-bootstrap";
import { notify } from "../../features/notification/notify";
import { acceptOneBid } from "../../features/rfq/action";
import { confirmAlert } from "react-confirm-alert";
import { ShowHideRfq } from "./rfqModal/showHideRfq";
export const Bid = (props) => {
  const {
    rfqId,
    setDefaultBid,
    setGeneratePO,
    bidToBe,
    setBidToBe,
    setCreatedBidBy,
    setBidTo,
    setActiveTab,
    setCurrentChat,
    acceptFrom,
    setAcceptFrom
  } = props;
  const dispatch = useDispatch();
  const [bidInfo, setBidInfo] = useState([]);
  const [replyDetails, setReplyDetails] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [ttlBid, setTtlBid] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const rfqResponse = useSelector((state) => state.rfq);
  const userId = parseInt(localStorage.getItem("userId"));
  const roleType = localStorage.getItem("roleType");
  const [showResponse, setShowResponse] = useState(false);
  const [replyFrom, setReplyFrom] = useState();
  const [bidFrom, setBidFrom] = useState();
  const [bidId, setBidId] = useState();
  const [showHideRfqDetailsModal, setShowHideRfqDetailsModal] = useState(false);
  const [openModalFor, setOpenModalFor] = useState();
  const btnRef = useRef(null);
  useEffect(() => {
    if (rfqId) callAllBids();
  }, [currentPage, itemCount, rfqId]);

  const callAllBids = () => {
    dispatch(
      getBidByRfqId({ page: currentPage + 1, limit: itemCount, rfqId: rfqId })
    );
  };

  let currentUser = JSON.parse(localStorage.getItem("authorization"));

  useEffect(() => {
    if (rfqResponse.actionName == "getBidByRfqId") {
      var bidList;
      if (roleType == "External") {
        bidList = rfqResponse.bidList[1].filter(
          (x) => x?.bidToUser?.id == userId
        );
      } else {
        bidList = rfqResponse.bidList[1];
      }
      if (rfqResponse.bidList[0] !== null && pageCount === 0) {
        setPageCount(Math.ceil(rfqResponse.bidList[0] / itemCount));
      }
      setTtlBid(rfqResponse.bidList[0]);
      setBidInfo(bidList);

      setBidTo(bidList[0]?.createdByUser?.emailId);
      setCreatedBidBy(bidList[0]?.createdByUser?.name);
      dispatch(resetRfq());
    }
    if (rfqResponse.actionName == "getBidReplyDetails" && rfqResponse.success) {
      setReplyDetails(rfqResponse.replyDetails);
      setDefaultBid(rfqResponse.replyDetails);
      dispatch(resetRfq());
    }
    if (rfqResponse.actionName == "acceptOneBid") {
      notify("Bid is accepted", "success");
      setGeneratePO(true);
      callAllBids();
      dispatch(resetRfq());
    }
    if (rfqResponse.actionName === "replyBid" && rfqResponse.success) {
      if(acceptFrom == 'internal'){
        const data = {
          rfqId: rfqId,
          id: bidToBe,
        };
        dispatch(acceptOneBid(data));
        setAcceptFrom()
      }
     }
  }, [rfqResponse]);

  const handleViewChat = (bidId) => {
    setActiveTab("conversation-tab-pane");
    // setCurrentChat(chatUser)
    let getPayload = {
      rfqId: rfqId,
      bidId: bidId,
    };
    dispatch(getChatByRfqBid(getPayload));
  };

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };

  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const handleReplyClick = (bid) => {
    setBidToBe(bid.id);
    if(bidToBe != bid.id)
    {
    dispatch(getBidReplyDetails({ bidId: bid.id, roleType: roleType, bidTo: bid.bidTo }))
    }
  };

  const showBidReply = (bid) => {
    setBidToBe(0);
    setReplyFrom(bid.bidToUser.name);
    setBidFrom(bid.createdByUser.name);
    dispatch(getBidReplyDetails({ bidId: bid.id, roleType: roleType, bidTo: bid.bidTo }));
    setShowResponse(true);
  };

  const handleAccept = (item) => {
    if (!item.bidReplies || item.bidReplies.length == 0) {
      setAcceptFrom('internal')
      btnRef.current.click();
    } else {
      confirmAlert({
        title: "Confirm to Acceptance",
        message: "Are you sure want to accept this bid? This cannot be undone.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const data = {
                rfqId: item.rfqId,
                id: item.id,
              };
              dispatch(acceptOneBid(data));
            },
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  const showHideRfqModal = (userRoleType) => {
    let role = "";
    if (userRoleType == "factory") {
      role = CONSTANT.USER_ROLE.SUPPLIER;
    } else {
      role = CONSTANT.USER_ROLE.BUYER;
    }
    setOpenModalFor(role);
    setShowHideRfqDetailsModal(true);
  };
  return (
    <>
      {bidInfo.length > 0 && roleType == "Internal" && (
        <>
          <div className="row row-gap-2 mt-3">
            <div className="col-xxl-8 col-xl-9 col-lg-8 col-sm-12">
              <div className="row row-gap-2">
                <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                  <label className="form-label">
                    Internal Sampling Cost (INR)
                  </label>
                  {bidInfo[0].rfqDetail?.internalSamplingCost ? (
                    <input
                      type="text"
                      readOnly
                      value={parseFloat(
                        bidInfo[0].rfqDetail?.internalSamplingCost
                      ).toFixed(2)}
                      className="form-control"
                    />
                  ) : (
                    <p>--</p>
                  )}
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                  <label className="form-label">
                    Internal Sampling Time (Man days)
                  </label>
                  {bidInfo[0].rfqDetail?.internalSamplingTime ? (
                    <input
                      type="text"
                      readOnly
                      value={bidInfo[0].rfqDetail?.internalSamplingTime + " MD"}
                      className="form-control"
                    />
                  ) : (
                    <p>--</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-3 col-lg-4 col-sm-12">
              <button
                className="btn ornge-brdr-btn release-button"
                style={{ marginBottom: "4px" }}
                onClick={(e) => {
                  showHideRfqModal("factory");
                }}
              >
                Modify RFQ Details Visibility to Factory
              </button>
              <button
                className="btn ornge-brdr-btn release-button"
                style={{ marginBottom: "4px" }}
                onClick={(e) => {
                  showHideRfqModal("buyer");
                }}
              >
                Modify RFQ Details Visibility to Buyer
              </button>
            </div>
          </div>
          <div className="row row-gap-2 mt-5">
            <div className="col-xxl-8 col-xl-9 col-lg-8 col-sm-12">
              <div className="row row-gap-2">
                <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                  <label className="form-label">
                    Internal Production Cost (INR)
                  </label>
                  {bidInfo[0].rfqDetail?.internalProductionCost ? (
                    <input
                      type="text"
                      readOnly
                      value={parseFloat(
                        bidInfo[0].rfqDetail?.internalProductionCost
                      ).toFixed(2)}
                      className="form-control"
                    />
                  ) : (
                    <p>--</p>
                  )}
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                  <label className="form-label">
                    Internal Production Time (Man days)
                  </label>
                  {bidInfo[0].rfqDetail?.internalProductionTime ? (
                    <input
                      type="text"
                      readOnly
                      value={
                        bidInfo[0].rfqDetail?.internalProductionTime + " MD"
                      }
                      className="form-control"
                    />
                  ) : (
                    <p>--</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="rfq-status-table tab-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Bid Sent To</th>
                <th>Bid Sent By</th>
                <th>Bid Type</th>
                <th>Bid Chat</th>
                {roleType == "Internal" && <th>Bid Response</th>}
                {roleType == "External" && <th>Bid Info</th>}
                {roleType == "Internal" && <th>Action</th>}
                <th>Status</th>
                {localStorage.getItem("roleId") == 4 ||
                localStorage.getItem("roleId") == 3 ? (
                  <th>Reply Bid</th>
                ) : (
                  ""
                )}
                <th>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="user-tab"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {bidInfo.length > 0 &&
                bidInfo.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.bidToUser.name}</td>
                    <td>{item.createdByUser.name}</td>
                    <td>{item.bidType}</td>
                    <td>
                      <a
                        className="cht-viw-clr"
                        onClick={() => handleViewChat(item.id)}
                      >
                        Chat
                      </a>
                    </td>
                    {((item.bidReplies && item.bidReplies.length > 0) ||
                      roleType == "External") && (
                      <td>
                        <a
                          className="cht-viw-clr"
                          style={{ color: "#FD4900" }}
                          onClick={() => showBidReply(item)}
                        >
                          View
                        </a>
                      </td>
                    )}
                    {(!item.bidReplies || item.bidReplies.length == 0) &&
                      roleType == "Internal" && (
                        <td>
                          <a>-</a>
                        </td>
                      )}
                    {roleType == "Internal" && (
                      <td>
                        <button
                          style={{ display: 'none' }}
                          ref={btnRef}
                          data-bs-toggle="modal"
                          data-bs-target="#reply-factory"
                          onClick={(e) => handleReplyClick(item)}
                        >
                          Hidden Btn
                        </button>
                        {item.status == null ? (
                          <button
                            className="btn ornge-brdr-btn btn-actin"
                            onClick={() => handleAccept(item)}
                          >
                            Accept
                          </button>
                          
                        ) : item.status == 0 ? (
                          <button className="btn btn-danger" disabled>
                            Rejected
                          </button>
                        ) : (
                          <button className="btn btn-success" disabled>
                            Accepted
                          </button>
                        )}
                      </td>
                    )}
                    <td>
                      <span className="green status" />
                    </td>
                    {localStorage.getItem("roleId") == 4 ||
                    localStorage.getItem("roleId") == 3 ? (
                      <td>
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#reply-factory"
                          className="btn ornge-brdr-btn"
                          onClick={(e) => handleReplyClick(item)}
                        >
                          Reply
                        </button>
                        
                      </td>
                    ) : (
                      ""
                    )}
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="user-tab"
                      />
                    </td>
                  </tr>
                ))}
              {bidInfo.length == 0 && (
                <tr>
                  <td colSpan={12} className="text-center">
                    No Bids Available!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {ttlBid > 10 && (
            <nav className="pagination-block">
              <p className="total">{ttlBid} items total</p>

              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                forcePage={currentPage}
                onPageChange={handlePageChange}
                containerClassName={"pagination justify-content-center mt-4"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
              <div className="customSelect">
                <label htmlFor="">Items per page :</label>
                <div className="selectBox">
                  <select name="" id="" onChange={handleItemsChange}>
                    <option value={CONSTANT.PAGE_LIMIT}>
                      {CONSTANT.PAGE_LIMIT}
                    </option>
                    <option value="15">15</option>

                    <option value="20">20</option>
                  </select>
                </div>
              </div>
            </nav>
          )}
        </div>
      </div>
      <ShowHideRfq
        openModalFor={openModalFor}
        setOpenModalFor={setOpenModalFor}
        rfqId={rfqId}
        showHideRfqDetailsModal={showHideRfqDetailsModal}
        setShowHideRfqDetailsModal={setShowHideRfqDetailsModal}
      />

      <Modal show={showResponse} className="custom-modal" center>
        <Modal.Header>
          <h1 className="modal-title" id="set-pin-Label"></h1>
        </Modal.Header>
        <Modal.Body className="bd-rply-inf">
          {roleType == "Internal" && (
            <div>
              {" "}
              <h5>Bid Reply </h5>
              <hr />
              <label>Reply From: </label> {replyFrom}
              <br />
              <label>Subject:</label> {replyDetails?.subject ?replyDetails?.subject : 'NA'}
              <br />
              <label>Message:</label> {replyDetails?.message ? replyDetails?.message : 'NA'}
              <br />
              <label>Type:</label> {replyDetails?.type ? replyDetails?.type : 'NA'}
              <h5 className="mt-3">Bid Samples</h5>
              {replyDetails &&
                replyDetails?.bidDetail?.bidSamples?.map((item, index) => (
                  <ul key={index}>
                    <li>{item.sampleDetail.sampleName}</li>
                    <ul>
                      {item.replyPrice ? (
                        <li>
                          <b>Reply price</b> : {item.replyPrice}
                        </li>
                      ) : (
                        ""
                      )}
                      {item.replyTarget ? (
                        <li>
                          <b>Reply Target</b> : {item.replyTarget}
                        </li>
                      ) : (
                        ""
                      )}
                      {item.replyLeadTime ? (
                        <li>
                          <b>Reply lead Time</b> : {item.replyLeadTime}
                        </li>
                      ) : (
                        ""
                      )}
                      {item.replyMoq ? (
                        <li>
                          <b>Reply moq</b> : {item.replyMoq}
                        </li>
                      ) : (
                        ""
                      )}
                      {replyDetails &&
                        replyDetails?.bidDetail?.bidSamples?.map((item, i) =>
                          item?.sampleCustomFields?.map((data, j) =>
                            index == i ? (
                              <li>
                                <b>{data.label}</b> : {data.value}
                              </li>
                            ) : (
                              ""
                            )
                          )
                        )}
                    </ul>
                  </ul>
                ))}
            </div>
          )}
          {roleType == "External" && (
            <div>
              {" "}
              <h5>Bid Info </h5>
              <hr />
              <label>Bid From: </label> {bidFrom}
              <br />
              <label>Subject:</label> {replyDetails?.info?.subject}
              <br />
              <label>Message:</label> {replyDetails?.info?.message}
              <br />
              <label>Type:</label> {replyDetails?.info?.bidType}
              <br />
              <label>Payment Term:</label> {replyDetails?.info?.paymentTerm}
              <br />
              <label>Domesitc/International:</label>{" "}
              {replyDetails?.isDomestic ? "Domestic" : "International"}
              <h5 className="mt-3">Bid Samples</h5>
              {replyDetails &&
                replyDetails?.bidSamples.map((item, index) => (
                  <ul key={index}>
                    <li>{item.sampleName} :</li>
                    <ul>
                      {item.quantity ? (
                        <li>
                          {" "}
                          <b>Quantity</b> : {item.quantity}
                        </li>
                      ) : (
                        ""
                      )}
                      {item.price ? (
                        <li>
                          {" "}
                          <b>Price</b> : {item.price}
                        </li>
                      ) : (
                        ""
                      )}
                      {item.leadTime ? (
                        <li>
                          {" "}
                          <b>Lead Time</b> : {item.leadTime}
                        </li>
                      ) : (
                        ""
                      )}
                      {item.target ? (
                        <li>
                          {" "}
                          <b>Target</b> : {item.target}
                        </li>
                      ) : (
                        ""
                      )}
                      {item.moq ? (
                        <li>
                          {" "}
                          <b>Moq</b> : {item.moq}
                        </li>
                      ) : (
                        ""
                      )}
                      {replyDetails &&
                        replyDetails?.customFieldInfo?.map((item, i) =>
                          item?.sampleCustomFields?.map((data, j) =>
                            index == i ? (
                              <li>
                                <b>{data.label}</b> : {data.value}
                              </li>
                            ) : (
                              ""
                            )
                          )
                        )}
                    </ul>
                  </ul>
                ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setShowResponse(false)}
            type="button"
            className="btn inner-blue-border-btn"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
